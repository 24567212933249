import { createSelector } from "reselect";

const selectCourseMaster = (state) => state.courseMaster;

export const selectCourseMasterSingular = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.singular
);

export const selectCourseMasterPlural = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.plural
);

export const selectCourseMasterError = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.error
);

export const selectCourseMasterLoading = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.loading
);

export const selectCourseMasterUploadProgress = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.uploadProgress
);

export const selectCourseMasterFltGrade = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.fltGrade
);

export const selectCourseMasterFltCourseType = createSelector(
	[selectCourseMaster],
	(courseMaster) => courseMaster.fltCourseType
);
