import AcademicYearActionTypes from "./academic-year.types";

const INITIAL_STATE = {
	plural: [],
	singular: null,
	error: null,
	loading: false,
};

const academicYearReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_START:
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_START:
		case AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_START:
		case AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_START:
		case AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_START:
		case AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_START:
		case AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_START:
			return {
				...state,
				loading: true,
			};
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_FAILURE:
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_FAILURE:
		case AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_FAILURE:
		case AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_FAILURE:
		case AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_FAILURE:
		case AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_FAILURE:
		case AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_SUCCESS:
		case AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_SUCCESS:
		case AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_SUCCESS:
		case AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_SUCCESS:
		case AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case AcademicYearActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case AcademicYearActionTypes.CLEAR_DATA:
			return {
				...state,
				plural: [],
				singular: null,
				error: null,
			};
		default:
			return state;
	}
};

export default academicYearReducer;
