import TestimonyActionTypes from "./testimony.types";

const INITIAL_STATE = {
	plural: [],
	singular: null,
	error: null,
	loading: false,
};

const testimonyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TestimonyActionTypes.FETCH_TESTIMONIES_START:
		case TestimonyActionTypes.FETCH_TESTIMONY_START:
		case TestimonyActionTypes.CREATE_TESTIMONY_START:
		case TestimonyActionTypes.UPDATE_TESTIMONY_START:
		case TestimonyActionTypes.DELETE_TESTIMONY_START:
			return {
				...state,
				loading: true,
			};
		case TestimonyActionTypes.FETCH_TESTIMONIES_FAILURE:
		case TestimonyActionTypes.FETCH_TESTIMONY_FAILURE:
		case TestimonyActionTypes.CREATE_TESTIMONY_FAILURE:
		case TestimonyActionTypes.UPDATE_TESTIMONY_FAILURE:
		case TestimonyActionTypes.DELETE_TESTIMONY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case TestimonyActionTypes.FETCH_TESTIMONIES_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case TestimonyActionTypes.FETCH_TESTIMONY_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case TestimonyActionTypes.CREATE_TESTIMONY_SUCCESS:
		case TestimonyActionTypes.UPDATE_TESTIMONY_SUCCESS:
		case TestimonyActionTypes.DELETE_TESTIMONY_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case TestimonyActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case TestimonyActionTypes.CLEAR_DATA:
			return {
				...state,
				plural: [],
				singular: null,
				error: null,
			};
		default:
			return state;
	}
};

export default testimonyReducer;
