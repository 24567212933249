import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import { selectUserLanguage } from "redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { useStyles } from "./CustomSelect.styles";

const CustomSelect = (props) => {
	const classes = useStyles();
	const {
		id,
		label,
		name,
		options,
		control,
		rules,
		language,
		error,
		helperText,
		removeNone,
		...otherSelectProps
	} = props;

	return (
		<FormControl
			className={classes.formControl}
			error={error}
			{...otherSelectProps}
		>
			<InputLabel id={`${id}Label`}>{label}</InputLabel>
			<Controller
				rules={{
					...rules,
				}}
				control={control}
				name={name}
				defaultValue={null}
				render={(controlProps) => {
					return (
						<Select
							labelId={`${id}Label`}
							id={name}
							value={
								controlProps.value !== null &&
								controlProps.value !== undefined
									? controlProps.value
									: ""
							}
							onChange={(e) => controlProps.onChange(e)}
						>
							{!!!removeNone && (
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
							)}
							{options.map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
								>
									{
										option[
											`text${
												language !== "ID" &&
												language !== "EN"
													? "EN"
													: language
											}`
										]
									}
								</MenuItem>
							))}
						</Select>
					);
				}}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

const mapStateToProps = createStructuredSelector({
	language: selectUserLanguage,
});

const mapDispatchToProps = (dispatch) => ({
	// createTestimonyStart : (testimony) =>
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelect);
