import React from "react";
import { Grid } from "@material-ui/core";
import IOSSwitch from "components/Shared/IOSSwitch/IOSSwitch";
import { couriers } from "./App.helper";

export const tableColumns = [
	{
		title: "Child Name",
		field: "childFullname",
	},
	{
		title: "Address",
		field: "fullAddress",
	},
	{
		title: "Courier",
		field: "courierName",
	},
	{
		title: "Phone Number",
		field: "users.phone_number",
		render: (rowData) =>
			!!rowData.users ? rowData.users.phone_number : "-",
	},
	{
		title: "Status",
		field: "deliveryStatusText",
	},
	{
		title: "Resi",
		field: "resi",
		render: (rowData) => (!!rowData.resi ? rowData.resi : "-"),
	},
];

export const orderStatuses = [
	{ value: "shipped", textEN: "Shipped", textID: "Shipped" },
	{ value: "unshipped", textEN: "Unshipped", textID: "Unshipped" },
];
