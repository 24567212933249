import axios from "axios";
import { getAttrFromString } from "helpers/App.helper";
import { baseUrlNonApi } from "helpers/App.helper";
import { channel } from "redux-saga";
import { takeLatest, put, all, call, select, take } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearStudentPerformanceError,
	createStudentPerformanceFailure,
	createStudentPerformanceSuccess,
	deleteStudentPerformanceFailure,
	deleteStudentPerformanceSuccess,
	fetchAllStudentPerformanceFailure,
	fetchAllStudentPerformanceStart,
	fetchAllStudentPerformanceSuccess,
	fetchStudentPerformanceFailure,
	fetchStudentPerformancesLimitedFailure,
	fetchStudentPerformancesLimitedSuccess,
	fetchStudentPerformanceSuccess,
	setStudentPerformanceUploadProgress,
	updateStudentPerformanceFailure,
	updateStudentPerformanceSuccess,
} from "./student-performance.action";
import StudentPerformanceActionTypes from "./student-performance.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}
const courseMasterChannel = channel();

export function* watchCourseMasterChannel() {
	while (true) {
		const action = yield take(courseMasterChannel);
		yield put(action);
	}
}

export function* fetchAllStudentPerformance({ payload }) {
	const { category } = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/category/${category}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const studentPerformance = response.data.news;
		yield put(fetchAllStudentPerformanceSuccess(studentPerformance));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllStudentPerformanceFailure(errorMessage));
	}
}

export function* onFetchAllStudentPerformanceStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_START,
		fetchAllStudentPerformance
	);
}

export function* createStudentPerformance({ payload }) {
	const { data, history } = payload;
	const formData = new FormData();
	formData.append("content_detail", data.descriptionEN);
	formData.append("content_detail_indo", data.descriptionID);
	formData.append("title", data.titleEN);
	formData.append("title_indo", data.titleID);
	formData.append("category", data.category); // FIXED STUDENT_PERFORMANCE CATEGORY
	formData.append("video_url", data.video[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/news/store`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable
					? progressEvent.total
					: progressEvent.target.getResponseHeader(
							"content-length"
					  ) ||
					  progressEvent.target.getResponseHeader(
							"x-decompressed-content-length"
					  );
				if (totalLength !== null) {
					courseMasterChannel.put(
						setStudentPerformanceUploadProgress(
							Math.round(
								(progressEvent.loaded * 100) / totalLength
							)
						)
					);
				}
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(createStudentPerformanceSuccess());
		history.push("/admin/student-performances");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createStudentPerformanceFailure(errorMessage));
	}
}

export function* onCreateStudentPerformanceStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_START,
		createStudentPerformance
	);
}

export function* fetchStudentPerformance({ payload }) {
	const studentPerformanceId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/id/${studentPerformanceId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const studentPerformance = response.data.news;
		yield put(fetchStudentPerformanceSuccess(studentPerformance[0]));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchStudentPerformanceFailure(errorMessage));
	}
}

export function* onFetchStudentPerformanceStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_START,
		fetchStudentPerformance
	);
}

export function* updateStudentPerformance({ payload }) {
	const { data, history } = payload;
	const formData = new FormData();
	formData.append("news_id", data.id);
	formData.append("content_detail", data.descriptionEN);
	formData.append("content_detail_indo", data.descriptionID);
	formData.append("title", data.titleEN);
	formData.append("title_indo", data.titleID);
	formData.append("category", data.category); // FIXED STUDENT_PERFORMANCE CATEGORY
	formData.append("video_url", data.video[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/news/update`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable
					? progressEvent.total
					: progressEvent.target.getResponseHeader(
							"content-length"
					  ) ||
					  progressEvent.target.getResponseHeader(
							"x-decompressed-content-length"
					  );
				if (totalLength !== null) {
					courseMasterChannel.put(
						setStudentPerformanceUploadProgress(
							Math.round(
								(progressEvent.loaded * 100) / totalLength
							)
						)
					);
				}
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateStudentPerformanceSuccess());
		history.push("/admin/student-performances");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateStudentPerformanceFailure(errorMessage));
	}
}

export function* onUpdateStudentPerformanceStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_START,
		updateStudentPerformance
	);
}

export function* deleteStudentPerformance({ payload }) {
	const { studentPerformanceId, category } = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/destroy/${studentPerformanceId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(deleteStudentPerformanceSuccess());
		yield put(fetchAllStudentPerformanceStart({ category }));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteStudentPerformanceFailure(errorMessage));
	}
}

export function* onDeleteStudentPerformanceStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_START,
		deleteStudentPerformance
	);
}

export function* fetchStudentPerformancesLimited({ payload }) {
	const limit = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/student-performance?limit=${limit}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const studentPerformance = response.data.student_performance;
		yield put(fetchStudentPerformancesLimitedSuccess(studentPerformance));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearStudentPerformanceError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchStudentPerformancesLimitedFailure(errorMessage));
	}
}

export function* onFetchStudentPerformancesLimitedStart() {
	yield takeLatest(
		StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_START,
		fetchStudentPerformancesLimited
	);
}

export function* studentPerformanceSagas() {
	yield all([
		call(onFetchAllStudentPerformanceStart),
		call(onCreateStudentPerformanceStart),
		call(onFetchStudentPerformanceStart),
		call(onUpdateStudentPerformanceStart),
		call(onDeleteStudentPerformanceStart),
		call(watchCourseMasterChannel),
		call(onFetchStudentPerformancesLimitedStart),
	]);
}
