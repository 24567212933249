import { createSelector } from "reselect";

const selectNews = (state) => state.news;

export const selectNewsSingular = createSelector(
	[selectNews],
	(news) => news.singular
);

export const selectNewsPlural = createSelector(
	[selectNews],
	(news) => news.plural
);

export const selectNewsError = createSelector(
	[selectNews],
	(news) => news.error
);

export const selectNewsLoading = createSelector(
	[selectNews],
	(news) => news.loading
);
