import RajaOngkirActionTypes from "./raja-ongkir.types";

export const clearRajaOngkirError = () => ({
	type: RajaOngkirActionTypes.CLEAR_ERROR,
});

export const fetchProvincesStart = (handleSetValueProvince) => ({
	type: RajaOngkirActionTypes.FETCH_PROVINCES_START,
	payload: "provinces",
	handleSetValueProvince,
});

export const fetchProvincesSuccess = (provinces) => ({
	type: RajaOngkirActionTypes.FETCH_PROVINCES_SUCCESS,
	payload: provinces,
});

export const fetchProvincesFailure = (errorMessage) => ({
	type: RajaOngkirActionTypes.FETCH_PROVINCES_FAILURE,
	payload: {
		type: "provinces",
		message: errorMessage,
	},
});

export const fetchCitiesByProvinceStart = (provinceId, handleSetValueCity) => ({
	type: RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_START,
	payload: "cities",
	provinceId,
	handleSetValueCity,
});

export const fetchCitiesByProvinceSuccess = (cities) => ({
	type: RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_SUCCESS,
	payload: cities,
});

export const fetchCitiesByProvinceFailure = (errorMessage) => ({
	type: RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_FAILURE,
	payload: {
		type: "cities",
		message: errorMessage,
	},
});

export const calculateCostStart = ({ city, courier, setDeliveryCost }) => ({
	type: RajaOngkirActionTypes.CALCULATE_COST_START,
	payload: "cost",
	city,
	courier,
	setDeliveryCost,
});

export const calculateCostSuccess = (cost) => ({
	type: RajaOngkirActionTypes.CALCULATE_COST_SUCCESS,
	payload: cost,
});

export const calculateCostFailure = (errorMessage) => ({
	type: RajaOngkirActionTypes.CALCULATE_COST_FAILURE,
	payload: {
		type: "cost",
		message: errorMessage,
	},
});
