import moment from "moment";
import QRISLogo from "../assets/img/qris-logo.svg";
import VisaLogo from "../assets/img/visa-logo.png";
import MastercardLogo from "../assets/img/mastercard-logo.png";
import MandiriLogo from "../assets/img/mandiri-logo.png";

// export const baseUrlNonApi = "http://localhost:8000";
export const baseUrlNonApi = "https://api.adiwidya-school.sch.id";

export const genders = [
	{
		textEN: "Male",
		textID: "Laki-Laki",
		value: "MALE",
	},
	{
		textEN: "Female",
		textID: "Perempuan",
		value: "FEMALE",
	},
];

// return to component with number separated by commas (.)
export const numberWithCommas = (n) => {
	return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// return number with padded 0 infront
export const paddedNumber = (n, width, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const roundNumber = (n, roundValue) => {
	var num = Number(n); // The Number() only visualizes the type and is not needed
	var roundedString = num.toFixed(roundValue);
	var rounded = Number(roundedString);
	return rounded;
};

export const numberWithSuffixOrdinal = (n) => {
	return `${n}${
		["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th"
	}`;
};

export const creditCardRegex = [
	{
		card: "mastercard",
		regex: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
	},
	{
		card: "visa",
		regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
	},
	{
		card: "jcb",
		regex: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
	},
];

export const creditCardValidator = (cardNumber) => {
	const cleanNumber = cardNumber.replace(/\D+/g, "");
	for (const creditCard of creditCardRegex) {
		if (cleanNumber.match(creditCard.regex)) {
			return creditCard.card;
		}
	}
	return null;
};

export const creditCardExpiryValidator = (expiryDate) => {
	if (!!expiryDate) {
		const hasSlash = expiryDate.indexOf("/") >= 0;
		if (hasSlash) {
			const splittedString = expiryDate.split("/");
			const month = splittedString[0].replace(/\D+/g, "");
			const year = splittedString[1].replace(/\D+/g, "");

			const date = `${year}-${paddedNumber(month, 2, 0)}-01`;

			const expirationDate = moment(date, "YYYY-MM-DD", true);
			const today = moment();

			if (!expirationDate.isValid()) return false;
			if (expirationDate < today) return false;

			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const paymentIntervals = [
	{
		value: "2",
		featuresEN: [
			"Free Registration Lifetime",
			"Annual English Course Book (for a year)",
			"Annual Study Kit (for a year)",
			"English audio, video & family designed games",
			"Weekly Feedback",
			"Free Consultation",
		],
		featuresID: [
			"Biaya Pendaftaran Seumur Hidup",
			"Buku Paket Kursus (untuk satu tahun)",
			"Study Kit (untuk satu tahun)",
			"Video dan audio belajar & aktifitas keluarga",
			"Umpan Balik Mingguan",
			"Konsultasi Gratis",
		],
	},
	{
		value: "4",
		featuresEN: [
			"Free Registration Lifetime",
			"Annual English Course Book (for a year)",
			"Annual Study Kit (for a year)",
			"English audio, video & family designed games",
			"Weekly Feedback",
			"Free Consultation",
			"1 Written & Oral Tests",
			"1 Written Report Progress",
			"Bonus: ZOOM Session with Native Teacher (Grade 5-6)",
		],
		featuresID: [
			"Biaya Pendaftaran Seumur Hidup",
			"Buku Paket Kursus (untuk satu tahun)",
			"Study Kit (untuk satu tahun)",
			"Video dan audio belajar & aktifitas keluarga",
			"Umpan Balik Mingguan",
			"Konsultasi Gratis",
			"1 Ujian Tertulis & Lisan",
			"1 Pelaporan Tertulis",
			"Bonus: Bagi Grade 5-6, belajar bersama Guru Native",
		],
	},
	{
		value: "12",
		featuresEN: [
			"Free Registration Lifetime",
			"Annual English Course Book (for a year)",
			"Annual Study Kit (for a year)",
			"English audio, video & family designed games",
			"Weekly Feedback",
			"Free Consultation",
			"3 Written & Oral Tests",
			"3 Written Report Progress",
			"Bonus: ZOOM Session with Native Teacher (Grade 5-6)",
			// "FREE Study Kit Delivery fee",
			"eCertificate*",
		],
		featuresID: [
			"Biaya Pendaftaran Seumur Hidup",
			"Buku Paket Kursus (untuk satu tahun)",
			"Study Kit (untuk satu tahun)",
			"Video dan audio belajar & aktifitas keluarga",
			"Umpan Balik Mingguan",
			"Konsultasi Gratis",
			"3 Ujian Tertulis & Lisan",
			"3 Pelaporan Tertulis",
			"Bonus: Bagi Grade 5-6, belajar bersama Guru Native",
			// "Ongkir Pengiriman Gratis",
			"E-Certificate*",
		],
	},
];

export const fixedPriceList = {
	buyCourse: [
		"c08fab9e-c87b-4b4a-8e06-77bf7afbbe2a",
		"9c20d548-b7e8-43b0-aa40-5c0a7a5cfa60",
		"819d7113-f625-44c3-88b9-e17950b6bd9b",
	],
	nextGrade: [
		"c08fab9e-c87b-4b4a-8e06-77bf7afbbe2a",
		"9c20d548-b7e8-43b0-aa40-5c0a7a5cfa60",
		"819d7113-f625-44c3-88b9-e17950b6bd9b",
	],
	paymentIntervals: [
		"378d97fb-9aa6-4842-9cf0-efcd96f684be",
		"e8dfcd73-f84b-4427-ad16-b7ff08d0029b",
		"3bfcb57a-189e-4444-b6f8-ac2764f43184",
	],
};

export const paymentMethods = [
	{
		id: "QRIS",
		value: "QRIS",
		logos: [QRISLogo],
		howToStepsEN: [
			"Click Continue at the bottom right of this page.",
			"In the next screen, you will found your QR Code.",
			"Open an apps that supports payments using QRIS.",
			"Point your device’s camera to the QR Code.",
			"Continue the payment process.",
		],
		howToStepsID: [
			"Klik tombol “Continue” di bagian bawah halaman ini.",
			"Pada halaman selanjutnya Anda akan menemukan sebuah QR Code.",
			"Buka aplikasi yang mendukung pembayaran dengan scan QR Code.",
			"Arahkan kamera perangkat Anda ke QR Code tersebut.",
			"Lanjutkan proses pembayaran.",
		],
	},
	{
		id: "VA Mandiri",
		value: "Mandiri Virtual Account",
		logos: [MandiriLogo],
		howToStepsEN: [
			"Click “Continue” at the bottom right of this page.",
			"In the next screen, you will found your Mandiri Virtual Account Number.",
			"Visit Mandiri eChannel (ATM, Mobile Banking or Mandiri Internet Banking).",
			"Paste the virtual code that you copy previously.",
			"Continue Payment process.",
		],
		howToStepsID: [
			"Klik tombol “Continue” di bagian bawah halaman ini.",
			"Pada halaman selanjutnya Anda akan Nomor Mandiri Virtual Account Anda.",
			"Kunjungi eChannel Mandiri (ATM, Mobile Banking atau Internet Banking Mandiri).",
			"Tempelkan kode Virtual Account yang sudah Anda salin.",
			"Lanjutkan proses pembayaran.",
		],
	},
	{
		id: "Credit Card",
		value: "Credit Card",
		logos: [MastercardLogo, VisaLogo],
		howToStepsEN: [
			"Please input your credit card information in the next page.",
			"After you hit “Pay” you will be redirected to 3D Secure Page.",
			"Please enter the OTP code that we sent to your phone number.",
			"If the payment successful, you’ll be redirected to success page.",
			"If the payment failed, please use another credit card, or check if you input a valid OTP code.",
		],
		howToStepsID: [
			"Silahkan masukan informasi kartu kredit anda di halaman selanjutnya.",
			"Setelah menekan “Bayar” anda akan diarahkan ke halaman aman 3D.",
			"Silahkan masukan kode OTP yang sudah dikirim ke nomor telepon anda.",
			"Jika pembayaran berhasil, anda akan diarahkan ke halaman yang berhasil.",
			"Jika pembayaran gagal, silahkan gunakan kartu kredit lain, atau pastikan anda memasukkan kode OTP yang benar.",
		],
	},
];

export const couriers = [
	{
		textEN: "JNE",
		textID: "JNE",
		value: "jne",
	},
	{
		textEN: "POS Indonesia",
		textID: "POS Indonesia",
		value: "pos",
	},
	{
		textEN: "TIKI",
		textID: "TIKI",
		value: "tiki",
	},
];

export const paymentForTypes = {
	BUY_COURSE: "BUY_COURSE",
	NEXT_GRADE: "NEXT_GRADE",
	COURSE_EXTENSION: "COURSE_EXTENSION",
};

export const grades = [
	{
		textEN: 2,
		textID: 2,
		value: 2,
	},
	{
		textEN: 3,
		textID: 3,
		value: 3,
	},
	{
		textEN: 4,
		textID: 4,
		value: 4,
	},
	{
		textEN: 5,
		textID: 5,
		value: 5,
	},
	{
		textEN: 6,
		textID: 6,
		value: 6,
	},
];

export const paymentMethodTypes = {
	echannel: "VA Mandiri",
	credit_card: "Credit Card",
	gopay: "QRIS",
};

export const getAttrFromString = (str, node, attr) => {
	var regex = new RegExp("<" + node + " .*?" + attr + '="(.*?)"', "gi"),
		result,
		res = [];
	while ((result = regex.exec(str))) {
		res.push(result[1]);
	}
	return res;
};

export const courseTypes = [
	"Welcoming video",
	"Zoom meeting review",
	"Unit week",
	"Oral Test",
	"Written Test",
];

export const weeks = () => {
	let weekOption = [];
	const weekTotal = 4;

	for (let i = 0; i < weekTotal; i++) {
		weekOption.push({
			textID: `Week ${i + 1}`,
			textEN: `Week ${i + 1}`,
			value: i + 1,
		});
	}
	return weekOption;
};

export const units = () => {
	let unitOption = [];
	const unitTotal = 9;

	for (let i = 0; i < unitTotal; i++) {
		unitOption.push({
			textID: `Unit ${i + 1}`,
			textEN: `Unit ${i + 1}`,
			value: i + 1,
		});
	}
	return unitOption;
};

export const studentPerformanceCategories = [
	{
		textEN: "#WFHArt",
		textID: "#WFHArt",
		value: 2,
	},
	{
		textEN: "My Parents & Me",
		textID: "My Parents & Me",
		value: 3,
	},
	{
		textEN: "Assignments",
		textID: "Assignments",
		value: 4,
	},
];

export const languages = [
	{
		textEN: "English",
		textID: "English",
		value: "EN",
	},
	{
		textEN: "Indonesia",
		textID: "Indonesia",
		value: "ID",
	},
];
