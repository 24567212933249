import AcademicYearActionTypes from "./academic-year.types";

// Fetch all
export const fetchAcademicYearsStart = (filters) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_START,
	payload: filters,
});

export const fetchAcademicYearsSuccess = (testimonies) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_SUCCESS,
	payload: testimonies,
});

export const fetchAcademicYearsFailure = (error) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_FAILURE,
	payload: error,
});

// Fetch One
export const fetchAcademicYearStart = (academicYearId) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_START,
	payload: academicYearId,
});

export const fetchAcademicYearSuccess = (academicYear) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_SUCCESS,
	payload: academicYear,
});

export const fetchAcademicYearFailure = (error) => ({
	type: AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

// Create
export const createAcademicYearStart = ({ academicYearData, history }) => ({
	type: AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_START,
	payload: { academicYearData, history },
});

export const createAcademicYearSuccess = () => ({
	type: AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_SUCCESS,
});

export const createAcademicYearFailure = (error) => ({
	type: AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

// Update
export const updateAcademicYearStart = ({ academicYearData, history }) => ({
	type: AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_START,
	payload: { academicYearData, history },
});

export const updateAcademicYearSuccess = () => ({
	type: AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_SUCCESS,
});

export const updateAcademicYearFailure = (error) => ({
	type: AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

// Non Activate
export const deactivateAcademicYearStart = (dataId) => ({
	type: AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_START,
	payload: dataId,
});

export const deactivateAcademicYearSuccess = () => ({
	type: AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_SUCCESS,
});

export const deactivateAcademicYearFailure = (error) => ({
	type: AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

export const activateAcademicYearStart = (dataId) => ({
	type: AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_START,
	payload: dataId,
});

export const activateAcademicYearSuccess = () => ({
	type: AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_SUCCESS,
});

export const activateAcademicYearFailure = (error) => ({
	type: AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

export const clearAcademicYearError = () => ({
	type: AcademicYearActionTypes.CLEAR_ERROR,
});

export const clearAcademicYearData = () => ({
	type: AcademicYearActionTypes.CLEAR_DATA,
});

export const fetchAvailableBatchStart = () => ({
	type: AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_START,
});

export const fetchAvailableBatchSuccess = (availableBatchs) => ({
	type: AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_SUCCESS,
	payload: availableBatchs,
});

export const fetchAvailableBatchFailure = (error) => ({
	type: AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_SUCCESS,
	payload: error,
});
