import React, { useEffect, useState } from "react";
import {
	Switch,
	Route,
	Redirect,
	useHistory,
	useLocation,
} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Admin/Navbars/Navbar.js";
import Footer from "components/Admin/Footer/Footer.js";
import Sidebar from "components/Admin/Sidebar/Sidebar.js";
import NotFound from "views/Shared/ErrorPages/NotFound";

import routes from "../routes/admin.routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "../assets/img/adiwidya-logo.svg";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "redux/user/user.selector.js";
import { selectUserLoading } from "redux/user/user.selector.js";
import { checkUserSession } from "redux/user/user.action.js";
import { signOutStart } from "redux/user/user.action.js";
import { connect } from "react-redux";

let ps;

const switchRoutes = (
	<Switch>
		{routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.path}
						component={prop.component}
						key={key}
						exact
					/>
				);
			}
			return null;
		})}
		<Route
			exact
			path="/admin"
			render={() => <Redirect to="/admin/orders" />}
		/>
		<Route component={NotFound} />
	</Switch>
);

const useStyles = makeStyles(styles);

const Admin = ({
	currentUser,
	isUserLoading,
	checkUserSession,
	signOutStart,
	...rest
}) => {
	const location = useLocation();
	const [isLogoutStart, setIsLogoutStart] = useState(false);

	useEffect(() => {
		if (!!currentUser) {
			const isAdminUser = !!parseInt(`${currentUser.status}`);

			if (!isAdminUser) setIsLogoutStart(true);
		}
	}, [currentUser, location]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const container = document.querySelector(
			"#perfect-scrollbar-container"
		);
		if (!!container) container.scrollTop = 0;
	}, [location]);

	useEffect(() => {
		checkUserSession();
	}, [checkUserSession]);

	useEffect(() => {
		if (!isUserLoading && isLogoutStart) {
			signOutStart();
			setIsLogoutStart(false);
		}
	}, [isUserLoading, isLogoutStart]);

	// styles
	const classes = useStyles();
	// ref to help us initialize PerfectScrollbar on windows devices
	const mainPanel = React.createRef();

	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getRoute = () => {
		return window.location.pathname !== "/admin/maps";
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};
	// initialize and destroy the PerfectScrollbar plugin
	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);
		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	}, [mainPanel]);

	return (
		<div className={classes.wrapper}>
			<Sidebar
				routes={routes}
				logoText={"Adiwidya"}
				logo={logo}
				image={bgImage}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={"blue"}
				{...rest}
			/>
			<div className={classes.mainPanel} ref={mainPanel}>
				<Navbar
					routes={routes}
					handleDrawerToggle={handleDrawerToggle}
					{...rest}
				/>
				{/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
				{getRoute() ? (
					<div className={classes.content}>
						<div className={classes.container}>{switchRoutes}</div>
					</div>
				) : (
					<div className={classes.map}>{switchRoutes}</div>
				)}
				{getRoute() ? <Footer /> : null}
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	isUserLoading: selectUserLoading,
});
const mapDispatchToProps = (dispatch) => ({
	checkUserSession: () => dispatch(checkUserSession()),
	signOutStart: () => dispatch(signOutStart()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
