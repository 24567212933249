import RajaOngkirActionTypes from "./raja-ongkir.types";

const INITIAL_STATE = {
	error: {
		cities: null,
		provinces: null,
		cost: null,
	},
	loading: {
		cities: false,
		provinces: false,
		cost: false,
	},
	cities: [],
	provinces: [],
	cost: null,
};

const rajaOngkirReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RajaOngkirActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: {
					cities: null,
					provinces: null,
					cost: null,
				},
			};
		case RajaOngkirActionTypes.FETCH_PROVINCES_START:
		case RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_START:
		case RajaOngkirActionTypes.CALCULATE_COST_START:
			return {
				...state,
				loading: { ...state.loading, [action.payload]: true },
			};
		case RajaOngkirActionTypes.FETCH_PROVINCES_SUCCESS:
			return {
				...state,
				loading: { ...state.loading, provinces: false },
				provinces: action.payload.map((province) => {
					return {
						value: province.province_id,
						textEN: province.province,
						textID: province.province,
					};
				}),
			};
		case RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_SUCCESS:
			return {
				...state,
				loading: { ...state.loading, cities: false },
				cities: action.payload.map((city) => {
					return {
						value: city.city_id,
						textEN: `${city.type} ${city.city_name}`,
						textID: `${city.type} ${city.city_name}`,
					};
				}),
			};
		case RajaOngkirActionTypes.CALCULATE_COST_SUCCESS:
			return {
				...state,
				loading: { ...state.loading, cost: false },
				cost: action.payload,
			};
		case RajaOngkirActionTypes.FETCH_PROVINCES_FAILURE:
		case RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_FAILURE:
		case RajaOngkirActionTypes.CALCULATE_COST_FAILURE:
			const { type, message } = action.payload;
			return {
				...state,
				error: { ...state.error, [type]: message },
				loading: { ...state.loading, [type]: false },
			};
		default:
			return state;
	}
};

export default rajaOngkirReducer;
