import { Checkbox } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	imagePreview: {
		width: "508px",
		height: "577px",
	},
	ul: {
		paddingInlineStart: "24px",
		marginBottom: "8px",
		"& li": {
			marginBottom: "8px",
			fontSize: "18px",
			lineHeight: "24px",
		},
	},
	holidayLabel: {
		fontWeight: "600",
		marginTop: "12px",
	},
	holidayWeeksAddButton: {
		marginTop: "12px",
	},
	formControlLabel: {},
	removeHolidayWeeks: {
		marginLeft: "12px",
	},
}));

export const CustomCheckBox = withStyles({
	root: {
		color: "#BDBDBD",
		"&$checked": {
			color: "#2E7D32",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);
