import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
	[selectUser],
	(user) => user.currentUser
);

export const selectUserError = createSelector(
	[selectUser],
	(user) => user.error
);

export const selectUserLoading = createSelector(
	[selectUser],
	(user) => user.loading
);

export const selectUserLanguage = createSelector(
	[selectUser],
	(user) => user.language
);

export const selectUserChilds = createSelector(
	[selectUser],
	(user) => user.childs
);

export const selectUserNotGradedChilds = createSelector([selectUser], (user) =>
	user.childs.filter(
		(child) =>
			(child.paymentStatus === "settlement" ||
				child.paymentStatus === "capture") &&
			child.result === null &&
			!!!child.isAuto
	)
);

export const selectUserPageActivePayment = createSelector(
	[selectUser],
	(user) => user.userPageActivePayment
);

export const selectUserPageActiveTab = createSelector(
	[selectUser],
	(user) => user.userPageActiveTab
);
