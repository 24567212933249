import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./InfoCard.styles";

const InfoCard = (props) => {
	const { color, backgroundColor, icon, text, maxWidth } = props;
	const classes = useStyles({ color, backgroundColor, maxWidth });

	const createMarkup = () => {
		return { __html: text };
	};

	return (
		<Grid
			container
			className={classes.infoCard}
			alignContent="center"
			justify="center"
		>
			<Grid
				item
				xs={2}
				md={1}
				container
				alignContent="center"
				justify="center"
			>
				{icon}
			</Grid>
			<Grid
				item
				xs={10}
				md={11}
				dangerouslySetInnerHTML={createMarkup()}
			/>
		</Grid>
	);
};

export default InfoCard;
