import React, { useEffect, useRef, useState } from "react";
import { useStyles, CustomCheckBox } from "./CreateAcademicYear.styles";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { FormControlLabel, Grid } from "@material-ui/core";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { useHistory } from "react-router";
import CustomDatePicker from "components/Shared/CustomDatePicker/CustomDatePicker";
import moment from "moment";
import { selectAcademicYearLoading } from "redux/academic-year/academic-year.selector";
import { createAcademicYearStart } from "redux/academic-year/academic-year.action";
import Button from "components/Shared/CustomButtons/Button";

const CreateAcademicYear = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { isLoading, createAcademicYearStart } = props;
	const { handleSubmit, errors, watch, control } = useForm({
		defaultValues: {
			isNew: false,
		},
	});
	const [holidayWeekStarts, setHolidayWeekStarts] = useState([]);
	const [holidayWeekStart, setHolidayWeekStart] = useState(null);

	const startDate = useRef();
	startDate.current = watch("startDate", "");
	const startRegistrationDate = useRef();
	startRegistrationDate.current = watch("startRegistrationDate", "");

	useEffect(() => {
		if (!!startDate) {
			setHolidayWeekStarts([]);
		}
	}, [startDate]);

	const handleHolidayPickerChange = (date) => {
		if (date !== "Invalid Date") setHolidayWeekStart(date);
	};

	const addHolidayWeek = () => {
		if (!!holidayWeekStart) {
			setHolidayWeekStarts([
				...holidayWeekStarts,
				moment(holidayWeekStart).format("YYYY-MM-DD"),
			]);

			setHolidayWeekStart(null);
		}
	};

	const storeBatch = (data) => {
		if (!isLoading) {
			const finalData = { ...data, holidayWeekStarts };
			createAcademicYearStart({ academicYearData: finalData, history });
		}
	};

	const deleteHolidayWeek = (deletedDate) => {
		const deletedHolidayWeekStarts = [...holidayWeekStarts].filter(
			(date) => date !== deletedDate
		);
		setHolidayWeekStarts([...deletedHolidayWeekStarts]);
	};

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Create Batch</h4>
					</CardHeader>
					<CardBody>
						<form onSubmit={handleSubmit(storeBatch)}>
							<Grid container spacing={2}>
								<Grid item container xs={12} lg={6} spacing={2}>
									<Grid
										item
										xs={12}
										className={classes.checkBoxContainer}
									>
										<Controller
											render={({
												onChange,
												onBlur,
												ref,
											}) => (
												<FormControlLabel
													onChange={(e) =>
														onChange(
															e.target.checked
														)
													}
													onBlur={onBlur}
													inputRef={ref}
													className={
														classes.formControlLabel
													}
													control={
														<CustomCheckBox
															value={true}
														/>
													}
													label="Is new Academic Year? (Selecting this will deactivate previous batch)"
												/>
											)}
											name="isNew"
											control={control}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomDatePicker
											fullWidth
											autoOk
											minDate={moment()}
											id="startDate"
											name="startDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="Start Date"
											views={["year", "month", "date"]}
											rules={{
												required: {
													value: true,
													message:
														"Start Date are required",
												},
											}}
											error={!!errors.startDate}
											helperText={
												errors.startDate &&
												errors.startDate.message
											}
										/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<CustomDatePicker
											disabled={!!!startDate.current}
											maxDate={
												!!startDate && startDate.current
											}
											fullWidth
											autoOk
											id="startRegistrationDate"
											name="startRegistrationDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="Start Registration Date"
											views={["year", "month", "date"]}
											rules={{
												required: {
													value: true,
													message:
														"Start Registration Date are required",
												},
											}}
											error={
												!!errors.startRegistrationDate
											}
											helperText={
												errors.startRegistrationDate &&
												errors.startRegistrationDate
													.message
											}
										/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<CustomDatePicker
											disabled={
												!!!startRegistrationDate.current
											}
											minDate={
												!!startRegistrationDate &&
												startRegistrationDate.current
											}
											fullWidth
											autoOk
											id="endRegistrationDate"
											name="endRegistrationDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="End Registration Date"
											views={["year", "month", "date"]}
											rules={{
												required: {
													value: true,
													message:
														"End Registration Date are required",
												},
											}}
											error={!!errors.endRegistrationDate}
											helperText={
												errors.endRegistrationDate &&
												errors.endRegistrationDate
													.message
											}
										/>
									</Grid>
									<Grid item xs={9}>
										<CustomDatePicker
											disabled={!!!startDate.current}
											minDate={
												!!startDate && startDate.current
											}
											shouldDisableDate={(date) => {
												const formattedStartDate = moment(
													startDate.current
												).format("YYYY-MM-DD");
												const dayDiff = moment(
													date
												).diff(
													formattedStartDate,
													"days"
												);

												const isFirstWeek =
													dayDiff % 7 === 0;

												if (!isFirstWeek) {
													return true;
												} else {
													const alreadyExist =
														holidayWeekStarts.indexOf(
															moment(date).format(
																"YYYY-MM-DD"
															)
														) > -1;

													return alreadyExist;
												}
											}}
											onChange={handleHolidayPickerChange}
											value={holidayWeekStart}
											fullWidth
											autoOk
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="First Day in Holiday Week"
											views={["year", "month", "date"]}
										/>
									</Grid>
									<Grid item xs={3}>
										<Button
											disabled={
												!!!startDate.current ||
												!!!holidayWeekStart
											}
											type="button"
											variant="contained"
											color="info"
											className={
												classes.holidayWeeksAddButton
											}
											onClick={addHolidayWeek}
										>
											ADD HOLIDAY
										</Button>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									lg={6}
									className={classes.ulContainer}
								>
									<h4 className={classes.holidayLabel}>
										Holiday Weeks
									</h4>
									{holidayWeekStarts.length > 0 ? (
										<ul className={classes.ul}>
											{holidayWeekStarts.map(
												(date, idx) => (
													<li key={idx}>
														{moment(date).format(
															"DD-MM-YYYY"
														)}{" "}
														until{" "}
														{moment(date)
															.add(6, "days")
															.format(
																"DD-MM-YYYY"
															)}
														<Button
															type="button"
															variant="contained"
															color="danger"
															className={
																classes.removeHolidayWeeks
															}
															onClick={() =>
																deleteHolidayWeek(
																	date
																)
															}
														>
															DELETE
														</Button>
													</li>
												)
											)}
										</ul>
									) : (
										"There are no holiday week in this batch"
									)}
								</Grid>
								<Grid item container justify="flex-end" xs={12}>
									<Button
										type="submit"
										variant="contained"
										color="info"
										className={classes.submit}
									>
										Create Batch
									</Button>
								</Grid>
							</Grid>
						</form>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectAcademicYearLoading,
});

const mapDispatchToProps = (dispatch) => ({
	createAcademicYearStart: ({ academicYearData, history }) =>
		dispatch(createAcademicYearStart({ academicYearData, history })),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateAcademicYear);
