const CourseExtensionActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET ACTIVE CHILD ID
	SET_COURSE_EXTENSION_ACTIVE_CHILD_ID:
		"SET_COURSE_EXTENSION_ACTIVE_CHILD_ID",
	// SET ACTIVE BILLING
	SET_COURSE_EXTENSION_ACTIVE_BILLING: "SET_COURSE_EXTENSION_ACTIVE_BILLING",
	// SET PAYMENT METHOD
	SET_COURSE_EXTENSION_PAYMENT_METHOD: "SET_COURSE_EXTENSION_PAYMENT_METHOD",
	// OPEN CLOSE DRAWER
	OPEN_COURSE_EXTENSION_DRAWER: "OPEN_COURSE_EXTENSION_DRAWER",
	CLOSE_COURSE_EXTENSION_DRAWER: "CLOSE_COURSE_EXTENSION_DRAWER",
	// RESET COURSE EXTENSION
	RESET_COURSE_EXTENSION: "RESET_COURSE_EXTENSION",
	// SET PAYMENT METHOD
	SET_COURSE_EXTENSION_PAYMENT_METHOD: "SET_COURSE_EXTENSION_PAYMENT_METHOD",
	// SET ACTIVE STEP
	SET_COURSE_EXTENSION_ACTIVE_STEP: "SET_COURSE_EXTENSION_ACTIVE_STEP",
	// SET NEXT STEP FUNCTION
	SET_COURSE_EXTENSION_NEXT_STEP_FUNCTION:
		"SET_COURSE_EXTENSION_NEXT_STEP_FUNCTION",
	// SET_COURSE_EXTENSION_SHOW_PAYMENT_DETAILS
	SET_COURSE_EXTENSION_SHOW_PAYMENT_DETAILS:
		"SET_COURSE_EXTENSION_SHOW_PAYMENT_DETAILS",
	// SET_COURSE_EXTENSION_HIDE_BILL_FOOTER
	SET_COURSE_EXTENSION_HIDE_BILL_FOOTER:
		"SET_COURSE_EXTENSION_HIDE_BILL_FOOTER",
};

export default CourseExtensionActionTypes;
