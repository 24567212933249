import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexTestimony.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import {
	tableColumns,
	testimonyCategories,
} from "../../../helpers/IndexTestimony.helper";
import { useHistory } from "react-router";
import { selectTestimonyLoading } from "redux/testimony/testimony.selector";
import { fetchAllTestimoniesStart } from "redux/testimony/testimony.action";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { selectTestimonyPlural } from "redux/testimony/testimony.selector";
import { clearTestimonyData } from "redux/testimony/testimony.action";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import PillButton from "components/Public/PillButton/PillButton";
import { deleteTestimonyStart } from "redux/testimony/testimony.action";

const IndexTestimony = (props) => {
	const classes = useStyles();
	const tableRef = useRef();
	const { control, watch } = useForm();
	const category = useRef();
	category.current = watch("category", "");

	const {
		fetchAllTestimoniesStart,
		testimonies,
		isLoading,
		clearTestimonyData,
		deleteTestimonyStart,
	} = props;

	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(
		false
	);
	const [activeData, setActiveData] = useState(null);

	const handleOpenDeleteConfirmationModal = (rowData) => {
		setDeleteConfirmationModal(true);
		setActiveData(rowData);
	};
	const handleCloseDeleteConfirmationModal = () => {
		setDeleteConfirmationModal(false);
		setActiveData(null);
	};

	const handleDeleteNews = () => {
		if (!isLoading) {
			deleteTestimonyStart({
				testimonyId: activeData.testimony_id,
				category: category.current,
			});
			handleCloseDeleteConfirmationModal();
			tableRef.current.onChangePage(null, 0);
		}
	};

	useEffect(() => {
		fetchAllTestimoniesStart({ category: category.current });
	}, [category.current]);

	useEffect(() => {
		return () => {
			clearTestimonyData();
		};
	}, []);
	return (
		<Grid container>
			<CustomModal
				open={deleteConfirmationModal}
				width="40vw"
				title="Are you sure?"
				onClose={handleCloseDeleteConfirmationModal}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Yes, I am sure"
								backgroundColor="green"
								onClick={handleDeleteNews}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="No, I change my mind"
								onClick={handleCloseDeleteConfirmationModal}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				Are you sure you want delete testimony by{" "}
				<b>{activeData && `${activeData.sender}`}</b>?
			</CustomModal>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Testimonies</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="space-between">
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.selectCategoriesContainer}
							>
								<CustomSelect
									fullWidth
									id="category"
									label="Testimony Category"
									name="category"
									options={testimonyCategories}
									control={control}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
								container
								justify="flex-end"
							>
								<a href="/admin/testimonies/create">
									<Button
										className={classes.buttonOnTable}
										color="info"
									>
										CREATE NEW TESTIMONY
									</Button>
								</a>
							</Grid>
						</Grid>
						<MaterialTable
							isLoading={isLoading}
							tableRef={tableRef}
							columns={tableColumns({
								classes,
								handleOpenDeleteConfirmationModal,
							})}
							data={testimonies || []}
							title={""}
							options={{
								search: true,
								exportButton: false,
								pageSize: 10,
								actionsColumnIndex: -1,
							}}
						/>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	testimonies: selectTestimonyPlural,
	isLoading: selectTestimonyLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllTestimoniesStart: (filters) =>
		dispatch(fetchAllTestimoniesStart(filters)),
	clearTestimonyData: () => dispatch(clearTestimonyData()),
	deleteTestimonyStart: ({ testimonyId, category }) =>
		dispatch(deleteTestimonyStart({ testimonyId, category })),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexTestimony);
