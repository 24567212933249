const NotificationActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// FETCH USER NOTIFICATIONS
	FETCH_NOTIFICATIONS_START: "FETCH_NOTIFICATIONS_START",
	FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
	FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",
	// READ USER NOTIFICATION
	READ_NOTIFICATION_START: "READ_NOTIFICATION_START",
	READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
	READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",
};

export default NotificationActionTypes;
