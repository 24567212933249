/*eslint-disable*/
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Admin/Navbars/AdminNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
	const classes = useStyles();
	const location = useLocation();
	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return location.pathname.indexOf(routeName) > -1 ? true : false;
	};

	const { color, logo, image, logoText, routes } = props;

	const filteredRoutes = routes.filter((route) => route.showNavbar);
	var links = (
		<List className={classes.list}>
			{filteredRoutes.map((prop, key) => {
				var listItemClasses;

				listItemClasses = classNames({
					[" " + classes[color]]: activeRoute(prop.path),
				});
				const whiteFontClasses = classNames({
					[" " + classes.whiteFont]: activeRoute(prop.path),
				});
				return (
					<NavLink
						to={prop.path}
						className={classes.item}
						activeClassName="active"
						key={key}
					>
						<ListItem
							button
							className={classes.itemLink + listItemClasses}
						>
							{typeof prop.icon === "string" ? (
								<Icon
									className={classNames(
										classes.itemIcon,
										whiteFontClasses
									)}
								>
									{prop.icon}
								</Icon>
							) : (
								<prop.icon
									className={classNames(
										classes.itemIcon,
										whiteFontClasses
									)}
								/>
							)}
							<ListItemText
								primary={prop.name}
								className={classNames(
									classes.itemText,
									whiteFontClasses
								)}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				);
			})}
		</List>
	);
	var brand = (
		<div className={classes.logo}>
			<span className={classNames(classes.logoLink)}>
				<div className={classes.logoImage}>
					<img src={logo} alt="logo" className={classes.img} />
				</div>
				{logoText}
			</span>
		</div>
	);
	return (
		<div>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor="right"
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper),
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						<AdminNavbarLinks />
						{links}
					</div>
					{image !== undefined ? (
						<div
							className={classes.background}
							style={{ backgroundImage: "url(" + image + ")" }}
						/>
					) : null}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					anchor="left"
					variant="permanent"
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper),
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>{links}</div>
					{image !== undefined ? (
						<div
							className={classes.background}
							style={{ backgroundImage: "url(" + image + ")" }}
						/>
					) : null}
				</Drawer>
			</Hidden>
		</div>
	);
}

Sidebar.propTypes = {
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
	logo: PropTypes.string,
	image: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
};
