import { Grid } from "@material-ui/core";
import React from "react";
import FooterSection from "../FooterSection/FooterSection";
import { useStyles } from "./Footer.styles";
import { ReactComponent as InstagramLogo } from "../../../assets/img/instagram-logo.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/img/facebook-logo.svg";
import { ReactComponent as AdiwidyaLogo } from "../../../assets/img/adiwidya-logo.svg";
import useDictionary from "hooks/Dictionary.hooks";

const Footer = () => {
	const classes = useStyles();
	const translate = useDictionary();

	return (
		<footer className={classes.footer}>
			<Grid container>
				<AdiwidyaLogo className={classes.adiwidyaLogoFooter} />
				<Grid
					className={classes.footerContent}
					container
					alignContent="flex-end"
					justify="center"
				>
					<Grid
						item
						xs={12}
						md={8}
						container
						justify="center"
						alignContent="center"
					>
						<Grid item xs={12} md={3}>
							<FooterSection
								title={translate("OUR_LOCATION")}
								items={[
									{
										value:
											"Jl. Pasundan No.45\nKota Kulon, Kec. Garut Kota,\nKabupaten Garut\nJawa Barat 44112",
									},
								]}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<FooterSection
								title={translate("HELP_CENTER")}
								items={[
									{
										value: "WhatsApp",
										to: "https://wa.me/628112111369",
										target: "_blank",
									},
									{
										value: translate("EMAIL_US"),
										to: "mailto:adiwidya.course@gmail.com",
									},
								]}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<FooterSection
								title={translate("ABOUT_ADIWIDYA")}
								items={[
									{
										value: translate("NEWS"),
										to: "/news",
									},
									{
										value: translate("STORIES_OF_ADIWIDYA"),
										to: "/story-of-adiwidya",
									},
									{
										value: translate("STUDENT_PERFORMANCE"),
										to: "/student-performance",
									},
								]}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<FooterSection
								title={translate("SUPPORT")}
								items={[
									{
										value: "FAQ",
										to: "/frequently-asked-questions",
									},
								]}
							/>
						</Grid>
					</Grid>
					<Grid
						className={classes.socialMediaSection}
						container
						alignContent="center"
						justify="center"
					>
						<Grid
							item
							xs={12}
							className={classes.socialMediaSectionTitle}
						>
							{translate("FOLLOW_US_ON_SOCIAL_MEDIA")}
						</Grid>
						<Grid item xs={12} className={classes.socialMedia}>
							<a
								href="https://www.instagram.com/adiwidyacourse/"
								target="_blank"
								className={classes.socialMediaLogo}
							>
								<InstagramLogo />
							</a>
							<a
								href="https://www.facebook.com/adiwidya.adiwidya"
								target="_blank"
								className={classes.socialMediaLogo}
							>
								<FacebookLogo />
							</a>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					className={classes.copyright}
					container
					alignContent="center"
					justify="center"
				>
					(C) 2021. Adiwidya Course. All Rights Reserved. Powered by&nbsp;
					<a href="https://www.anthem.co.id/" target="_blank">
						Anthem
					</a>
					.
				</Grid>
			</Grid>
		</footer>
	);
};

export default Footer;
