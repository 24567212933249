import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { customTheme } from "./CustomDatePicker.styles";
import CalendarIcon from "../../../assets/img/calendar-icon.svg";

const CustomDatePicker = (props) => {
	const { id, name, control, rules, ...otherProps } = props;
	if (!!control) {
		return (
			<Controller
				control={control}
				name={name}
				rules={{
					...rules,
					validate: {
						value: (value) => {
							return (
								(value instanceof Date && !isNaN(value)) ||
								"Invalid date format"
							);
						},
					},
				}}
				defaultValue={null}
				render={(controlProps) => {
					return (
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<ThemeProvider theme={customTheme}>
								<KeyboardDatePicker
									{...otherProps}
									keyboardIcon={<img src={CalendarIcon} />}
									onChange={(e) => controlProps.onChange(e)}
									value={
										!!controlProps.value
											? controlProps.value
											: null
									}
								/>
							</ThemeProvider>
						</MuiPickersUtilsProvider>
					);
				}}
			/>
		);
	} else {
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<ThemeProvider theme={customTheme}>
					<KeyboardDatePicker
						{...otherProps}
						keyboardIcon={<img src={CalendarIcon} />}
					/>
				</ThemeProvider>
			</MuiPickersUtilsProvider>
		);
	}
};

export default CustomDatePicker;
