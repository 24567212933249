import React from "react";
import { Grid } from "@material-ui/core";
import PillButton from "components/Public/PillButton/PillButton";
import RegularButton from "components/Shared/CustomButtons/Button";

export const tableColumns = (props) => {
	const {
		classes,
		handleOpenNonActiveConfirmationModal,
		handleOpenActiveConfirmationModal,
	} = props;
	return [
		{
			title: "Batch",
			field: "batch",
		},
		{
			title: "Start Date",
			field: "start_date",
		},
		{
			title: "End Date",
			field: "end_date",
		},
		{
			title: "Start Registration Date",
			field: "start_registration",
		},
		{
			title: "End Registration Date",
			field: "end_registration",
		},
		{
			title: "Status",
			field: "",
			render: (rowData) => {
				return rowData.is_active ? "Active" : "Not Active";
			},
		},
		{
			title: "",
			field: "",
			cellStyle: {
				width: 300,
				minWidth: 300,
			},
			headerStyle: {
				width: 300,
				minWidth: 300,
			},
			render: (rowData) => {
				return (
					<Grid container>
						<Grid item xs={6} className={classes.buttonContainer}>
							<a
								href={`/admin/batchs/${rowData.academic_year_id}/update`}
							>
								<RegularButton type="button" color="info">
									EDIT
								</RegularButton>
							</a>
						</Grid>
						{!!rowData.is_active ? (
							<Grid
								item
								xs={6}
								className={classes.buttonContainer}
							>
								<RegularButton
									type="button"
									onClick={() =>
										handleOpenNonActiveConfirmationModal(
											rowData
										)
									}
									color="danger"
									className={classes.button}
								>
									Deactivate
								</RegularButton>
							</Grid>
						) : (
							<Grid
								item
								xs={6}
								className={classes.buttonContainer}
							>
								<RegularButton
									type="button"
									onClick={() =>
										handleOpenActiveConfirmationModal(
											rowData
										)
									}
									color="info"
									className={classes.button}
								>
									Activate
								</RegularButton>
							</Grid>
						)}
					</Grid>
				);
			},
		},
	];
};
