const BuyCourseActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET ACTIVE CHILD ID
	SET_BUY_COURSE_ACTIVE_CHILD_ID: "SET_BUY_COURSE_ACTIVE_CHILD_ID",
	// SET PAYMENT INTERVAL
	SET_BUY_COURSE_PAYMENT_INTERVAL: "SET_BUY_COURSE_PAYMENT_INTERVAL",
	// SET PAYMENT METHOD
	SET_BUY_COURSE_PAYMENT_METHOD: "SET_BUY_COURSE_PAYMENT_METHOD",
	// SET DELIVERY COST
	SET_BUY_COURSE_DELIVERY_COST: "SET_BUY_COURSE_DELIVERY_COST",
	// OPEN CLOSE DRAWER
	OPEN_BUY_COURSE_DRAWER: "OPEN_BUY_COURSE_DRAWER",
	CLOSE_BUY_COURSE_DRAWER: "CLOSE_BUY_COURSE_DRAWER",
	// RESET BUY COURSE
	RESET_BUY_COURSE: "RESET_BUY_COURSE",
	// SET ACTIVE STEP
	SET_BUY_COURSE_ACTIVE_STEP: "SET_BUY_COURSE_ACTIVE_STEP",
	// SET NEXT STEP FUNCTION
	SET_BUY_COURSE_NEXT_STEP_FUNCTION: "SET_BUY_COURSE_NEXT_STEP_FUNCTION",
	// SET_BUY_COURSE_SHOW_PAYMENT_DETAILS
	SET_BUY_COURSE_SHOW_PAYMENT_DETAILS: "SET_BUY_COURSE_SHOW_PAYMENT_DETAILS",
	// SET_BUY_COURSE_HIDE_BILL_FOOTER
	SET_BUY_COURSE_HIDE_BILL_FOOTER: "SET_BUY_COURSE_HIDE_BILL_FOOTER",
	// SET_BUY_COURSE_BATCH
	SET_BUY_COURSE_BATCH: "SET_BUY_COURSE_BATCH",
	// SET FIXED PRICE LIST
	SET_BUY_COURSE_FIXED_PRICE_LIST: "SET_BUY_COURSE_FIXED_PRICE_LIST",
	// SET BUY COURSE COURIER
	SET_BUY_COURSE_COURIER: "SET_BUY_COURSE_COURIER",
	// SET PAYMENT INTERVAL DETAIL OPENED
	SET_BUY_COURSE_PAYMENT_INTERVAL_DETAIL:
		"SET_BUY_COURSE_PAYMENT_INTERVAL_DETAIL",
	// SET PAYMENT INTERVAL CURRENT SLIDE
	SET_BUY_COURSE_PAYMENT_INTERVAL_CURRENT_SLIDE:
		"SET_BUY_COURSE_PAYMENT_INTERVAL_CURRENT_SLIDE",
};

export default BuyCourseActionTypes;
