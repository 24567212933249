import OrderActionTypes from "./order.types";

// Fetch all
export const fetchAllOrdersStart = (status) => ({
	type: OrderActionTypes.FETCH_ORDERS_START,
	payload: status,
});

export const fetchAllOrdersSuccess = (orders) => ({
	type: OrderActionTypes.FETCH_ORDERS_SUCCESS,
	payload: orders,
});

export const fetchAllOrdersFailure = (error) => ({
	type: OrderActionTypes.FETCH_ORDERS_FAILURE,
	payload: error,
});

// Fetch One
export const fetchOrderStart = (filters) => ({
	type: OrderActionTypes.FETCH_ORDER_START,
	payload: filters,
});

export const fetchOrderSuccess = (testimony) => ({
	type: OrderActionTypes.FETCH_ORDER_SUCCESS,
	payload: testimony,
});

export const fetchOrderFailure = (error) => ({
	type: OrderActionTypes.FETCH_ORDER_FAILURE,
	payload: error,
});

// Update
export const updateOrderStart = (orderInfo) => ({
	type: OrderActionTypes.UPDATE_ORDER_START,
	payload: orderInfo,
});

export const updateOrderSuccess = () => ({
	type: OrderActionTypes.UPDATE_ORDER_SUCCESS,
});

export const updateOrderFailure = (error) => ({
	type: OrderActionTypes.UPDATE_ORDER_FAILURE,
	payload: error,
});

export const clearOrderError = () => ({
	type: OrderActionTypes.CLEAR_ERROR,
});

export const clearOrderData = () => ({
	type: OrderActionTypes.CLEAR_DATA,
});
