import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "flex-end",
		},
	},
	pillButton: {
		color: "white",
		margin: "4px",
		padding: "18px 24px",
		minWidth: "130px",
		fontSize : '16px',

		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
}));
