import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import { setDeliveryCost } from "redux/buy-course/buy-course.action";
import { selectBuyCoursePaymentInterval } from "redux/buy-course/buy-course.selector";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	calculateCostFailure,
	calculateCostSuccess,
	clearRajaOngkirError,
	fetchCitiesByProvinceFailure,
	fetchCitiesByProvinceSuccess,
	fetchProvincesFailure,
	fetchProvincesSuccess,
} from "./raja-ongkir.action";
import RajaOngkirActionTypes from "./raja-ongkir.types";

const rajaOngkirAPIKey = process.env.REACT_APP_RAJA_ONGKIR_API_KEY;
let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchProvinces({ handleSetValueProvince }) {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/rajaongkir/province`,
		});

		if (response.status != 200) {
			throw new Error(
				"Oops.. Something went wrong please try again later."
			);
		}
		const provinces = response.data.rajaongkir.results;
		yield put(fetchProvincesSuccess(provinces));
		if (!!handleSetValueProvince) {
			handleSetValueProvince();
		}
	} catch (error) {
		const snackbarData = {
			message: error.response.data.message,
			color: "error",
			place: "bl",
			dispatchActions: [clearRajaOngkirError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchProvincesFailure(error.message));
	}
}

export function* onFetchProvincesStart() {
	yield takeLatest(
		RajaOngkirActionTypes.FETCH_PROVINCES_START,
		fetchProvinces
	);
}

export function* fetchCitiesByProvince({ provinceId, handleSetValueCity }) {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/rajaongkir/city/${provinceId}`,
		});

		if (response.status != 200) {
			throw new Error(
				"Oops.. Something went wrong please try again later."
			);
		}
		const cities = response.data.rajaongkir.results;
		yield put(fetchCitiesByProvinceSuccess(cities));
		if (!!handleSetValueCity) {
			handleSetValueCity();
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearRajaOngkirError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCitiesByProvinceFailure(errorMessage));
	}
}

export function* onFetchCitiesByProvinceStart() {
	yield takeLatest(
		RajaOngkirActionTypes.FETCH_CITIES_BY_PROVINCE_START,
		fetchCitiesByProvince
	);
}

export function* calculateCost({ city, courier, setDeliveryCost }) {
	const origin = 126; // Kabupaten Garut, Jawa Barat
	const weight = 2000; // 2kg == 2000gr
	// const paymentInterval = yield select(selectBuyCoursePaymentInterval);
	// FREE ONGKIR
	// if (paymentInterval === "12") {
	// 	yield put(setDeliveryCost(0));
	// 	yield put(calculateCostSuccess(0));
	// } else {
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/rajaongkir/cost`,
			data: {
				origin,
				weight,
				courier,
				destination: city,
			},
		});

		if (response.status != 200) {
			throw new Error(
				"Oops.. Something went wrong please try again later."
			);
		}
		const result = response.data.rajaongkir.results[0];
		const serviceUsed = ["REG", "Paket Kilat Khusus", "CTC"];

		// if same city
		if (origin === city) {
			const cost = result.costs[0].cost[0].value;
			yield setDeliveryCost(cost);
			yield put(calculateCostSuccess(cost));
		} else {
			const service = result.costs.find((cost) =>
				serviceUsed.includes(cost.service)
			);
			yield setDeliveryCost(service.cost[0].value);
			yield put(calculateCostSuccess(service.cost[0].value));
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearRajaOngkirError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(calculateCostFailure(errorMessage));
	}
	// }
}

export function* onCalculateCostStart() {
	yield takeLatest(RajaOngkirActionTypes.CALCULATE_COST_START, calculateCost);
}

export function* rajaOngkirSagas() {
	yield all([
		call(onFetchProvincesStart),
		call(onFetchCitiesByProvinceStart),
		call(onCalculateCostStart),
	]);
}
