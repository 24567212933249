import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import Admin from "layouts/Admin.js";
import { connect } from "react-redux";
import { selectCurrentUser } from "redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { checkUserSession } from "redux/user/user.action";
import Public from "layouts/Public";
import LoginAdmin from "views/Admin/LoginAdmin/LoginAdmin";
import CustomSnackbar from "components/Shared/CustomSnackbar/CustomSnackbar";
import { Suspense } from "react";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";

const App = (props) => {
	const { checkUserSession } = props;
	useEffect(() => {
		checkUserSession();
	}, [checkUserSession]);
	return (
		<>
			<CustomSnackbar />
			<Suspense
				fallback={
					<LoadingBackdrop
						color="black"
						backgroundColor="white"
						open={true}
					/>
				}
			>
				<Switch>
					<Route
						exact
						path="/admin/login"
						render={() =>
							props.currentUser ? (
								<Redirect to="/admin" />
							) : (
								<LoginAdmin />
							)
						}
					/>
					<Route
						path="/admin"
						render={() =>
							props.currentUser ? (
								<Admin />
							) : (
								<Redirect to="/admin/login" />
							)
						}
					/>
					<Route path="/" component={Public} />
				</Switch>
			</Suspense>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	checkUserSession: () => dispatch(checkUserSession()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
