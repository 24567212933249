import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexStudentPerformance.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableColumns } from "../../../helpers/IndexStudentPerformance.helper";
import { fetchAllStudentPerformanceStart } from "redux/student-performance/student-performance.action";
import { clearStudentPerformanceError } from "redux/student-performance/student-performance.action";
import { selectStudentPerformancePlural } from "redux/student-performance/student-performance.selector";
import { selectStudentPerformanceError } from "redux/student-performance/student-performance.selector";
import { selectStudentPerformanceLoading } from "redux/student-performance/student-performance.selector";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import PillButton from "components/Public/PillButton/PillButton";
import { deleteStudentPerformanceStart } from "redux/student-performance/student-performance.action";
import { studentPerformanceCategories } from "helpers/App.helper";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";

const IndexStudentPerformance = (props) => {
	const classes = useStyles();
	const tableRef = useRef();
	const {
		fetchAllStudentPerformanceStart,
		studentPerformance,
		isLoading,
		deleteStudentPerformanceStart,
	} = props;
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(
		false
	);
	const [activeData, setActiveData] = useState(null);
	const { control, watch } = useForm();
	const category = useRef();
	category.current = watch("category", "");

	const handleOpenDeleteConfirmationModal = (rowData) => {
		setDeleteConfirmationModal(true);
		setActiveData(rowData);
	};
	const handleCloseDeleteConfirmationModal = () => {
		setDeleteConfirmationModal(false);
		setActiveData(null);
	};

	const handleDeleteStudentPerformance = () => {
		if (!isLoading) {
			deleteStudentPerformanceStart({
				studentPerformanceId: activeData.news_id,
				category: activeData.category,
			});
			handleCloseDeleteConfirmationModal();
			tableRef.current.onChangePage(null, 0);
		}
	};

	useEffect(() => {
		if (!!category.current)
			fetchAllStudentPerformanceStart({ category: category.current });
	}, [category.current]);

	return (
		<Grid container>
			<CustomModal
				open={deleteConfirmationModal}
				width="40vw"
				title="Are you sure?"
				onClose={handleCloseDeleteConfirmationModal}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Yes, I am sure"
								backgroundColor="green"
								onClick={handleDeleteStudentPerformance}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="No, I change my mind"
								onClick={handleCloseDeleteConfirmationModal}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				Are you sure you want delete student performance with title{" "}
				<b>{activeData && `${activeData.title}`}</b>?
			</CustomModal>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Student Performance</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="space-between">
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.selectContainer}
							>
								<CustomSelect
									fullWidth
									id="category"
									label="Student Performance Category"
									name="category"
									options={studentPerformanceCategories}
									control={control}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
								container
								justify="flex-end"
							>
								<a href="/admin/student-performances/create">
									<Button
										color="info"
										className={classes.buttonOnTable}
									>
										CREATE NEW STUDENT PERFORMANCE
									</Button>
								</a>
							</Grid>
						</Grid>
						{!!category.current ? (
							<MaterialTable
								tableRef={tableRef}
								isLoading={isLoading}
								columns={tableColumns({
									classes,
									handleOpenDeleteConfirmationModal,
								})}
								data={studentPerformance || []}
								title={""}
								options={{
									search: true,
									exportButton: false,
									pageSize: 10,
									actionsColumnIndex: -1,
								}}
							/>
						) : (
							<h4 className={classes.emptyLabel}>
								Please select one of the student performance
								category
							</h4>
						)}
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	studentPerformance: selectStudentPerformancePlural,
	studentPerformanceError: selectStudentPerformanceError,
	isLoading: selectStudentPerformanceLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllStudentPerformanceStart: (filters) =>
		dispatch(fetchAllStudentPerformanceStart(filters)),
	clearStudentPerformanceError: (onClose) =>
		dispatch(clearStudentPerformanceError(onClose)),
	deleteStudentPerformanceStart: ({ studentPerformanceId, category }) =>
		dispatch(
			deleteStudentPerformanceStart({ studentPerformanceId, category })
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexStudentPerformance);
