import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import testimonyReducer from "./testimony/testimony.reducer";
import newsReducer from "./news/news.reducer";
import buyCourseReducer from "./buy-course/buy-course.reducer";
import rajaOngkirReducer from "./raja-ongkir/raja-ongkir.reducer";
import childReducer from "./child/child.reducer";
import courseExtensionReducer from "./course-extension/course-extension.reducer";
import placementTestReducer from "./placement-test/placement-test.reducer";
import snackbarReducer from "./snackbar/snackbar.reducer";
import orderReducer from "./order/order.reducer";
import paymentReducer from "./payment/payment.reducer";
import nextGradeReducer from "./next-grade/next-grade.reducer";
import academicYearReducer from "./academic-year/academic-year.reducer";
import courseMasterReducer from "./course-master/course-master.reducer";
import notificationReducer from "./notification/notification.reducer";
import studentPerformanceReducer from "./student-performance/student-performance.reducer";

const persistConfig = {
	key: "shakespeare",
	storage,
	blacklist: [
		"user",
		"testimony",
		"news",
		"buyCourse",
		"rajaOngkir",
		"child",
		"courseExtension",
		"placementTest",
		"snackbar",
		"order",
		"payment",
		"nextGrade",
		"academicYear",
		"courseMaster",
		"notification",
		"studentPerformance",
	],
};

const userPersistConfig = {
	key: "shakespeare.user",
	storage,
	whitelist: ["currentUser", "language"],
};

const placementTestPersistConfig = {
	key: "shakespeare.placementTest",
	storage,
	whitelist: ["finishTime", "answers", "activePart"],
};

const courseMasterPersistConfig = {
	key: "shakespeare.courseMaster",
	storage,
	whitelist: ["fltGrade", "fltCourseType"],
};

const rootReducer = combineReducers({
	user: persistReducer(userPersistConfig, userReducer),
	testimony: testimonyReducer,
	news: newsReducer,
	buyCourse: buyCourseReducer,
	rajaOngkir: rajaOngkirReducer,
	child: childReducer,
	courseExtension: courseExtensionReducer,
	placementTest: persistReducer(
		placementTestPersistConfig,
		placementTestReducer
	),
	snackbar: snackbarReducer,
	order: orderReducer,
	payment: paymentReducer,
	nextGrade: nextGradeReducer,
	academicYear: academicYearReducer,
	courseMaster: persistReducer(
		courseMasterPersistConfig,
		courseMasterReducer
	),
	notification: notificationReducer,
	studentPerformance: studentPerformanceReducer,
});

export default persistReducer(persistConfig, rootReducer);
