import NewsActionTypes from "./news.types";

const INITIAL_STATE = {
	singular: null,
	plural: [],
	error: null,
	loading: false,
};

const newsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NewsActionTypes.FETCH_ALL_NEWS_START:
		case NewsActionTypes.FETCH_NEWS_START:
		case NewsActionTypes.CREATE_NEWS_START:
		case NewsActionTypes.UPDATE_NEWS_START:
		case NewsActionTypes.DELETE_NEWS_START:
		case NewsActionTypes.UPLOAD_TEMP_FILE_START:
			return {
				...state,
				loading: true,
			};
		case NewsActionTypes.FETCH_ALL_NEWS_FAILURE:
		case NewsActionTypes.FETCH_NEWS_FAILURE:
		case NewsActionTypes.CREATE_NEWS_FAILURE:
		case NewsActionTypes.UPDATE_NEWS_FAILURE:
		case NewsActionTypes.DELETE_NEWS_FAILURE:
		case NewsActionTypes.UPLOAD_TEMP_FILE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case NewsActionTypes.FETCH_ALL_NEWS_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case NewsActionTypes.FETCH_NEWS_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case NewsActionTypes.CREATE_NEWS_SUCCESS:
		case NewsActionTypes.UPDATE_NEWS_SUCCESS:
		case NewsActionTypes.DELETE_NEWS_SUCCESS:
		case NewsActionTypes.UPLOAD_TEMP_FILE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case NewsActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
};

export default newsReducer;
