import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	footer: {
		position: "absolute",
		bottom: "0",
		width: "100%",
		height: "2.5em",
	},
	adiwidyaLogoFooter: {
		width: "168px",
		height: "168px",
		position: "absolute",
		marginLeft: "auto",
		marginRight: "auto",
		left: 0,
		right: 0,
		top: "-84px",
	},
	footerContent: {
		background: "#E9BD35",
		height: "437px",
		[theme.breakpoints.down("sm")]: {
			height: "850px",
		},
		[theme.breakpoints.down("md")]: {
			marginTop: "20px",
		},
	},
	copyright: {
		background: "#D29F00",
		height: "59px",
		fontSize: "12px",
	},
	socialMediaSection: {
		marginTop: "58px",
		marginBottom: "31px",
	},
	socialMediaSectionTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "700",
	},
	socialMedia: {
		display: "flex",
		justifyContent: "center",
		aligntContent: "center",
	},
	socialMediaLogo: {
		marginTop: "22px",
		marginLeft: "24px",
		"&:nth-child(1)": {
			marginLeft: "0",
		},
	},
}));
