import moment from "moment";
import { createSelector } from "reselect";

const selectChild = (state) => state.child;

export const selectSelectedChild = createSelector(
	[selectChild],
	(child) => child.selectedChild
);

export const selectChildError = createSelector(
	[selectChild],
	(child) => child.error
);

export const selectChildLoading = createSelector(
	[selectChild],
	(child) => child.loading
);

export const selectChildBillingProgress = createSelector(
	[selectChild],
	(child) => child.billingProgress
);

export const selectChildPlural = createSelector(
	[selectChild],
	(child) => child.plural
);

export const selectChildCourseLoading = createSelector(
	[selectChild],
	(child) => child.courseLoading
);

export const selectChildCourses = createSelector(
	[selectChild],
	(child) => child.courses
);

export const selectChildCourse = createSelector(
	[selectChild],
	(child) => child.course
);

export const selectChildNextCourse = createSelector(
	[selectChild],
	(child) => child.nextCourse
);

export const selectChildActiveBilling = createSelector(
	[selectChild],
	(child) => child.activeBilling
);

export const selectChildGrade = createSelector([selectSelectedChild], (child) =>
	parseInt(`${child.grade}`)
);

export const selectChildEligibleNextGrade = createSelector(
	[selectSelectedChild],
	(child) => child.eligibleNextGrade
);

export const selectLastAcademicYearDate = createSelector(
	[selectSelectedChild],
	(child) => child.latestAcademicYear.end_date
);