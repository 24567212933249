import React from "react";
import { Grid } from "@material-ui/core";
import RegularButton from "components/Shared/CustomButtons/Button";

export const tableColumns = (props) => {
	const { classes, handleOpenDeleteConfirmationModal } = props;
	return [
		{
			title: "Title",
			field: "title",
			cellStyle: {
				width: 400,
				minWidth: 400,
			},
			headerStyle: {
				width: 400,
				minWidth: 400,
			},
		},
		{
			title: "Description",
			field: "content_detail",
			cellStyle: {
				width: 400,
				minWidth: 400,
			},
			headerStyle: {
				width: 400,
				minWidth: 400,
			},
		},
		{
			title: "",
			field: "",
			render: (rowData) => {
				return (
					<Grid container justify="center">
						<Grid item className={classes.buttonContainer}>
							<a
								href={`/admin/student-performances/${rowData.news_id}/update`}
							>
								<RegularButton type="button" color="info">
									Edit
								</RegularButton>
							</a>
						</Grid>
						<Grid item className={classes.buttonContainer}>
							<RegularButton
								type="button"
								onClick={() =>
									handleOpenDeleteConfirmationModal(rowData)
								}
								color="danger"
							>
								Delete
							</RegularButton>
						</Grid>
					</Grid>
				);
			},
		},
	];
};
