import PaymentActionTypes from "./payment.types";

export const clearPaymentError = () => ({
	type: PaymentActionTypes.CLEAR_ERROR,
});

export const setPaymentFor = (paymentFor) => ({
	type: PaymentActionTypes.SET_PAYMENT_FOR,
	payload: paymentFor,
});

export const setCreditCard = ({
	creditCardNumber,
	cardHolderName,
	expiryDate,
	cvv,
}) => ({
	type: PaymentActionTypes.SET_CREDIT_CARD,
	payload: {
		creditCardNumber,
		cardHolderName,
		expiryDate,
		cvv,
	},
});

export const fetchPriceListStart = () => ({
	type: PaymentActionTypes.FETCH_PRICE_LIST_START,
});

export const fetchPriceListSuccess = (priceList) => ({
	type: PaymentActionTypes.FETCH_PRICE_LIST_SUCCESS,
	payload: priceList,
});

export const fetchPriceListFailure = (error) => ({
	type: PaymentActionTypes.FETCH_PRICE_LIST_FAILURE,
	payload: error,
});

export const storePaymentStart = (paymentMethod) => ({
	type: PaymentActionTypes.STORE_PAYMENT_START,
	payload: paymentMethod,
});

export const storePaymentSuccess = (successResponse) => ({
	type: PaymentActionTypes.STORE_PAYMENT_SUCCESS,
	payload: successResponse,
});

export const storePaymentFailure = (error) => ({
	type: PaymentActionTypes.STORE_PAYMENT_FAILURE,
	payload: error,
});

export const setCreditCardToken = (token) => ({
	type: PaymentActionTypes.SET_CREDIT_CARD_TOKEN,
	payload: token,
});

export const setCreditCardRedirectUrl = (url) => ({
	type: PaymentActionTypes.SET_CREDIT_CARD_REDIRECT_URL,
	payload: url,
});

export const setOpen3dsAuthentication = (isOpen) => ({
	type: PaymentActionTypes.SET_OPEN_3DS_AUTHENTICATION,
	payload: isOpen,
});

export const checkPaymentStart = (orderId) => ({
	type: PaymentActionTypes.CHECK_PAYMENT_START,
	payload: orderId,
});

export const checkPaymentSuccess = ({ paymentStatus, isAutoGrade }) => ({
	type: PaymentActionTypes.CHECK_PAYMENT_SUCCESS,
	payload: { paymentStatus, isAutoGrade },
});

export const checkPaymentFailure = (error) => ({
	type: PaymentActionTypes.CHECK_PAYMENT_FAILURE,
	payload: error,
});

export const sendPaymentNotificationStart = (response) => ({
	type: PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_START,
	payload: response,
});

export const sendPaymentNotificationSuccess = () => ({
	type: PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_SUCCESS,
});

export const sendPaymentNotificationFailure = (error) => ({
	type: PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_FAILURE,
	payload: error,
});

export const setOrderId = (orderId) => ({
	type: PaymentActionTypes.SET_ORDER_ID,
	payload: orderId,
});

export const retryPaymentStart = ({ paymentMethod, bill }) => ({
	type: PaymentActionTypes.RETRY_PAYMENT_START,
	payload: { paymentMethod, bill },
});

export const retryPaymentSuccess = (successResponse) => ({
	type: PaymentActionTypes.RETRY_PAYMENT_SUCCESS,
	payload: successResponse,
});

export const retryPaymentFailure = (error) => ({
	type: PaymentActionTypes.RETRY_PAYMENT_FAILURE,
	payload: error,
});

export const fetchPaymentHistoriesStart = () => ({
	type: PaymentActionTypes.FETCH_PAYMENT_HISTORIES_START,
});

export const fetchPaymentHistoriesSuccess = (paymentHistories) => ({
	type: PaymentActionTypes.FETCH_PAYMENT_HISTORIES_SUCCESS,
	payload: paymentHistories,
});

export const fetchPaymentHistoriesFailure = (error) => ({
	type: PaymentActionTypes.FETCH_PAYMENT_HISTORIES_FAILURE,
	payload: error,
});

export const setPaymentSuccess = (paymentSuccess) => ({
	type: PaymentActionTypes.SET_PAYMENT_SUCCESS,
	payload: paymentSuccess,
});

