import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexCourseMaster.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableColumns } from "../../../helpers/IndexCourseMaster.helper";
import { useHistory } from "react-router";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { grades } from "helpers/App.helper";
import { selectCourseMasterLoading } from "redux/course-master/course-master.selector";
import { selectCourseMasterPlural } from "redux/course-master/course-master.selector";
import { fetchCourseMastersStart } from "redux/course-master/course-master.action";
import { courseTypes } from "helpers/App.helper";
import { deleteCourseMasterStart } from "redux/course-master/course-master.action";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import PillButton from "components/Public/PillButton/PillButton";
import { swapCourseMasterStart } from "redux/course-master/course-master.action";
import { setCourseMasterFilter } from "redux/course-master/course-master.action";
import { selectCourseMasterFltCourseType } from "redux/course-master/course-master.selector";
import { selectCourseMasterFltGrade } from "redux/course-master/course-master.selector";

const IndexCourseMaster = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const tableRef = useRef();
	const {
		isCourseMasterLoading,
		courses,
		fetchCourseMastersStart,
		deleteCourseMasterStart,
		swapCourseMasterStart,
		setCourseMasterFilter,
		fltGrade,
		fltCourseType,
	} = props;
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(
		false
	);
	const [swapConfirmationModal, setSwapConfirmationModal] = useState(false);
	const [activeData, setActiveData] = useState(null);
	const [secondActiveData, setSecondActiveData] = useState(null);
	const [isSwapActive, setIsSwapActive] = useState(null);

	const { control, watch } = useForm({
		defaultValues: {
			grade: fltGrade,
			courseType: fltCourseType,
		},
	});
	const grade = useRef();
	grade.current = watch("grade", fltGrade);
	const courseType = useRef();
	courseType.current = watch("courseType", fltCourseType);

	const handleActivateSwap = (rowData) => {
		setIsSwapActive(true);
		setActiveData(rowData);
	};
	const handleDeactivateSwap = () => {
		setIsSwapActive(false);
		setActiveData(null);
	};

	const handleOpenDeleteConfirmationModal = (rowData) => {
		setDeleteConfirmationModal(true);
		setActiveData(rowData);
	};
	const handleCloseDeleteConfirmationModal = () => {
		setDeleteConfirmationModal(false);
		setActiveData(null);
	};

	const handleOpenSwapConfirmationModal = (rowData) => {
		setSwapConfirmationModal(true);
		setSecondActiveData(rowData);
	};
	const handleCloseSwapConfirmationModal = () => {
		setSwapConfirmationModal(false);
		setSecondActiveData(null);
	};

	const handleSwap = () => {
		if (!isCourseMasterLoading) {
			swapCourseMasterStart({
				first: activeData.master_course_id,
				second: secondActiveData.master_course_id,
			});
			handleCloseSwapConfirmationModal();
			handleDeactivateSwap();
		}
	};

	const handleDeleteCourseMaster = () => {
		if (!isCourseMasterLoading) {
			deleteCourseMasterStart(activeData.master_course_id);
			handleCloseDeleteConfirmationModal();
			tableRef.current.onChangePage(null, 0);
		}
	};

	const handleYesConfirmation = () => {
		if (swapConfirmationModal) handleSwap();
		if (deleteConfirmationModal) handleDeleteCourseMaster();
	};

	const handleNoConfirmation = () => {
		if (swapConfirmationModal) handleCloseSwapConfirmationModal();
		if (deleteConfirmationModal) handleCloseDeleteConfirmationModal();
	};

	useEffect(() => {
		fetchCourseMastersStart({
			type: courseType.current !== "" ? courseType.current : undefined,
			grade: !!grade.current ? grade.current : undefined,
		});
		setCourseMasterFilter({
			courseType: courseType.current,
			grade: grade.current,
		});
		tableRef.current.onChangePage(null, 0);
	}, [grade.current, courseType.current]);

	return (
		<Grid container>
			<CustomModal
				open={deleteConfirmationModal || swapConfirmationModal}
				width="40vw"
				title="Are you sure?"
				onClose={handleNoConfirmation}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Yes, I am sure"
								backgroundColor="green"
								onClick={handleYesConfirmation}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="No, I change my mind"
								onClick={handleNoConfirmation}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				{deleteConfirmationModal && (
					<>
						Are you sure you want delete course master{" "}
						<b>{activeData && `${activeData.title}`}</b>?
					</>
				)}
				{swapConfirmationModal && (
					<>
						Are you sure you want to swap course master{" "}
						<b>{activeData && `${activeData.title}`}</b> with{" "}
						<b>{secondActiveData && `${secondActiveData.title}`}</b>
						?
					</>
				)}
			</CustomModal>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Course Master Data</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="space-between">
							<Grid container item xs={12} lg={8}>
								<Grid
									item
									xs={12}
									lg={4}
									className={classes.selectContainer}
								>
									<CustomSelect
										margin="normal"
										fullWidth
										id="grade"
										label="Grade"
										name="grade"
										options={grades}
										control={control}
										value={fltGrade}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									lg={4}
									className={classes.selectContainer}
								>
									<CustomSelect
										margin="normal"
										fullWidth
										id="courseType"
										label="Course Type"
										name="courseType"
										options={courseTypes.map(
											(courseType, idx) => ({
												textEN: courseType,
												textID: courseType,
												value: idx,
											})
										)}
										value={fltCourseType}
										control={control}
									/>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
								lg={4}
								container
								justify="flex-end"
							>
								<a href="/admin/courses/create">
									<Button
										className={classes.buttonOnTable}
										color="info"
									>
										CREATE NEW COURSE MASTER DATA
									</Button>
								</a>
							</Grid>
						</Grid>
						<MaterialTable
							tableRef={tableRef}
							isLoading={isCourseMasterLoading}
							columns={tableColumns({
								classes,
								handleOpenDeleteConfirmationModal,
								isSwapActive,
								activeData,
								handleActivateSwap,
								handleDeactivateSwap,
								handleOpenSwapConfirmationModal,
							})}
							data={courses}
							title={""}
							options={{
								search: true,
								exportButton: false,
								pageSize: 10,
							}}
						/>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isCourseMasterLoading: selectCourseMasterLoading,
	courses: selectCourseMasterPlural,
	fltGrade: selectCourseMasterFltGrade,
	fltCourseType: selectCourseMasterFltCourseType,
});

const mapDispatchToProps = (dispatch) => ({
	fetchCourseMastersStart: (filters) =>
		dispatch(fetchCourseMastersStart(filters)),
	deleteCourseMasterStart: (courseId) =>
		dispatch(deleteCourseMasterStart(courseId)),
	swapCourseMasterStart: ({ first, second }) =>
		dispatch(swapCourseMasterStart({ first, second })),
	setCourseMasterFilter: (filter) => dispatch(setCourseMasterFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexCourseMaster);
