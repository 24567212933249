import PlacementTestActionTypes from "./placement-test.types";

const INITIAL_STATE = {
	error: null,
	isLoading: false,
	placementTestData: null,
	activeChildId: null,
	activePart: 1,
	finishTime: null,
	answers: [
		[null, null, null, null], // part 1
		[null, null, null, null, null], // part 2
		[null, null, null, null], // part 3
		[null, null, null], // part 4
		[null, null, null, null], // part 5
		[null, null, null, null, null], // part 6
		[null, null, null, null], // part 7
		[null, null, null], // part 8
		[null, null, null, null], // part 9
		[null, null, null, null], // part 10
		[null, null, null, null, null], // part 11
		[null, null, null, null, null], // part 12
	],
	result: null,
	isButtonNextDisabled: true,
};

const placementTestReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PlacementTestActionTypes.SET_ACTIVE_CHILD_ID:
			return {
				...state,
				activeChildId: action.payload,
			};
		case PlacementTestActionTypes.SET_FINISH_TIME:
			return {
				...state,
				finishTime: action.payload,
			};
		case PlacementTestActionTypes.SET_ACTIVE_PART:
			return {
				...state,
				activePart: action.payload,
			};
		case PlacementTestActionTypes.SET_IS_BUTTON_NEXT_DISABLED:
			return {
				...state,
				isButtonNextDisabled: action.payload,
			};
		case PlacementTestActionTypes.SET_ANSWER:
			const { answer, part, question } = action.payload;

			let tempAnswers = state.answers;
			tempAnswers[part - 1][question - 1] = answer;
			return {
				...state,
				answers: [...tempAnswers],
			};
		case PlacementTestActionTypes.HANDLE_FINISH_TEST_START:
		case PlacementTestActionTypes.HANDLE_START_TEST_START:
		case PlacementTestActionTypes.FETCH_PLACEMENT_TEST_START:
			return {
				...state,
				isLoading: true,
			};
		case PlacementTestActionTypes.HANDLE_FINISH_TEST_FAILURE:
		case PlacementTestActionTypes.HANDLE_START_TEST_FAILURE:
		case PlacementTestActionTypes.FETCH_PLACEMENT_TEST_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case PlacementTestActionTypes.HANDLE_FINISH_TEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				result: action.payload,
			};
		case PlacementTestActionTypes.FETCH_PLACEMENT_TEST_SUCCESS:
		case PlacementTestActionTypes.HANDLE_START_TEST_SUCCESS:
			const placementTestData = action.payload;

			if (!!placementTestData) {
				return {
					...state,
					isLoading: false,
					error: null,
					answers: JSON.parse(placementTestData.answer),
					activePart: parseInt(placementTestData.active_part),
					finishTime: placementTestData.finish_time,
					placementTestData: action.payload,
				};
			}
			return {
				...state,
				isLoading: false,
				error: null,
			};
		case PlacementTestActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case PlacementTestActionTypes.RESET_PLACEMENT_TEST:
			return {
				...state,
				error: null,
				isLoading: false,
				activeChildId: null,
				activePart: 1,
				timer: null,
				answers: [
					[null, null, null, null], // part 1
					[null, null, null, null, null], // part 2
					[null, null, null, null], // part 3
					[null, null, null], // part 4
					[null, null, null, null], // part 5
					[null, null, null, null, null], // part 6
					[null, null, null, null], // part 7
					[null, null, null], // part 8
					[null, null, null, null], // part 9
					[null, null, null, null], // part 10
					[null, null, null, null, null], // part 11
					[null, null, null, null, null], // part 12
				],
				isButtonNextDisabled: true,
				placementTestData: null,
			};
		default:
			return state;
	}
};

export default placementTestReducer;
