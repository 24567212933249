import UserActionTypes from "./user.types";

const INITIAL_STATE = {
	currentUser: null,
	language: "EN",
	childs: [],
	error: null,
	loading: false,

	// user page
	userPageActiveTab: 1,
	userPageActivePayment: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserActionTypes.EMAIL_SIGN_IN_START:
		case UserActionTypes.SIGN_UP_START:
		case UserActionTypes.SIGN_OUT_START:
		case UserActionTypes.FETCH_USER_START:
		case UserActionTypes.UPLOAD_USER_PICTURE_START:
		case UserActionTypes.UPDATE_USER_START:
		case UserActionTypes.UPDATE_USER_PASSWORD_START:
		case UserActionTypes.FORGOT_PASSWORD_START:
		case UserActionTypes.RESET_PASSWORD_START:
			return {
				...state,
				loading: true,
			};
		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				currentUser: { ...action.payload },
				language: action.payload.locale,
				error: null,
				loading: false,
			};
		case UserActionTypes.SIGN_OUT_SUCCESS:
			return {
				...state,
				loading: false,
				currentUser: null,
			};
		case UserActionTypes.SIGN_IN_FAILURE:
		case UserActionTypes.SIGN_OUT_FAILURE:
		case UserActionTypes.SIGN_UP_FAILURE:
		case UserActionTypes.FETCH_USER_FAILURE:
		case UserActionTypes.UPLOAD_USER_PICTURE_FAILURE:
		case UserActionTypes.UPDATE_USER_FAILURE:
		case UserActionTypes.UPDATE_USER_PASSWORD_FAILURE:
		case UserActionTypes.FORGOT_PASSWORD_FAILURE:
		case UserActionTypes.RESET_PASSWORD_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case UserActionTypes.FETCH_USER_SUCCESS:
		case UserActionTypes.UPDATE_USER_SUCCESS:
			const { join_childs, locale, ...otherUserData } = action.payload;
			return {
				...state,
				currentUser: { ...otherUserData },
				language: locale,
				childs: join_childs,
				error: null,
				loading: false,
			};
		case UserActionTypes.UPLOAD_USER_PICTURE_SUCCESS:
		case UserActionTypes.UPDATE_USER_PASSWORD_SUCCESS:
		case UserActionTypes.FORGOT_PASSWORD_SUCCESS:
		case UserActionTypes.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UserActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case UserActionTypes.UPDATE_USER_LANGUAGE:
			return {
				...state,
				language: action.payload,
			};
		case UserActionTypes.SET_USER_PAGE_ACTIVE_TAB:
			return {
				...state,
				userPageActiveTab: action.payload,
			};
		case UserActionTypes.SET_USER_PAGE_ACTIVE_PAYMENT:
			return {
				...state,
				userPageActivePayment: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
