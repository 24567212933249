import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	imageContainer: {
		display: "flex",
		justifyContent: "center",
		"& img": {
			borderRadius: "16px",
			maxWidth: "588px",
			maxHeight: "577px",
			[theme.breakpoints.down("sm")]: {
				maxWidth: "85vw",
				maxHeight: "372px",
			},
		},
	},
	testimonyQuote: {
		width: "100%",
		fontSize: "32px",
		lineHeight: "40px",
		fontWeight: "400",
		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
			fontSize: "16px",
			lineHeight: "24px",
		},
	},
	personName: {
		width: "100%",
		marginTop: "32px",
		fontWeight: "bold",
		fontSize: "16px",
		lineHeight: "24px",
	},
	textContainer: {
		justifyContent: "flex-start",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center",
		},
	},
}));
