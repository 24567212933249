import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import OrderActionTypes from "./order.types";
import {
	fetchAllOrdersSuccess,
	fetchAllOrdersFailure,
	clearOrderError,
	updateOrderSuccess,
	updateOrderFailure,
	fetchAllOrdersStart,
} from "./order.action";
import { orderStatuses } from "helpers/IndexOrder.helper";
import { couriers } from "helpers/App.helper";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}
const rajaOngkirAPIKey = process.env.REACT_APP_RAJA_ONGKIR_API_KEY;

export function* fetchAllOrders({ payload }) {
	const status = payload;

	let endpoint = "order";
	if (!!status) endpoint += `/status/${status}`;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/${endpoint}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const orders = response.data.orders;

		let completeOrdersData = [];
		if (!!orders) {
			const responseCity = yield axios({
				method: "GET",
				url: `${baseUrlApi}/rajaongkir/cities`,
			});
			const tempCities = responseCity.data.rajaongkir.results;
			for (const order of orders) {
				const statusObj = orderStatuses.find(
					(status) => order.delivery_status === status.value
				);
				const rajaOngkirInfo = tempCities.find(
					(city) => order.users.city === city.city_id
				);
				const courierData = couriers.find(
					(courier) => courier.value === order.courier
				);
				if (!!order.users && !!rajaOngkirInfo) {
					completeOrdersData.push({
						...order,
						fullAddress: `${order.users.address_details}, ${rajaOngkirInfo.type} ${rajaOngkirInfo.city_name} ${rajaOngkirInfo.province}, ${order.users.zip_code} [Notes : ${order.users.special_notes}]`,
						childFullname: `${order.child.first_name} ${order.child.last_name}`,
						courierName: !!courierData ? courierData.textEN : "-",
						rajaOngkirInfo,
						deliveryStatusText: statusObj.textEN,
					});
				} else {
					completeOrdersData.push({
						...order,
						fullAddress: `-`,
						childFullname: `${order.child.first_name} ${order.child.last_name}`,
						courierName: !!courierData ? courierData.textEN : "-",
						rajaOngkirInfo: null,
						deliveryStatusText: statusObj.textEN,
					});
				}
			}
		}
		yield put(fetchAllOrdersSuccess(completeOrdersData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearOrderError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllOrdersFailure(errorMessage));
	}
}

export function* onGetAllOrdersStart() {
	yield takeLatest(OrderActionTypes.FETCH_ORDERS_START, fetchAllOrders);
}

export function* updateOrder({ payload }) {
	const { resi, status, orderId, courier } = payload;

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/order/update`,
			data: {
				order_id: orderId,
				resi: !!resi ? resi : undefined,
				delivery_status: status,
				courier,
			},
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		yield put(updateOrderSuccess());
		yield put(fetchAllOrdersStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearOrderError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateOrderFailure(errorMessage));
	}
}

export function* onUpdateOrderStart() {
	yield takeLatest(OrderActionTypes.UPDATE_ORDER_START, updateOrder);
}

export function* orderSagas() {
	yield all([call(onGetAllOrdersStart), call(onUpdateOrderStart)]);
}
