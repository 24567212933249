import { all, call } from "redux-saga/effects";
import { buyCourseSagas } from "./buy-course/buy-course.sagas";
import { childSagas } from "./child/child.sagas";
import { newsSagas } from "./news/news.sagas";
import { orderSagas } from "./order/order.sagas";
import { paymentSagas } from "./payment/payment.sagas";
import { placementTestSagas } from "./placement-test/placement-test.sagas";
import { rajaOngkirSagas } from "./raja-ongkir/raja-ongkir.sagas";
import { testimonySagas } from "./testimony/testimony.sagas";
import { userSagas } from "./user/user.sagas";
import { academicYearSagas } from "./academic-year/academic-year.sagas";
import { courseMasterSagas } from "./course-master/course-master.sagas";
import { notificationSagas } from "./notification/notification.sagas";
import { studentPerformanceSagas } from "./student-performance/student-performance.sagas";

export default function* rootSaga() {
	yield all([
		call(userSagas),
		call(testimonySagas),
		call(newsSagas),
		call(rajaOngkirSagas),
		call(childSagas),
		call(placementTestSagas),
		call(buyCourseSagas),
		call(paymentSagas),
		call(orderSagas),
		call(academicYearSagas),
		call(courseMasterSagas),
		call(notificationSagas),
		call(studentPerformanceSagas),
	]);
}
