import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	imagePreview: {
		width: "508px",
		height: "577px",
	},
	previewContainer: {
		display: "flex",
		justifyContent: "center",
	},
	contentDetailContainer: {
		marginTop: "32px",
		display: "flex",
		justifyContent: "center",
	},
	dividerContainer: {
		marginTop: "24px",
	},
	contentHeaderPreview: {
		display: "flex",
		justifyContent: "center",

		"& img": {
			width: "100%",
		},
	},
	text: {
		textAlign: "center",
		fontWeight: "600",
		fontSize: "20px",
		lineHeight: "28px",
	},
	child: {
		textAlign: "center",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "16px",
		marginTop: "4px",
	},
	videoContainer: {
		padding: "16px",
	},
	videoWrapper: {
		position: "relative",
		paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
	},
	videoPlayer: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	previewLabel: {
		fontWeight: "600",
		marginTop: "20px",
		textAlign: "center",
	},
	languageSelectorContainer: {
		marginBottom: "30px",
	},
}));
