import React, { useEffect, useRef } from "react";
import { useStyles } from "./CreateCourseMaster.styles";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid, TextField } from "@material-ui/core";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useHistory } from "react-router";
import Button from "components/Shared/CustomButtons/Button";
import { selectCourseMasterLoading } from "redux/course-master/course-master.selector";
import { selectAcademicYearLoading } from "redux/academic-year/academic-year.selector";
import { selectAcademicYearPlural } from "redux/academic-year/academic-year.selector";
import { createCourseMasterStart } from "redux/course-master/course-master.action";
import { fetchAcademicYearsStart } from "redux/academic-year/academic-year.action";
import moment from "moment";
import { grades } from "helpers/App.helper";
import { courseTypes } from "helpers/App.helper";
import { weeks } from "helpers/App.helper";
import ReactPlayer from "react-player";
import { units } from "helpers/App.helper";
import { selectCourseMasterUploadProgress } from "redux/course-master/course-master.selector";

const CreateCourseMaster = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const {
		isCourseMasterLoading,
		createCourseMasterStart,
		uploadProgress,
	} = props;
	const { register, handleSubmit, errors, watch, control } = useForm();

	const video = useRef();
	video.current = watch("video", "");
	const courseType = useRef();
	courseType.current = watch("courseType", "");

	const storeCourseMaster = (data) => {
		if (!isCourseMasterLoading) {
			createCourseMasterStart({ courseMasterData: data, history });
		}
	};

	return (
		<Grid container>
			<LoadingBackdrop
				open={isCourseMasterLoading}
				value={uploadProgress}
			/>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Course Master Data</h4>
					</CardHeader>
					<CardBody>
						<Grid container>
							<form onSubmit={handleSubmit(storeCourseMaster)}>
								<Grid container item xs={12} spacing={3}>
									<Grid
										item
										container
										xs={12}
										lg={6}
										spacing={2}
									>
										<Grid item xs={12}>
											<TextField
												margin="normal"
												fullWidth
												id="titleEN"
												label="Title (English)"
												name="titleEN"
												autoFocus
												inputRef={register({
													required: {
														value: true,
														message:
															"Title (English) are required",
													},
												})}
												error={!!errors.titleEN}
												helperText={
													errors.titleEN &&
													errors.titleEN.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												margin="normal"
												fullWidth
												id="titleID"
												label="Title (Indonesia)"
												name="titleID"
												autoFocus
												inputRef={register({
													required: {
														value: true,
														message:
															"Title (Indonesia) are required",
													},
												})}
												error={!!errors.titleID}
												helperText={
													errors.titleID &&
													errors.titleID.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={3}
												margin="normal"
												fullWidth
												id="descriptionEN"
												label="Description (English)"
												name="descriptionEN"
												inputRef={register({
													required: {
														value: true,
														message:
															"Description (English) are required",
													},
												})}
												error={!!errors.descriptionEN}
												helperText={
													errors.descriptionEN &&
													errors.descriptionEN.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={3}
												margin="normal"
												fullWidth
												id="descriptionID"
												label="Description (Indonesia)"
												name="descriptionID"
												inputRef={register({
													required: {
														value: true,
														message:
															"Description (Indonesia) are required",
													},
												})}
												error={!!errors.descriptionID}
												helperText={
													errors.descriptionID &&
													errors.descriptionID.message
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<CustomSelect
												margin="normal"
												fullWidth
												id="week"
												label="Week"
												name="week"
												options={weeks()}
												control={control}
												rules={{
													required: {
														value: true,
														message:
															"Grade are required",
													},
												}}
												error={!!errors.week}
												helperText={
													errors.week &&
													errors.week.message
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<CustomSelect
												margin="normal"
												fullWidth
												id="unit"
												label="Unit"
												name="unit"
												options={units()}
												control={control}
												rules={{
													required: {
														value: true,
														message:
															"Grade are required",
													},
												}}
												error={!!errors.unit}
												helperText={
													errors.unit &&
													errors.unit.message
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<CustomSelect
												margin="normal"
												fullWidth
												id="grade"
												label="Grade"
												name="grade"
												options={grades}
												control={control}
												rules={{
													required: {
														value: true,
														message:
															"Grade are required",
													},
												}}
												error={!!errors.grade}
												helperText={
													errors.grade &&
													errors.grade.message
												}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											lg={6}
											className={classes.selectContainer}
										>
											<CustomSelect
												fullWidth
												margin="normal"
												id="courseType"
												label="Course Type"
												name="courseType"
												options={courseTypes.map(
													(courseType, idx) => ({
														textEN: courseType,
														textID: courseType,
														value: idx,
													})
												)}
												control={control}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputFileField
												margin="normal"
												accept="video/mp4,video/x-m4v,video/*"
												id="video"
												label="Upload Video"
												inputRef={register({
													required: {
														value:
															courseType.current ===
																0 ||
															courseType.current ===
																2,
														message:
															"Video are required",
													},
												})}
												value={video.current}
												error={!!errors.video}
												helperText={
													errors.video &&
													errors.video.message
												}
											/>
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										lg={6}
										container
										className={classes.previewContainer}
										justify="center"
									>
										<h3>Preview Video</h3>

										<Grid item xs={12}>
											{video.current.length > 0 &&
												!isCourseMasterLoading && (
													<div
														className={
															classes.videoWrapper
														}
														onContextMenu={(e) =>
															e.preventDefault()
														}
													>
														<ReactPlayer
															className={
																classes.videoPlayer
															}
															url={URL.createObjectURL(
																video.current[0]
															)}
															width="100%"
															height="100%"
															controls={true}
															config={{
																file: {
																	attributes: {
																		controlsList:
																			"nodownload",
																	},
																},
															}}
														/>
													</div>
												)}
										</Grid>
									</Grid>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<Button
											type="submit"
											variant="contained"
											color="info"
											className={classes.submit}
										>
											Create Course Master
										</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isCourseMasterLoading: selectCourseMasterLoading,
	uploadProgress: selectCourseMasterUploadProgress,
});

const mapDispatchToProps = (dispatch) => ({
	createCourseMasterStart: ({ courseMasterData, history }) =>
		dispatch(createCourseMasterStart({ courseMasterData, history })),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseMaster);
