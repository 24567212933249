import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	inputRoot: {
		display: "none",
	},
	textField: {
		cursor: "pointer !important",
	},
}));
