import OrderActionTypes from "./order.types";

const INITIAL_STATE = {
	plural: [],
	singular: null,
	error: null,
	loading: false,
};

const orderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OrderActionTypes.FETCH_ORDERS_START:
		case OrderActionTypes.FETCH_ORDER_START:
		case OrderActionTypes.UPDATE_ORDER_START:
			return {
				...state,
				loading: true,
			};
		case OrderActionTypes.FETCH_ORDERS_FAILURE:
		case OrderActionTypes.FETCH_ORDER_FAILURE:
		case OrderActionTypes.UPDATE_ORDER_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case OrderActionTypes.FETCH_ORDERS_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case OrderActionTypes.FETCH_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case OrderActionTypes.UPDATE_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case OrderActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case OrderActionTypes.CLEAR_DATA:
			return {
				...state,
				plural: [],
				singular: null,
				error: null,
			};
		default:
			return state;
	}
};

export default orderReducer;
