import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import React, { useRef } from "react";
import { useStyles } from "./InputFileField.styles";
import { ReactComponent as CameraIcon } from "../../../assets/img/camera-icon.svg";

const InputFileField = (props) => {
	const textFieldRef = useRef();
	const {
		id,
		label,
		multiple,
		inputRef,
		accept,
		error,
		helperText,
		value,
		...textFieldProps
	} = props;
	const classes = useStyles();

	const handleTextFieldOnClick = (event) => {
		event.preventDefault();
		document.getElementById(id).click();
		textFieldRef.current.blur();
	};
	return (
		<>
			<input
				accept={accept}
				className={classes.inputRoot}
				id={id}
				name={id}
				type="file"
				multiple={!!multiple}
				ref={inputRef}
			/>
			<TextField
				{...textFieldProps}
				inputRef={textFieldRef}
				fullWidth
				label={label}
				htmlFor={id}
				error={!!error}
				helperText={helperText}
				onClick={handleTextFieldOnClick}
				value={
					value &&
					(value.length > 0
						? !!multiple
							? value.map((img) => img.name).join(" ,")
							: value[0].name
						: "")
				}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton aria-label="open-file-explorer">
								<CameraIcon />
							</IconButton>
						</InputAdornment>
					),
					onKeyDown: (event) => {
						event.preventDefault();
					},
				}}
			/>
		</>
	);
};

export default InputFileField;
