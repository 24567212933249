const NextGradeActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET ACTIVE CHILD ID
	SET_NEXT_GRADE_ACTIVE_CHILD_ID: "SET_NEXT_GRADE_ACTIVE_CHILD_ID",
	// IS MOVING TO NEXT GRADE
	SET_NEXT_GRADE_IS_MOVING_TO_NEXT_GRADE:
		"SET_NEXT_GRADE_IS_MOVING_TO_NEXT_GRADE",
	// SET PAYMENT INTERVAL
	SET_NEXT_GRADE_PAYMENT_INTERVAL: "SET_NEXT_GRADE_PAYMENT_INTERVAL",
	// SET DELIVERY COST
	SET_NEXT_GRADE_DELIVERY_COST: "SET_NEXT_GRADE_DELIVERY_COST",
	// SET PAYMENT METHOD
	SET_NEXT_GRADE_PAYMENT_METHOD: "SET_NEXT_GRADE_PAYMENT_METHOD",
	// OPEN CLOSE DRAWER
	OPEN_NEXT_GRADE_DRAWER: "OPEN_NEXT_GRADE_DRAWER",
	CLOSE_NEXT_GRADE_DRAWER: "CLOSE_NEXT_GRADE_DRAWER",
	// RESET NEXT GRADE
	RESET_NEXT_GRADE: "RESET_NEXT_GRADE",
	// SET ACTIVE STEP
	SET_NEXT_GRADE_ACTIVE_STEP: "SET_NEXT_GRADE_ACTIVE_STEP",
	// SET NEXT STEP FUNCTION
	SET_NEXT_GRADE_NEXT_STEP_FUNCTION: "SET_NEXT_GRADE_NEXT_STEP_FUNCTION",
	// SET_NEXT_GRADE_SHOW_PAYMENT_DETAILS
	SET_NEXT_GRADE_SHOW_PAYMENT_DETAILS: "SET_NEXT_GRADE_SHOW_PAYMENT_DETAILS",
	// SET_NEXT_GRADE_HIDE_BILL_FOOTER
	SET_NEXT_GRADE_HIDE_BILL_FOOTER: "SET_NEXT_GRADE_HIDE_BILL_FOOTER",
	// SET_NEXT_GRADE_FIXED_PRICE_LIST
	SET_NEXT_GRADE_FIXED_PRICE_LIST: "SET_NEXT_GRADE_FIXED_PRICE_LIST",
	// SET NEXT_GRADE COURIER
	SET_NEXT_GRADE_COURIER: "SET_NEXT_GRADE_COURIER",
	// SET PAYMENT INTERVAL DETAIL OPENED
	SET_NEXT_GRADE_PAYMENT_INTERVAL_DETAIL:
		"SET_NEXT_GRADE_PAYMENT_INTERVAL_DETAIL",
	// SET PAYMENT INTERVAL CURRENT SLIDE
	SET_NEXT_GRADE_PAYMENT_INTERVAL_CURRENT_SLIDE:
		"SET_NEXT_GRADE_PAYMENT_INTERVAL_CURRENT_SLIDE",
	// SET_NEXT_GRADE_BATCH
	SET_NEXT_GRADE_BATCH: "SET_NEXT_GRADE_BATCH",
};

export default NextGradeActionTypes;
