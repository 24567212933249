import StudentPerformanceActionTypes from "./student-performance.types";

// Fetch All
export const fetchAllStudentPerformanceStart = (filters) => ({
	type: StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_START,
	payload: filters,
});

export const fetchAllStudentPerformanceSuccess = (studentPerformance) => ({
	type: StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_SUCCESS,
	payload: studentPerformance,
});

export const fetchAllStudentPerformanceFailure = (error) => ({
	type: StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_FAILURE,
	payload: error,
});

// Fetch One
export const fetchStudentPerformanceStart = (studentPerformanceId) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_START,
	payload: studentPerformanceId,
});

export const fetchStudentPerformanceSuccess = (studentPerformance) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_SUCCESS,
	payload: studentPerformance,
});

export const fetchStudentPerformanceFailure = (error) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_FAILURE,
	payload: error,
});

// Create
export const createStudentPerformanceStart = ({ data, history }) => ({
	type: StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_START,
	payload: { data, history },
});

export const createStudentPerformanceSuccess = () => ({
	type: StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_SUCCESS,
});

export const createStudentPerformanceFailure = (error) => ({
	type: StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_FAILURE,
	payload: error,
});

// Update
export const updateStudentPerformanceStart = ({ data, history }) => ({
	type: StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_START,
	payload: { data, history },
});

export const updateStudentPerformanceSuccess = () => ({
	type: StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_SUCCESS,
});

export const updateStudentPerformanceFailure = (error) => ({
	type: StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_FAILURE,
	payload: error,
});

// Delete
export const deleteStudentPerformanceStart = ({
	studentPerformanceId,
	category,
}) => ({
	type: StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_START,
	payload: { studentPerformanceId, category },
});

export const deleteStudentPerformanceSuccess = () => ({
	type: StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_SUCCESS,
});

export const deleteStudentPerformanceFailure = (error) => ({
	type: StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_FAILURE,
	payload: error,
});

export const clearStudentPerformanceError = () => ({
	type: StudentPerformanceActionTypes.CLEAR_ERROR,
});

export const setStudentPerformanceUploadProgress = (progress) => ({
	type: StudentPerformanceActionTypes.SET_STUDENT_PERFORMANCE_UPLOAD_PROGRESS,
	payload: progress,
});

// fetch limited
export const fetchStudentPerformancesLimitedStart = (limit) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_START,
	payload: limit,
});

export const fetchStudentPerformancesLimitedSuccess = (studentPerformances) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_SUCCESS,
	payload: studentPerformances,
});

export const fetchStudentPerformancesLimitedFailure = (error) => ({
	type: StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_FAILURE,
	payload: error,
});