const TestimonyActionTypes = {
	// FETCH ALL TESTIMONIES
	FETCH_TESTIMONIES_START: "FETCH_TESTIMONIES_START",
	FETCH_TESTIMONIES_SUCCESS: "FETCH_TESTIMONIES_SUCCESS",
	FETCH_TESTIMONIES_FAILURE: "FETCH_TESTIMONIES_FAILURE",
	// FETCH ONE TESTIMONY
	FETCH_TESTIMONY_START: "FETCH_TESTIMONY_START",
	FETCH_TESTIMONY_SUCCESS: "FETCH_TESTIMONY_SUCCESS",
	FETCH_TESTIMONY_FAILURE: "FETCH_TESTIMONY_FAILURE",
	// CREATE TESTIMONY
	CREATE_TESTIMONY_START: "CREATE_TESTIMONY_START",
	CREATE_TESTIMONY_SUCCESS: "CREATE_TESTIMONY_SUCCESS",
	CREATE_TESTIMONY_FAILURE: "CREATE_TESTIMONY_FAILURE",
	// UPDATE TESTIMONY
	UPDATE_TESTIMONY_START: "UPDATE_TESTIMONY_START",
	UPDATE_TESTIMONY_SUCCESS: "UPDATE_TESTIMONY_SUCCESS",
	UPDATE_TESTIMONY_FAILURE: "UPDATE_TESTIMONY_FAILURE",
	// DELETE TESTIMONY
	DELETE_TESTIMONY_START: "DELETE_TESTIMONY_START",
	DELETE_TESTIMONY_SUCCESS: "DELETE_TESTIMONY_SUCCESS",
	DELETE_TESTIMONY_FAILURE: "DELETE_TESTIMONY_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
	CLEAR_DATA: "CLEAR_DATA",
};

export default TestimonyActionTypes;
