import { createSelector } from "reselect";

const selectStudentPerformance = (state) => state.studentPerformance;

export const selectStudentPerformanceSingular = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.singular
);

export const selectStudentPerformancePlural = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.plural
);

export const selectStudentPerformanceError = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.error
);

export const selectStudentPerformanceLoading = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.loading
);

export const selectStudentPerformanceCarouselLoading = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.carouselLoading
);

export const selectStudentPerformanceUploadProgress = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.uploadProgress
);

export const selectStudentPerformanceCarouseItems = createSelector(
	[selectStudentPerformance],
	(studentPerformance) => studentPerformance.carouselItems
);
