import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	button: {
		border: "none",
		background: "transparent",
	},
	line: {
		fill: "none",
		stroke: "black",
		strokeWidth: "6",
		transition:
			"stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
	},
	line1: {
		strokeDasharray: "60 207",
		strokeWidth: "6",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "90 207",
			strokeDashoffset: "-134",
			strokeWidth: "6",
		},
	},
	line2: {
		strokeDasharray: "90 60",
		strokeWidth: "6",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "1 90",
			strokeDashoffset: "-48",
			strokeWidth: "6",
		},
	},
	line3: {
		strokeDasharray: "60 207",
		strokeWidth: "6",
		strokeLinecap: "round",
		"&.opened": {
			strokeDasharray: "90 207",
			strokeDashoffset: "-134",
			strokeWidth: "6",
		},
	},
}));
