const StudentPerformanceActionTypes = {
	// FETCH ALL STUDENT_PERFORMANCE
	FETCH_ALL_STUDENT_PERFORMANCE_START: "FETCH_ALL_STUDENT_PERFORMANCE_START",
	FETCH_ALL_STUDENT_PERFORMANCE_SUCCESS: "FETCH_ALL_STUDENT_PERFORMANCE_SUCCESS",
	FETCH_ALL_STUDENT_PERFORMANCE_FAILURE: "FETCH_ALL_STUDENT_PERFORMANCE_FAILURE",
	// FETCH ONE STUDENT_PERFORMANCE
	FETCH_STUDENT_PERFORMANCE_START: "FETCH_STUDENT_PERFORMANCE_START",
	FETCH_STUDENT_PERFORMANCE_SUCCESS: "FETCH_STUDENT_PERFORMANCE_SUCCESS",
	FETCH_STUDENT_PERFORMANCE_FAILURE: "FETCH_STUDENT_PERFORMANCE_FAILURE",
	// CREATE STUDENT_PERFORMANCE
	CREATE_STUDENT_PERFORMANCE_START: "CREATE_STUDENT_PERFORMANCE_START",
	CREATE_STUDENT_PERFORMANCE_SUCCESS: "CREATE_STUDENT_PERFORMANCE_SUCCESS",
	CREATE_STUDENT_PERFORMANCE_FAILURE: "CREATE_STUDENT_PERFORMANCE_FAILURE",
	// UPDATE STUDENT_PERFORMANCE
	UPDATE_STUDENT_PERFORMANCE_START: "UPDATE_STUDENT_PERFORMANCE_START",
	UPDATE_STUDENT_PERFORMANCE_SUCCESS: "UPDATE_STUDENT_PERFORMANCE_SUCCESS",
	UPDATE_STUDENT_PERFORMANCE_FAILURE: "UPDATE_STUDENT_PERFORMANCE_FAILURE",
	// DELETE STUDENT_PERFORMANCE
	DELETE_STUDENT_PERFORMANCE_START: "DELETE_STUDENT_PERFORMANCE_START",
	DELETE_STUDENT_PERFORMANCE_SUCCESS: "DELETE_STUDENT_PERFORMANCE_SUCCESS",
	DELETE_STUDENT_PERFORMANCE_FAILURE: "DELETE_STUDENT_PERFORMANCE_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
	SET_STUDENT_PERFORMANCE_UPLOAD_PROGRESS: "SET_STUDENT_PERFORMANCE_UPLOAD_PROGRESS",
	// FETCH STUDENT_PERFORMANCES_LIMITED
	FETCH_STUDENT_PERFORMANCES_LIMITED_START: "FETCH_STUDENT_PERFORMANCES_LIMITED_START",
	FETCH_STUDENT_PERFORMANCES_LIMITED_SUCCESS: "FETCH_STUDENT_PERFORMANCES_LIMITED_SUCCESS",
	FETCH_STUDENT_PERFORMANCES_LIMITED_FAILURE: "FETCH_STUDENT_PERFORMANCES_LIMITED_FAILURE",
};

export default StudentPerformanceActionTypes;
