import axios from "axios";
import { takeLatest, put, all, call, select, delay } from "redux-saga/effects";
import {
	signInFailure,
	signInSuccess,
	signOutFailure,
	signOutSuccess,
	signUpFailure,
	signUpSuccess,
	fetchUserSuccess,
	fetchUserFailure,
	uploadUserPictureSuccess,
	uploadUserPictureFailure,
	fetchUserStart,
	updateUserSuccess,
	updateUserFailure,
	clearUserError,
	updateLocale,
	updateUserPasswordSuccess,
	updateUserPasswordFailure,
	forgotPasswordFailure,
	forgotPasswordSuccess,
	resetPasswordSuccess,
	resetPasswordFailure,
} from "./user.action";
import { selectCurrentUser, selectUserLanguage } from "./user.selector";
import UserActionTypes from "./user.types";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import moment from "moment";
const md5 = require("md5");

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* signInWithEmail({ payload }) {
	const { credentials, type } = payload;
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/login`,
			data: {
				...credentials,
				password: md5(credentials.password),
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const userData = response.data.users;
		if (parseInt(`${userData.status}`) === type) {
			yield put(signInSuccess(userData));
		} else {
			throw new Error("Account not Found");
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(signInFailure(errorMessage));
	}
}

export function* onEmailSignInStart() {
	yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* isUserAuthenticated() {
	try {
		const userAuth = yield select(selectCurrentUser);
		if (!userAuth) return;

		yield signInSuccess(userAuth);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(signInFailure(errorMessage));
	}
}

export function* onCheckUserSession() {
	yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOut() {
	try {
		yield put(signOutSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(signOutFailure(errorMessage));
	}
}

export function* onSignOutStart() {
	yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* signUp({ payload: { userInformation, history } }) {
	const language = yield select(selectUserLanguage);
	const data = {
		first_name: userInformation.firstName,
		last_name: userInformation.lastName,
		email: userInformation.email,
		password: userInformation.password,
		phone_number: userInformation.phoneNumber,
		gender: userInformation.gender,
		birthdate: moment(userInformation.birthday).format("YYYY-MM-DD"),
		province: userInformation.province,
		city: userInformation.city,
		zip_code: userInformation.zipCode,
		address_details: userInformation.addressDetails,
		special_notes: userInformation.specialNotes,
		//default value
		locale: language,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/register`,
			data: data,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const userData = response.data.users;
		yield put(signUpSuccess(userData));
		history.push({ pathname: "/welcome", state: { isFromSignUp: true } });
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(signUpFailure(errorMessage));
	}
}

export function* onSignUpStart() {
	yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* fetchChildPaymentStatus(childId) {
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/childs/status`,
			data: {
				child_id: childId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const paymentStatus = response.data;
		return paymentStatus;
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserFailure(errorMessage));
	}
}

export function* fetchChildPaymentStatusNew(childId) {
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/childs/billing-status`,
			data: {
				child_id: childId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const paymentStatus = response.data;
		return paymentStatus;
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserFailure(errorMessage));
	}
}

export function* fetchChild(childId) {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/childs/${childId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const childData = response.data.childs;
		return childData;
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserFailure(errorMessage));
	}
}

export function* fetchUser({ payload }) {
	const email = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/users/${email}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const userData = response.data.users;

		let finalChildArr = [];
		for (const child of userData.join_childs) {
			const tempChildObj = yield fetchChild(child.child_id);
			const tempPaymentStatus = yield fetchChildPaymentStatusNew(
				child.child_id
			);
			finalChildArr.push({
				...tempChildObj,
				paymentStatus: tempPaymentStatus.payment_status,
				isAuto: tempPaymentStatus.is_auto,
				grade: tempPaymentStatus.grade,
			});
		}
		yield put(
			fetchUserSuccess({ ...userData, join_childs: finalChildArr })
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserFailure(errorMessage));
	}
}

export function* onFetchUserStart() {
	yield takeLatest(UserActionTypes.FETCH_USER_START, fetchUser);
}

export function* uploadUserPicture({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	let data = new FormData();
	data.append("image_url", payload);
	data.append("user_id", currentUser.user_id);
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/update-image`,
			data: data,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(uploadUserPictureSuccess());
		yield put(fetchUserStart(currentUser.email));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(uploadUserPictureFailure(errorMessage));
	}
}

export function* onUploadUserPictureStart() {
	yield takeLatest(
		UserActionTypes.UPLOAD_USER_PICTURE_START,
		uploadUserPicture
	);
}

export function* updateUser({ payload }) {
	const userInformation = payload;
	const language = yield select(selectUserLanguage);
	const data = {
		first_name: userInformation.firstName,
		last_name: userInformation.lastName,
		email: userInformation.email,
		phone_number: userInformation.phoneNumber,
		gender: userInformation.gender,
		birthdate: moment(userInformation.birthday).format("YYYY-MM-DD"),
		province: userInformation.province,
		city: userInformation.city,
		zip_code: userInformation.zipCode,
		address_details: userInformation.addressDetails,
		special_notes: userInformation.specialNotes,
		locale: language,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/update-user`,
			data: data,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const userData = response.data.users;
		yield put(fetchUserStart(userInformation.email));
		yield put(updateUserSuccess(userData));
		const snackbarData = {
			message: language === "EN" ? "Update Success!" : "Update Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(snackbarData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateUserFailure(errorMessage));
	}
}

export function* onUpdateUserStart() {
	yield takeLatest(UserActionTypes.UPDATE_USER_START, updateUser);
}

export function* handleUpdateLocale({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const locale = payload;
	const data = {
		user_id: currentUser.user_id,
		locale,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/update-locale`,
			data: data,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;

		console.log("Error Update Locale ", errorMessage);
		yield delay(5000);
		yield put(updateLocale(locale));
	}
}

export function* onUpdateLocale() {
	yield takeLatest(UserActionTypes.UPDATE_LOCALE, handleUpdateLocale);
}

export function* updateUserPassword({ payload }) {
	const passwordInfo = payload;
	const currentUser = yield select(selectCurrentUser);
	const language = yield select(selectUserLanguage);
	const data = {
		email: currentUser.email,
		old_password: md5(passwordInfo.oldPassword),
		new_password: md5(passwordInfo.password),
		password_confirmation: md5(passwordInfo.passwordConfirmation),
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/change-password`,
			data: data,
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateUserPasswordSuccess());
		const snackbarData = {
			message:
				language === "EN"
					? "Password succesfully changed!"
					: "Password berhasil diubah!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(snackbarData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateUserPasswordFailure(errorMessage));
	}
}

export function* onUpdateUserPasswordStart() {
	yield takeLatest(
		UserActionTypes.UPDATE_USER_PASSWORD_START,
		updateUserPassword
	);
}

export function* forgotPassword({ payload }) {
	const email = payload;
	const language = yield select(selectUserLanguage);
	const data = {
		email,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/forgot-password`,
			data: data,
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(forgotPasswordSuccess());
		const snackbarData = {
			message:
				language === "EN"
					? "Change Password request has been sent!"
					: "Permintaan ubah password sudah dikirim!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(snackbarData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(forgotPasswordFailure(errorMessage));
	}
}

export function* onForgotPasswordStart() {
	yield takeLatest(UserActionTypes.FORGOT_PASSWORD_START, forgotPassword);
}

export function* resetPassword({ payload }) {
	const passwordInfo = payload;
	const language = yield select(selectUserLanguage);
	const data = {
		password: md5(passwordInfo.password),
		password_confirmation: md5(passwordInfo.passwordConfirmation),
		token: passwordInfo.token,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/reset-password`, // NEEDS TO BE UPDATED
			data: data,
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(resetPasswordSuccess());
		const snackbarData = {
			message:
				language === "EN"
					? "Password succesfully changed!"
					: "Password berhasil diubah!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(snackbarData));
		window.location.href = "/login";
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearUserError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(resetPasswordFailure(errorMessage));
	}
}

export function* onResetPasswordStart() {
	yield takeLatest(UserActionTypes.RESET_PASSWORD_START, resetPassword);
}

export function* userSagas() {
	yield all([
		call(onEmailSignInStart),
		call(onCheckUserSession),
		call(onSignOutStart),
		call(onSignUpStart),
		call(onFetchUserStart),
		call(onUploadUserPictureStart),
		call(onUpdateUserStart),
		call(onUpdateLocale),
		call(onUpdateUserPasswordStart),
		call(onForgotPasswordStart),
		call(onResetPasswordStart),
	]);
}
