import React from "react";
import { Grid } from "@material-ui/core";
import PillButton from "components/Public/PillButton/PillButton";
import moment from "moment";
import RegularButton from "components/Shared/CustomButtons/Button";

export const tableColumns = (props) => {
	const {
		classes,
		handleOpenDeleteConfirmationModal,
		isSwapActive,
		activeData,
		handleActivateSwap,
		handleDeactivateSwap,
		handleOpenSwapConfirmationModal,
	} = props;
	return [
		{
			title: "Title",
			field: "title",
			cellStyle: {
				width: 300,
				minWidth: 300,
			},
			headerStyle: {
				width: 300,
				minWidth: 300,
			},
		},
		{
			title: "Grade",
			field: "grade",
		},
		{
			title: "Type",
			field: "courseType",
			cellStyle: {
				width: 150,
				minWidth: 150,
			},
			headerStyle: {
				width: 150,
				minWidth: 150,
			},
		},
		{
			title: "Week",
			field: "week",
		},
		{
			title: "",
			field: "",
			cellStyle: {
				width: 430,
				minWidth: 430,
			},
			headerStyle: {
				width: 430,
				minWidth: 430,
			},
			render: (rowData) => {
				return (
					<Grid container>
						<Grid item className={classes.buttonContainer}>
							{isSwapActive ? (
								<>
									{activeData.master_course_id ===
									rowData.master_course_id ? (
										<RegularButton
											type="button"
											color="success"
											onClick={handleDeactivateSwap}
										>
											CANCEL
										</RegularButton>
									) : (
										<RegularButton
											type="button"
											color="info"
											onClick={() => handleOpenSwapConfirmationModal(rowData)}
										>
											SELECT
										</RegularButton>
									)}
								</>
							) : (
								<RegularButton
									type="button"
									color="info"
									onClick={() => handleActivateSwap(rowData)}
								>
									SWAP
								</RegularButton>
							)}
						</Grid>
						<Grid item className={classes.buttonContainer}>
							<a
								href={`/admin/courses/${rowData.master_course_id}/update`}
							>
								<RegularButton type="button" color="info">
									EDIT
								</RegularButton>
							</a>
						</Grid>
						<Grid item className={classes.buttonContainer}>
							{/* <RegularButton
								type="button"
								color="danger"
								onClick={() =>
									handleOpenDeleteConfirmationModal(rowData)
								}
							>
								DELETE
							</RegularButton> */}
						</Grid>
					</Grid>
				);
			},
		},
	];
};
