import React from "react";
import { Divider, Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "./Navigation.styles";
import { ReactComponent as UserIcon } from "../../../assets/img/user-icon.svg";
import NavLinkItem from "../NavlinkItem/NavLinkItem";
import { ReactComponent as EnglishIcon } from "../../../assets/img/english-icon.svg";
import { ReactComponent as IndonesiaIcon } from "../../../assets/img/indonesia-icon.svg";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "redux/user/user.selector";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import AvatarDefaultSmall from "../../../assets/img/avatar-default-small.png";
import { selectUserLanguage } from "redux/user/user.selector";
import { updateUserLanguage } from "redux/user/user.action";
import useDictionary from "../../../hooks/Dictionary.hooks";

const Navigation = (props) => {
	const { currentUser, language, updateUserLanguage } = props;
	const classes = useStyles();
	const translate = useDictionary();

	return (
		<>
			<Hidden mdDown>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					xs={9}
					className={classes.navigationContainer}
				>
					<nav>
						<ul className={classes.navLists}>
							<NavLinkItem
								to="/"
								name={translate("HOME")}
								underlineWidth={70}
							/>
							<NavLinkItem
								to="/our-courses"
								name={translate("OUR_COURSES")}
								underlineWidth={120}
							/>
							<NavLinkItem
								to="/student-performance"
								name={translate("STUDENT_PERFORMANCE")}
								underlineWidth={170}
							/>
							<NavLinkItem
								to="/story-of-adiwidya"
								name={translate("STORIES_OF_ADIWIDYA")}
								underlineWidth={170}
							/>
						</ul>
					</nav>
				</Grid>
				<Grid
					item
					container
					justify="center"
					xs={1}
					alignContent="center"
					className={classes.languageContainer}
				>
					<LanguageSelector />
				</Grid>
				<Grid
					item
					container
					justify="center"
					xs={2}
					alignItems="center"
					className={classes.authContainer}
				>
					{currentUser ? (
						<>
							<Link to="/profile" className={classes.userName}>
								Hello, {currentUser.first_name}!
							</Link>
							<img
								src={
									!!currentUser.image_url
										? currentUser.image_url
										: AvatarDefaultSmall
								}
								alt="user thumbnail"
								className={classes.userThumbnail}
							/>
						</>
					) : (
						<>
							<Link className={classes.auth} to="/login">
								{translate("LOGIN")}
							</Link>
							&nbsp;/&nbsp;
							<Link className={classes.auth} to="/sign-up">
								{translate("SIGNUP")}
							</Link>
							<UserIcon className={classes.userIcon} />
						</>
					)}
				</Grid>
			</Hidden>
			<Hidden lgUp>
				<Grid
					item
					container
					justify="center"
					xs={12}
					alignItems="center"
					className={classes.authContainer}
				>
					{currentUser ? (
						<>
							<Link to="/profile" className={classes.userName}>
								Hello, {currentUser.first_name}!
							</Link>
							<img
								src={
									!!currentUser.image_url
										? currentUser.image_url
										: AvatarDefaultSmall
								}
								alt="user thumbnail"
								className={classes.userThumbnail}
							/>
						</>
					) : (
						<>
							<Link className={classes.auth} to="/login">
								{translate("LOGIN")}
							</Link>
							&nbsp;/&nbsp;
							<Link className={classes.auth} to="/sign-up">
								{translate("SIGNUP")}
							</Link>
							<UserIcon className={classes.userIcon} />
						</>
					)}
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="flex-start"
					xs={12}
					className={`${classes.navigationContainer} `}
				>
					<nav>
						<ul className={classes.navLists}>
							<NavLinkItem
								to="/"
								name={translate("HOME")}
								underlineWidth={70}
							/>
							<NavLinkItem
								to="/our-courses"
								name={translate("OUR_COURSES")}
								underlineWidth={120}
							/>
							<NavLinkItem
								to="/student-performance"
								name={translate("STUDENT_PERFORMANCE")}
								underlineWidth={170}
							/>
							<NavLinkItem
								to="/story-of-adiwidya"
								name={translate("STORIES_OF_ADIWIDYA")}
								underlineWidth={170}
							/>
						</ul>
					</nav>
				</Grid>
				<Grid
					item
					container
					justify="center"
					xs={12}
					alignContent="center"
					className={classes.languageContainer}
				>
					<span
						className={classes.languageSpan}
						onClick={() => updateUserLanguage("EN")}
					>
						<EnglishIcon />
						<p
							className={`${classes.language} ${
								language === "EN" ? "active" : null
							}`}
						>
							EN
						</p>
					</span>
					<Divider
						orientation="vertical"
						flexItem
						className={classes.divider}
					/>
					<span
						className={classes.languageSpan}
						onClick={() => updateUserLanguage("ID")}
					>
						<IndonesiaIcon />
						<p
							className={`${classes.language} ${
								language === "ID" ? "active" : null
							}`}
						>
							ID
						</p>
					</span>
				</Grid>
			</Hidden>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	language: selectUserLanguage,
});
const mapDispatchToProps = (dispatch) => ({
	updateUserLanguage: (language) => dispatch(updateUserLanguage(language)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
