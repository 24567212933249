import React, { useEffect, useRef } from "react";
import { useStyles } from "./CreateTestimony.styles";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid, TextField } from "@material-ui/core";
import { selectTestimonyError } from "redux/testimony/testimony.selector";
import { selectTestimonyLoading } from "redux/testimony/testimony.selector";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import Testimony from "components/Public/Testimony/Testimony";
import ParentTestimonyImage from "../../../assets/img/parent-testimonial.png";
import { createTestimonyStart } from "redux/testimony/testimony.action";
import { clearTestimonyError } from "redux/testimony/testimony.action";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { testimonyCategories } from "helpers/IndexTestimony.helper";
import { useHistory } from "react-router";
import RegularButton from "components/Shared/CustomButtons/Button";
import { languages } from "helpers/App.helper";

const CreateTestimony = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { isLoading, createTestimonyStart } = props;
	const { register, handleSubmit, errors, watch, control } = useForm({
		defaultValues: {
			language: "EN",
		},
	});

	const testimonyEN = useRef();
	testimonyEN.current = watch("testimonyEN", "");
	const personNameEN = useRef();
	personNameEN.current = watch("personNameEN", "");
	const testimonyID = useRef();
	testimonyID.current = watch("testimonyID", "");
	const personNameID = useRef();
	personNameID.current = watch("personNameID", "");
	const image = useRef();
	image.current = watch("image", "");
	const language = useRef();
	language.current = watch("language", "");

	const storeTestimony = (data) => {
		if (!isLoading) {
			createTestimonyStart(data, history);
		}
	};

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Testimony</h4>
					</CardHeader>
					<CardBody>
						<Grid container spacing={3}>
							<Grid container item xs={12}>
								<form onSubmit={handleSubmit(storeTestimony)}>
									<Grid item container xs={12} lg={6}>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={3}
												margin="normal"
												fullWidth
												id="testimonyEN"
												label="Testimony (English)"
												name="testimonyEN"
												inputRef={register({
													required: {
														value: true,
														message:
															"Testimony (English) are required",
													},
												})}
												error={!!errors.testimonyEN}
												helperText={
													errors.testimonyEN &&
													errors.testimonyEN.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={3}
												margin="normal"
												fullWidth
												id="testimonyID"
												label="Testimony (Indonesia)"
												name="testimonyID"
												inputRef={register({
													required: {
														value: true,
														message:
															"Testimony (Indonesia) are required",
													},
												})}
												error={!!errors.testimonyID}
												helperText={
													errors.testimonyID &&
													errors.testimonyID.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												margin="normal"
												fullWidth
												id="personNameEN"
												label="Person Name (English)"
												name="personNameEN"
												inputRef={register({
													required: {
														value: true,
														message:
															"Person Name (English) are required",
													},
												})}
												error={!!errors.personNameEN}
												helperText={
													errors.personNameEN &&
													errors.personNameEN.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												margin="normal"
												fullWidth
												id="personNameID"
												label="Person Name (Indonesia)"
												name="personNameID"
												inputRef={register({
													required: {
														value: true,
														message:
															"Person Name (Indonesia) are required",
													},
												})}
												error={!!errors.personNameID}
												helperText={
													errors.personNameID &&
													errors.personNameID.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<CustomSelect
												margin="normal"
												fullWidth
												id="category"
												label="Testimony Categories"
												name="category"
												options={testimonyCategories}
												control={control}
												rules={{
													required: {
														value: true,
														message:
															"Testimony Categories are required",
													},
												}}
												error={!!errors.category}
												helperText={
													errors.category &&
													errors.category.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputFileField
												margin="normal"
												accept="image/*"
												id="image"
												label="Upload Image"
												inputRef={register({
													required: {
														value: true,
														message:
															"Image are required",
													},
												})}
												value={image.current}
												error={!!errors.image}
												helperText={
													errors.image &&
													errors.image.message
												}
											/>
										</Grid>
									</Grid>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<RegularButton
											type="submit"
											variant="contained"
											color="info"
											className={classes.submit}
										>
											Create Testimony
										</RegularButton>
									</Grid>
								</form>
							</Grid>
						</Grid>
						<Grid
							container
							justify="center"
							className={classes.previewContainer}
						>
							<Grid item xs={12}>
								<h3 className={classes.previewLabel}>
									Preview{" "}
									{language.current === "EN"
										? "English"
										: "Indonesia"}
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.languageSelectorContainer}
							>
								<CustomSelect
									fullWidth
									id="language"
									label="Language"
									name="language"
									options={languages}
									control={control}
									removeNone
								/>
							</Grid>
							<Testimony
								preview
								quote={
									language.current === "EN"
										? testimonyEN.current ||
										  "Testimony goes here..."
										: testimonyID.current ||
										  "Testimony goes here..."
								}
								image={
									(image.current.length > 0 &&
										URL.createObjectURL(
											image.current[0]
										)) ||
									ParentTestimonyImage
								}
								personName={
									language.current === "EN"
										? personNameEN.current ||
										  "Person Name goes Here..."
										: personNameID.current ||
										  "Person Name goes Here..."
								}
							/>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectTestimonyLoading,
});

const mapDispatchToProps = (dispatch) => ({
	createTestimonyStart: (testimonyData, history) =>
		dispatch(createTestimonyStart(testimonyData, history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateTestimony);
