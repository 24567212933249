import NextGradeActionTypes from "./next-grade.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	isDrawerOpen: false,
	activeStep: 0,
	hideBillFooter: false,
	activeChildId: null,
	nextStepFunction: null,
	billDetails: [],
	// batch registration step
	batch: null,
	// next grade step
	isMovingToNextGrade: null,
	// payment interval step
	paymentInterval: null,
	term2Opened: false,
	term4Opened: false,
	term12Opened: false,
	currentSlide: 0,
	// payment method step
	paymentMethod: null,
	showPaymentDetails: false,
	adminFeePercentage: 0,
	// delivery fee step
	courier: null,
	deliveryFee: null,
};

const nextGradeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NextGradeActionTypes.OPEN_NEXT_GRADE_DRAWER:
			return {
				...state,
				isDrawerOpen: true,
			};
		case NextGradeActionTypes.CLOSE_NEXT_GRADE_DRAWER:
			return {
				...state,
				isDrawerOpen: false,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_ACTIVE_CHILD_ID:
			return {
				...state,
				activeChildId: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_PAYMENT_INTERVAL:
			const paymentInterval = action.payload;

			let tempBillDetailsPI = state.billDetails;
			tempBillDetailsPI[1] = {
				nameEN: paymentInterval.setting_name,
				descriptionEN: paymentInterval.setting_desc,
				nameID: paymentInterval.setting_name_indo,
				descriptionID: paymentInterval.setting_desc_indo,
				// name: `${action.payload} months course`,
				discount: parseInt(paymentInterval.value.discount),
				discountTextEN: `${paymentInterval.value.term} months interval discount`,
				discountTextID: `Diskon jangka waktu ${paymentInterval.value.term} bulan`,
				value: parseInt(paymentInterval.value.normal_price),
				additionalInfoEN: `pay per ${paymentInterval.value.term} months`,
				additionalInfoID: `Bayar per ${paymentInterval.value.term} bulan`,
			};
			return {
				...state,
				paymentInterval: paymentInterval,
				billDetails: [...tempBillDetailsPI],
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_PAYMENT_METHOD:
			const paymentMethod = action.payload;
			return {
				...state,
				paymentMethod,
				adminFeePercentage: paymentMethod === "Credit Card" ? 0.03 : 0,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_DELIVERY_COST:
			let tempBillDetailsDC = state.billDetails;

			tempBillDetailsDC[2] = {
				nameEN: `Delivery Fee`,
				nameID: `Biaya Pengiriman`,
				value: action.payload,
				// discount:
				// 	state.paymentInterval.value.term === "12"
				// 		? action.payload
				// 		: 0,
				discount: 0,
				discountTextEN: `Free delivery discount`,
				discountTextID: `Gratis ongkos kirim`,
				additionalInfoEN: `one time delivery`,
				additionalInfoID: `satu kali pengiriman`,
			};
			return {
				...state,
				// deliveryFee:
				// 	state.paymentInterval.value.term === "12"
				// 		? 0
				// 		: action.payload,
				deliveryFee: action.payload,
				billDetails: [...tempBillDetailsDC],
			};
		case NextGradeActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case NextGradeActionTypes.RESET_NEXT_GRADE:
			return {
				...state,
				error: null,
				loading: false,
				isDrawerOpen: false,
				activeStep: 0,
				hideBillFooter: false,
				activeChildId: null,
				nextStepFunction: null,
				billDetails: [],
				// next grade step
				isMovingToNextGrade: null,
				// payment interval step
				paymentInterval: null,
				term2Opened: false,
				term4Opened: false,
				term12Opened: false,
				currentSlide: 0,
				// payment method step
				paymentMethod: null,
				showPaymentDetails: false,
				// delivery fee step
				courier: null,
				deliveryFee: null,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_ACTIVE_STEP:
			return {
				...state,
				activeStep: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_NEXT_STEP_FUNCTION:
			return {
				...state,
				nextStepFunction: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_SHOW_PAYMENT_DETAILS:
			return {
				...state,
				showPaymentDetails: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_HIDE_BILL_FOOTER:
			return {
				...state,
				hideBillFooter: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_IS_MOVING_TO_NEXT_GRADE:
			return {
				...state,
				isMovingToNextGrade: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_FIXED_PRICE_LIST:
			const priceList = action.payload;
			let tempBillDetailsFPL = state.billDetails;
			for (let i = 0; i < priceList.length; i++) {
				const price = priceList[i];
				if (
					price.setting_name !== "Study Kit" &&
					price.setting_name !== "English Course Book"
				) {
					tempBillDetailsFPL[i] = {
						...price,
						nameEN: price.setting_name,
						descriptionEN: price.setting_desc,
						nameID: price.setting_name_indo,
						descriptionID: price.setting_desc_indo,
						value: price.value.price,
						discount:
							price.setting_name === "Lifetime Registration"
								? price.value.price
								: 0,
						discountTextEN:
							price.setting_name === "Lifetime Registration"
								? `Free lifetime registration`
								: "",
						discountTextID:
							price.setting_name === "Lifetime Registration"
								? `Gratis registrasi seumur hidup`
								: "",
						valueObj: price.value,
						value: parseInt(price.value.price),
						additionalInfoID: price.value.term_indo,
						additionalInfoEN: price.value.term,
					};
				}
			}

			return {
				...state,
				billDetails: [...tempBillDetailsFPL],
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_PAYMENT_INTERVAL_DETAIL:
			return {
				...state,
				[`term${action.payload.term}Opened`]: action.payload.value,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_PAYMENT_INTERVAL_CURRENT_SLIDE:
			return {
				...state,
				currentSlide: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_BATCH:
			return {
				...state,
				batch: action.payload,
			};
		case NextGradeActionTypes.SET_NEXT_GRADE_COURIER:
			return {
				...state,
				courier: action.payload,
			};
		default:
			return state;
	}
};

export default nextGradeReducer;
