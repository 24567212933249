import CourseMasterActionTypes from "./course-master.types";

const INITIAL_STATE = {
	plural: [],
	singular: null,
	error: null,
	loading: false,
	uploadProgress: 0,
	fltGrade: "",
	fltCourseType: "",
};

const courseMasterReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CourseMasterActionTypes.FETCH_COURSE_MASTERS_START:
		case CourseMasterActionTypes.FETCH_COURSE_MASTER_START:
		case CourseMasterActionTypes.CREATE_COURSE_MASTER_START:
		case CourseMasterActionTypes.UPDATE_COURSE_MASTER_START:
		case CourseMasterActionTypes.DELETE_COURSE_MASTER_START:
		case CourseMasterActionTypes.SWAP_COURSE_MASTER_START:
			return {
				...state,
				loading: true,
			};
		case CourseMasterActionTypes.FETCH_COURSE_MASTERS_FAILURE:
		case CourseMasterActionTypes.FETCH_COURSE_MASTER_FAILURE:
		case CourseMasterActionTypes.CREATE_COURSE_MASTER_FAILURE:
		case CourseMasterActionTypes.UPDATE_COURSE_MASTER_FAILURE:
		case CourseMasterActionTypes.DELETE_COURSE_MASTER_FAILURE:
		case CourseMasterActionTypes.SWAP_COURSE_MASTER_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case CourseMasterActionTypes.FETCH_COURSE_MASTERS_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case CourseMasterActionTypes.FETCH_COURSE_MASTER_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case CourseMasterActionTypes.CREATE_COURSE_MASTER_SUCCESS:
		case CourseMasterActionTypes.UPDATE_COURSE_MASTER_SUCCESS:
		case CourseMasterActionTypes.DELETE_COURSE_MASTER_SUCCESS:
		case CourseMasterActionTypes.SWAP_COURSE_MASTER_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case CourseMasterActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case CourseMasterActionTypes.CLEAR_DATA:
			return {
				...state,
				plural: [],
				singular: null,
				error: null,
			};
		case CourseMasterActionTypes.SET_COURSE_MASTER_UPLOAD_PROGRESS:
			return {
				...state,
				uploadProgress: action.payload,
			};
		case CourseMasterActionTypes.SET_COURSE_MASTER_FILTER:
			return {
				...state,
				fltGrade: action.payload.grade,
				fltCourseType: action.payload.courseType,
			};
		default:
			return state;
	}
};

export default courseMasterReducer;
