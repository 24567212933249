import { createSelector } from "reselect";

const selectPayment = (state) => state.payment;

export const selectPaymentError = createSelector(
	[selectPayment],
	(payment) => payment.error
);

export const selectPaymentLoading = createSelector(
	[selectPayment],
	(payment) => payment.loading
);

export const selectPaymentFor = createSelector(
	[selectPayment],
	(payment) => payment.paymentFor
);

export const selectPaymentPriceList = createSelector(
	[selectPayment],
	(payment) => payment.priceList
);

export const selectPaymentSuccess = createSelector(
	[selectPayment],
	(payment) => payment.paymentSuccess
);

export const selectSuccessResponse = createSelector(
	[selectPayment],
	(payment) => payment.successResponse
);

export const selectCreditCardToken = createSelector(
	[selectPayment],
	(payment) => payment.creditCardToken
);

export const selectCreditCardRedirectUrl = createSelector(
	[selectPayment],
	(payment) => payment.creditCardRedirectUrl
);

export const selectOpen3dsAuthentication = createSelector(
	[selectPayment],
	(payment) => payment.isOpen3dsAuthentication
);

export const selectPaymentOrderId = createSelector(
	[selectPayment],
	(payment) => payment.orderId
);

export const selectPaymentStatus = createSelector(
	[selectPayment],
	(payment) => payment.paymentStatus
);

export const selectPaymentIsAutoGrade = createSelector(
	[selectPayment],
	(payment) => payment.isAutoGrade
);

export const selectPaymentHistories = createSelector(
	[selectPayment],
	(payment) => payment.paymentHistories
);
