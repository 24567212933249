import React, { useEffect, useRef } from "react";
import { useStyles } from "./UpdateTestimony.styles";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Button, Grid, TextField } from "@material-ui/core";
import { selectTestimonyLoading } from "redux/testimony/testimony.selector";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import Testimony from "components/Public/Testimony/Testimony";
import ParentTestimonyImage from "../../../assets/img/parent-testimonial.png";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { testimonyCategories } from "helpers/IndexTestimony.helper";
import { useHistory, useParams } from "react-router";
import { selectTestimonySingular } from "redux/testimony/testimony.selector";
import { getTestimonyStart } from "redux/testimony/testimony.action";
import { updateTestimonyStart } from "redux/testimony/testimony.action";
import { clearTestimonyData } from "redux/testimony/testimony.action";
import { languages } from "helpers/App.helper";

const UpdateTestimony = (props) => {
	const classes = useStyles();
	const { testimonyId } = useParams();
	const history = useHistory();
	const {
		isLoading,
		getTestimonyStart,
		testimonyData,
		updateTestimonyStart,
		clearTestimonyData,
	} = props;
	const {
		register,
		handleSubmit,
		errors,
		watch,
		control,
		setValue,
	} = useForm({
		defaultValues: {
			language: "EN",
		},
	});

	const testimonyEN = useRef();
	testimonyEN.current = watch("testimonyEN", "");
	const personNameEN = useRef();
	personNameEN.current = watch("personNameEN", "");
	const testimonyID = useRef();
	testimonyID.current = watch("testimonyID", "");
	const personNameID = useRef();
	personNameID.current = watch("personNameID", "");
	const image = useRef();
	image.current = watch("image", "");
	const language = useRef();
	language.current = watch("language", "");

	useEffect(() => {
		if (testimonyData) {
			setValue("testimonyEN", testimonyData.testimony);
			setValue("personNameEN", testimonyData.sender);
			setValue("testimonyID", testimonyData.testimony_title_indo);
			setValue("personNameID", testimonyData.sender_indo);
			setValue("category", testimonyData.category);
		}
	}, [testimonyData]);

	useEffect(() => {
		getTestimonyStart({ uuid: testimonyId });
		return () => {
			clearTestimonyData();
		};
	}, []);

	const updateTestimony = (data) => {
		if (!isLoading) {
			updateTestimonyStart({ id: testimonyId, ...data }, history);
		}
	};

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Testimony</h4>
					</CardHeader>
					<CardBody>
						<Grid container spacing={3}>
							<Grid container item xs={12}>
								<form onSubmit={handleSubmit(updateTestimony)}>
									<Grid item container xs={6}>
										<Grid item xs={12}>
											<Controller
												render={({
													onChange,
													onBlur,
													value,
													name,
													ref,
												}) => (
													<TextField
														multiline
														rows={3}
														margin="normal"
														fullWidth
														id="testimonyEN"
														label="Testimony (English)"
														name={name}
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														inputRef={ref}
														error={
															!!errors.testimonyEN
														}
														helperText={
															errors.testimonyEN &&
															errors.testimonyEN
																.message
														}
													/>
												)}
												control={control}
												name="testimonyEN"
												defaultValue=""
												rules={{
													required: {
														value: true,
														message:
															"Testimony (English) are required",
													},
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												render={({
													onChange,
													onBlur,
													value,
													name,
													ref,
												}) => (
													<TextField
														multiline
														rows={3}
														margin="normal"
														fullWidth
														id="testimonyID"
														label="Testimony (Indonesia)"
														name={name}
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														inputRef={ref}
														error={
															!!errors.testimonyID
														}
														helperText={
															errors.testimonyID &&
															errors.testimonyID
																.message
														}
													/>
												)}
												control={control}
												name="testimonyID"
												defaultValue=""
												rules={{
													required: {
														value: true,
														message:
															"Testimony (Indonesia) are required",
													},
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												render={({
													onChange,
													onBlur,
													value,
													name,
													ref,
												}) => (
													<TextField
														multiline
														rows={3}
														margin="normal"
														fullWidth
														id="personNameEN"
														label="Person Name (English)"
														name={name}
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														inputRef={ref}
														error={
															!!errors.personNameEN
														}
														helperText={
															errors.personNameEN &&
															errors.personNameEN
																.message
														}
													/>
												)}
												control={control}
												name="personNameEN"
												defaultValue=""
												rules={{
													required: {
														value: true,
														message:
															"Person Name (English) are required",
													},
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												render={({
													onChange,
													onBlur,
													value,
													name,
													ref,
												}) => (
													<TextField
														multiline
														rows={3}
														margin="normal"
														fullWidth
														id="personNameID"
														label="Person Name (Indonesia)"
														name={name}
														onChange={onChange}
														onBlur={onBlur}
														value={value}
														inputRef={ref}
														error={
															!!errors.personNameID
														}
														helperText={
															errors.personNameID &&
															errors.personNameID
																.message
														}
													/>
												)}
												control={control}
												name="personNameID"
												defaultValue=""
												rules={{
													required: {
														value: true,
														message:
															"Person Name (Indonesia) are required",
													},
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<CustomSelect
												margin="normal"
												fullWidth
												id="category"
												label="Testimony Categories"
												name="category"
												options={testimonyCategories}
												control={control}
												rules={{
													required: {
														value: true,
														message:
															"Testimony Categories are required",
													},
												}}
												error={!!errors.category}
												helperText={
													errors.category &&
													errors.category.message
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<InputFileField
												margin="normal"
												accept="image/*"
												id="image"
												label="Upload Image"
												inputRef={register()}
												value={image.current}
												error={!!errors.image}
												helperText={
													errors.image &&
													errors.image.message
												}
											/>
										</Grid>
									</Grid>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											className={classes.submit}
										>
											Update Testimony
										</Button>
									</Grid>
								</form>
							</Grid>
						</Grid>
						<Grid
							container
							justify="center"
							className={classes.previewContainer}
						>
							<Grid item xs={12}>
								<h3 className={classes.previewLabel}>
									Preview{" "}
									{language.current === "EN"
										? "English"
										: "Indonesia"}
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.languageSelectorContainer}
							>
								<CustomSelect
									fullWidth
									id="language"
									label="Language"
									name="language"
									options={languages}
									control={control}
									removeNone
								/>
							</Grid>
							<Testimony
								preview
								quote={
									language.current === "EN"
										? testimonyEN.current ||
										  "Testimony goes here..."
										: testimonyID.current ||
										  "Testimony goes here..."
								}
								image={
									image.current.length > 0
										? (image.current.length > 0 &&
												URL.createObjectURL(
													image.current[0]
												)) ||
										  ParentTestimonyImage
										: testimonyData &&
										  `${testimonyData.image_url}`
								}
								personName={
									language.current === "EN"
										? personNameEN.current ||
										  "Person Name goes Here..."
										: personNameID.current ||
										  "Person Name goes Here..."
								}
							/>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectTestimonyLoading,
	testimonyData: selectTestimonySingular,
});

const mapDispatchToProps = (dispatch) => ({
	getTestimonyStart: (filters) => dispatch(getTestimonyStart(filters)),
	updateTestimonyStart: (testimonyData, history) =>
		dispatch(updateTestimonyStart(testimonyData, history)),
	clearTestimonyData: () => dispatch(clearTestimonyData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTestimony);
