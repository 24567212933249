import { Grid, TextField } from "@material-ui/core";
import PillButton from "components/Public/PillButton/PillButton";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { grades } from "helpers/App.helper";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { updateChildGradeStart } from "redux/child/child.action";
import { selectChildLoading } from "redux/child/child.selector";
import { createStructuredSelector } from "reselect";
import { useStyles } from "./UpdateChildModal.styles";

const UpdateChildModal = (props) => {
	const classes = useStyles();
	const {
		data,
		open,
		onClose,
		isChildLoading,
		updateChildGradeStart,
		onFinishUpdate
	} = props;

	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		setValue,
	} = useForm({
		shouldUnregister: false,
	});

	const handleClose = () => {
		onClose();
		reset({
			status: "",
			resi: "",
		});
	};

	const updateChildGrade = (formData) => {
		updateChildGradeStart({
			...formData,
			childId: data.child_id,
			onFinishUpdate,
		});
		handleClose();
	};

	useEffect(() => {
		if (!!data) {
			setValue("grade", data.grade);
		}
	}, [data]);

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			width="40vw"
			title="Update Child"
		>
			<Grid container>
				<form onSubmit={handleSubmit(updateChildGrade)}>
					<Grid container justify="center" alignContent="center">
						<Grid item xs={12} container>
							<Grid item xs={3}>
								<b>Child Name</b>
							</Grid>
							<Grid item xs={1}>
								:
							</Grid>
							<Grid
								item
								xs={8}
							>{`${data.first_name} ${data.last_name}`}</Grid>
						</Grid>
						<Grid item xs={12} container>
							<Grid item xs={3}>
								<b>Parent Name</b>
							</Grid>
							<Grid item xs={1}>
								:
							</Grid>
							<Grid
								item
								xs={8}
							>{`${data.parent_first_name} ${data.parent_last_name}`}</Grid>
						</Grid>
						<Grid item xs={12} className={classes.selectContainer}>
							<CustomSelect
								margin="normal"
								fullWidth
								id="grade"
								label="Grade"
								name="grade"
								options={grades}
								control={control}
							/>
						</Grid>
						<Grid item xs={12} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="submit"
								text="UPDATE"
								backgroundColor="#2E7D32"
								color="white"
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</CustomModal>
	);
};

const mapStateToProps = createStructuredSelector({
	isChildLoading: selectChildLoading,
});

const mapDispatchToProps = (dispatch) => ({
	updateChildGradeStart: (childInfo) =>
		dispatch(updateChildGradeStart(childInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateChildModal);
