import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexAcademicYear.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableColumns } from "../../../helpers/IndexAcademicYear.helper";
import { selectAcademicYearPlural } from "redux/academic-year/academic-year.selector";
import { selectAcademicYearLoading } from "redux/academic-year/academic-year.selector";
import { fetchAcademicYearsStart } from "redux/academic-year/academic-year.action";
import { clearAcademicYearData } from "redux/academic-year/academic-year.action";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import PillButton from "components/Public/PillButton/PillButton";
import { deactivateAcademicYearStart } from "redux/academic-year/academic-year.action";
import { activateAcademicYearStart } from "redux/academic-year/academic-year.action";
import moment from "moment";
import { openSnackbar } from "redux/snackbar/snackbar.action";

const IndexAcademicYear = (props) => {
	const classes = useStyles();
	const tableRef = useRef();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [activeData, setActiveData] = useState(null);
	const [type, setType] = useState(null);

	const {
		isLoading,
		academicYears,
		fetchAcademicYearsStart,
		clearAcademicYearData,
		deactivateAcademicYearStart,
		activateAcademicYearStart,
		openSnackbar,
	} = props;

	const handleOpenNonActiveConfirmationModal = (rowData) => {
		const isActiveBatch =
			moment(rowData.start_date) < moment() &&
			moment(rowData.end_date) > moment();

		if (!isActiveBatch) {
			setConfirmationModal(true);
			setActiveData(rowData);
			setType("deactivate");
		} else {
			const snackbarData = {
				message: "The batch is currently in progress",
				color: "error",
				place: "bl",
				dispatchActions: [],
			};
			openSnackbar(snackbarData);
		}
	};
	const handleCloseNonActiveConfirmationModal = () => {
		setConfirmationModal(false);
		setActiveData(null);
		setType(null);
	};

	const handleOpenActiveConfirmationModal = (rowData) => {
		const validDate = moment(rowData.start_date) > moment();

		if (validDate) {
			setConfirmationModal(true);
			setActiveData(rowData);
			setType("activate");
		} else {
			const snackbarData = {
				message: "The batch is already finished",
				color: "error",
				place: "bl",
				dispatchActions: [],
			};
			openSnackbar(snackbarData);
		}
	};
	const handleCloseActiveConfirmationModal = () => {
		setConfirmationModal(false);
		setActiveData(null);
		setType(null);
	};

	const handleConfirmation = () => {
		if (!isLoading) {
			if (type === "activate") {
				activateAcademicYearStart(activeData.academic_year_id);
			} else {
				deactivateAcademicYearStart(activeData.academic_year_id);
			}
			handleCloseNonActiveConfirmationModal();
			tableRef.current.onChangePage(null, 0);
		}
	};

	const handleCloseModal = () => {
		if (type === "activate") {
			handleCloseActiveConfirmationModal();
		} else {
			handleCloseNonActiveConfirmationModal();
		}
	};

	useEffect(() => {
		fetchAcademicYearsStart();
		return () => {
			clearAcademicYearData();
		};
	}, []);
	return (
		<Grid container>
			<CustomModal
				open={confirmationModal}
				width="40vw"
				title="Are you sure?"
				onClose={handleCloseModal}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Yes, I am sure"
								backgroundColor="green"
								onClick={handleConfirmation}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="No, I change my mind"
								onClick={handleCloseModal}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				Are you sure you want {type} batch{" "}
				{activeData && `${activeData.batch}`}?<br />
				<br />
				{type === "deactivate" && (
					<b>
						Deactivate means you only simply make the batch no
						longer available for the user, but the data still will
						be stored in the database.
					</b>
				)}
			</CustomModal>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Batchs</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="flex-end">
							<Grid
								item
								xs={12}
								lg={6}
								container
								justify="flex-end"
							>
								<a href="/admin/batchs/create">
									<Button
										className={classes.buttonOnTable}
										color="info"
									>
										CREATE NEW BATCH
									</Button>
								</a>
							</Grid>
						</Grid>
						<MaterialTable
							tableRef={tableRef}
							isLoading={isLoading}
							columns={tableColumns({
								classes,
								handleOpenNonActiveConfirmationModal,
								handleOpenActiveConfirmationModal,
							})}
							data={academicYears || []}
							title={""}
							options={{
								search: true,
								exportButton: false,
								pageSize: 10,
							}}
						/>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	academicYears: selectAcademicYearPlural,
	isLoading: selectAcademicYearLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAcademicYearsStart: (filters) =>
		dispatch(fetchAcademicYearsStart(filters)),
	clearAcademicYearData: () => dispatch(clearAcademicYearData()),
	deactivateAcademicYearStart: (academicYearId) =>
		dispatch(deactivateAcademicYearStart(academicYearId)),
	activateAcademicYearStart: (academicYearId) =>
		dispatch(activateAcademicYearStart(academicYearId)),
	openSnackbar: (data) => dispatch(openSnackbar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexAcademicYear);
