import PlacementTestActionTypes from "./placement-test.types";

export const setActiveChildId = (childId) => ({
	type: PlacementTestActionTypes.SET_ACTIVE_CHILD_ID,
	payload: childId,
});

export const setFinishTime = (finishTime) => ({
	type: PlacementTestActionTypes.SET_FINISH_TIME,
	payload: finishTime,
});

export const clearPlacementTestError = () => ({
	type: PlacementTestActionTypes.CLEAR_ERROR,
});

export const setActivePart = (activePart) => ({
	type: PlacementTestActionTypes.SET_ACTIVE_PART,
	payload: activePart,
});

export const handleFinishTestStart = (history) => ({
	type: PlacementTestActionTypes.HANDLE_FINISH_TEST_START,
	history,
});

export const handleFinishTestSuccess = (result) => ({
	type: PlacementTestActionTypes.HANDLE_FINISH_TEST_SUCCESS,
	payload: result,
});

export const handleFinishTestFailure = (error) => ({
	type: PlacementTestActionTypes.HANDLE_FINISH_TEST_FAILURE,
	payload: error,
});

export const handleStartTestStart = ({ childId }) => ({
	type: PlacementTestActionTypes.HANDLE_START_TEST_START,
	payload: childId,
});

export const handleStartTestSuccess = (result) => ({
	type: PlacementTestActionTypes.HANDLE_START_TEST_SUCCESS,
	payload: result,
});

export const handleStartTestFailure = (error) => ({
	type: PlacementTestActionTypes.HANDLE_START_TEST_FAILURE,
	payload: error,
});

export const fetchPlacementTestStart = (placementTestId) => ({
	type: PlacementTestActionTypes.FETCH_PLACEMENT_TEST_START,
	payload: placementTestId,
});

export const fetchPlacementTestSuccess = (result) => ({
	type: PlacementTestActionTypes.FETCH_PLACEMENT_TEST_SUCCESS,
	payload: result,
});

export const fetchPlacementTestFailure = (error) => ({
	type: PlacementTestActionTypes.FETCH_PLACEMENT_TEST_FAILURE,
	payload: error,
});

export const setAnswer = ({ answer, part, question }) => ({
	type: PlacementTestActionTypes.SET_ANSWER,
	payload: { answer, part, question },
});

export const resetPlacementTest = () => ({
	type: PlacementTestActionTypes.RESET_PLACEMENT_TEST,
});

export const setIsNextButtonDisabled = (isButtonNextDisabled) => ({
	type: PlacementTestActionTypes.SET_IS_BUTTON_NEXT_DISABLED,
	payload: isButtonNextDisabled,
});
