const ChildrenActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// Fetch CHILD
	FETCH_CHILD_START: "FETCH_CHILD_START",
	FETCH_CHILD_SUCCESS: "FETCH_CHILD_SUCCESS",
	FETCH_CHILD_FAILURE: "FETCH_CHILD_FAILURE",
	// Add child
	ADD_CHILD_START: "ADD_CHILD_START",
	ADD_CHILD_SUCCESS: "ADD_CHILD_SUCCESS",
	ADD_CHILD_FAILURE: "ADD_CHILD_FAILURE",
	// reset var
	RESET_CHILD: "RESET_CHILD",
	// Upload Child Picture
	UPLOAD_CHILD_PICTURE_START: "UPLOAD_CHILD_PICTURE_START",
	UPLOAD_CHILD_PICTURE_SUCCESS: "UPLOAD_CHILD_PICTURE_SUCCESS",
	UPLOAD_CHILD_PICTURE_FAILURE: "UPLOAD_CHILD_PICTURE_FAILURE",
	// update child
	UPDATE_CHILD_START: "UPDATE_CHILD_START",
	UPDATE_CHILD_SUCCESS: "UPDATE_CHILD_SUCCESS",
	UPDATE_CHILD_FAILURE: "UPDATE_CHILD_FAILURE",
	// FETCH BILLING PROGRESS
	FETCH_CHILD_BILLING_PROGRESS_START: "FETCH_CHILD_BILLING_PROGRESS_START",
	FETCH_CHILD_BILLING_PROGRESS_SUCCESS:
		"FETCH_CHILD_BILLING_PROGRESS_SUCCESS",
	FETCH_CHILD_BILLING_PROGRESS_FAILURE:
		"FETCH_CHILD_BILLING_PROGRESS_FAILURE",
	// FETCH CHILD BY ACADEMIC YEAR
	FETCH_CHILD_BY_ACADEMIC_YEAR_START: "FETCH_CHILD_BY_ACADEMIC_YEAR_START",
	FETCH_CHILD_BY_ACADEMIC_YEAR_SUCCESS:
		"FETCH_CHILD_BY_ACADEMIC_YEAR_SUCCESS",
	FETCH_CHILD_BY_ACADEMIC_YEAR_FAILURE:
		"FETCH_CHILD_BY_ACADEMIC_YEAR_FAILURE",
	// UPDATE CHILD GRADE
	UPDATE_CHILD_GRADE_START: "UPDATE_CHILD_GRADE_START",
	UPDATE_CHILD_GRADE_SUCCESS: "UPDATE_CHILD_GRADE_SUCCESS",
	UPDATE_CHILD_GRADE_FAILURE: "UPDATE_CHILD_GRADE_FAILURE",
	// FETCH CHILD COURSES
	FETCH_CHILD_COURSES_START: "FETCH_CHILD_COURSES_START",
	FETCH_CHILD_COURSES_SUCCESS: "FETCH_CHILD_COURSES_SUCCESS",
	FETCH_CHILD_COURSES_FAILURE: "FETCH_CHILD_COURSES_FAILURE",
	// FETCH CHILD COURSE
	FETCH_CHILD_COURSE_START: "FETCH_CHILD_COURSE_START",
	FETCH_CHILD_COURSE_SUCCESS: "FETCH_CHILD_COURSE_SUCCESS",
	FETCH_CHILD_COURSE_FAILURE: "FETCH_CHILD_COURSE_FAILURE",
	// SET CHILD ACTIVE BILLING
	SET_CHILD_ACTIVE_BILLING: "SET_CHILD_ACTIVE_BILLING",
	// MARK WATCH VIDEO
	MARK_WATCH_VIDEO: "MARK_WATCH_VIDEO",
};

export default ChildrenActionTypes;
