import React from "react";
import { Grid } from "@material-ui/core";
import RegularButton from "components/Shared/CustomButtons/Button";

export const tableColumns = (props) => {
	const { classes, handleOpenDeleteConfirmationModal } = props;
	return [
		{
			title: "Testimony",
			field: "testimony",
			cellStyle: {
				width: 600,
				minWidth: 600,
			},
			headerStyle: {
				width: 600,
				minWidth: 600,
			},
		},
		{
			title: "Sender",
			field: "sender",
		},
		{
			title: "",
			field: "",
			render: (rowData) => {
				return (
					<Grid container justify="center">
						<Grid item className={classes.buttonContainer}>
							<a
								href={`/admin/testimonies/${rowData.testimony_id}/update`}
							>
								<RegularButton type="button" color="info">
									Edit
								</RegularButton>
							</a>
						</Grid>
						<Grid item className={classes.buttonContainer}>
							<RegularButton
								type="button"
								onClick={() =>
									handleOpenDeleteConfirmationModal(rowData)
								}
								color="danger"
							>
								Delete
							</RegularButton>
						</Grid>
					</Grid>
				);
			},
		},
	];
};

export const testimonyCategories = [
	{ value: 1, textEN: "Parent Testimonial", textID: "Parent Testimonial" },
	{ value: 2, textEN: "Our Graduates", textID: "Our Graduates" },
	{ value: 3, textEN: "Our Students", textID: "Our Students" },
];
