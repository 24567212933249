import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./PillButton.styles";

function PillButton(props) {
	const {
		text,
		element,
		target,
		to,
		type,
		onClick,
		backgroundColor,
		color,
		additionalClasses,
		disabled,
	} = props;
	const classes = useStyles({
		backgroundColor,
		color,
	});

	const additionalClassesText =
		!!additionalClasses && additionalClasses.length > 0
			? additionalClasses.join(" ")
			: "";

	switch (element) {
		case "button":
			return (
				<button
					disabled={!!disabled}
					type={type}
					className={`${
						classes.pillButton
					} ${additionalClassesText} ${
						!!disabled ? "disabled" : null
					}`}
					onClick={onClick}
				>
					{text}
				</button>
			);
		case "link":
			return (
				<a href={to} target={target}>
					<button
						disabled={!!disabled}
						type="button"
						className={`${
							classes.pillButton
						} ${additionalClassesText} ${
							!!disabled ? "disabled" : null
						}`}
					>
						{text}
					</button>
				</a>
			);
		default:
			return (
				<button
					disabled={!!disabled}
					type={type}
					className={`${
						classes.pillButton
					} ${additionalClassesText} ${
						!!disabled ? "disabled" : null
					}`}
					onClick={onClick}
				>
					{text}
				</button>
			);
	}
}

export default PillButton;
