import React, { useEffect, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from "react-router-dom";
import NotFound from "views/Shared/ErrorPages/NotFound.js";
import routes from "../routes/public.routes.js";
import "../assets/css/app.css";
import Header from "components/Public/Header/Header.js";
import Content from "components/Public/Content/Content.js";
import Footer from "components/Public/Footer/Footer.js";
import "pure-react-carousel/dist/react-carousel.es.css";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectCurrentUser } from "redux/user/user.selector.js";
import { checkUserSession } from "redux/user/user.action.js";
import { selectUserLoading } from "redux/user/user.selector.js";
import { signOutStart } from "redux/user/user.action.js";

const switchRoutes = (props) => (
	<Switch>
		{routes.map((prop, key) => {
			switch (prop.checkAuth) {
				case false:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => (
								<>
									{prop.withHeader && <Header />}
									<Content>
										{props.currentUser ? (
											<Redirect to="/" />
										) : (
											React.createElement(prop.component)
										)}
									</Content>
									{prop.withFooter && <Footer />}
								</>
							)}
						/>
					);
				case true:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => (
								<>
									{prop.withHeader && <Header />}
									<Content>
										{props.currentUser ? (
											React.createElement(prop.component)
										) : (
											<Redirect to="/login" />
										)}
									</Content>
									{prop.withFooter && <Footer />}
								</>
							)}
						/>
					);
				default:
					return (
						<Route
							path={prop.path}
							key={key}
							exact
							render={() => (
								<>
									{prop.withHeader && <Header />}
									<Content>
										{React.createElement(prop.component)}
									</Content>
									{prop.withFooter && <Footer />}
								</>
							)}
						/>
					);
			}
		})}
		<Route
			render={() => (
				<>
					<Header />
					<Content>{React.createElement(NotFound)}</Content>
					<Footer />
				</>
			)}
		/>
	</Switch>
);

const Public = (props) => {
	const { currentUser, isUserLoading, signOutStart } = props;
	const history = useHistory();
	const location = useLocation();
	const [isLogoutStart, setIsLogoutStart] = useState(false);

	useEffect(() => {
		if (!!currentUser) {
			const isPublicUser = !!!parseInt(`${currentUser.status}`);

			if (!isPublicUser) setIsLogoutStart(true);
		}
	}, [currentUser, location]);

	useEffect(() => {
		window.scrollTo(0, 0);
		checkUserSession();
	}, [checkUserSession, location]);

	useEffect(() => {
		if (!isUserLoading && isLogoutStart) {
			signOutStart();
			setIsLogoutStart(false);
		}
	}, [isUserLoading, isLogoutStart]);

	return (
		<div
			style={{
				position: "relative",
				minHeight: "50vh",
				backgroundColor: "#FFFFFF",
			}}
		>
			{switchRoutes(props)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	isUserLoading: selectUserLoading,
});
const mapDispatchToProps = (dispatch) => ({
	checkUserSession: () => dispatch(checkUserSession()),
	signOutStart: () => dispatch(signOutStart()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Public);
