const OrderActionTypes = {
	// FETCH ALL ORDERS
	FETCH_ORDERS_START: "FETCH_ORDERS_START",
	FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
	FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE",
	// FETCH ONE ORDER
	FETCH_ORDER_START: "FETCH_ORDER_START",
	FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
	FETCH_ORDER_FAILURE: "FETCH_ORDER_FAILURE",
	// UPDATE ORDER
	UPDATE_ORDER_START: "UPDATE_ORDER_START",
	UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
	UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
	CLEAR_DATA: "CLEAR_DATA",
};

export default OrderActionTypes;
