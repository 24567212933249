import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	fetchAvailableBatchsSuccess,
	clearBuyCourseError,
	fetchAvailableBatchsFailure,
} from "./buy-course.action";
import BuyCourseActionTypes from "./buy-course.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* buyCourseSagas() {
	yield all([]);
}
