import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useStyles } from "../NavlinkItem/NavLinkItem.styles";
import NavLinkUnderline from "../NavLinkUnderline/NavLinkUnderline";

const NavLinkItem = (props) => {
	const classes = useStyles();
	const { to, underlineWidth, name } = props;
	const match = useRouteMatch(to);
	const hidden = !!match
		? match.path === "/"
			? !match.isExact
			: to.indexOf(match.path) >= 0
			? false
			: true
		: true;

	return (
		<li className={classes.navLinkItem}>
			<NavLink
				className={classes.navigation}
				to={to}
				activeClassName={classes.activeNavigation}
				exact
			>
				{name}
			</NavLink>
			<NavLinkUnderline hidden={hidden} width={underlineWidth} />
		</li>
	);
};

export default NavLinkItem;
