import { Grid, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./Header.styles";
import { ReactComponent as AdiwidyaLogo } from "../../../assets/img/adiwidya-logo.svg";
import Navigation from "../Navigation/Navigation";
import BurgerIcon from "../BurgerIcon/BurgerIcon";
import { useHistory } from "react-router";

const Header = () => {
	const classes = useStyles();
	const history = useHistory();
	// MOBILE ONLY
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(false);
	}, [history.location.pathname]);

	return (
		<header>
			<Grid
				className={`${classes.header} ${open && "open"}`}
				container
				justify="center"
				alignContent="flex-start"
				alignItems="flex-start"
			>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={2}
					className={classes.logoContainer}
				>
					<a href="/">
						<AdiwidyaLogo className={classes.logo} />
					</a>
					<Hidden lgUp>
						<BurgerIcon open={open} handleOpen={setOpen} />
					</Hidden>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={10}
					container
					justify="center"
					alignItems="flex-start"
					className={classes.navigationContainer}
				>
					<Navigation />
				</Grid>
			</Grid>
		</header>
	);
};

export default Header;
