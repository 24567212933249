import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	header: {
		background: "#FFFFFF",
		boxShadow: "0px 4px 10px rgba(91, 83, 83, 0.25)",
		minHeight: "124px",
		maxHeight: "124px",
		borderRadius: "0px 0px 10px 10px",
		position: "fixed",
		top: "0",
		zIndex: 1,
		transition: "all 0.7s cubic-bezier(0.19, 1, 0.22, 1)",
		"&.open": {
			minHeight: "100vh",
		},
		[theme.breakpoints.down("md")]: {
			overflow: "hidden",
			maxHeight: "80px",
			minHeight: "80px",
			borderRadius: "0px 0px 10px 10px",
		},
	},
	logoContainer: {
		display: "flex",
		paddingTop: "30px",
		justifyContent: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "space-between",
			paddingTop: "15px",
			paddingLeft: "15px",
			paddingRight: "15px",
		},
	},
	logo: {
		cursor: "pointer",
		width: "68px",
		height: "68px",
		[theme.breakpoints.down("md")]: {
			maxWidth: "50px",
			maxHeight: "50px",
		},
	},
	navigationContainer: {
		[theme.breakpoints.down("md")]: {
			marginTop: "50px",
		},
	},
}));
