import UserActionTypes from "./user.types";

export const clearUserError = () => ({
	type: UserActionTypes.CLEAR_ERROR,
});

export const emailSignInStart = ({ credentials, type }) => ({
	type: UserActionTypes.EMAIL_SIGN_IN_START,
	payload: { credentials, type },
});

export const signInSuccess = (user) => ({
	type: UserActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = (error) => ({
	type: UserActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

export const checkUserSession = () => ({
	type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
	type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
	type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
	type: UserActionTypes.SIGN_OUT_FAILURE,
	payload: error,
});

export const signUpStart = ({ userInformation, history }) => ({
	type: UserActionTypes.SIGN_UP_START,
	payload: { userInformation, history },
});

export const signUpSuccess = (user) => ({
	type: UserActionTypes.SIGN_UP_SUCCESS,
	payload: user,
});

export const signUpFailure = (error) => ({
	type: UserActionTypes.SIGN_UP_FAILURE,
	payload: error,
});

export const fetchUserStart = (email) => ({
	type: UserActionTypes.FETCH_USER_START,
	payload: email,
});

export const fetchUserSuccess = (user) => ({
	type: UserActionTypes.FETCH_USER_SUCCESS,
	payload: user,
});

export const fetchUserFailure = (error) => ({
	type: UserActionTypes.FETCH_USER_FAILURE,
	payload: error,
});

export const uploadUserPictureStart = (file) => ({
	type: UserActionTypes.UPLOAD_USER_PICTURE_START,
	payload: file,
});

export const uploadUserPictureSuccess = () => ({
	type: UserActionTypes.UPLOAD_USER_PICTURE_SUCCESS,
});

export const uploadUserPictureFailure = (error) => ({
	type: UserActionTypes.UPLOAD_USER_PICTURE_FAILURE,
	payload: error,
});

export const updateUserStart = (userInfo) => ({
	type: UserActionTypes.UPDATE_USER_START,
	payload: userInfo,
});

export const updateUserSuccess = (userData) => ({
	type: UserActionTypes.UPDATE_USER_SUCCESS,
	payload: userData,
});

export const updateUserFailure = (error) => ({
	type: UserActionTypes.UPDATE_USER_FAILURE,
	payload: error,
});

export const updateUserLanguage = (language) => ({
	type: UserActionTypes.UPDATE_USER_LANGUAGE,
	payload: language,
});

export const setUserPageActiveTab = (activeTab) => ({
	type: UserActionTypes.SET_USER_PAGE_ACTIVE_TAB,
	payload: activeTab,
});

export const setUserPageActivePayment = (activePayment) => ({
	type: UserActionTypes.SET_USER_PAGE_ACTIVE_PAYMENT,
	payload: activePayment,
});

export const updateLocale = (locale) => ({
	type: UserActionTypes.UPDATE_LOCALE,
	payload: locale,
});

export const updateUserPasswordStart = (passwordInfo) => ({
	type: UserActionTypes.UPDATE_USER_PASSWORD_START,
	payload: passwordInfo,
});

export const updateUserPasswordSuccess = () => ({
	type: UserActionTypes.UPDATE_USER_PASSWORD_SUCCESS,
});

export const updateUserPasswordFailure = (error) => ({
	type: UserActionTypes.UPDATE_USER_PASSWORD_FAILURE,
	payload: error,
});

export const forgotPasswordStart = (email) => ({
	type: UserActionTypes.FORGOT_PASSWORD_START,
	payload: email,
});

export const forgotPasswordSuccess = () => ({
	type: UserActionTypes.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailure = (error) => ({
	type: UserActionTypes.FORGOT_PASSWORD_FAILURE,
	payload: error,
});

export const resetPasswordStart = (passwordInfo) => ({
	type: UserActionTypes.RESET_PASSWORD_START,
	payload: passwordInfo,
});

export const resetPasswordSuccess = () => ({
	type: UserActionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
	type: UserActionTypes.RESET_PASSWORD_FAILURE,
	payload: error,
});