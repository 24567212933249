import PaymentActionTypes from "./payment.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	paymentFor: null,
	paymentSuccess: false,
	successResponse: null,
	// credit card
	creditCardNumber: null,
	cardHolderName: null,
	expiryDate: null,
	cvv: null,
	creditCardToken: null,
	creditCardRedirectUrl: null,
	isOpen3dsAuthentication: false,
	// price list
	priceList: [],
	// check payment
	orderId: null,
	paymentStatus: null,
	isAutoGrade: null,
	// payment histories
	paymentHistories: [],
	selectedPayment: null,
};

const paymentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PaymentActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case PaymentActionTypes.SET_CREDIT_CARD:
			const {
				creditCardNumber,
				cardHolderName,
				expiryDate,
				cvv,
			} = action.payload;
			return {
				...state,
				creditCardNumber,
				cardHolderName,
				expiryDate,
				cvv,
			};
		case PaymentActionTypes.SET_PAYMENT_FOR:
			return {
				...state,
				paymentFor: action.payload,
			};
		case PaymentActionTypes.FETCH_PRICE_LIST_START:
		case PaymentActionTypes.STORE_PAYMENT_START:
		case PaymentActionTypes.RETRY_PAYMENT_START:
		case PaymentActionTypes.CHECK_PAYMENT_START:
		case PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_START:
		case PaymentActionTypes.FETCH_PAYMENT_HISTORIES_START:
			return {
				...state,
				loading: true,
			};
		case PaymentActionTypes.FETCH_PRICE_LIST_FAILURE:
		case PaymentActionTypes.STORE_PAYMENT_FAILURE:
		case PaymentActionTypes.RETRY_PAYMENT_FAILURE:
		case PaymentActionTypes.CHECK_PAYMENT_FAILURE:
		case PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_FAILURE:
		case PaymentActionTypes.FETCH_PAYMENT_HISTORIES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case PaymentActionTypes.FETCH_PRICE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				priceList: action.payload,
			};
		case PaymentActionTypes.STORE_PAYMENT_SUCCESS:
		case PaymentActionTypes.RETRY_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				paymentSuccess: true,
				successResponse: action.payload,
			};
		case PaymentActionTypes.SET_CREDIT_CARD_TOKEN:
			return {
				...state,
				creditCardToken: action.payload,
			};
		case PaymentActionTypes.SET_CREDIT_CARD_REDIRECT_URL:
			return {
				...state,
				creditCardRedirectUrl: action.payload,
			};
		case PaymentActionTypes.SET_OPEN_3DS_AUTHENTICATION:
			return {
				...state,
				isOpen3dsAuthentication: action.payload,
			};
		case PaymentActionTypes.CHECK_PAYMENT_SUCCESS:
			const { paymentStatus, isAutoGrade } = action.payload;
			return {
				...state,
				paymentStatus,
				isAutoGrade,
				loading: false,
			};
		case PaymentActionTypes.SET_ORDER_ID:
			return {
				...state,
				orderId: action.payload,
			};
		case PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case PaymentActionTypes.FETCH_PAYMENT_HISTORIES_SUCCESS:
			return {
				...state,
				loading: false,
				paymentHistories: action.payload,
			};
		case PaymentActionTypes.SET_PAYMENT_SUCCESS:
			return {
				...state,
				paymentSuccess: action.payload,
			};
		default:
			return state;
	}
};

export default paymentReducer;
