import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexNews.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableColumns } from "../../../helpers/IndexNews.helper";
import { useHistory } from "react-router";
import { fetchAllNewsStart } from "redux/news/news.action";
import { clearNewsError } from "redux/news/news.action";
import { selectNewsPlural } from "redux/news/news.selector";
import { selectNewsError } from "redux/news/news.selector";
import { selectNewsLoading } from "redux/news/news.selector";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import PillButton from "components/Public/PillButton/PillButton";
import { deleteNewsStart } from "redux/news/news.action";

const IndexNews = (props) => {
	const classes = useStyles();
	const tableRef = useRef();
	const { fetchAllNewsStart, news, isLoading, deleteNewsStart } = props;
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(
		false
	);
	const [activeData, setActiveData] = useState(null);

	const handleOpenDeleteConfirmationModal = (rowData) => {
		setDeleteConfirmationModal(true);
		setActiveData(rowData);
	};
	const handleCloseDeleteConfirmationModal = () => {
		setDeleteConfirmationModal(false);
		setActiveData(null);
	};

	const handleDeleteNews = () => {
		if (!isLoading) {
			deleteNewsStart(activeData.news_id);
			handleCloseDeleteConfirmationModal();
			tableRef.current.onChangePage(null, 0);
		}
	};

	useEffect(() => {
		fetchAllNewsStart();
	}, [fetchAllNewsStart]);

	return (
		<Grid container>
			<CustomModal
				open={deleteConfirmationModal}
				width="40vw"
				title="Are you sure?"
				onClose={handleCloseDeleteConfirmationModal}
				footer={
					<Grid container justify="flex-end">
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="Yes, I am sure"
								backgroundColor="green"
								onClick={handleDeleteNews}
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<PillButton
								element="button"
								type="button"
								text="No, I change my mind"
								onClick={handleCloseDeleteConfirmationModal}
								additionalClasses={[
									classes.pillButton,
									classes.dangerButton,
								]}
							/>
						</Grid>
					</Grid>
				}
			>
				Are you sure you want delete news with title{" "}
				<b>{activeData && `${activeData.title}`}</b>?
			</CustomModal>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of News</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="space-between">
							<Grid
								item
								xs={12}
								lg={12}
								container
								justify="flex-end"
							>
								<a href="/admin/news/create">
									<Button
										color="info"
										className={classes.buttonOnTable}
									>
										CREATE NEW NEWS
									</Button>
								</a>
							</Grid>
						</Grid>
						<MaterialTable
							tableRef={tableRef}
							isLoading={isLoading}
							columns={tableColumns({
								classes,
								handleOpenDeleteConfirmationModal,
							})}
							data={news || []}
							title={""}
							options={{
								search: true,
								exportButton: false,
								pageSize: 10,
								actionsColumnIndex: -1,
							}}
						/>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	news: selectNewsPlural,
	newsError: selectNewsError,
	isLoading: selectNewsLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllNewsStart: (filters) => dispatch(fetchAllNewsStart(filters)),
	clearNewsError: (onClose) => dispatch(clearNewsError(onClose)),
	deleteNewsStart: (newsId) => dispatch(deleteNewsStart(newsId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexNews);
