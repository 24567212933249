import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexChild.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableColumns } from "../../../helpers/IndexChild.helper";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { selectChildPlural } from "redux/child/child.selector";
import { fetchChildByAcademicYearStart } from "redux/child/child.action";
import { fetchAcademicYearsStart } from "redux/academic-year/academic-year.action";
import { selectAcademicYearPlural } from "redux/academic-year/academic-year.selector";
import { selectChildLoading } from "redux/child/child.selector";
import { selectAcademicYearLoading } from "redux/academic-year/academic-year.selector";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import UpdateChildModal from "../../../components/Admin/UpdateChildModal/UpdateChildModal";

const IndexChild = (props) => {
	const classes = useStyles();
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const [activeData, setActiveData] = useState(null);
	const { control, watch } = useForm();
	const academicYear = useRef();
	academicYear.current = watch("academicYear", "");

	const {
		childs,
		isChildLoading,
		isAcademicYearLoading,
		academicYears,
		fetchAcademicYearsStart,
		fetchChildByAcademicYearStart,
	} = props;

	const handleUpdateModalOpen = (data) => {
		setIsUpdateModalOpen(true);
		setActiveData(data);
	};

	const handleUpdateModalClose = () => {
		setIsUpdateModalOpen(false);
		setActiveData(null);
	};

	const handleOnFinishUpdate = () => {
		fetchChildByAcademicYearStart(academicYear.current);
	};

	useEffect(() => {
		if (!!academicYear.current)
			fetchChildByAcademicYearStart(academicYear.current);
	}, [academicYear.current]);

	useEffect(() => {
		fetchAcademicYearsStart();
	}, []);

	return (
		<Grid container>
			{!!activeData && (
				<UpdateChildModal
					open={isUpdateModalOpen}
					onClose={handleUpdateModalClose}
					data={activeData}
					onFinishUpdate={handleOnFinishUpdate}
				/>
			)}
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Children</h4>
					</CardHeader>
					<CardBody>
						<Grid container justify="space-between">
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.selectContainer}
							>
								{isAcademicYearLoading ? (
									<Skeleton
										animation="wave"
										variant="rect"
										width="100%"
										height="30px"
									/>
								) : (
									<CustomSelect
										fullWidth
										id="academicYear"
										label="Batch"
										name="academicYear"
										options={academicYears.map(
											(academicYear) => {
												const startDate = moment(
													academicYear.start_date
												).format("DD-MM-YYYY");
												const endDate = moment(
													academicYear.end_date
												).format("DD-MM-YYYY");
												return {
													textEN: `Batch ${academicYear.batch} (${startDate} - ${endDate})`,
													textID: `Batch ${academicYear.batch} (${startDate} - ${endDate})`,
													value:
														academicYear.academic_year_id,
												};
											}
										)}
										control={control}
									/>
								)}
							</Grid>
						</Grid>
						{!!academicYear.current ? (
							<MaterialTable
								isLoading={isChildLoading}
								columns={tableColumns({
									classes,
									handleUpdateModalOpen,
								})}
								data={childs || []}
								title={""}
								options={{
									search: true,
									exportButton: false,
									pageSize: 10,
									actionsColumnIndex: -1,
								}}
							/>
						) : (
							<h4 className={classes.emptyLabel}>
								Please select one of the batch
							</h4>
						)}
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isChildLoading: selectChildLoading,
	isAcademicYearLoading: selectAcademicYearLoading,
	childs: selectChildPlural,
	academicYears: selectAcademicYearPlural,
});

const mapDispatchToProps = (dispatch) => ({
	fetchChildByAcademicYearStart: (academicYearId) =>
		dispatch(fetchChildByAcademicYearStart(academicYearId)),
	fetchAcademicYearsStart: () => dispatch(fetchAcademicYearsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexChild);
