import { createSelector } from "reselect";

const selectBuyCourse = (state) => state.buyCourse;

export const selectBuyCourseError = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.error
);

export const selectBuyCourseLoading = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.loading
);

export const selectIsBuyCourseDrawerOpen = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.isDrawerOpen
);

export const selectBuyCourseActiveChild = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.activeChildId
);

export const selectBuyCoursePaymentInterval = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.paymentInterval
);

export const selectBuyCoursePaymentMethod = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.paymentMethod
);

export const selectBuyCourseBillDetails = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.billDetails.filter((billDetail) => !!billDetail)
);

export const selectBuyCourseTotalBill = createSelector(
	[selectBuyCourseBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.value : total),
			0
		)
);

export const selectBuyCourseTotalDiscount = createSelector(
	[selectBuyCourseBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.discount : total),
			0
		)
);

export const selectBuyCourseActiveStep = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.activeStep
);

export const selectBuyCourseBatch = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.batch
);

export const selectBuyCourseDisableNextButton = createSelector(
	[selectBuyCourse],
	(buyCourse) => {
		switch (buyCourse.activeStep) {
			case 0: // Introduction
				return true;
			case 1: // Batch Registration
				return !!!buyCourse.batch;
			// case 3: // Delivery Cost
			// 	return true;
			case 4:
				return !!!buyCourse.paymentMethod;
			default:
				// Payment Interval
				// find bill according to step
				// (3 bill already in default +1 to get to first dynamic bill
				// because have 1 step without affecting the bill details)
				const billFound = !!!buyCourse.billDetails[
					buyCourse.activeStep + 1
				];

				return billFound;
		}
	}
);

export const selectBuyCourseHideBillFooter = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.hideBillFooter
);

export const selectBuyCourseShowPaymentDetails = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.showPaymentDetails
);

export const selectBuyCourseNextStepFunction = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.nextStepFunction
);

export const selectBuyCourseDeliveryFee = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.deliveryFee
);

export const selectBuyCourseCourier = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.courier
);

export const selectBuyCourseDiscountedBillDetails = createSelector(
	[selectBuyCourse],
	(buyCourse) =>
		buyCourse.billDetails.filter((billDetail) =>
			!!billDetail ? billDetail.discount > 0 : false
		)
);

export const selectBuyCourseTotalBillWithDiscount = createSelector(
	[selectBuyCourseBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) =>
				!!bill ? total + bill.value - bill.discount : total,
			0
		)
);

export const selectBuyCourseTerm2Opened = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.term2Opened
);

export const selectBuyCourseTerm4Opened = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.term4Opened
);

export const selectBuyCourseTerm12Opened = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.term12Opened
);

export const selectBuyCoursePaymentMethodOpened = createSelector(
	[selectBuyCourse],
	(buyCourse) =>
		buyCourse.term2Opened || buyCourse.term4Opened || buyCourse.term12Opened
);

export const selectBuyCoursePaymentIntervalCurrentSlide = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.currentSlide
);

export const selectBuyCourseAdminFeePercentage = createSelector(
	[selectBuyCourse],
	(buyCourse) => buyCourse.adminFeePercentage
);

export const selectBuyCourseAdminFee = createSelector(
	[selectBuyCourseTotalBillWithDiscount, selectBuyCourseAdminFeePercentage],
	(totalBillWithDiscount, adminFeePercentage) =>
		totalBillWithDiscount * adminFeePercentage
);
