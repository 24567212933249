import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./FooterSection.styles";

const FooterSection = (props) => {
	const { title, items } = props;
	const classes = useStyles();

	return (
		<>
			<p className={classes.footerSectionTitle}>{title}</p>
			<ul className={classes.footerSectionItems}>
				{items.map((item, key) => {
					if (!!item.to) {
						return (
							<li key={key}>
								<a
									href={item.to}
									target={!!item.target ? item.target : null}
								>
									{item.value}
								</a>
							</li>
						);
					} else {
						return <li key={key}>{item.value}</li>;
					}
				})}
			</ul>
		</>
	);
};

export default FooterSection;
