import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	imagePreview: {
		width: "508px",
		height: "577px",
	},
	previewContainer: {
		maxHeight: "577px",
		display: "flex",
		justifyContent: "center",
		marginBottom: "120px",

		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
		},
	},
	previewLabel: {
		fontWeight: "600",
		marginTop: "20px",
		textAlign: "center",
	},
	languageSelectorContainer: {
		marginBottom: "30px",
	},
}));
