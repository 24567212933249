import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	ordersContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	selectContainer: {
		minWidth: "200px",

		[theme.breakpoints.up("lg")]: {
			paddingLeft: "24px",
		},
	},
	emptyLabel: {
		fontWeight: "600",
		marginTop: "24px",

		[theme.breakpoints.up("lg")]: {
			paddingLeft: "24px",
		},
	},
	buttonContainer: {
		paddingLeft: "12px",
		paddingRight: "12px",
	},
}));
