import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import NotificationActionTypes from "./notification.types";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearNotificationError,
	fetchUserNotificationsFailure,
	fetchUserNotificationsSuccess,
	readUserNotificationFailure,
	readUserNotificationSuccess,
} from "./notification.action";
import { selectCurrentUser } from "redux/user/user.selector";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchUserNotifications() {
	const currentUser = yield select(selectCurrentUser);
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/notif/${currentUser.user_id}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const notifications = response.data.notification;

		yield put(fetchUserNotificationsSuccess(notifications));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNotificationError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchUserNotificationsFailure(errorMessage));
	}
}

export function* onFetchUserNotificationsStart() {
	yield takeLatest(
		NotificationActionTypes.FETCH_NOTIFICATIONS_START,
		fetchUserNotifications
	);
}

export function* readUserNotification({ payload }) {
	const notificationId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/notif/mark/${notificationId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(readUserNotificationSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNotificationError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(readUserNotificationFailure(errorMessage));
	}
}

export function* onReadUserNotificationStart() {
	yield takeLatest(
		NotificationActionTypes.READ_NOTIFICATION_START,
		readUserNotification
	);
}

export function* notificationSagas() {
	yield all([
		call(onFetchUserNotificationsStart),
		call(onReadUserNotificationStart),
	]);
}
