/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { Announcement, MenuBook, Person } from "@material-ui/icons";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import WorkIcon from "@material-ui/icons/Work";
// core components/views for Admin layout
import CreateNews from "views/Admin/CreateNews/CreateNews";
import CreateTestimony from "views/Admin/CreateTestimony/CreateTestimony";
import IndexNews from "views/Admin/IndexNews/IndexNews";
import IndexTestimony from "views/Admin/IndexTestimony/IndexTestimony";
import UpdateTestimony from "views/Admin/UpdateTestimony/UpdateTestimony";
import IndexOrder from "views/Admin/IndexOrder/IndexOrder";
import IndexChild from "views/Admin/IndexChild/IndexChild";
import IndexAcademicYear from "views/Admin/IndexAcademicYear/IndexAcademicYear";
import CreateAcademicYear from "views/Admin/CreateAcademicYear/CreateAcademicYear";
import IndexCourseMaster from "views/Admin/IndexCourseMaster/IndexCourseMaster";
import CreateCourseMaster from "views/Admin/CreateCourseMaster/CreateCourseMaster";
import IndexStudentPerformance from "views/Admin/IndexStudentPerformance/IndexStudentPerformance";
import UpdateNews from "views/Admin/UpdateNews/UpdateNews";
import UpdateCourseMaster from "views/Admin/UpdateCourseMaster/UpdateCourseMaster";
import CreateStudentPerformance from "views/Admin/CreateStudentPerformance/CreateStudentPerformance";
import UpdateStudentPerformance from "views/Admin/UpdateStudentPerformance/UpdateStudentPerformance";
import UpdateAcademicYear from "views/Admin/UpdateAcademicYear/UpdateAcademicYear";

const adminRoutes = [
	{
		path: "/admin/orders",
		name: "Orders",
		icon: WorkIcon,
		component: IndexOrder,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/testimonies",
		name: "Testimonies",
		icon: PeopleAlt,
		component: IndexTestimony,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/testimonies/create",
		name: "Create Testimony",
		component: CreateTestimony,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/testimonies/:testimonyId/update",
		name: "Update Testimony",
		component: UpdateTestimony,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/news",
		name: "News",
		icon: Announcement,
		component: IndexNews,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/news/create",
		name: "Create News",
		component: CreateNews,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/news/:newsId/update",
		name: "Update News",
		component: UpdateNews,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/student-performances",
		name: "Student Performances",
		icon: PeopleAlt,
		component: IndexStudentPerformance,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/student-performances/create",
		name: "Create Student Performance",
		component: CreateStudentPerformance,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/student-performances/:studentPerformanceId/update",
		name: "Update Student Performance",
		component: UpdateStudentPerformance,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/batchs",
		name: "Batch",
		icon: MenuBook,
		component: IndexAcademicYear,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/batchs/create",
		name: "Batch",
		component: CreateAcademicYear,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/batchs/:academicYearId/update",
		name: "Batch",
		component: UpdateAcademicYear,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/courses",
		name: "Course Master Data",
		icon: MenuBook,
		component: IndexCourseMaster,
		layout: "/admin",
		showNavbar: true,
	},
	{
		path: "/admin/courses/create",
		name: "Course Master Data",
		component: CreateCourseMaster,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/courses/:courseId/update",
		name: "Course Master Data",
		component: UpdateCourseMaster,
		layout: "/admin",
		showNavbar: false,
	},
	{
		path: "/admin/childs",
		name: "Children",
		icon: Person,
		component: IndexChild,
		layout: "/admin",
		showNavbar: true,
	},
];

export default adminRoutes;
