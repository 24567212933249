import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	navigation: {
		fontSize: "16px",
		fontWeight: "400",
		textAlign: "center",
	},
	activeNavigation: {
		fontWeight: "700",
	},
	navLinkItem: {
		marginLeft: "56px",
		display: "flex",
		flexDirection: "column",
		"&:nth-child(1)": {
			marginLeft: "0px",
		},
		[theme.breakpoints.down("md")]: {
			marginTop: "15px",
			marginLeft: "0px",
		},
	},
}));
