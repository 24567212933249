import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import {
	selectPlacementTestActiveChild,
	selectPlacementTestAnswers,
	selectPlacementTestData,
} from "./placement-test.selector";
import PlacementTestActionTypes from "./placement-test.types";
import {
	clearPlacementTestError,
	fetchPlacementTestFailure,
	fetchPlacementTestSuccess,
	handleFinishTestFailure,
	handleFinishTestSuccess,
	handleStartTestFailure,
	handleStartTestSuccess,
	resetPlacementTest,
} from "./placement-test.action";
import axios from "axios";
import moment from "moment";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import { fetchChildStart } from "redux/child/child.action";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchChild(childId) {
	const response = yield axios({
		method: "GET",
		url: `${baseUrlApi}/childs/${childId}`,
		headers: {},
	});

	if (response.data.code !== 200 && response.data.code !== 201) {
		throw new Error(response.data.message);
	}
	const childData = response.data.childs;
	const academicYearId = response.data.academic_year_id;

	return { ...childData, academicYearId };
}

export function* finishTest({ history }) {
	// handle finish test
	const childId = yield select(selectPlacementTestActiveChild);

	const childData = yield call(fetchChild, childId);
	const answers = yield select(selectPlacementTestAnswers);
	const placementTestData = yield select(selectPlacementTestData);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/placement-test/take-test`,
			data: {
				child_id: childId,
				placement_test_id: placementTestData.placement_test_id,
				active_part: 12,
				start_time: placementTestData.start_time,
				finish_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
				answer: JSON.stringify(answers),
				status: "1", // this what makes it finish
				academic_year_id: childData.academicYearId,
			},
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const res = response.data.placement_test_result;
		yield put(handleFinishTestSuccess(res));
		yield put(resetPlacementTest());
		history.push(`/placement-test-finish/${childId}`);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlacementTestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(handleFinishTestFailure(errorMessage));
	}
}

export function* onFinishTestStart() {
	yield takeLatest(
		PlacementTestActionTypes.HANDLE_FINISH_TEST_START,
		finishTest
	);
}

export function* startTest({ payload }) {
	const defaultAnswers = [
		[null, null, null, null], // part 1
		[null, null, null, null, null], // part 2
		[null, null, null, null], // part 3
		[null, null, null], // part 4
		[null, null, null, null], // part 5
		[null, null, null, null, null], // part 6
		[null, null, null, null], // part 7
		[null, null, null], // part 8
		[null, null, null, null], // part 9
		[null, null, null, null], // part 10
		[null, null, null, null, null], // part 11
		[null, null, null, null, null], // part 12
	];
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/placement-test/take-test`,
			data: {
				child_id: payload,
				answer: JSON.stringify(defaultAnswers),
				start_time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
				finish_time: moment()
					.utc()
					.add(20, "minutes")
					.format("YYYY-MM-DD HH:mm:ss"),
			},
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const placementTestData = response.data.placement_test;
		yield put(handleStartTestSuccess(placementTestData));
		window.open(`/placement-test/${payload}`);
		window.location.href = "/profile";
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlacementTestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(handleFinishTestFailure(errorMessage));
	}
}

export function* onStartTestStart() {
	yield takeLatest(
		PlacementTestActionTypes.HANDLE_START_TEST_START,
		startTest
	);
}

export function* fetchPlacementTest({ payload }) {
	const childId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/placement-test/${childId}`,
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			if (response.data.message === "Placement Test not found") {
				yield put(fetchPlacementTestSuccess(null));
			} else {
				throw new Error(response.data.message);
			}
		} else {
		}
		const placementTestData = response.data.placement_test;
		yield put(fetchPlacementTestSuccess(placementTestData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlacementTestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPlacementTestFailure(error.message));
	}
}

export function* onFetchPlacementTestStart() {
	yield takeLatest(
		PlacementTestActionTypes.FETCH_PLACEMENT_TEST_START,
		fetchPlacementTest
	);
}

export function* updatePlacementTest({ payload }) {
	const newActivePart = payload;
	const childId = yield select(selectPlacementTestActiveChild);
	const answers = yield select(selectPlacementTestAnswers);
	const placementTestData = yield select(selectPlacementTestData);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/placement-test/take-test`,
			data: {
				child_id: childId,
				placement_test_id: placementTestData.placement_test_id,
				active_part: newActivePart,
				answer: JSON.stringify(answers),
			},
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const res = response.data.placement_test;
		yield put(fetchPlacementTestSuccess(res));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlacementTestError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPlacementTestFailure(error.message));
	}
}

export function* onSetActivePart() {
	yield takeLatest(
		PlacementTestActionTypes.SET_ACTIVE_PART,
		updatePlacementTest
	);
}

export function* placementTestSagas() {
	yield all([
		call(onFinishTestStart),
		call(onStartTestStart),
		call(onFetchPlacementTestStart),
		call(onSetActivePart),
	]);
}
