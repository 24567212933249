import React, { useRef } from "react";
import { useStyles } from "./CreateStudentPerformance.styles";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid, TextField } from "@material-ui/core";
import { selectStudentPerformanceLoading } from "redux/student-performance/student-performance.selector";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import { createStudentPerformanceStart } from "redux/student-performance/student-performance.action";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { useHistory } from "react-router-dom";
import RegularButton from "components/Shared/CustomButtons/Button";
import ReactPlayer from "react-player";
import { studentPerformanceCategories } from "helpers/App.helper";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { selectStudentPerformanceUploadProgress } from "redux/student-performance/student-performance.selector";
import { languages } from "helpers/App.helper";

const CreateStudentPerformance = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { isLoading, createStudentPerformanceStart, uploadProgress } = props;
	const { register, handleSubmit, errors, watch, control } = useForm({
		defaultValues: {
			language: "EN",
		},
	});

	const video = useRef();
	video.current = watch("video", "");
	const titleEN = useRef();
	titleEN.current = watch("titleEN", "");
	const descriptionEN = useRef();
	descriptionEN.current = watch("descriptionEN", "");
	const titleID = useRef();
	titleID.current = watch("titleID", "");
	const descriptionID = useRef();
	descriptionID.current = watch("descriptionID", "");
	const language = useRef();
	language.current = watch("language", "");

	const storeStudentPerformance = (data) => {
		if (!isLoading) {
			createStudentPerformanceStart({ data, history });
		}
	};

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} value={uploadProgress} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Create Student Performance</h4>
					</CardHeader>
					<CardBody>
						<form onSubmit={handleSubmit(storeStudentPerformance)}>
							<Grid container spacing={3}>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									alignContent="flex-start"
								>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="titleEN"
											label="Title (English)"
											name="titleEN"
											autoFocus
											inputRef={register({
												required: {
													value: true,
													message:
														"Title (English) are required",
												},
											})}
											error={!!errors.titleEN}
											helperText={
												errors.titleEN &&
												errors.titleEN.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="titleID"
											label="Title (Indonesia)"
											name="titleID"
											autoFocus
											inputRef={register({
												required: {
													value: true,
													message:
														"Title (Indonesia) are required",
												},
											})}
											error={!!errors.titleID}
											helperText={
												errors.titleID &&
												errors.titleID.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="descriptionEN"
											label="Description (English)"
											name="descriptionEN"
											inputRef={register({
												required: {
													value: true,
													message:
														"Description (English) are required",
												},
											})}
											error={!!errors.descriptionEN}
											helperText={
												errors.descriptionEN &&
												errors.descriptionEN.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="descriptionID"
											label="Description (Indonesia)"
											name="descriptionID"
											inputRef={register({
												required: {
													value: true,
													message:
														"Description (Indonesia) are required",
												},
											})}
											error={!!errors.descriptionID}
											helperText={
												errors.descriptionID &&
												errors.descriptionID.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomSelect
											fullWidth
											margin="normal"
											id="category"
											label="Category"
											name="category"
											options={
												studentPerformanceCategories
											}
											control={control}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputFileField
											margin="normal"
											accept="video/mp4,video/x-m4v,video/*"
											id="video"
											label="Upload Video"
											inputRef={register({
												required: {
													value: true,
													message:
														"Video are required",
												},
											})}
											value={video.current}
											error={!!errors.video}
											helperText={
												errors.video &&
												errors.video.message
											}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									justify="center"
									alignContent="flex-start"
									className={classes.previewContainer}
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Preview{" "}
											{language.current === "EN"
												? "English"
												: "Indonesia"}
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										lg={4}
										className={
											classes.languageSelectorContainer
										}
									>
										<CustomSelect
											fullWidth
											id="language"
											label="Language"
											name="language"
											options={languages}
											control={control}
											removeNone
										/>
									</Grid>
									<Grid container>
										<Grid
											item
											xs={12}
											className={classes.videoContainer}
										>
											{video.current.length > 0 &&
												!isLoading && (
													<div
														className={
															classes.videoWrapper
														}
														onContextMenu={(e) =>
															e.preventDefault()
														}
													>
														<ReactPlayer
															className={
																classes.videoPlayer
															}
															url={URL.createObjectURL(
																video.current[0]
															)}
															width="100%"
															height="100%"
															controls={true}
															config={{
																file: {
																	attributes: {
																		controlsList:
																			"nodownload",
																	},
																},
															}}
														/>
													</div>
												)}
										</Grid>
										<Grid
											item
											xs={12}
											className={classes.text}
										>
											“
											{language.current === "EN"
												? titleEN.current ||
												  "Title goes here..."
												: titleID.current ||
												  "Title goes here..."}
											”
										</Grid>
										<Grid
											item
											xs={12}
											className={classes.child}
										>
											“
											{language.current === "EN"
												? descriptionEN.current ||
												  "Description goes here..."
												: descriptionID.current ||
												  "Description goes here..."}
											”
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<RegularButton
											type="submit"
											variant="contained"
											color="info"
											className={classes.submit}
										>
											Create Student Performance
										</RegularButton>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectStudentPerformanceLoading,
	uploadProgress: selectStudentPerformanceUploadProgress,
});

const mapDispatchToProps = (dispatch) => ({
	createStudentPerformanceStart: ({ data, history }) =>
		dispatch(createStudentPerformanceStart({ data, history })),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateStudentPerformance);
