import { createSelector } from "reselect";

const selectTestimony = (state) => state.testimony;

export const selectTestimonySingular = createSelector(
	[selectTestimony],
	(testimony) => testimony.singular
);

export const selectTestimonyPlural = createSelector(
	[selectTestimony],
	(testimony) => testimony.plural
);

export const selectTestimonyError = createSelector(
	[selectTestimony],
	(testimony) => testimony.error
);

export const selectTestimonyLoading = createSelector(
	[selectTestimony],
	(testimony) => testimony.loading
);
