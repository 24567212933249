const AcademicYearActionTypes = {
	// FETCH ALL ACADEMIC_YEARS
	FETCH_ACADEMIC_YEARS_START: "FETCH_ACADEMIC_YEARS_START",
	FETCH_ACADEMIC_YEARS_SUCCESS: "FETCH_ACADEMIC_YEARS_SUCCESS",
	FETCH_ACADEMIC_YEARS_FAILURE: "FETCH_ACADEMIC_YEARS_FAILURE",
	// FETCH ONE ACADEMIC_YEAR
	FETCH_ACADEMIC_YEAR_START: "FETCH_ACADEMIC_YEAR_START",
	FETCH_ACADEMIC_YEAR_SUCCESS: "FETCH_ACADEMIC_YEAR_SUCCESS",
	FETCH_ACADEMIC_YEAR_FAILURE: "FETCH_ACADEMIC_YEAR_FAILURE",
	// CREATE ACADEMIC_YEAR
	CREATE_ACADEMIC_YEAR_START: "CREATE_ACADEMIC_YEAR_START",
	CREATE_ACADEMIC_YEAR_SUCCESS: "CREATE_ACADEMIC_YEAR_SUCCESS",
	CREATE_ACADEMIC_YEAR_FAILURE: "CREATE_ACADEMIC_YEAR_FAILURE",
	// UPDATE ACADEMIC_YEAR
	UPDATE_ACADEMIC_YEAR_START: "UPDATE_ACADEMIC_YEAR_START",
	UPDATE_ACADEMIC_YEAR_SUCCESS: "UPDATE_ACADEMIC_YEAR_SUCCESS",
	UPDATE_ACADEMIC_YEAR_FAILURE: "UPDATE_ACADEMIC_YEAR_FAILURE",
	// NON_ACTIVATE ACADEMIC_YEAR
	NON_ACTIVATE_ACADEMIC_YEAR_START: "NON_ACTIVATE_ACADEMIC_YEAR_START",
	NON_ACTIVATE_ACADEMIC_YEAR_SUCCESS: "NON_ACTIVATE_ACADEMIC_YEAR_SUCCESS",
	NON_ACTIVATE_ACADEMIC_YEAR_FAILURE: "NON_ACTIVATE_ACADEMIC_YEAR_FAILURE",
	// ACTIVATE ACADEMIC_YEAR
	ACTIVATE_ACADEMIC_YEAR_START: "ACTIVATE_ACADEMIC_YEAR_START",
	ACTIVATE_ACADEMIC_YEAR_SUCCESS: "ACTIVATE_ACADEMIC_YEAR_SUCCESS",
	ACTIVATE_ACADEMIC_YEAR_FAILURE: "ACTIVATE_ACADEMIC_YEAR_FAILURE",
	// FETCH_AVAILABLE_BATCH
	FETCH_AVAILABLE_BATCH_START: "FETCH_AVAILABLE_BATCH_START",
	FETCH_AVAILABLE_BATCH_SUCCESS: "FETCH_AVAILABLE_BATCH_SUCCESS",
	FETCH_AVAILABLE_BATCH_FAILURE: "FETCH_AVAILABLE_BATCH_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
	CLEAR_DATA: "CLEAR_DATA",
};

export default AcademicYearActionTypes;
