import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "./CustomModal.styles";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function CustomModal(props) {
	const {
		width = "50vw",
		height = "auto",
		open,
		title,
		onClose,
		children,
		withoutTitle,
		footer,
	} = props;
	const classes = useStyles({
		modalWidth: width,
		modalHeight: height,
	});
	return (
		<Modal
			aria-labelledby={title}
			className={classes.modal}
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Grid
					container
					className={classes.modalContentContainer}
					justify="center"
					alignContent="flex-start"
				>
					<CloseIcon
						className={classes.closeIcon}
						onClick={onClose}
					/>
					{!!!withoutTitle && (
						<Grid item xs={12} className={classes.modalTitle}>
							{title}
						</Grid>
					)}
					<Grid item xs={12} className={classes.modalContent}>
						{children}
					</Grid>
					<Grid item xs={12} className={classes.modalFooter}>
						{footer}
					</Grid>
				</Grid>
			</Fade>
		</Modal>
	);
}
