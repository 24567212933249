import React from "react";
import { Grid } from "@material-ui/core";
import RegularButton from "components/Shared/CustomButtons/Button";

export const tableColumns = (props) => {
	const { classes, handleUpdateModalOpen } = props;

	return [
		{
			title: "Child Name",
			field: "childFullname",
		},
		{
			title: "Parent Name",
			field: "parentFullname",
		},
		{
			title: "Grade",
			field: "grade",
		},
		{
			title: "",
			render: (rowData) => {
				return (
					<Grid container justify="center">
						<Grid item className={classes.buttonContainer}>
							<RegularButton
								type="button"
								color="info"
								onClick={() => handleUpdateModalOpen(rowData)}
							>
								Edit
							</RegularButton>
						</Grid>
					</Grid>
				);
			},
		},
	];
};

export const childStatuses = [
	{ value: 1, textEN: "Active", textID: "Aktif" },
	{ value: 0, textEN: "Non Active", textID: "Non Aktif" },
];
