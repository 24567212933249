import StudentPerformanceActionTypes from "./student-performance.types";

const INITIAL_STATE = {
	singular: null,
	plural: [],
	error: null,
	loading: false,
	carouselLoading: false,
	uploadProgress: 0,
	carouselItems: [],
};

const studentPerformanceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_START:
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_START:
		case StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_START:
		case StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_START:
		case StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_START:
			return {
				...state,
				loading: true,
			};
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_START:
			return {
				...state,
				carouselLoading: true,
			};
		case StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_FAILURE:
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_FAILURE:
		case StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_FAILURE:
		case StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_FAILURE:
		case StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_FAILURE:
			return {
				...state,
				carouselLoading: false,
				error: action.payload,
			};
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_FAILURE:
			return {
				...state,
				carouselLoading: false,
				error: action.payload,
			};
		case StudentPerformanceActionTypes.FETCH_ALL_STUDENT_PERFORMANCE_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCE_SUCCESS:
			return {
				...state,
				loading: false,
				singular: action.payload,
			};
		case StudentPerformanceActionTypes.CREATE_STUDENT_PERFORMANCE_SUCCESS:
		case StudentPerformanceActionTypes.UPDATE_STUDENT_PERFORMANCE_SUCCESS:
		case StudentPerformanceActionTypes.DELETE_STUDENT_PERFORMANCE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case StudentPerformanceActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case StudentPerformanceActionTypes.SET_STUDENT_PERFORMANCE_UPLOAD_PROGRESS:
			return {
				...state,
				uploadProgress: action.payload,
			};
		case StudentPerformanceActionTypes.FETCH_STUDENT_PERFORMANCES_LIMITED_SUCCESS:
			return {
				...state,
				carouselLoading: false,
				carouselItems: action.payload,
			};
		default:
			return state;
	}
};

export default studentPerformanceReducer;
