import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./Testimony.styles";

const Testimony = (props) => {
	const { quote, personName, image, preview } = props;
	const classes = useStyles();
	return (
		<Grid
			item
			xs={12}
			md={!!preview ? 12 : 9}
			container
			justify="center"
			alignContent="flex-start"
			spacing={3}
		>
			<Grid item xs={12} md={6} className={classes.imageContainer}>
				<img src={image} alt={`testimony ${personName}`} />
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				container
				justify="flex-start"
				alignContent="center"
			>
				<p className={classes.testimonyQuote}>“{quote}”</p>
				<p className={classes.personName}>{personName}</p>
			</Grid>
		</Grid>
	);
};

export default Testimony;
