import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./UpdateStudentPerformance.styles";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid, TextField } from "@material-ui/core";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { useHistory, useParams } from "react-router-dom";
import RegularButton from "components/Shared/CustomButtons/Button";
import { studentPerformanceCategories } from "helpers/App.helper";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import ReactPlayer from "react-player";
import {
	selectStudentPerformanceLoading,
	selectStudentPerformanceSingular,
	selectStudentPerformanceUploadProgress,
} from "../../../redux/student-performance/student-performance.selector";
import {
	fetchStudentPerformanceStart,
	updateStudentPerformanceStart,
} from "../../../redux/student-performance/student-performance.action";
import { languages } from "helpers/App.helper";

const UpdateStudentPerformance = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { studentPerformanceId } = useParams();
	const {
		isLoading,
		updateStudentPerformanceStart,
		fetchStudentPerformanceStart,
		studentPerformance,
		uploadProgress,
	} = props;
	const {
		register,
		handleSubmit,
		errors,
		watch,
		control,
		setValue,
	} = useForm({
		defaultValues: {
			language: "EN",
		},
	});

	const [defaultVideo, setDefaultVideo] = useState(null);

	const video = useRef();
	video.current = watch("video", "");
	const titleEN = useRef();
	titleEN.current = watch("titleEN", "");
	const descriptionEN = useRef();
	descriptionEN.current = watch("descriptionEN", "");
	const titleID = useRef();
	titleID.current = watch("titleID", "");
	const descriptionID = useRef();
	descriptionID.current = watch("descriptionID", "");
	const language = useRef();
	language.current = watch("language", "");

	const updateStudentPerformance = (data) => {
		if (!isLoading) {
			updateStudentPerformanceStart({
				data: { ...data, id: studentPerformance.news_id },
				history,
			});
		}
	};

	useEffect(() => {
		if (!!studentPerformanceId)
			fetchStudentPerformanceStart(studentPerformanceId);
	}, [studentPerformanceId, fetchStudentPerformanceStart]);

	useEffect(() => {
		if (!!studentPerformance) {
			setDefaultVideo(studentPerformance.video_url);

			setValue("titleEN", studentPerformance.title);
			setValue("descriptionEN", studentPerformance.content_detail);
			setValue("titleID", studentPerformance.title_indo);
			setValue("descriptionID", studentPerformance.content_detail_indo);
			setValue("category", studentPerformance.category);
		}
	}, [studentPerformance]);

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} value={uploadProgress} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Edit Student Performance</h4>
					</CardHeader>
					<CardBody>
						<form onSubmit={handleSubmit(updateStudentPerformance)}>
							<Grid container spacing={3}>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									alignContent="flex-start"
								>
									<Grid item xs={12}>
										<Controller
											render={({
												onChange,
												onBlur,
												value,
												name,
												ref,
											}) => (
												<TextField
													margin="normal"
													fullWidth
													id="titleEN"
													label="Title (English)"
													error={!!errors.titleEN}
													helperText={
														errors.titleEN &&
														errors.titleEN.message
													}
													name={name}
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													inputRef={ref}
												/>
											)}
											control={control}
											name="titleEN"
											defaultValue=""
											rules={{
												required: {
													value: true,
													message:
														"Title (English) are required",
												},
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											render={({
												onChange,
												onBlur,
												value,
												name,
												ref,
											}) => (
												<TextField
													margin="normal"
													fullWidth
													id="titleID"
													label="Title (Indonesia)"
													error={!!errors.titleID}
													helperText={
														errors.titleID &&
														errors.titleID.message
													}
													name={name}
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													inputRef={ref}
												/>
											)}
											control={control}
											name="titleID"
											defaultValue=""
											rules={{
												required: {
													value: true,
													message:
														"Title (Indonesia) are required",
												},
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											render={({
												onChange,
												onBlur,
												value,
												name,
												ref,
											}) => (
												<TextField
													margin="normal"
													fullWidth
													id="descriptionEN"
													label="Description (English)"
													error={
														!!errors.descriptionEN
													}
													helperText={
														errors.descriptionEN &&
														errors.descriptionEN
															.message
													}
													name={name}
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													inputRef={ref}
												/>
											)}
											control={control}
											name="descriptionEN"
											defaultValue=""
											rules={{
												required: {
													value: true,
													message:
														"Description (English) are required",
												},
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											render={({
												onChange,
												onBlur,
												value,
												name,
												ref,
											}) => (
												<TextField
													margin="normal"
													fullWidth
													id="descriptionID"
													label="Description (Indonesia)"
													error={
														!!errors.descriptionID
													}
													helperText={
														errors.descriptionID &&
														errors.descriptionID
															.message
													}
													name={name}
													onChange={onChange}
													onBlur={onBlur}
													value={value}
													inputRef={ref}
												/>
											)}
											control={control}
											name="descriptionID"
											defaultValue=""
											rules={{
												required: {
													value: true,
													message:
														"Description (Indonesia) are required",
												},
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<CustomSelect
											fullWidth
											margin="normal"
											id="category"
											label="Category"
											name="category"
											options={
												studentPerformanceCategories
											}
											control={control}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputFileField
											margin="normal"
											accept="video/mp4,video/x-m4v,video/*"
											id="video"
											label="Upload Video"
											inputRef={register()}
											value={video.current}
											error={!!errors.video}
											helperText={
												errors.video &&
												errors.video.message
											}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									justify="center"
									alignContent="flex-start"
									className={classes.previewContainer}
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Preview{" "}
											{language.current === "EN"
												? "English"
												: "Indonesia"}
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										lg={4}
										className={
											classes.languageSelectorContainer
										}
									>
										<CustomSelect
											fullWidth
											id="language"
											label="Language"
											name="language"
											options={languages}
											control={control}
											removeNone
										/>
									</Grid>
									<Grid container>
										<Grid
											item
											xs={12}
											className={classes.videoContainer}
										>
											{!isLoading && (
												<div
													className={
														classes.videoWrapper
													}
													onContextMenu={(e) =>
														e.preventDefault()
													}
												>
													<ReactPlayer
														className={
															classes.videoPlayer
														}
														url={
															video.current
																.length > 0
																? URL.createObjectURL(
																		video
																			.current[0]
																  )
																: defaultVideo
														}
														width="100%"
														height="100%"
														controls={true}
														config={{
															file: {
																attributes: {
																	controlsList:
																		"nodownload",
																},
															},
														}}
													/>
												</div>
											)}
										</Grid>
										<Grid
											item
											xs={12}
											className={classes.text}
										>
											“
											{language.current === "EN"
												? titleEN.current ||
												  "Title goes here..."
												: titleID.current ||
												  "Title goes here..."}
											”
										</Grid>
										<Grid
											item
											xs={12}
											className={classes.child}
										>
											“
											{language.current === "EN"
												? descriptionEN.current ||
												  "Description goes here..."
												: descriptionID.current ||
												  "Description goes here..."}
											”
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<RegularButton
											type="submit"
											variant="contained"
											color="info"
											className={classes.submit}
										>
											Update Student Performance
										</RegularButton>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectStudentPerformanceLoading,
	studentPerformance: selectStudentPerformanceSingular,
	uploadProgress: selectStudentPerformanceUploadProgress,
});

const mapDispatchToProps = (dispatch) => ({
	updateStudentPerformanceStart: ({ data, history }) =>
		dispatch(updateStudentPerformanceStart({ data, history })),
	fetchStudentPerformanceStart: (studentPerformanceId) =>
		dispatch(fetchStudentPerformanceStart(studentPerformanceId)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateStudentPerformance);
