import BuyCourseActionTypes from "./buy-course.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	isDrawerOpen: false,
	activeStep: 0,
	hideBillFooter: false,
	activeChildId: null,
	nextStepFunction: null,
	billDetails: [],
	// payment interval step
	paymentInterval: null,
	term2Opened: false,
	term4Opened: false,
	term12Opened: false,
	currentSlide: 0,
	// payment method step
	paymentMethod: null,
	showPaymentDetails: false,
	adminFeePercentage: 0,
	// batch registration step
	batch: null,
	// delivery fee step
	courier: null,
	deliveryFee: null,
};

const buyCourseReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BuyCourseActionTypes.OPEN_BUY_COURSE_DRAWER:
			return {
				...state,
				isDrawerOpen: true,
			};
		case BuyCourseActionTypes.CLOSE_BUY_COURSE_DRAWER:
			return {
				...state,
				isDrawerOpen: false,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_ACTIVE_CHILD_ID:
			return {
				...state,
				activeChildId: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL:
			const paymentInterval = action.payload;

			let tempBillDetailsPI = state.billDetails;
			tempBillDetailsPI[3] = {
				nameEN: paymentInterval.setting_name,
				descriptionEN: paymentInterval.setting_desc,
				nameID: paymentInterval.setting_name_indo,
				descriptionID: paymentInterval.setting_desc_indo,
				// name: `${action.payload} months course`,
				discount: parseInt(paymentInterval.value.discount),
				discountTextEN: `${paymentInterval.value.term} months interval discount`,
				discountTextID: `Diskon jangka waktu ${paymentInterval.value.term} bulan`,
				value: parseInt(paymentInterval.value.normal_price),
				additionalInfoEN: `pay per ${paymentInterval.value.term} months`,
				additionalInfoID: `Bayar per ${paymentInterval.value.term} bulan`,
			};

			return {
				...state,
				paymentInterval: paymentInterval,
				billDetails: [...tempBillDetailsPI],
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_METHOD:
			const paymentMethod = action.payload;

			return {
				...state,
				paymentMethod,
				adminFeePercentage: paymentMethod === "Credit Card" ? 0.03 : 0,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_DELIVERY_COST:
			let tempBillDetailsDC = state.billDetails;

			tempBillDetailsDC[4] = {
				nameEN: `Delivery Fee`,
				nameID: `Biaya Pengiriman`,
				value: action.payload,
				// discount:
				// 	state.paymentInterval.value.term === "12"
				// 		? action.payload
				// 		: 0,
				discount: 0,
				discountTextEN: `Free delivery discount`,
				discountTextID: `Gratis ongkos kirim`,
				additionalInfoEN: `one time delivery`,
				additionalInfoID: `satu kali pengiriman`,
			};
			return {
				...state,
				// deliveryFee:
				// 	state.paymentInterval.value.term === "12"
				// 		? 0
				// 		: action.payload,
				deliveryFee: action.payload,
				billDetails: [...tempBillDetailsDC],
			};
		case BuyCourseActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case BuyCourseActionTypes.RESET_BUY_COURSE:
			return {
				...state,
				error: null,
				loading: false,
				isDrawerOpen: false,
				activeStep: 0,
				hideBillFooter: false,
				activeChildId: null,
				nextStepFunction: null,
				billDetails: [],
				// payment interval step
				paymentInterval: null,
				term2Opened: false,
				term4Opened: false,
				term12Opened: false,
				currentSlide: 0,
				// payment method step
				paymentMethod: null,
				showPaymentDetails: false,
				// batch registration step
				batch: null,
				// delivery fee step
				courier: null,
				deliveryFee: null,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_ACTIVE_STEP:
			return {
				...state,
				activeStep: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_NEXT_STEP_FUNCTION:
			return {
				...state,
				nextStepFunction: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_SHOW_PAYMENT_DETAILS:
			return {
				...state,
				showPaymentDetails: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_HIDE_BILL_FOOTER:
			return {
				...state,
				hideBillFooter: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_BATCH:
			return {
				...state,
				batch: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_COURIER:
			return {
				...state,
				courier: action.payload,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_FIXED_PRICE_LIST:
			const priceList = action.payload;
			let tempBillDetailsFPL = state.billDetails;
			for (let i = 0; i < priceList.length; i++) {
				const price = priceList[i];

				tempBillDetailsFPL[i] = {
					...price,
					nameEN: price.setting_name,
					descriptionEN: price.setting_desc,
					nameID: price.setting_name_indo,
					descriptionID: price.setting_desc_indo,
					value: price.value.price,
					discount: 0,
					valueObj: price.value,
					value: parseInt(price.value.price),
					additionalInfoID: price.value.term_indo,
					additionalInfoEN: price.value.term,
				};
			}

			return {
				...state,
				billDetails: [...tempBillDetailsFPL],
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL_DETAIL:
			return {
				...state,
				[`term${action.payload.term}Opened`]: action.payload.value,
			};
		case BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL_CURRENT_SLIDE:
			return {
				...state,
				currentSlide: action.payload,
			};
		default:
			return state;
	}
};

export default buyCourseReducer;
