import { createSelector } from "reselect";

const selectNextGrade = (state) => state.nextGrade;

export const selectNextGradeError = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.error
);

export const selectNextGradeLoading = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.loading
);

export const selectIsNextGradeDrawerOpen = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.isDrawerOpen
);

export const selectNextGradeActiveChild = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.activeChildId
);

export const selectNextGradePaymentInterval = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.paymentInterval
);

export const selectNextGradePaymentMethod = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.paymentMethod
);

export const selectNextGradeBillDetails = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.billDetails.filter((billDetail) => !!billDetail)
);

export const selectNextGradeTotalBill = createSelector(
	[selectNextGradeBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.value : total),
			0
		)
);

export const selectNextGradeTotalDiscount = createSelector(
	[selectNextGradeBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.discount : total),
			0
		)
);

export const selectNextGradeActiveStep = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.activeStep
);

export const selectNextGradeDisableNextButton = createSelector(
	[selectNextGrade],
	(nextGrade) => {
		switch (nextGrade.activeStep) {
			case 0: // Choice
				return nextGrade.isMovingToNextGrade === null;
			case 1: // Batch Registration
				return !!!nextGrade.batch;
			// case 3: // Delivery Cost
			// 	return false;
			case 4:
				return !!!nextGrade.paymentMethod;
			default:
				// Payment Interval
				// find bill according to step
				const billFound = !!!nextGrade.billDetails[
					nextGrade.activeStep - 1
				];
				return billFound;
		}
	}
);

export const selectNextGradeHideBillFooter = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.hideBillFooter
);

export const selectNextGradeShowPaymentDetails = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.showPaymentDetails
);

export const selectNextGradeNextStepFunction = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.nextStepFunction
);

export const selectNextGradeDiscountedBillDetails = createSelector(
	[selectNextGrade],
	(nextGrade) =>
		nextGrade.billDetails.filter((billDetail) =>
			!!billDetail ? billDetail.discount > 0 : false
		)
);

export const selectNextGradeTotalBillWithDiscount = createSelector(
	[selectNextGradeBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) =>
				!!bill ? total + bill.value - bill.discount : total,
			0
		)
);

export const selectNextGradeIsMovingToNextGrade = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.isMovingToNextGrade
);

export const selectNextGradeDeliveryFee = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.deliveryFee
);

export const selectNextGradeCourier = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.courier
);
export const selectNextGradeTerm2Opened = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.term2Opened
);

export const selectNextGradeTerm4Opened = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.term4Opened
);

export const selectNextGradeTerm12Opened = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.term12Opened
);

export const selectNextGradePaymentMethodOpened = createSelector(
	[selectNextGrade],
	(nextGrade) =>
		nextGrade.term2Opened || nextGrade.term4Opened || nextGrade.term12Opened
);

export const selectNextGradePaymentIntervalCurrentSlide = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.currentSlide
);

export const selectNextGradeBatch = createSelector(
	[selectNextGrade],
	(nextGrade) => nextGrade.batch
);

export const selectNextGradeAdminFeePercentage = createSelector(
	[selectNextGrade],
	(buyCourse) => buyCourse.adminFeePercentage
);

export const selectNextGradeAdminFee = createSelector(
	[selectNextGradeTotalBillWithDiscount, selectNextGradeAdminFeePercentage],
	(totalBillWithDiscount, adminFeePercentage) =>
		totalBillWithDiscount * adminFeePercentage
);
