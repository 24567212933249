import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	notFoundContainer: {
		height: "90vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// backgroundImage: `url(${Background})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "cover",
	},
}));
