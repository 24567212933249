import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	homepageCardRectangle: {
		boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
		maxWidth: "410px",
		borderRadius: "12px",
		background: "#FFFFFF",
		overflow: "hidden",
		position: "relative",
	},
	contentContainer: {},
	cardTitle: {
		position: "absolute",
		width: "52%",
		left: "24px",
		top: "24px",
		fontWeight: "400",
		fontSize: "20px",
		lineHeight: "28px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px",
			lineHeight: "24px",
		},
	},
	link: {
		position: "absolute !important",
		left: "24px",
		bottom: "24px",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "32px",
		// marginLeft: "24px",
		color: "#FFC350 !important",
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px",
			lineHeight: "24px",
		},
	},
	imageContainer: {
		width: "188px",
		height: "188px",
		display: "flex",
		justifyContent: "flex-end",
		alignContent: "center",
		overflow: "hidden",
		borderRadius: "50% 0 0 50%",
		"& img ": {
			marginTop: "-10%",
			minHeight: "120%",
		},
	},
	imageSkeleton: {
		marginTop: "-10%",
		minHeight: "120%",
	},
	cardTitleLoading: {
		fontWeight: "400",
		fontSize: "20px",
		lineHeight: "28px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px",
			lineHeight: "24px",
		},
	},
}));
