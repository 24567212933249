import { createSelector } from "reselect";

const selectCourseExtension = (state) => state.courseExtension;

export const selectCourseExtensionError = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.error
);

export const selectCourseExtensionLoading = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.loading
);

export const selectIsCourseExtensionDrawerOpen = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.isDrawerOpen
);

export const selectCourseExtensionActiveChild = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.activeChildId
);

export const selectCourseExtensionActiveBilling = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.activeBilling
);

export const selectCourseExtensionPaymentMethod = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.paymentMethod
);

export const selectCourseExtensionBillDetails = createSelector(
	[selectCourseExtension],
	(courseExtension) =>
		courseExtension.billDetails.filter((billDetail) => !!billDetail)
);

export const selectCourseExtensionTotalBill = createSelector(
	[selectCourseExtensionBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.value : total),
			0
		)
);

export const selectCourseExtensionTotalDiscount = createSelector(
	[selectCourseExtensionBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) => (!!bill ? total + bill.discount : total),
			0
		)
);

export const selectCourseExtensionActiveStep = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.activeStep
);

export const selectCourseExtensionDisableNextButton = createSelector(
	[selectCourseExtension],
	(courseExtension) => {
		switch (courseExtension.currentStep) {
			case 0: // Choice
				return courseExtension.isMovingToCourseExtension === null;
			case 3: // Delivery Cost
				return true;
			case 4:
				return !!courseExtension.paymentMethod;
			default:
				// Payment Interval
				// find bill according to step
				// (3 bill already in default +1 to get to first dynamic bill
				// because have 1 step without affecting the bill details)
				const billFound = !!courseExtension.billDetails[
					courseExtension.currentStep + 1
				];
				return billFound;
		}
	}
);

export const selectCourseExtensionHideBillFooter = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.hideBillFooter
);

export const selectCourseExtensionShowPaymentDetails = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.showPaymentDetails
);

export const selectCourseExtensionNextStepFunction = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.nextStepFunction
);

export const selectCourseExtensionDiscountedBillDetails = createSelector(
	[selectCourseExtension],
	(courseExtension) =>
		courseExtension.billDetails.filter((billDetail) =>
			!!billDetail ? billDetail.discount > 0 : false
		)
);

export const selectCourseExtensionTotalBillWithDiscount = createSelector(
	[selectCourseExtensionBillDetails],
	(billDetails) =>
		billDetails.reduce(
			(total, bill) =>
				!!bill ? total + bill.value - bill.discount : total,
			0
		)
);

export const selectCourseExtensionIsMovingToCourseExtension = createSelector(
	[selectCourseExtension],
	(courseExtension) => courseExtension.isMovingToCourseExtension
);

export const selectCourseExtensionAdminFeePercentage = createSelector(
	[selectCourseExtension],
	(buyCourse) => buyCourse.adminFeePercentage
);

export const selectCourseExtensionAdminFee = createSelector(
	[
		selectCourseExtensionTotalBillWithDiscount,
		selectCourseExtensionAdminFeePercentage,
	],
	(totalBillWithDiscount, adminFeePercentage) =>
		totalBillWithDiscount * adminFeePercentage
);
