const NewsActionTypes = {
	// FETCH ALL NEWS
	FETCH_ALL_NEWS_START: "FETCH_ALL_NEWS_START",
	FETCH_ALL_NEWS_SUCCESS: "FETCH_ALL_NEWS_SUCCESS",
	FETCH_ALL_NEWS_FAILURE: "FETCH_ALL_NEWS_FAILURE",
	// FETCH ONE NEWS
	FETCH_NEWS_START: "FETCH_NEWS_START",
	FETCH_NEWS_SUCCESS: "FETCH_NEWS_SUCCESS",
	FETCH_NEWS_FAILURE: "FETCH_NEWS_FAILURE",
	// CREATE NEWS
	CREATE_NEWS_START: "CREATE_NEWS_START",
	CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
	CREATE_NEWS_FAILURE: "CREATE_NEWS_FAILURE",
	// UPDATE NEWS
	UPDATE_NEWS_START: "UPDATE_NEWS_START",
	UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
	UPDATE_NEWS_FAILURE: "UPDATE_NEWS_FAILURE",
	// DELETE NEWS
	DELETE_NEWS_START: "DELETE_NEWS_START",
	DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
	DELETE_NEWS_FAILURE: "DELETE_NEWS_FAILURE",
	// UPLOAD TEMP FILE
	UPLOAD_TEMP_FILE_START: "UPLOAD_TEMP_FILE_START",
	UPLOAD_TEMP_FILE_SUCCESS: "UPLOAD_TEMP_FILE_SUCCESS",
	UPLOAD_TEMP_FILE_FAILURE: "UPLOAD_TEMP_FILE_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
};

export default NewsActionTypes;
