import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./CreateNews.styles";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Divider, Grid, TextField } from "@material-ui/core";
import { selectNewsLoading } from "redux/news/news.selector";
import InputFileField from "components/Shared/InputFileField/InputFileField";
import HomepageCardRectangle from "components/Public/HomepageCardRectangle/HomepageCardRectangle";
import HomepageCardRectangleImage from "../../../assets/img/homepage-card.png";
import { createNewsStart } from "redux/news/news.action";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { Editor } from "@tinymce/tinymce-react";
import { uploadTempFileStart } from "redux/news/news.action";
import { baseUrlNonApi } from "helpers/App.helper";
import { useHistory } from "react-router-dom";
import RegularButton from "components/Shared/CustomButtons/Button";
import NewsHeader from "../../../assets/img/news-header.png";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import { closeSnackbar } from "redux/snackbar/snackbar.action";
import { ReactComponent as InfoIcon } from "../../../assets/img/info-icon.svg";
import InfoCard from "components/Shared/InfoCard/InfoCard";
import { languages } from "helpers/App.helper";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";

const tinyMCEApiKey = process.env.REACT_APP_TINY_MCE_API_KEY;

const CreateNews = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const {
		isLoading,
		uploadTempFileStart,
		createNewsStart,
		openSnackbar,
		closeSnackbar,
	} = props;
	const { register, handleSubmit, errors, watch, control } = useForm({
		defaultValues: {
			contentDetailEN: "",
			contentDetailID: "",
			language: "EN",
		},
	});

	const cardNewsTitleEN = useRef();
	cardNewsTitleEN.current = watch("cardNewsTitleEN", "");
	const cardNewsTitleID = useRef();
	cardNewsTitleID.current = watch("cardNewsTitleID", "");
	const cardImage = useRef();
	cardImage.current = watch("cardImage", "");
	const cardButtonTextEN = useRef();
	cardButtonTextEN.current = watch("cardButtonTextEN", "");
	const cardButtonTextID = useRef();
	cardButtonTextID.current = watch("cardButtonTextID", "");
	const contentHeaderImage = useRef();
	contentHeaderImage.current = watch("contentHeaderImage", "");
	const contentLinkTo = useRef();
	contentLinkTo.current = watch("contentLinkTo", "");
	const language = useRef();
	language.current = watch("language", "");

	const storeNews = (data) => {
		if (!isLoading) {
			createNewsStart({ data, history });
		}
	};

	const storeTempImage = ({ file, onSuccess, onFailure }) => {
		uploadTempFileStart({ file, onSuccess, onFailure });
	};

	useEffect(() => {
		if (!!errors.contentDetailEN) {
			const snackbarData = {
				message: errors.contentDetailEN.message,
				color: "error",
				place: "bl",
			};
			openSnackbar(snackbarData);
		} else {
			closeSnackbar();
		}
	}, [errors.contentDetailEN]);

	useEffect(() => {
		if (!!errors.contentDetailID) {
			const snackbarData = {
				message: errors.contentDetailID.message,
				color: "error",
				place: "bl",
			};
			openSnackbar(snackbarData);
		} else {
			closeSnackbar();
		}
	}, [errors.contentDetailID]);

	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Create News</h4>
					</CardHeader>
					<CardBody>
						<form onSubmit={handleSubmit(storeNews)}>
							<Grid container spacing={3}>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									alignContent="flex-start"
								>
									<Grid item xs={12}>
										<TextField
											multiline
											rows={3}
											margin="normal"
											fullWidth
											id="cardNewsTitleEN"
											label="Card News Title (English)"
											name="cardNewsTitleEN"
											inputRef={register({
												required: {
													value: true,
													message:
														"Card News Title (English) are required",
												},
											})}
											error={!!errors.cardNewsTitleEN}
											helperText={
												errors.cardNewsTitleEN &&
												errors.cardNewsTitleEN.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											multiline
											rows={3}
											margin="normal"
											fullWidth
											id="cardNewsTitleID"
											label="Card News Title (Indonesia)"
											name="cardNewsTitleID"
											inputRef={register({
												required: {
													value: true,
													message:
														"Card News Title (Indonesia) are required",
												},
											})}
											error={!!errors.cardNewsTitleID}
											helperText={
												errors.cardNewsTitleID &&
												errors.cardNewsTitleID.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="cardButtonTextEN"
											label="Card Button Text (English)"
											name="cardButtonTextEN"
											inputRef={register({
												required: {
													value: true,
													message:
														"Card Button Text (English) are required",
												},
											})}
											error={!!errors.cardButtonTextEN}
											helperText={
												errors.cardButtonTextEN &&
												errors.cardButtonTextEN.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="cardButtonTextID"
											label="Card Button Text (Indonesia)"
											name="cardButtonTextID"
											inputRef={register({
												required: {
													value: true,
													message:
														"Card Button Text (Indonesia) are required",
												},
											})}
											error={!!errors.cardButtonTextID}
											helperText={
												errors.cardButtonTextID &&
												errors.cardButtonTextID.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputFileField
											margin="normal"
											accept="image/*"
											id="cardImage"
											label="Upload Card Image"
											inputRef={register({
												required: {
													value: true,
													message:
														"Card Image are required",
												},
											})}
											value={cardImage.current}
											error={!!errors.cardImage}
											helperText={
												errors.cardImage &&
												errors.cardImage.message
											}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
									justify="center"
									alignContent="flex-start"
									className={classes.previewContainer}
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Preview{" "}
											{language.current === "EN"
												? "English"
												: "Indonesia"}
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										lg={4}
										className={
											classes.languageSelectorContainer
										}
									>
										<CustomSelect
											fullWidth
											id="language"
											label="Language"
											name="language"
											options={languages}
											control={control}
											removeNone
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										justify="center"
									>
										<HomepageCardRectangle
											preview
											title={
												language.current === "EN"
													? cardNewsTitleEN.current ||
													  "Title goes here..."
													: cardNewsTitleID.current ||
													  "Title goes here..."
											}
											linkText={
												language.current === "EN"
													? cardButtonTextEN.current ||
													  "Button Text goes here..."
													: cardButtonTextID.current ||
													  "Button Text goes here..."
											}
											linkTo="#"
											image={
												(cardImage.current.length > 0 &&
													URL.createObjectURL(
														cardImage.current[0]
													)) ||
												HomepageCardRectangleImage
											}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									className={classes.dividerContainer}
								>
									<Divider />
								</Grid>
								<Grid
									item
									xs={12}
									lg={6}
									container
									alignContent="flex-start"
								>
									<Grid item xs={12}>
										<InfoCard
											icon={<InfoIcon />}
											text="<p>External Web Example : 'https://www.youtube.com' <br />Internal Web Example : 'news/5'</p>"
											backgroundColor="#FFF8D4"
											color="#836300"
											maxWidth="480px"
										/>
									</Grid>
									<Grid item xs={12}>
										<InputFileField
											margin="normal"
											accept="image/*"
											id="contentHeaderImage"
											label="Upload Content Header Image"
											inputRef={register({
												required: {
													value: true,
													message:
														"Content Header Image are required",
												},
											})}
											value={contentHeaderImage.current}
											error={!!errors.contentHeaderImage}
											helperText={
												errors.contentHeaderImage &&
												errors.contentHeaderImage
													.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="contentLinkTo"
											label="Content Link To"
											name="contentLinkTo"
											inputRef={register()}
											error={!!errors.contentLinkTo}
											helperText={
												errors.contentLinkTo &&
												errors.contentLinkTo.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="contentButtonTextEN"
											label="Content Button Text (English)"
											name="contentButtonTextEN"
											inputRef={register({
												required: {
													value: !!contentLinkTo.current,
													message:
														"Content Button Text (English) are required if Content Link To is filled.",
												},
											})}
											error={!!errors.contentButtonTextEN}
											helperText={
												errors.contentButtonTextEN &&
												errors.contentButtonTextEN
													.message
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											margin="normal"
											fullWidth
											id="contentButtonTextID"
											label="Content Button Text (Indonesia)"
											name="contentButtonTextID"
											inputRef={register({
												required: {
													value: !!contentLinkTo.current,
													message:
														"Content Button Text (Indonesia) are required if Content Link To is filled.",
												},
											})}
											error={!!errors.contentButtonTextID}
											helperText={
												errors.contentButtonTextID &&
												errors.contentButtonTextID
													.message
											}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									lg={6}
									container
									className={classes.contentHeaderPreview}
									justify="center"
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Preview Header Image
										</h3>
									</Grid>

									<img
										src={
											(contentHeaderImage.current.length >
												0 &&
												URL.createObjectURL(
													contentHeaderImage
														.current[0]
												)) ||
											NewsHeader
										}
										alt="preview header"
									/>
								</Grid>

								<Grid
									item
									xs={12}
									container
									className={classes.contentDetailContainer}
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Content Detail (English)
										</h3>
									</Grid>
									<Controller
										control={control}
										name="contentDetailEN"
										rules={{
											required: {
												value: true,
												message:
													"Content Detail (English) are required",
											},
										}}
										defaultValue=""
										render={({
											onChange,
											onBlur,
											value,
											name,
											ref,
										}) => (
											<Editor
												apiKey={tinyMCEApiKey}
												initialValue=""
												textareaName={name}
												value={value}
												onEditorChange={onChange}
												onBlur={onBlur}
												init={{
													height: 500,
													menubar: false,
													plugins: [
														"advlist autolink lists link image charmap preview anchor",
														"searchreplace visualblocks code fullscreen",
														"insertdatetime media table paste code help wordcount imagetools ",
													],
													toolbar: [
														"undo redo | \
                                                    bold italic underline strikethrough | \
                                                    fontselect fontsizeselect formatselect | \
                                                    alignleft aligncenter alignright alignjustify | ",
														"outdent indent | \
                                                    numlist bullist | \
                                                    forecolor backcolor removeformat | \
                                                    pagebreak | \
                                                    charmap emoticons | \
                                                    fullscreen preview | \
                                                    insertfile image media template link anchor codesample code",
													],
													width: 932,
													relative_urls: false,
													remove_script_host: false,
													convert_urls: true,
													image_advtab: true,
													image_caption: true,
													file_picker_types: "image",
													document_base_url: baseUrlNonApi,
													images_upload_handler: function (
														blobInfo,
														success,
														failure
													) {
														storeTempImage({
															file: blobInfo.blob(),
															onSuccess: success,
															onFailure: failure,
														});
													},
												}}
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
									className={classes.contentDetailContainer}
								>
									<Grid item xs={12}>
										<h3 className={classes.previewLabel}>
											Content Detail (Indonesia)
										</h3>
									</Grid>
									<Controller
										control={control}
										name="contentDetailID"
										rules={{
											required: {
												value: true,
												message:
													"Content Detail (Indonesia) are required",
											},
										}}
										defaultValue=""
										render={({
											onChange,
											onBlur,
											value,
											name,
											ref,
										}) => (
											<Editor
												apiKey={tinyMCEApiKey}
												initialValue=""
												textareaName={name}
												value={value}
												onEditorChange={onChange}
												onBlur={onBlur}
												init={{
													height: 500,
													menubar: false,
													plugins: [
														"advlist autolink lists link image charmap preview anchor",
														"searchreplace visualblocks code fullscreen",
														"insertdatetime media table paste code help wordcount imagetools ",
													],
													toolbar: [
														"undo redo | \
                                                    bold italic underline strikethrough | \
                                                    fontselect fontsizeselect formatselect | \
                                                    alignleft aligncenter alignright alignjustify | ",
														"outdent indent | \
                                                    numlist bullist | \
                                                    forecolor backcolor removeformat | \
                                                    pagebreak | \
                                                    charmap emoticons | \
                                                    fullscreen preview | \
                                                    insertfile image media template link anchor codesample code",
													],
													width: 932,
													relative_urls: false,
													remove_script_host: false,
													convert_urls: true,
													image_advtab: true,
													image_caption: true,
													file_picker_types: "image",
													document_base_url: baseUrlNonApi,
													images_upload_handler: function (
														blobInfo,
														success,
														failure
													) {
														storeTempImage({
															file: blobInfo.blob(),
															onSuccess: success,
															onFailure: failure,
														});
													},
												}}
											/>
										)}
									/>
								</Grid>

								<Grid item xs={12}>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
									>
										<RegularButton
											type="submit"
											variant="contained"
											color="info"
											className={classes.submit}
										>
											Create News
										</RegularButton>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectNewsLoading,
});

const mapDispatchToProps = (dispatch) => ({
	createNewsStart: ({ data, history }) =>
		dispatch(createNewsStart({ data, history })),
	uploadTempFileStart: ({ file, onSuccess, onFailure }) =>
		dispatch(uploadTempFileStart({ file, onSuccess, onFailure })),
	openSnackbar: (snackbarData) => dispatch(openSnackbar(snackbarData)),
	closeSnackbar: () => dispatch(closeSnackbar()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateNews);
