const PaymentActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET_PAYMENT_FOR
	SET_PAYMENT_FOR: "SET_PAYMENT_FOR",
	// SET_PAYMENT_SUCCESS
	SET_PAYMENT_SUCCESS: "SET_PAYMENT_SUCCESS",
	// SET_CREDIT_CARD
	SET_CREDIT_CARD: "SET_CREDIT_CARD",
	// FETCH PRICE LIST
	FETCH_PRICE_LIST_START: "FETCH_PRICE_LIST_START",
	FETCH_PRICE_LIST_SUCCESS: "FETCH_PRICE_LIST_SUCCESS",
	FETCH_PRICE_LIST_FAILURE: "FETCH_PRICE_LIST_FAILURE",
	// STORE PAYMENT
	STORE_PAYMENT_START: "STORE_PAYMENT_START",
	STORE_PAYMENT_SUCCESS: "STORE_PAYMENT_SUCCESS",
	STORE_PAYMENT_FAILURE: "STORE_PAYMENT_FAILURE",
	// SET PAYMENT SUCCESS
	SET_PAYMENT_SUCCESS: "SET_PAYMENT_SUCCESS",
	// SET CREDIT_CARD_TOKEN
	SET_CREDIT_CARD_TOKEN: "SET_CREDIT_CARD_TOKEN",
	// SET CREDIT_CARD_REDIRECT_URL
	SET_CREDIT_CARD_REDIRECT_URL: "SET_CREDIT_CARD_REDIRECT_URL",
	// SET OPEN_3DS_AUTHENTICATION
	SET_OPEN_3DS_AUTHENTICATION: "SET_OPEN_3DS_AUTHENTICATION",
	// CHECK PAYMENT
	CHECK_PAYMENT_START: "CHECK_PAYMENT_START",
	CHECK_PAYMENT_SUCCESS: "CHECK_PAYMENT_SUCCESS",
	CHECK_PAYMENT_FAILURE: "CHECK_PAYMENT_FAILURE",
	// SEND PAYMENT NOTIFICATION
	SEND_PAYMENT_NOTIFICATION_START: "SEND_PAYMENT_NOTIFICATION_START",
	SEND_PAYMENT_NOTIFICATION_SUCCESS: "SEND_PAYMENT_NOTIFICATION_SUCCESS",
	SEND_PAYMENT_NOTIFICATION_FAILURE: "SEND_PAYMENT_NOTIFICATION_FAILURE",
	// RETRY PAYMENT
	RETRY_PAYMENT_START: "RETRY_PAYMENT_START",
	RETRY_PAYMENT_SUCCESS: "RETRY_PAYMENT_SUCCESS",
	RETRY_PAYMENT_FAILURE: "RETRY_PAYMENT_FAILURE",
	// SET ORDER_ID
	SET_ORDER_ID: "SET_ORDER_ID",
	// Fetch PAYMENT_HISTORIES
	FETCH_PAYMENT_HISTORIES_START: "FETCH_PAYMENT_HISTORIES_START",
	FETCH_PAYMENT_HISTORIES_SUCCESS: "FETCH_PAYMENT_HISTORIES_SUCCESS",
	FETCH_PAYMENT_HISTORIES_FAILURE: "FETCH_PAYMENT_HISTORIES_FAILURE",
};

export default PaymentActionTypes;
