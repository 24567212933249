import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./IndexOrder.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import Button from "components/Shared/CustomButtons/Button";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import {
	orderStatuses,
	tableColumns,
} from "../../../helpers/IndexOrder.helper";
import { useHistory } from "react-router";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { selectOrderPlural } from "redux/order/order.selector";
import { selectOrderLoading } from "redux/order/order.selector";
import { fetchAllOrdersStart } from "redux/order/order.action";
import { clearOrderData } from "redux/order/order.action";
import UpdateOrderModal from "components/Admin/UpdateOrderModal/UpdateOrderModal";

const IndexOrder = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { control, watch } = useForm();
	const status = useRef();
	status.current = watch("status", "");
	const tableRef = useRef();
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const [activeData, setActiveData] = useState(null);

	const { fetchAllOrdersStart, orders, isLoading, clearOrderData } = props;

	const handleUpdateModalOpen = (data) => {
		setIsUpdateModalOpen(true);
		setActiveData(data);
	};

	const handleUpdateModalClose = () => {
		setIsUpdateModalOpen(false);
		setActiveData(null);
	};

	useEffect(() => {
		fetchAllOrdersStart(status.current);
		tableRef.current.onChangePage(null, 0);
	}, [status.current]);

	useEffect(() => {
		return () => {
			clearOrderData();
		};
	}, []);
	return (
		<Grid container>
			<UpdateOrderModal
				open={isUpdateModalOpen}
				onClose={handleUpdateModalClose}
				orderData={activeData}
			/>
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>List of Orders</h4>
					</CardHeader>
					<CardBody>
						<Grid container>
							<Grid
								item
								xs={12}
								lg={4}
								className={classes.selectCategoriesContainer}
							>
								<CustomSelect
									fullWidth
									id="status"
									label="Order Status"
									name="status"
									options={orderStatuses}
									control={control}
								/>
							</Grid>
						</Grid>
						<MaterialTable
							tableRef={tableRef}
							isLoading={isLoading}
							columns={tableColumns}
							data={orders || []}
							title={""}
							actions={[
								{
									text: "update",
									color: "info",
									icon: "edit",
									onClick: (event, rowData) =>
										handleUpdateModalOpen(rowData),
								},
								// {
								// 	text: "DELETE",
								// 	color: "danger",
								// 	icon: "delete",
								// 	onClick: (event, rowData) =>
								// 		alert("You delete " + rowData.name),
								// },
							]}
							options={{
								search: true,
								exportButton: false,
								pageSize: 10,
								actionsColumnIndex: -1,
							}}
							components={{
								Action: (props) => {
									const { color, text } = props.action;
									return (
										<>
											<Button
												className={
													classes.buttonOnTable
												}
												onClick={(event) =>
													props.action.onClick(
														event,
														props.data
													)
												}
												color={color}
											>
												{text}
											</Button>
										</>
									);
								},
							}}
						/>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	orders: selectOrderPlural,
	isLoading: selectOrderLoading,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllOrdersStart: (filters) => dispatch(fetchAllOrdersStart(filters)),
	clearOrderData: () => dispatch(clearOrderData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexOrder);
