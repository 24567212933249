import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	infoCard: {
		color: ({ color }) => color || "#FFF",
		backgroundColor: ({ backgroundColor }) =>
			backgroundColor || "rgba(0, 0, 0, 0.5)",
		padding: "12px 16px",
		fontSize: "12px",
		lineHeight: "16px",
		borderRadius: "12px",
		maxWidth: ({ maxWidth }) => maxWidth || "480px",
	},
}));
