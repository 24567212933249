import axios from "axios";
import { courseTypes } from "helpers/App.helper";
import moment from "moment";
import { channel } from "redux-saga";
import { takeLatest, put, all, call, select, take } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearCourseMasterError,
	createCourseMasterFailure,
	createCourseMasterSuccess,
	deleteCourseMasterFailure,
	deleteCourseMasterSuccess,
	fetchCourseMastersFailure,
	fetchCourseMastersStart,
	fetchCourseMastersSuccess,
	deactivateCourseMasterFailure,
	deactivateCourseMasterSuccess,
	updateCourseMasterSuccess,
	updateCourseMasterFailure,
	fetchCourseMasterSuccess,
	fetchCourseMasterFailure,
	setCourseMasterUploadProgress,
	swapCourseMasterSuccess,
	swapCourseMasterFailure,
} from "./course-master.action";
import CourseMasterActionTypes from "./course-master.types";

const courseMasterChannel = channel();

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* watchCourseMasterChannel() {
	while (true) {
		const action = yield take(courseMasterChannel);
		yield put(action);
	}
}

export function* fetchCourseMasters({ payload }) {
	const filters = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/course/all`,
			params: {
				...filters,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const courseMasters = response.data.course.map((course) => ({
			...course,
			courseType: courseTypes[course.type],
		}));

		yield put(fetchCourseMastersSuccess(courseMasters));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCourseMastersFailure(errorMessage));
	}
}

export function* onFetchCourseMastersStart() {
	yield takeLatest(
		CourseMasterActionTypes.FETCH_COURSE_MASTERS_START,
		fetchCourseMasters
	);
}

export function* createCourseMaster({ payload }) {
	const { courseMasterData, history } = payload;
	const formData = new FormData();
	formData.append("title", courseMasterData.titleEN);
	formData.append("desc", courseMasterData.descriptionEN);
	formData.append("title_indo", courseMasterData.titleID);
	formData.append("desc_indo", courseMasterData.descriptionID);
	formData.append("unit", courseMasterData.unit);
	formData.append("week", courseMasterData.week);
	formData.append("grade", courseMasterData.grade);
	formData.append("type", courseMasterData.courseType);
	formData.append(
		"video_url",
		courseMasterData.video.length > 0 ? courseMasterData.video[0] : null
	);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/master-data/add`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable
					? progressEvent.total
					: progressEvent.target.getResponseHeader(
							"content-length"
					  ) ||
					  progressEvent.target.getResponseHeader(
							"x-decompressed-content-length"
					  );
				if (totalLength !== null) {
					courseMasterChannel.put(
						setCourseMasterUploadProgress(
							Math.round(
								(progressEvent.loaded * 100) / totalLength
							)
						)
					);
				}
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(createCourseMasterSuccess());
		history.push("/admin/courses");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createCourseMasterFailure(errorMessage));
	}
}

export function* onCreateCourseMastersStart() {
	yield takeLatest(
		CourseMasterActionTypes.CREATE_COURSE_MASTER_START,
		createCourseMaster
	);
}

export function* updateCourseMaster({ payload }) {
	const { courseMasterData, history } = payload;
	const formData = new FormData();
	formData.append("master_course_id", courseMasterData.id);
	formData.append("title", courseMasterData.titleEN);
	formData.append("desc", courseMasterData.descriptionEN);
	formData.append("title_indo", courseMasterData.titleID);
	formData.append("desc_indo", courseMasterData.descriptionID);
	formData.append("unit", courseMasterData.unit);
	formData.append("week", courseMasterData.week);
	formData.append("grade", courseMasterData.grade);
	formData.append("type", courseMasterData.courseType);
	formData.append(
		"video_url",
		courseMasterData.video && courseMasterData.video.length > 0
			? courseMasterData.video[0]
			: null
	);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/course/update`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable
					? progressEvent.total
					: progressEvent.target.getResponseHeader(
							"content-length"
					  ) ||
					  progressEvent.target.getResponseHeader(
							"x-decompressed-content-length"
					  );
				if (totalLength !== null) {
					courseMasterChannel.put(
						setCourseMasterUploadProgress(
							Math.round(
								(progressEvent.loaded * 100) / totalLength
							)
						)
					);
				}
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateCourseMasterSuccess());
		history.push("/admin/courses");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateCourseMasterFailure(errorMessage));
	}
}

export function* onUpdateCourseMastersStart() {
	yield takeLatest(
		CourseMasterActionTypes.UPDATE_COURSE_MASTER_START,
		updateCourseMaster
	);
}

export function* fetchCourseMaster({ payload }) {
	const courseMasterId = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/course/id/${courseMasterId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const courseMasters = response.data.course;

		yield put(fetchCourseMasterSuccess(courseMasters));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCourseMasterFailure(errorMessage));
	}
}

export function* onFetchCourseMasterStart() {
	yield takeLatest(
		CourseMasterActionTypes.FETCH_COURSE_MASTER_START,
		fetchCourseMaster
	);
}

export function* deleteCourseMaster({ payload }) {
	const courseMasterId = payload;

	try {
		const response = yield axios({
			method: "DELETE",
			url: `${baseUrlApi}/master-data/delete-one/${courseMasterId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(deleteCourseMasterSuccess());
		yield put(fetchCourseMastersStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteCourseMasterFailure(errorMessage));
	}
}

export function* onDeleteCourseMasterStart() {
	yield takeLatest(
		CourseMasterActionTypes.DELETE_COURSE_MASTER_START,
		deleteCourseMaster
	);
}

export function* swapCourseMaster({ payload }) {
	const { first, second } = payload;

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/course/switch`,
			data: {
				first_id: first,
				second_id: second,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(swapCourseMasterSuccess());
		yield put(fetchCourseMastersStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCourseMasterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(swapCourseMasterFailure(errorMessage));
	}
}

export function* onSwapCourseMasterStart() {
	yield takeLatest(
		CourseMasterActionTypes.SWAP_COURSE_MASTER_START,
		swapCourseMaster
	);
}

export function* courseMasterSagas() {
	yield all([
		call(watchCourseMasterChannel),
		call(onFetchCourseMastersStart),
		call(onCreateCourseMastersStart),
		call(onUpdateCourseMastersStart),
		call(onFetchCourseMasterStart),
		call(onDeleteCourseMasterStart),
		call(onSwapCourseMasterStart),
	]);
}
