import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const dictionary = {
	// Header Start
	HOME: {
		EN: "Home",
		ID: "Beranda",
	},
	OUR_COURSES: {
		EN: "Our Courses",
		ID: "Program Kami",
	},
	STUDENT_PERFORMANCE: {
		EN: "Student Performance",
		ID: "Portofolio Siswa",
	},
	STORIES_OF_ADIWIDYA: {
		EN: "Stories of Adiwidya",
		ID: "Kabar Adiwidya",
	},
	// Header End

	// Homepage Start
	HOMEPAGE_BANNER_TITLE: {
		EN: "Learning English from Home in fun and meaningful ways",
		ID:
			"Belajar Bahasa Inggris dari Rumah dengan Metode yang menyenangkan dan penuh makna",
	},
	HOMEPAGE_BANNER_SUBTITLE: {
		EN:
			"Bringing the best experience in English learning with international curriculum mixed with various childlike activities.",
		ID:
			"Memberikan pengalaman terbaik dalam belajar Bahasa Inggris berbasis kurikulum internasional dibalut dengan beragam aktifitas interaktif",
	},
	HOMEPAGE_BANNER_BUTTON_TEXT: {
		EN: "Buy Course",
		ID: "Beli Pelajaran",
	},
	HOMEPAGE_S1_TITLE: {
		EN: "What makes Us special?",
		ID: "Apa yang membuat kami spesial?",
	},
	HOMEPAGE_S1_SUBTITLE: {
		EN: "There are thousands of English courses out there, but why Us?",
		ID:
			"Ada banyak sekali penawaran kursus Bahasa Inggris yang lain, tapi kenapa harus kami?",
	},
	HOMEPAGE_S1_CARD_1: {
		EN:
			"UK Masters Graduate Teacher, specialized in educational management & curriculum development, designing interactively a well-planned online English Course for Kids.",
		ID:
			"Pengajar lulusan Masters Inggris, ahli manajemen pendidikan dan pengembangan kurikulum, mendesain online course anak yang interaktif dan terencana",
	},
	HOMEPAGE_S1_CARD_1_BUTTON_TEXT: {
		EN: "Watch Here",
		ID: "Lihat Disini",
	},
	HOMEPAGE_S1_CARD_2: {
		EN: "Proven student alumni who continue to study abroad",
		ID: "Bukti nyata alumni kami yang melanjutkan studi keluar negeri",
	},
	HOMEPAGE_S1_CARD_2_BUTTON_TEXT: {
		EN: "Meet Them",
		ID: "Temui Mereka",
	},
	HOMEPAGE_S1_CARD_3: {
		EN: "Guaranteed speaking English fluently based on strong foundation",
		ID:
			"Dijamin bisa fasih berkomunikasi Bahasa Inggris dengan dasar yang kuat",
	},
	HOMEPAGE_S1_CARD_3_BUTTON_TEXT: {
		EN: "Listen to it",
		ID: "Dengarkan",
	},
	HOMEPAGE_S1_CARD_4: {
		EN:
			"Project based English learning (not merely theories but real practices)",
		ID:
			"Pembelajaran berbasis project (bukan melulu teori tapi melalui praktik-praktik nyata)",
	},
	HOMEPAGE_S1_CARD_4_BUTTON_TEXT: {
		EN: "See Performances",
		ID: "Lihat Pertunjukan",
	},
	HOMEPAGE_S1_CARD_5: {
		EN:
			"We mix strong English curriculum with Fun Activities (involving family members to relearn English)",
		ID:
			"Kami gabungkan kurikulum Bahasa Inggris yang bermakna dengan aktifitas menyenangkan (melibatkan anggota keluarga turut kembali belajar Bahasa Inggris). Satu anak belajar, keluarga bisa ikutan",
	},
	HOMEPAGE_S1_CARD_5_BUTTON_TEXT: {
		EN: "See their activities",
		ID: "Lihat aktivitas mereka",
	},
	HOMEPAGE_S1_CARD_6: {
		EN:
			"Thorough assesment (direct feedback, Face to Face Test and Review Session for each student)",
		ID:
			"Penilaian menyeluruh (umpan balik langsung, tes tatap muka dan sesi ulasan untuk tiap siswa). Pembimbingan yang berlangsung intens",
	},
	HOMEPAGE_S1_CARD_6_BUTTON_TEXT: {
		EN: "Show Them",
		ID: "Lihat Disini",
	},
	HOMEPAGE_S2_TITLE: {
		EN:
			"Trial Class Available! Feel Free to experience English learning with Us",
		ID:
			"Kelas Trial Tersedia! Silahkan coba untuk belajar Bahasa Inggris bersama kami",
	},
	HOMEPAGE_S2_SUBTITLE: {
		EN:
			"See and feel the experience of learning English with Adiwidya English Course",
		ID:
			"Lihat dan rasakan pengalaman belajar Bahasa Inggris yang berbeda bersama Adiwidya English Course",
	},
	HOMEPAGE_S2_BUTTON_TEXT: {
		EN: "Try it Now",
		ID: "Coba Sekarang",
	},
	HOMEPAGE_S3_TITLE: {
		EN: "Study Kit",
		ID: "Perangkat Belajar",
	},
	HOMEPAGE_S3_SUBTITLE: {
		EN: "Full experience of learning with our custom study kit",
		ID:
			"Pengalaman belajar menarik melalui paket perangkat belajar kami. Praktis dan mengembangkan!!",
	},
	HOMEPAGE_S3_BUTTON_TEXT: {
		EN: "Look at Details",
		ID: "Lihat Detail",
	},
	HOMEPAGE_S4_TITLE: {
		EN: "What they say about us",
		ID: "Apa kata mereka tentang kami",
	},
	PARENT_TESTIMONIALS: {
		EN: "Parent's Testimonials",
		ID: "Kesaksian Orang Tua",
	},
	OUR_ALUMNI: {
		EN: "Our Alumni",
		ID: "Alumni Kami",
	},
	OUR_STUDENTS: {
		EN: "Our Students",
		ID: "Siswa Kami",
	},
	// Homepage End

	// Login Start
	LOGIN_TITLE: {
		EN: "Welcome Back!",
		ID: "Selamat Datang Kembali!",
	},
	LOGIN_SUBTITLE: {
		EN: "Please Sign in with your Adiwidya Account. Want to create one?",
		ID: "Silahkan masuk dengan akun Adiwidya Anda. Mau membuat akun?",
	},
	CLICK_HERE: {
		EN: "Click here.",
		ID: "Klik disini.",
	},
	FORGOT_PASSWORD: {
		EN: "Forgot Password?",
		ID: "Lupa Password?",
	},
	ACCOUNT_ACTIVATED: {
		EN: "Account Activated!",
		ID: "Akun telah Aktif!",
	},
	ACCOUNT_ALREADY_ACTIVATED: {
		EN: "Account already Activated!",
		ID: "Akun telah Aktif!",
	},
	// Login End

	// Register Start
	REGISTER_TITLE: {
		EN: "Let's Create Your Account",
		ID: "Mari Buat Akun Anda",
	},
	REGISTER_SUBTITLE: {
		EN: "Please fill in your details below.",
		ID: "Mohon masukkan detail Anda dibawah ini",
	},
	REGISTER_INFO_CARD: {
		EN:
			"All the information you fill in, will be used for course purpose only. We will not distribute your personal data for any other purpose",
		ID:
			"Semua informasi yang anda masukkan, hanya akan digunakan untuk keperluan kursus saja. Kami tidak akan mendistribusikan data diri Anda untuk kepentingan lain",
	},
	RE_TYPE_PASSWORD: {
		EN: "Re-type Password",
		ID: "Ketik Ulang Password",
	},
	PASSWORD_LENGTH_VALIDATION: {
		EN: "Password must have at least 8 characters",
		ID: "Password harus memiliki minimal 8 karakter",
	},
	PASSWORD_MATCH_VALIDATION: {
		EN: "The passwords do not match",
		ID: "Password tidak cocok",
	},
	// Register End

	// Register Child Start
	REGISTER_CHILD_TITLE: {
		EN: "Register Your Child",
		ID: "Daftarkan anak Anda",
	},
	ADD_PHOTO: {
		EN: "Add Photo (Max 5MB)",
		ID: "Tambahkan foto (Maks. 5MB)",
	},
	REGISTER_NOW: {
		EN: "Register Now",
		ID: "Daftar Sekarang",
	},
	// Register Child End

	// Register Child Finish Start
	REGISTER_CHILD_FINISH_TITLE: {
		EN: "Thank You",
		ID: "Terima Kasih",
	},
	REGISTER_CHILD_FINISH_SUBTITLE: {
		EN:
			"Thank You for registering. Your child is ready to learn and master English in no time.",
		ID:
			"Terima Kasih sudah mendaftar. Anak Anda siap belajar dan menjadi master bahasa Inggris segera",
	},
	// Register Child Finish End

	// User Profile Start
	ACCOUNT_DETAILS: {
		EN: "Account Details",
		ID: "Detail Akun",
	},
	MY_CHILDREN: {
		EN: "My Children",
		ID: "Anak Saya",
	},
	PAYMENTS: {
		EN: "Payments",
		ID: "Pembayaran",
	},
	INBOX: {
		EN: "Inbox",
		ID: "Kotak Masuk",
	},
	LOGOUT: {
		EN: "Logout",
		ID: "Keluar",
	},
	// User Profile End

	// Add Child Card Start
	ADD_NEW_CHILD: {
		EN: "Add New Child",
		ID: "Tambah Anak Baru",
	},
	// Add Child Card End

	// User Children Card Start
	SEE_DETAILS: {
		EN: "See Details",
		ID: "Lihat Detail",
	},
	CHECK_PAYMENT: {
		EN: "Check Payment",
		ID: "Cek Pembayaran",
	},
	// User Children Card End

	// !INFO : Drawer Step Buy Course Edit in BuyCourse.helper.js
	// Buy Course Introduction Start
	BUY_COURSE_INTRO_TITLE: {
		EN: "Here’s how Our Course Work",
		ID: "Inilah cara kerja kursus kami",
	},
	BUY_COURSE_INTRO_SUBTITLE: {
		EN: "Please understand this basic rules of buying the course",
		ID: "Mohon pahami aturan dasar pembelian kursus ini",
	},
	BUY_COURSE_INTRO_BUTTON_TEXT: {
		EN: "Let’s Start",
		ID: "Mari mulai",
	},
	BUY_COURSE_INTRO_CARD_1_TITLE: {
		EN: "Step 1 - First Registration",
		ID: "Langkah 1 - Pendaftaran pertama",
	},
	BUY_COURSE_INTRO_CARD_1_DESCRIPTION: {
		EN:
			"If it’s your first time join this The Course, you will need to pay for Registration fee, Study Kit, 1 year text book online code, and first payment interval of the course. After buying the course, your kid will need to take placement test.",
		ID:
			"Jika ini pertama kali Anda bergabung didalam kursus ini, Anda perlu membayar biaya pendaftaran, Perangkat Belajar, Kode online untuk Buku teks pembelajaran 1 tahun, dan pembayaran interval pertama dari kursus. Setelah membeli kursus, anak Anda harus mengikuti tes penempatan",
	},
	BUY_COURSE_INTRO_CARD_1_ADDITIONAL_INFO: {
		EN:
			"More info: Kids under 8 years old will automatically start from Grade 2. Older kids will need to take placement test.",
		ID:
			"Info tambahan: Anak berusia dibawah 8 tahun akan otomatis memulai kursus dari Tingkat 2. Anak yang berusia lebih tua, harus mengikuti tes penempatan",
	},
	BUY_COURSE_INTRO_CARD_2_TITLE: {
		EN: "Step 2 - Select Payment Interval",
		ID: "Langkah 2 - Pilih Interval Pembayaran",
	},
	BUY_COURSE_INTRO_CARD_2_DESCRIPTION: {
		EN:
			"Payment interval is when you want to get charged for your course payment. 12mo means you only need to pay once, 4mo means you need to pay 3 times, and 2mo means you have to pay 6 times.",
		ID:
			"Interval pembayaran adalah kapan Anda ingin ditagihkan untuk membayar kursus. 12 bulan berarti Anda hanya akan membayar 1 kali, 4 bulan berarti Anda akan membayar 3 kali, dan 2 bulan berarti Anda perlu membayar 6 kali.",
	},
	BUY_COURSE_INTRO_CARD_2_ADDITIONAL_INFO: {
		EN:
			"More info: It’s cheaper when you choose to pay the course 12 months in advance.",
		ID:
			"Info tambahan: Total pembayaran lebih murah Jika Anda memilih pembayaran 12 bulan didepan.",
	},
	BUY_COURSE_INTRO_CARD_3_TITLE: {
		EN: "Step 3 - Select Delivery Address",
		ID: "Langkah 3 - Pilih Alamat Pengiriman",
	},
	BUY_COURSE_INTRO_CARD_3_DESCRIPTION: {
		EN:
			"Please make sure that you put a correct address to make sure that we can send our study kit as a support for your kids course learning & activity package. Clear address help us and the courier send our goods.",
		ID:
			"Mohon pastikan Anda memasukkan alamat yang benar untuk memastikan kami dapat mengirim perangkat belajar sebagai alat bantu untuk pembelajaran kursus Anak Anda. Alamat yang jelas membantu kami dan kurir dalam pengiriman barang",
	},
	BUY_COURSE_INTRO_CARD_3_ADDITIONAL_INFO: {
		EN:
			"More info: Your study kit contains support tools for 1 year of course. So keep continue your course payment. You already have the kit!",
		ID:
			"Info tambahan: perangkat belajar sudah berisi alat bantu untuk pembelajaran 1 tahun. Jadi lanjutkan terus pembayaran kursus Anda. Anda sudah memiliki alat nya",
	},
	BUY_COURSE_INTRO_CARD_4_TITLE: {
		EN: "Step 4 - Payment & Placement Test",
		ID: "Langkah 4 - Pembayaran & Tes Penempatan",
	},
	BUY_COURSE_INTRO_CARD_4_DESCRIPTION: {
		EN:
			"Payment method valid for this time only and it won’t be used for recurring payment. After finishing the payment, make sure your kids can take Placement Test in range of 3x24 hours. Placement test duration will took 15-20 minutes",
		ID:
			"Metode pembayaran berlaku hanya untuk saat ini dan tidak akan kami gunakan untuk pembayaran berulang. Setelah membereskan pembayaran, pastikan anak Anda mengikuti Tes Penempatan dalam kurun waktu 3x24 jam. Tes Penempatan akan memakan waktu 15-20 menit",
	},
	BUY_COURSE_INTRO_CARD_4_ADDITIONAL_INFO: {
		EN:
			"More info: If you don’t pay for the bill, your status will become inactive and you won’t have access to the learning video and ZOOM classes.",
		ID:
			"Info tambahan: Jika Anda tidak membayar tagihan, status Anda akan menjadi tidak aktif dan Anda tidak akan memiliki akses ke video pembelajaran dan kelas ZOOM",
	},
	// Buy Course Introduction End

	// Buy Course Batch Registration Start
	BATCH_REGISTRATION_TITLE: {
		EN: "Your Registration",
		ID: "Pendaftaran Anda",
	},
	BATCH_REGISTRATION_SUBTITLE: {
		EN: "This is the batch of the course that you will join",
		ID: "Ini adalah gelombang kursus yang akan Anda ikuti",
	},
	BATCH_REGISTRATION_BUTTON_TEXT: {
		EN: "Join this Batch",
		ID: "Ikut gelombang ini",
	},
	// Buy Course Batch Registration End

	// Payment Interval Step Start
	// !INFO : To edit features, check App.helper.js on paymentIntervals const
	PAYMENT_INTERVAL_TITLE: {
		EN: "Please Select Your Payment Interval",
		ID: "Mohon Pilih Interval Pembayaran Anda",
	},
	PAYMENT_INTERVAL_SUBTITLE: {
		EN: "I would like to pay the course",
		ID: "Saya ingin membayar kursus",
	},
	PAYMENT_INTERVAL_INFOCARD: {
		EN:
			"Payment interval will determine when we will send the bills to you. Skip the bill means you won’t have access to Course Video and Zoom classes",
		ID:
			"Interval pembayaran akan menentukan kapan kami akan mengirimkan tagihan kepada Anda. Tidak membayar tagihan berarti Anda tidak akan memiliki akses ke video kursus dan kelas ZOOM",
	},
	PACKAGE_FEATURES: {
		EN: "Package Features",
		ID: "Fitur Paket",
	},
	MO: {
		EN: "mo",
		ID: "bln",
	},
	DISCOUNT_CAPS: {
		EN: "DISCOUNT",
		ID: "DISKON",
	},
	SHOW_MORE_DETAILS: {
		EN: "Show more details",
		ID: "Lihat detail",
	},
	STARTER_KIT: {
		EN: "Starter Kit",
		ID: "Starter Kit",
	},
	CONTINUATION_KIT: {
		EN: "Continuation Kit",
		ID: "Continuation Kit",
	},
	// TITLE IS PROCEED WITH + dynamic var + month terms
	PROCEED_WITH: {
		EN: "Proceed with",
		ID: "Lanjutkan dengan jangka waktu",
	},
	MONTH_TERMS: {
		EN: "months terms",
		ID: "bulan",
	},
	PAYMENT_INTERVAL_MODAL_SUBTITLE: {
		EN:
			"It’s cheaper when you buy the course in 1 year term. you will lose your chance to get bigger discount",
		ID:
			"Biaya kursus akan Lebih murah jika Anda membayar dalam jangka waktu 1 tahun. Anda akan kehilangan kesempatan untuk mendapat diskon yang lebih besar",
	},
	WAIT_LET_ME_THINK: {
		EN: "Wait, Let me think",
		ID: "Tunggu, biarkan saya berpikir",
	},
	// Payment Interval Step End

	// Delivery Fee Step Start
	DELIVERY_FEE_TITLE: {
		EN: "Let’s set your Delivery Address",
		ID: "Mari atur alamat pengiriman Anda",
	},
	DELIVERY_FEE_SUBTITLE: {
		EN:
			"To make sure you receive your study kit on time, please recheck your address",
		ID:
			"Untuk memastikan Anda menerima perangkat belajar tepat waktu, mohon cek kembali alamat Anda",
	},
	COURIER: {
		EN: "Courier",
		ID: "Kurir",
	},
	// Delivery Fee Step End

	// Payment Method Step Start
	// !INFO : To edit how te steps, check App.helper.js on paymentMethods const
	PAYMENT_METHOD_TITLE: {
		EN: "Let’s Pay for Your Kid’s Course",
		ID: "Mari bayar biaya kursus anak Anda",
	},
	PAYMENT_METHOD_SUBTITLE: {
		EN: "Select your payment method",
		ID: "Pilih metode pembayaran Anda",
	},
	PAYMENT_METHOD_INFO_CARD: {
		EN:
			"We won’t save your credit card information for other purpose. It will only used per transaction based.",
		ID:
			"Kami tidak akan menyimpan informasi kartu kredit Anda untuk keperluan lainnya. Kartu kredit hanya akan digunakan per transaksi",
	},
	PAYMENT_METHOD_CREDIT_CARD_INFO: {
		EN:
			"*there will be an additional 3% admin fee if you choose to pay using a credit card.",
		ID:
			"*akan ada tambahan admin fee sebesar 3%  apabila memilih pembayaran menggunakan kartu kredit.",
	},
	BACK_TO_CHOOSE_PAYMENT: {
		EN: "Back to Choose Payment Method",
		ID: "Kembali ke Pemilihan Metode Pembayaran",
	},
	HOW_TO_PAY_USING: {
		EN: "How to pay using",
		ID: "Bagaimana cara untuk membayar menggunakan",
	},
	// Payment Method Step End

	// QRIS Payment Card Start
	QRIS_PAYMENT_CARD_TITLE: {
		EN: "Your QR Code",
		ID: "Kode QR Anda",
	},
	QRIS_PAYMENT_CARD_ADDITIONAL_INFO: {
		EN:
			"Please scan the QR Code using your preferred payment app, once you finish your transaction, you can check the status here",
		ID:
			"Silahkan pindai Kode QR menggunakan aplikasi pembayaran pilihan anda, saat anda sudah menyelesaikan pembayaran, anda bisa mengecek status nya disini",
	},
	// QRIS Payment Card End

	// Credit Card Payment Start
	CREDIT_CARD_PAYMENT_INFO_CARD: {
		EN:
			"We won’t save your credit card information for other purpose. It will only used per transaction based.",
		ID:
			"Kami tidak akan menyimpan informasi kartu kredit Anda untuk keperluan lainnya. Kartu kredit hanya akan digunakan per transaksi",
	},
	CREDIT_CARD_NUMBER: {
		EN: "Credit Card Number",
		ID: "Nomor Kartu Kredit",
	},
	CARD_HOLDER_NAME: {
		EN: "Card Holder Name",
		ID: "Nama Pemegang Kartu",
	},
	EXPIRY_DATE: {
		EN: "Expiry Date",
		ID: "Tanggal Kedaluwarsa",
	},
	CVV: {
		EN: "CVV",
		ID: "CVV",
	},
	// Credit Card Payment End

	// VA Mandiri Payment Card Start
	VA_MANDIRI_PAYMENT_CARD_TITLE: {
		EN: "Your Mandiri Virtual Account Number",
		ID: "Nomor Virtual Akun Mandiri Anda",
	},
	VA_MANDIRI_PAYMENT_CARD_ADDITIONAL_INFO: {
		EN:
			"Please access your Mandiri Virtual Account and do the payment, once you finish your transaction, you can check the status here",
		ID:
			"Mohon akses Virtual Akun Mandiri Anda dan lakukan pembayaran, saat Anda sudah menyelesaikan pembayaran, Anda bisa mengecek status nya disini",
	},
	// QRIS Payment Card End

	// Check Payment Case 1 Start
	CHECK_PAYMENT_C1_TITLE: {
		EN: "Thank You",
		ID: "Terima Kasih",
	},
	CHECK_PAYMENT_C1_SUBTITLE: {
		EN:
			"You have successfully register and pay for your first course. And your kid will automatically start from Grade 2. See you in the class!",
		ID:
			"Anda sudah berhasil mendaftar dan membayar kursus pertama Anda. Anak Anda akan otomatis memulai dari Tingkat 2. Sampai jumpa di kelas!",
	},
	// Check Payment Case 1 End

	// Check Payment Case 2 Start
	CHECK_PAYMENT_C2_TITLE: {
		EN: "Let’s Start Your Kid Placement Test",
		ID: "Mari mulai Tes Penempatan anak Anda",
	},
	CHECK_PAYMENT_C2_SUBTITLE: {
		EN:
			"You have successfully register and pay for your first course. Now it’s time to take your placement test. Placement test is mandatory and will take about 15-20 minutes.",
		ID:
			"Anda sudah berhasil mendaftar dan membayar untuk kursus pertama Anda. Sekarang waktunya mengikuti Tes Penempatan. Tes Penempatan bersifat wajib dan akan memakan waktu 15-20 menit",
	},
	TAKE_PLACEMENT_TEST: {
		EN: "Take Placement Test",
		ID: "Ikuti Tes Penempatan",
	},
	TAKE_PLACEMENT_TEST_LATER: {
		EN: "Take the test later",
		ID: "Ikuti tes nanti",
	},
	CHECK_PAYMENT_C2_MODAL_TITLE: {
		EN: "Skip Placement Test?",
		ID: "Lewati Tes Penempatan?",
	},
	CHECK_PAYMENT_C2_MODAL_SUBTITLE: {
		EN:
			"Are you sure you want to take placement test later? You need to take test in range of 3x24 hours to make sure your kids can start the course on time",
		ID:
			"Anda yakin ingin mengikuti Tes Penempatan nanti? Anda perlu mengikuti Tes Penempatan dalam jangka waktu 3x24 jam untuk memastikan anak Anda dapat memulai kursus tepat waktu",
	},
	YES_SKIP_TEST: {
		EN: "Yes, Skip the test",
		ID: "Ya, lewati tes",
	},
	// Check Payment Case 2 End

	// Check Payment Case 3 Start
	CHECK_PAYMENT_C3_TITLE: {
		EN: "Oops! We haven’t received your payment",
		ID: "Ups! Kami belum menerima pembayaran Anda",
	},
	CHECK_PAYMENT_C3_SUBTITLE: {
		EN: "Your payment is not successful and you will need to try again.",
		ID: "Pembayaran Anda belum berhasil dan Anda perlu mencoba lagi",
	},
	CHANGE_PAYMENT_METHOD: {
		EN: "Change Payment Method",
		ID: "Ubah Metode Pembayaran",
	},
	TRY_AGAIN: {
		EN: "Try Again",
		ID: "Coba Lagi",
	},
	// Check Payment Case 3 End

	// Check Payment Case 4 Start
	CHECK_PAYMENT_C4_TITLE: {
		EN: "Thank You",
		ID: "Terima Kasih",
	},
	CHECK_PAYMENT_C4_SUBTITLE_P1: {
		EN: "You have successfully register and pay for your next course.",
		ID: "Kamu telah berhasil mendaftar dan membayar kursus selanjutnya.",
	},
	CHECK_PAYMENT_C4_SUBTITLE_P2: {
		EN: "And you will continue your course and start the Grade",
		ID: "Kamu akan melanjutkan kursus kamu ke Kelas.",
	},
	CHECK_PAYMENT_C4_SUBTITLE_P3: {
		EN: "See you in the class!",
		ID: "Sampai jumpa di kelas!",
	},
	// Check Payment Case 4 End

	// Check Payment Case Success Start
	CHECK_PAYMENT_SUCCESS_TITLE: {
		EN: "Thank You",
		ID: "Terima Kasih",
	},
	CHECK_PAYMENT_SUCCESS_SUBTITLE: {
		EN: "We already received your payment.",
		ID: "Kami sudah menerima pembayaran anda.",
	},
	// Check Payment Case Success End

	// Bill Details Footer Start
	// !INFO : To Edit Button Text per Step check BuyCourse.helper.js on buyCourseSteps const
	// !INFO : To Edit buy course fee breakdown items check buy-course.reducer.js and edit according to step you want to edit
	BILL_DETAILS_FOOTER_MODAL_TITLE: {
		EN: "Course Fee Breakdown",
		ID: "Detail Biaya Kursus",
	},
	PAY_AMOUNT: {
		EN: "Pay Amount",
		ID: "Nominal Pembayaran",
	},
	SEE_BREAKDOWN: {
		EN: "See Breakdown",
		ID: "Lihat Rincian",
	},
	BILL_DETAILS_ADMIN_FEE: {
		EN: "Admin Fee",
		ID: "Biaya Admin",
	},
	// Bill Details Footer End

	// FAQ Start
	FAQ_Q1: {
		EN: "What classes are available in Adiwidya English Course?",
		ID: "Kelas apa saja yang ada di Adiwidya English Course?",
	},
	FAQ_Q1_A1: {
		EN:
			"English for Kids (6-13 years and this is our specialty, laying a solid foundation and motivation to learn for the next stage)",
		ID:
			"Kelas untuk anak setingkat SD (dan ini spesialisasi kami, peletakkan dasar yang kuat untuk tahap selanjutnya)",
	},
	FAQ_Q1_A2: {
		EN:
			"English for Academics (for Teens & Adolescence, including for Junior, High school and university students)",
		ID: "Kelas untuk remaja setingkat SMP, SMU",
	},
	FAQ_Q1_A3: {
		EN:
			"English for Specific Purposes (for adults) such as TOEFL, English for Business/Bank, etc.",
		ID:
			"Kelas untuk dewasa dengan ESP (English for Specific Purposes) seperti TOEFL, English for Business/ Bank, dll.",
	},
	FAQ_Q2: {
		EN:
			"I’m not yet confirmed about Adiwidya for Kids. Do you provide Trial Class?",
		ID:
			"Saya belum terlalu yakin saya akan mengikutikan putra/i saya. Apa kursus Adiwidya menyediakan Trial Class?",
	},
	FAQ_Q2_A1: {
		EN:
			"Yes, we provide trial class in the form of sample learning videos (not Zoom Meetings). Take a look at our youtube, and learn how we teach your kids to learn English in fun meaningful way.",
		ID:
			"Ya, kami menyediakan trial class berupa contoh video belajar (bukan Zoom Meeting). Silakan lihat di youtube kami, dan pelajari cara kami mengajarkan putra/i Anda belajar Bahasa Inggris.",
	},
	FAQ_Q3: {
		EN: "How could I join you then?",
		ID: "Bagaimana caranya saya bergabung?",
	},
	FAQ_Q3_A1: {
		EN:
			"First, learn who we are, as our online English Course for Kids is so unique. Read this FAQ n other info in great detail, it will help to understand how we go more clearly.",
		ID:
			"Pertama, pelajari siapa kami, karena Kursus Bahasa Inggris untuk Anak secara Daring ala kami sangat unik. Baca FAQ ini dan info lainnya dengan sangat rinci, ini akan membantu untuk memahami lebih baik. Kemudian, jika kualitas & sistem kami sesuai dengan harapan Anda, silakan lakukan pembayaran.",
	},
	FAQ_Q3_A2: {
		EN:
			"There are 3 types of payment intervals offered i.e. per 2 mo, 4 mo n 12 mo. Surely, the longer payment you choose the more discount you will get, even up to Rp. 1 million rups.",
		ID:
			"Ada 3 jenis interval pembayaran yang ditawarkan yaitu per 2 bln, 4 bln n 12 bln. Tentunya, semakin lama pembayaran yang Anda pilih, semakin banyak diskon yang akan Anda dapatkan, bahkan hingga Rp. 1 juta rups",
	},
	FAQ_Q3_A3: {
		EN:
			"After the payment, please ask your kid to directly have his/ her Placement Test in THREE DAYS only, because if you fail to do so, your kid will directly be placed in Grade 2.",
		ID:
			"Setelah pembayaran, mohon minta putra/i anda untuk langsung mengikuti Placement Test hanya dalam TIGA HARI saja, karena jika lalai (tidak diikuti) maka anak anda akan langsung ditempatkan di Tingkat 2.",
	},
	FAQ_Q3_A4: {
		EN:
			"Our Tutor will inform you via private line of your kids’ grade as well the zoom schedule for week 4 of each unit as well for other necessary communication.",
		ID:
			"Tutor kami akan memberi tahu Anda melalui WA tentang penempatan kelas serta jadwal zoom untuk minggu ke-4 dari setiap unit serta untuk kepentingan komunikasi lainnya.",
	},
	FAQ_Q3_A5: {
		EN:
			"Every Monday, you can access our learning video as scheduled in our syllabus and give back the tasks as parts of the learning to our tutor private line to be reviewed.",
		ID:
			"Setiap hari Senin, Anda sudah dapat mengakses video pembelajaran kami sesuai jadwal di silabus dan mengirimkan tugas sebagai bagian dari pembelajaran hasil belajar putra/i anda lewat WA Tutor yang bertanggungjawab untuk dikoreksi.",
	},
	FAQ_Q4: {
		EN:
			"As Adiwidya English Course provides online English learning, how would you guarantee that my kids won’t feel bored?",
		ID:
			"Kalau anak saya ikut kelas English for Kids secara online begini, apa jaminan anak saya tidak akan bosan? Seperti apa cara belajarnya?",
	},
	FAQ_Q4_A1: {
		EN:
			"We combine learning methods through embedded videos (available on our website) and zoom meetings. All of these are designed to be fun and interactive for children as our learning methods are holistic (considering the use of 5 senses). The program is also designed carefully by our curriculum developer who is an expert in her field.",
		ID:
			"Kami menggabungkan cara belajar lewat video embedded (ada di website) dan zoom meeting, yang didesain menyenangkan dan interaktif untuk anak dan dari kesemuanya itu, metode belajar kami yang holistik dan menyentuh 5 indera anak.",
	},
	FAQ_Q4_A2: {
		EN:
			"Every week, there are different games/activities to stimulate children's desire to speak English. There are games and activities designed to invite parties at home to get involved.",
		ID:
			"Setiap minggu, ada permainan/aktifitas berbeda untuk merangsang keinginan anak berbicara Bahasa Inggris, asyik deh (ada permainan pun aktifitas yang didesain mengundang pihak di rumah untuk ikut terlibat).",
	},
	FAQ_Q4_A3: {
		EN:
			"Children are happy if they are given creative space. We guide kids to translate the theories into real practice and English presentation through our project based learning system at the end of each Unit. We give challenges in the form of simple projects, even cooking in English.",
		ID:
			"Anak senang jika diberi ruang berkreasi, akan sangat menyenangkan di setiap akhir Unit, kami memberi tantangan berupa project sederhana, bahkan memasak dalam Bahasa Inggris.",
	},
	FAQ_Q4_A4: {
		EN:
			"Learning is wrapped in interesting songs, simple poems, short films (audio visuals with complete multimedia) and online games and offline activities at home.",
		ID:
			"Pembelajaran dibalut dengan lagu yang menarik, puisi sederhana dan lucu, film film pendek (audio visual dengan multimedia lengkap) dan permainan online.",
	},
	FAQ_Q4_A5: {
		EN:
			"Children's Worksheets are also designed with very attractive pictures and colors (available in our specially-designed Study Kit)",
		ID:
			"Lembar Kerja anak pun dirancang dengan gambar dan warna yang sangat menarik (ada di Study Kit)",
	},
	FAQ_Q5: {
		EN: "How will I be informed of my kids’ progress?",
		ID: "Bagaimana saya mengetahui perkembangan kemampuan anak saya?",
	},
	FAQ_Q5_A1: {
		EN:
			"Kids’ Weekly feedback based on their tasks and dictation will have direct review in a maximum of 7 working days. Besides the written tasks, we also evaluate kids’ pronunciation in the form of their recorded pronunciation and speaking (through activities we design)",
		ID:
			"Feedback mingguan dari hasil belajar anak dikirimkan pada tutor untuk diperiksa dan dikembalikan maximal 7 hari kerja. Salah satu rancangan kami selain lembar kerja siswa yang harus dikembalikan pada tutor untuk diperiksa, adalah adanya tugas merekam pelafalan dan dikte mingguan dari kosa kata minggu sebelumnya yang harus selalu dipelajari.",
	},
	FAQ_Q5_A2: {
		EN:
			"The weekly embedded learning video which you can access will be deleted by the system in two weeks, this will enforce you to build the habit of learning English continuously, gradually whilst ensuring high quality",
		ID:
			"Sistem belajar dengan video yang akan terhapus setiap 2 minggunya, akan mendisiplinkan anak (pun orangtua) untuk terus maju belajar (sayang dong sudah bayar, masak dilewatkan belajar videonya). Hal ini akan membentuk kebiasaan belajar Bahasa Inggris secara kontinyu, bertahap dan berkualitas",
	},
	FAQ_Q5_A3_S1: {
		EN: "After 3 Units (evert 12 weeks) children will get 2 types of Tests",
		ID: "Per 3 Unit (12 minggu) anak akan mendapatkan 2 jenis Review Tests",
	},
	FAQ_Q5_A3_S2: {
		EN: "C1. Written Test",
		ID: "C1. Test Tertulis",
	},
	FAQ_Q5_A3_S3: {
		EN: "C2. Oral Test (one on one with our Tutor)",
		ID: "C2. Test Lisan (one on one dengan guru)",
	},
	FAQ_Q5_A3_S4: {
		EN:
			"The results will be communicated in writing in the form of quarterly progress report to parents",
		ID:
			"Hasilnya akan dikomunikasikan secara tertulis berupa rapot perkembangan pada orangtua",
	},
	FAQ_Q6: {
		EN: "Do you teach in English?",
		ID: "Apa pengajaran disampaikan dalam Bahasa Inggris?",
	},
	FAQ_Q6_A1: {
		EN:
			"Yes, we familiarize children using English as our main language of instruction. Particularly this applies to those in Grade 4 and above (with almost 100% instructional content). We use Indonesian only for explaining grammar. This is intended to 'familiarize' children to be more confident in listening, capturing information and speaking English later on.",
		ID:
			"Ya, kami membiasakan anak belajar dengan bahasa pengantar Bahasa Inggris, terlebih di Grade 4 ke atas (dengan kandung pengantar hampir 100%). Hanya saat menerangkan konsep struktur kalimat, pengajar utama, memakai Bahasa Indonesia. Hal ini dimaksudkan ‘membiasakan’ anak untuk percaya diri mendengar, menangkap info dan bicara Bahasa Inggris.",
	},
	FAQ_Q7: {
		EN: "What curriculum is used in Adiwidya English Course?",
		ID: "Kurikulum apa yang dipakai di Adiwidya English Course?",
	},
	FAQ_Q7_A1: {
		EN:
			"We use Pearson's international curriculum standards. Since 2005, since Adiwidya is managed under the new management, we have consistently used International curriculum that has been proven to enable children to compete in the global era.",
		ID:
			"Kami menggunakan standar kurikulum internasional dari Pearson. Dari tahun 2005 sejak manajemen kursus baru, kami konsisten menggunakan kurikulum yang telah terbukti mampu menghantarkan anak bersaing di era global.",
	},
	FAQ_Q8: {
		EN: "Who are the teachers?",
		ID: "Siapa saja pengajar/instruktur Adiwidya English Course?",
	},
	FAQ_Q8_A1_S1: {
		EN: "Mam Tanti (Christanti Gomulia)",
		ID: "Pengajar utama untuk English for Kids",
	},
	FAQ_Q8_A1_S2: {
		EN:
			"- English for Kids Main Teacher. She earned her Bachelor Degree from English Teaching Education, Sanata Dharma University, Jogjakarta, and has received a full Scholarship from British Chevening Awards for her Master's Degree in Educational Management in the UK. She is the winner of the National Frans Seda Awards (Education category) and has consistently established alternative schools (Adiwidya Pre-school, Bestari Utami Schools, Bimasena Primary as well Middle School, Cimahi & Semesta Hati Primary School Purwakarta) since 2007 until now, and has been a consultant for curriculum development and teacher training.",
		ID:
			"adalah Mam Tanti, beliau lulusan Pendidikan Bahasa Inggris Universitas Sanata Dharma, Jogjakarta, dan mendapatkan beasiswa penuh British Chevening Awards Scholarship untuk gelar S2 Educational Management di UK (Inggris). Beliau pemenang Nasional Frans Seda Awards kategori Pendidikan dan secara konsisten dari 2007 hingga saat ini, mendirikan sekolah sekolah alternative (Adiwidya Pre-school, SD SMP Bimasena Cimahi, SD Semesta Hati Purwakarta), dan menjadi konsultan pengembangan kurikulum dan pelatihan guru.",
	},
	FAQ_Q8_A2_S1: {
		EN: "Mam Anita (Anita Liugito)",
		ID: "Pengajar utama untuk English for Teens and Adults",
	},
	FAQ_Q8_A2_S2: {
		EN:
			"- English for Teens and Adults. She graduated from UGM majoring in International Politics and the English Language Extension D2 Program, Sanata Dharma University, Yogyakarta. She has consistently become an educator and currently is the principal of Bestari Utami primary school in Garut.",
		ID:
			"adalah Mam Anita, beliau lulusan UGM jurusan International Politics dan Program D2 Extension Bahasa Inggris, Universitas Sanata Dharma, Yogyakarta. Saat ini, beliau konsisten menjadi pendidik dan kepala sekolah di SMP Bestari Utami.",
	},
	FAQ_Q8_A3_S1: {
		EN: "Our tutors",
		ID: "Para Tutor kami untuk English for Kids",
	},
	FAQ_Q8_A3_S2: {
		EN:
			"- English for Kids are Mam Iin (Fransisca Indriasari), Mam Sri (Neneng Sri Rahayu), Mam Lesa (Lesa Mariesa). They are English teacher graduates from several universities and have consistently been engaged in children's education for more than 5 years.",
		ID:
			"adalah Mam Iin, Mam Sri, Mam Lesa. Beliau beliau ini adalah lulusan Pendidikan Guru untuk Anak dan telah secara konsisten bergerak di pendidikan anak lebih dari 5 tahun.",
	},
	FAQ_Q9: {
		EN: "Are there native speakers in Adiwidya English Course?",
		ID: "Apakah ada native speaker di Adiwidya English Course?",
	},
	FAQ_Q9_A1: {
		EN:
			"Yes, there is. Our Native teacher is Mam Bill (Guilhemine Miquel). She earned her BA (Bachelor of Arts degree) in 1995 with 1 year specialization in PGCE (QTS- Qualified Teacher Status). She is now a professional Middle School Teacher in Pyrenees, France from 2004.",
		ID:
			"Ada. Beliau adalah Mam Bill (Guilheminne Miquel), lulus dengan gelar S1 Pendidikan Guru dan mengambil specialisasi QTS (PGCE - Qualified Teacher Status). Saat ini beliau pengajar profesional di setingkat SMP di Pyrenees, Prancis, sejak tahun 2004.",
	},
	FAQ_Q10: {
		EN: "How will you know my child's initial abilities?",
		ID: "Bagaimana cara kursus mengetahui kemampuan anak saya?",
	},
	FAQ_Q10_A1: {
		EN:
			"For children over 8 years old, we conduct a placement test, to determine the level of prospective students.  However, children under 8 years old, they are directly placed in Grade 2.",
		ID:
			"Untuk anak berusia lebih dari 8 tahun, kami mengadakan placement test/tes penempatan, untuk bisa mengetahui kemampuan calon peserta didik, hasilnya akan menenentukan calon tersebut sebaiknya mulai dari Grade/ Tingkat berapa. Namun untuk anak dibawah 8 tahun, secara langsung ditempatkan di Grade 2.",
	},
	FAQ_Q11: {
		EN: "So the schedule is fixed? Or flexible?",
		ID:
			"Jadi jadwal anak saya tetap? Tidak fleksibel dalam mengikuti kursus online ini?",
	},
	FAQ_Q11_A1: {
		EN: "FLEXIBLE",
		ID: "FLEKSIBEL",
	},
	FAQ_Q11_A2: {
		EN:
			"Every Monday, the learning video as scheduled according to the syllabus given will be accessible",
		ID:
			"Setiap Senin, video belajar sesuai dengan silabus, sudah bisa dipelajari (sudah bisa diakses)",
	},
	FAQ_Q11_A3: {
		EN:
			"Children are expected to finish studying the lesson accessed in one week (adjusted to your kids’ availability of time). In consideration of the current online from school, we give a grace period of up to 2 weeks.",
		ID:
			"Anak diharap selesaikan mempelajari materi dari video belajar yang telah diakses dalam satu minggu (karenanya,  waktu fleksibel, disesuaikan dengan ketersediaan waktu anak). Selain itu, melihat kesibukan anak saat ini bersamaan online dari sekolah, kami memberi tenggang s.d 2 minggu.",
	},
	FAQ_Q11_A4: {
		EN:
			"However, for the zoom class (once in four weeks period), as each child will be enrolled in a class under one tutor with 12 friends, your kid has to follow the schedule agreed previously at the start of the class by WA group. Even if there is a change for one reason or another, it will be communicated in advance.",
		ID:
			"Namun untuk kelas zoom (1 kali dalam 4 minggu, karena 3 minggu lainnya berupa video belajar), setiap anak, akan kami daftarkan dalam satu kelas bersama 12 temannya dengan satu tutor yang menjadi pembimbing belajar (pengoreksi tugas juga). Karenanya, kelas zoom di setiap akhir Tema, akan terjadwal sesuai kesepakatan WA group, di awal kelas berjalan. Jikapun, ada perubahan karena satu dan lain hal akan dikomunikasikan secepatnya.",
	},
	FAQ_Q12: {
		EN: "So how many intakes are there in a year?",
		ID: "Jadi ada berapa kali intake dalam satu tahun?",
	},
	FAQ_Q12_A1: {
		EN:
			"Only twice a year. Registration starts every July and December for the 2nd week of August and 2nd week of January class intake.",
		ID:
			"Pada saat ini, hanya 2 kali dalam setahun. Pendaftaran dimulai setiap bulan Juli dan Desember untuk intake kelas Agustus minggu ke 2 dan Januari minggu ke 2.",
	},
	FAQ_Q13: {
		EN: "Can my child join the course at any time?",
		ID: "Apakah anak saya bisa ikut kursus kapanpun?",
	},
	FAQ_Q13_A1: {
		EN:
			"Nope. Because our courses are programmed in detail and carefully designed for semi-school programs (not just to pursue school academics, rather than to provide a strong and motivating foundation for kids). Currently, registration starts in July (for classes starting August week II) and December (for classes starting January week II). Except for the other program such as for teens and adults, we have different policy, please contact Mam Anita/ Mam Reni in private",
		ID:
			"Tidak. Untuk program kursus English for Kids, karena kursus kami terprogram dengan detil dan dirancang secara seksama untuk program semi sekolah (tapi bukan untuk sekadar mengejar akademis sekolah, lebih pada memberi dasar kuat dan memotivasi untuk pembelajaran bahasa ini). Saat ini, pendaftaran dimulai di bulan Juli (untuk kelas mulai Agustus minggu II) dan Desember (untuk kelas mulai Januari minggu II). Untuk program kursus di luar anak, silahkan hubungi langsung Mam Anita/ Mam Reni",
	},
	FAQ_Q14: {
		EN: "If I find out too late, and want to join, what should I do?",
		ID:
			"Kalau saya terlambat mengetahui, dan ingin ikut bergabung, bagaimana?",
	},
	FAQ_Q14_A1: {
		EN: "Waiting for the next admission/registration or",
		ID: "Menunggu penerimaan/pendaftaran selanjutnya atau",
	},
	FAQ_Q14_A2: {
		EN:
			"Join as soon as possible. But at the risk of missing some lessons. Even so, you still have to follow the STARTER PACK path, to get a Study Pack and Online Code Textbook for one year of learning. If the study has reached example the 3rd month, you still have to pay for the Continuation Pack and it will be compensated.",
		ID:
			"Turut bergabung secepatnya. Namun dengan resiko tertinggal beberapa pelajaran. Pun, tetap harus mengikuti jalur STARTER PACK, karena harus mendaftar dan mendapat Study Kit dan Buku Teks Online Code untuk pembelajaran satu tahun. Jika pembelajaran sudah masuk bulan ke 3, Anda tetap harus membayar Continuation Pack dan akan diberikan Kompensasi.",
	},
	FAQ_Q15: {
		EN: "When we buy this program, what will we get?",
		ID: "Saat kami membeli program ini, apa saja yang akan kami dapatkan?",
	},
	FAQ_Q15_A1: {
		EN: "Zoom lessons and class videos.",
		ID: "Video belajar dan kelas Zoom.",
	},
	FAQ_Q15_A2: {
		EN:
			"Pearson's online code textbook for a year (learning videos, interactive online games, listening)",
		ID:
			"Buku online code dari Pearson untuk setahun (video belajar, game online interaktif, listening)",
	},
	FAQ_Q15_A3: {
		EN:
			"Study Kit for one year, which contains 1 Student Worksheet module, 1 activity package module and learning aids supported for lesson activities, packaged in the form of a Tote Bag.",
		ID:
			"Study Kit untuk satu tahun, yang berisi 1 modul Lembar Kerja Siswa, 1 modul paket aktifitas dan alat bantu belajar saat aktifitas, dikemas dalam bentuk Tote Bag.",
	},
	FAQ_Q15_A4: {
		EN: "Comprehensive and continuous feedback.",
		ID: "Umpan balik yang menyeluruh dan kontinyu.",
	},
	FAQ_Q15_A5: {
		EN: "Bonus: for Grades 4 – 6, study with Native teachers.",
		ID: "Bonus: bagi Grade 4 – 6, belajar bersama guru Native.",
	},
	FAQ_Q15_A6_S1: {
		EN:
			"E-certificate* (at the end of the school year, after a year of study)",
		ID: "E-certificate* (pada akhir tahun ajaran, setelah setahun belajar)",
	},
	FAQ_Q15_A6_S2: {
		EN: "*Terms and Conditions apply",
		ID: "*Syarat dan ketentuan berlaku",
	},
	FAQ_Q16: {
		EN: "Can I take any lessons from the class videos?",
		ID: "Apakah saya bisa ambil pelajaran manapun dari video belajar?",
	},
	FAQ_Q16_A1: {
		EN: "You can't, as mentioned previously that",
		ID: "Tidak bisa, seperti telah disebut di atas bahwa",
	},
	FAQ_Q16_A2: {
		EN:
			"Our program is designed per theme with a step-by-step lesson, therefore children should follow consecutively in a weekly session as the learning videos (class videos) are automatically deleted after 2 weeks of access.",
		ID:
			"Rancangan belajar didesain per Tema dengan tahapan step by step, karenanya anak harus mengikuti secara berurutan setiap minggu, karena video belajar dihapus oleh sistem setelah 2 minggu.",
	},
	FAQ_Q16_A3: {
		EN: "The completion of one lesson will be the basis for the next one.",
		ID:
			"Penguasaan satu materi akan menjadi dasar untuk pembelajaran berikutnya.",
	},
	FAQ_Q16_A4: {
		EN:
			"Each theme will end with a Zoom meeting to see the understanding of each child, also to guide kids having our PBL (project based learning) to translate theories into practice",
		ID:
			"Setiap satu tema akan diakhiri dengan Zoom meeting untuk melihat pemahaman dari setiap anak, juga untuk membimbing anak mempraktikan teori lewat PBL (Project Based Learning)",
	},
	FAQ_Q17: {
		EN: "How long does the lesson take place?",
		ID: "Berapa lama waktu pembelajaran?",
	},
	FAQ_Q17_A1: {
		EN:
			"Each grade with its coverage is completed in 1 year, which is divided into 9 Thematic Units. Each theme is completed in 4-week lessons (divided into 3 videos and 1 zoom class)",
		ID:
			"Materi untuk setiap tingkatan/grade diselesaikan dalam 1 tahun, yang dibagi kedalam 9 Unit Tema. Setiap tema diselesaikan dalam 4 minggu belajar (dibagi menjadi 3 minggu belajar dengan video dan 1 zoom meeting)",
	},
	FAQ_Q17_A2: {
		EN:
			"Each lesson will be delivered in 30-45 minute videos, the children learn gradually and will gain understanding after completing 6-7 assignments (as parts of learning NOT homework) for 90 minutes.",
		ID:
			"Pembelajaran disampaikan dalam video belajar berdurasi 30 - 45 menit, anak belajar secara bertahap dan akan mendapat pemahaman setelah mengikuti 6 - 7 penugasan (sebagai bagian dari pembelajaran dan BUKAN PR) selama 90 menit.",
	},
	FAQ_Q17_A3: {
		EN:
			"ZOOM meeting lasts 1.5 hours with 4-5 assignments (3-4 learning assignments for 1 project)",
		ID:
			"ZOOM meeting berlangsung 1,5 jam dengan 4-5 penugasan (3-4 tugas belajar 1 project)",
	},
	FAQ_Q18: {
		EN:
			"I forgot, my child was supposed to take the Placement Test after 3 days of payment, I was late. So how?",
		ID:
			"Saya lupa, anak saya harusnya ikut Placement Test setelah 3 hari membayar, saya terlambat. Jadi bagaimana? ",
	},
	FAQ_Q18_A1: {
		EN:
			"Please try to understand, if there is such a default, the system will automatically place your kid in Grade 2. We have notified parents in the Website Dashboard if your kid has not yet taken the Placement Test. Therefore it is very important to read our notice.",
		ID:
			"Mohon pengertiannya, jika ada kealphaan seperti ini, maka putra/i anda secara langsung akan ditempatkan di Grade 2. Sistem akan memberikan notifikasi lewat parents’ dashboard selama anak belum mengikuti Placement Test. Karenanya, harap selalu membaca dengan baik setiap pemberitahuan sebagai alat komunikasi kami",
	},
	FAQ_Q19: {
		EN: "What should I prepare for my child taking the Placement Test?",
		ID:
			"Apa yang harus saya persiapkan saat anak saya mengikuti placement test?",
	},
	FAQ_Q19_A1: {
		EN:
			"No need to prepare anything except for a laptop or other gadget. Let your child take the test as it is, according to his/her own abilities. However, it is highly advisable to do the Placement Test via a laptop or tablet to ease your child to see more clearly.",
		ID:
			"Tidak usah mempersiapkan apapun kecuali laptop atau gadget lain, biarkan anak Anda mengikuti Placement Test ini sesuai kemampuan ‘apa adanya’. Sangat disarankan, Placement Test dikerjakan melalui laptop atau tab demi memudahkan anak melihat dengan jelas.",
	},
	FAQ_Q20: {
		EN:
			"During the Placement Test, can my child who is still 8 years, be accompanied?",
		ID:
			"Saat Placement Test, kalau anak saya masih kecil (usia 8 tahun an) apa boleh ditemani?",
	},
	FAQ_Q20_A1: {
		EN: "You may accompany him/her by studying the instructions together.",
		ID:
			"Silakan ditemani, dipelajari terlebih dahulu petunjuknya secara bersama-sama.",
	},
	FAQ_Q20_A2: {
		EN:
			"However, in the test-taking process do not tell the answer, even if the child seems confused, because the purpose of this test is to place your child at the right level.",
		ID:
			"Namun pada saat pengerjaan, janganlah diberitahu jawabannya, walau anak bingung, karena tujuan Placement Test adalah untuk penempatan anak Anda di level yang tepat.",
	},
	FAQ_Q21: {
		EN:
			"Can my child who is less than 8 years old join the Placement Test?",
		ID:
			"Bolehkan anak saya yang berusia kurang dari 8 tahun ikut Placement?",
	},
	FAQ_Q21_A1: {
		EN:
			"We designed the program to provide a solid foundation for children aged 6 years and above, so there is no need to take the Placement Test.",
		ID:
			"Kami merancang program untuk memberi dasar kuat bagi anak sesuai usia 6 tahun ke atas, karenanya tidak perlu mengikuti Placement Test.",
	},
	FAQ_Q22: {
		EN: "At what age should my child join the Adiwidya course?",
		ID: "Umur berapa sebaiknya anak saya ikut program kursus Adiwidya?",
	},
	FAQ_Q22_A1: {
		EN:
			"It is recommended at 6 years (provided that your kid can read and write QUITE FLUENTLY, NOT necessarily to be really fluent). Even if your kid is underage, but is already quite fluent in reading and writing, he/she will be able to follow with parental guidance.",
		ID:
			"Disarankan berusia 6 (dengan syarat sudah bisa CUKUP LANCAR Membaca Menulis, BUKAN harus lancar sekali). Pun jika anak Anda kurang umur, namun sudah sangat lancar Baca Tulis, ia akan bisa mengikuti dengan bimbingan orangtua.",
	},
	FAQ_Q23: {
		EN: "My child is under 6 years old, can I join this program?",
		ID:
			"Anak saya berusia di bawah 6 tahun, bisakah saya ikutkan program ini?",
	},
	FAQ_Q23_A1: {
		EN:
			"We return the decision to parental policy. This course is designed for those kids who have already got just enough mastery in Reading and Writing and at least 6 years. However, if parents are willing to take time studying with their kid, then this program is likely to be followed.",
		ID:
			"Kami kembalikan pada kebijakan orangtua. Kursus ini dirancang berdasar kemampuan anak yang sudah CUKUP menguasai Baca dan Tulis. Namun, jika orangtua bersedia meluangkan waktu untuk belajar bersama anaknya, maka program ini kemungkinan bisa diikuti. Namun dengan catatan, jika dirasa dalam evaluasi, putra/i belum berkembang maksimal, maka kami anjurkan untuk mengulangi.",
	},
	FAQ_Q24: {
		EN:
			"Why does this children's course start from Grade 2 instead of Grade 1?",
		ID:
			"Kenapa kursus anak ini dimulainya dari Grade 2 bukan dari Grade 1?",
	},
	FAQ_Q24_A1: {
		EN: "Based on the consideration as follows:",
		ID: "Berdasar pertimbangan:",
	},
	FAQ_Q24_A2: {
		EN:
			"Online learning requires more 'learning maturity' (in contrast to learning offline, there are teachers who directly present and give guidance) In the beginning of our lessons, young kids still need guidance from parents at home to familiarize themselves with our unique system of learning till they grab ‘our way’, and this correlates with age. At the age of 8 (children in grade 2) at least they have learnt to adapt to primary school habits and this we believe will ease parents to accelerate their learning to adapt.",
		ID:
			"Online learning membutuhkan ‘kedewasaan’ belajar (berbeda dengan offline, ada guru yang langsung mengarahkan)",
	},
	FAQ_Q24_A3: {
		EN: "Our program requires a fairly fluent READ and WRITE ability.",
		ID:
			"Memulai dan membiasakan anak dengan pola belajar unik ala kursus kami, di awalnya tetap dibutuhkan bimbingan dari orangtua, dan selanjutnya kami harapkan bisa lebih mandiri. Dan usia anak di grade 2 setidaknya telah belajar beradaptasi dengan pola belajar di SD dan ini sangat membantu anak untuk mendapat pembelajaran yang maksimal dari kami dan memudahkan orangtua untuk memberi kepercayaan anaknya belajar mandiri.",
	},
	FAQ_Q24_A4: {
		EN: "",
		ID:
			"Rancang belajar ala kursus kami sudah membutuhkan kemampuan BACA TULIS yang cukup lancar.",
	},
	FAQ_Q25: {
		EN:
			"If I have followed the 2-month payment interval, and for the next term of payment I want to join the 4-month, is that possible?",
		ID:
			"Jika saya sudah mengikuti interval pembayaran 2 bulanan, dan berikutnya saya ingin ikut yang 4 bulan, apa bisa?",
	},
	FAQ_Q25_A1: {
		EN:
			"You can't, you have to wait for next year to be able to change your choice. Therefore, please consider carefully before deciding.",
		ID:
			"Belum bisa, anda harus menunggu di tahun berikutnya untuk bisa mengubah pilihan. Karenanya, harap dipertimbangkan baik baik sebelum memutuskan.",
	},
	FAQ_Q26: {
		EN: "When will my kid get the e-certificate?",
		ID: "Kapankah e-certificate diberikan?",
	},
	FAQ_Q26_A1: {
		EN:
			"The e-certificate will be given in the end of every academic year as the appreciation for those who finish the program.",
		ID:
			"Hanya di setiap akhir tahun pelajaran sebagai penghargaan atas anak anak yang bisa dengan tekun menyelesaikan program belajar tahunan kami sesuai tingkatan mereka.",
	},
	// FAQ End

	// Our Courses Start
	OUR_COURSES_MAIN_TITLE: {
		EN: "Welcome to Adiwidya English Course",
		ID: "Selamat datang di Kursus Inggris Adiwidya",
	},
	OUR_COURSES_MAIN_DESCRIPTION: {
		EN:
			"We believe that Everyone can learn English. this is the core idea when we think about Adiwidya at the first place starting from small town of Garut, Adiwidya graduates already went around the world and speaks English Fluently.",
		ID:
			"Kami percaya bahwa setiap orang dapat belajar bahasa Inggris. Ini adalah dasar ide ketika pertama kami berpikir untuk memulai Adiwidya di sebuah kota kecil yang bernama Garut, kini lulusan Adiwidya sudah keliling dunia dan tentunya fasih dalam berbahasa Inggris.",
	},
	OUR_COURSES_S1_TITLE: {
		EN: "Who is it for?",
		ID: "Untuk siapakah kursus ini ditujukan?",
	},
	OUR_COURSES_S1_DESCRIPTION_P1: {
		EN:
			"Serving for 33 years, specializing in providing strong English Foundation for KIDS aged 5.5 -13 (*pre-requisites: can read and write already).",
		ID:
			"ANAK-ANAK berusia 5,5 – 13 (*prasyarat: sudah bisa baca tulis). Keahlian Kursus Adiwidya adalah memberi dasar Bahasa Inggris kuat untuk anak (English for Kids adalah specialty kami).",
	},
	OUR_COURSES_S1_DESCRIPTION_P2: {
		EN: "For teens (Junior, High School, College students).",
		ID: "Remaja (usia SMP dan SMU pun kuliah)",
	},
	OUR_COURSES_S1_DESCRIPTION_P3: {
		EN:
			"For adults with specific purposes (banking, business, IELTS & TOEFL iBT preparation, suited to clients’ needs).",
		ID:
			"Dewasa dengan tujuan tertentu (perbankan, bisnis, persiapan IELTS & TOEFL iBT, sesuai dengan kebutuhan klien).",
	},
	OUR_COURSES_S2_TITLE: {
		EN: "How is this program, English for Kids designed?",
		ID: "Seperti apakah Gambaran Program English for Kids Adiwidya?",
	},
	OUR_COURSES_S2_DESCRIPTION_P1: {
		EN:
			"We use Pearson English International Curriculum standards translated in 45 lessons @90-minute effective learning session for each Grade.",
		ID:
			"Kurikulum: Standar Internasional Bahasa Inggris dari Pearson, dikemas dalam 45 pertemuan, @90 menit/sesi pembelajaran efektif untuk setiap tingkatan.",
	},
	OUR_COURSES_S2_DESCRIPTION_P2: {
		EN:
			"Learning format: step-by-step video design, zoom class meeting (not more than 12 kids) embedded with English Audios, Videos & Family designed Games, Movies.",
		ID:
			"Bentuk Pengajaran: video pembelajaran yang didesain khusus sesuai usia dan tingkatannya, Pertemuan kelas lewat zoom meeting. Pembelajaran dikreasikan lewat Audio berbahasa Inggris, Game yang dirancang untuk anak aplikasikan pembelajaran lewat interaksi dengan keluarga, pun film pendek berbahasa Inggris sesuai tema. Setiap kelas berisi 12-13 siswa/i.",
	},
	OUR_COURSES_S2_DESCRIPTION_P3: {
		EN:
			"The program is designed to be INTERACTIVELY FUN and intended to involve the family to participate in learning (one for all benefit) as well as stimulating kids’ five senses to learn through songs, chants, fun games and other experiential based activities which are children-friendly (HOLISTIC and HANDS ON).",
		ID:
			"Desain Program: INTERAKTIF MENYENANGKAN.  Dimaksudkan selain untuk pengembangan kemampuan berbahasa Inggris anak didik, juga mengundang keterlibatan keluarga berpartisipasi dan belajar lagi (satu belajar, manfaat ‘tuk semua*). HOLISTIC dan HANDS ON: merangsang panca indera anak, belajar melalui lagu, nyanyian, permainan asyik kegiatan berbasis kehidupan nyata dan ramah anak.",
	},
	OUR_COURSES_S2_DESCRIPTION_P4: {
		EN:
			"The program is created on the basis of online synchronous and asynchronous learning accentuating strong human touch in the form of weekly feedback & thorough consultation.",
		ID:
			"Metoda Belajar: Daring secara langsung (synchronous) dan tidak langsung (asynchronous) dengan tetap memberi sentuhan manusiawi yang kuat lewat komunikasi antar guru-anak, umpan balik mingguan & konsultasi serta live zoom meeting bersamaan teman temannya.",
	},
	OUR_COURSES_S2_DESCRIPTION_P5: {
		EN:
			"Overall result from theories is measured by kids’ abilities to present their project based learning in the end of each theme and in a quarterly written and individual face to face oral test.",
		ID:
			"Bentuk Supervisi: Teori ke praktik dalam bentuk Project Based per tema diikuti presentasi anak; Umpan balik tugas tertulis mingguan; Tes berkala secara lisan (tatap muka one to one) dan tertulis.",
	},
	OUR_COURSES_S2_DESCRIPTION_P6: {
		EN:
			"Emphasizing native-like pronunciation and speaking ability along with all skills and underlying abilities.",
		ID:
			"Target Belajar: Pengucapan dan kemampuan bicara seperti penutur asli. Dan Kemampuan bahasa lainnya: listening, reading, writing & grammar.",
	},
	OUR_COURSES_S2_DESCRIPTION_P7: {
		EN:
			"Taught by 1 main teacher and 1 class tutor for each class (supervision is guaranteed on the basis of one-to-one needs).",
		ID: "Penanggung Jawab Pengajaran: 1 guru utama dan 1 tutor kelas.",
	},
	OUR_COURSES_S3_TITLE: {
		EN: "What will you get?",
		ID: "Apa yang akan saya dapatkan?",
	},
	OUR_COURSES_S3_DESCRIPTION_P1: {
		EN: "The English Textbook with online resources for one year.",
		ID:
			"Buku Teks Bahasa Inggris terbitan Pearson Online Code untuk satu tahun.",
	},
	OUR_COURSES_S3_DESCRIPTION_P2: {
		EN:
			"Weekly video learning and Zoom meeting Class for each ending of Theme.",
		ID: "Video Belajar mingguan dan Zoom Meeting di setiap akhir tema.",
	},
	OUR_COURSES_S3_DESCRIPTION_P3: {
		EN:
			"Study Kit, comes in a specially designed bag, to help ease and reinforce the learning process for one year.",
		ID:
			"Study Kit, yang dikemas dalam tas yang didesain khusus (dimaksudkan untuk memudahkan orangtua mendukung proses pembelajaran anak di rumah masing masing, untuk satu tahun).",
	},
	OUR_COURSES_S3_DESCRIPTION_P3_SP1: {
		EN: "1 Activity Package Module.",
		ID: "1 Modul Bimbingan Kegiatan",
	},
	OUR_COURSES_S3_DESCRIPTION_P3_SP2: {
		EN: "Home Supported Learning Aids.",
		ID: "Seperangkat Alat Bantu Belajar",
	},
	OUR_COURSES_S3_DESCRIPTION_P3_SP3: {
		EN: "1 Worksheet Module.",
		ID: "1 Modul Lembar Kerja Siswa",
	},
	OUR_COURSES_S3_DESCRIPTION_P4: {
		EN:
			"Thorough feedback (direct weekly written comment, written and one to one oral tests with direct report progress as well free counseling).",
		ID:
			"Umpan balik menyeluruh (komentar mingguan dari tugas tertulis, tes tertulis, dan ujian lisan one to one, laporan tertulis perkembangan anak, serta konseling gratis).",
	},
	OUR_COURSES_S3_DESCRIPTION_P5: {
		EN:
			"Bonus : Zoom learning with our natives who are English Teachers & Masters Graduates (for Grade 4 ups and have been with us for more than six months).",
		ID:
			"Bonus: Zoom belajar dengan Penutur Asli Lulusan Keguruan dan Masters Bahasa Inggris (*untuk Tingkat 4, 5, 6 dan telah belajar bersama kami lebih dari enam bulan).",
	},
	OUR_COURSES_S3_DESCRIPTION_P6: {
		EN: "E-Certificate * (if covering one year program).",
		ID: "E-Certificate * (jika menyelesaikan program satu tahun).",
	},
	OUR_COURSES_S4_TITLE: {
		EN: "Teachers Profile",
		ID: "Profil Guru",
	},
	OUR_COURSES_S4_T1_NAME: {
		EN: "Mam Tanti",
		ID: "Mam Tanti",
	},
	OUR_COURSES_S4_T1_PROFILE: {
		EN:
			"Howdy dear, I’m Mam Tanti. I have been teaching English for 32 years. I’m just dreaming that each Indonesian kid can communicate like natives (as similar to my present students) and enjoy learning English. Be confident with my way of teaching in fun meaningful ways though online, coz I am a UK Masters Graduate earning under British Chevening Awards Scholarship, as well the national winner of Frans Seda Award. At present I have built 3 schools and helped established another 3 as the curriculum and teacher trainer consultant. So, what are you waiting for? Join us!",
		ID:
			"Apa kabarnya, saya Mam Tanti. Saya sudah mengajar bahasa Inggris selama 32 tahun. Impian saya, setiap anak Indonesia  senang belajar Bahasa Inggris pun bisa  bicara persis penutur asli  (sebagaimana murid kami sekarang). Percayalah lewat cara saya mengajar yang menyenangkan dan bermakna walau daring, kalian pasti bisa! Karena saya   lulusan S2 Negara Inggris dengan beasiswa dari British Chevening Awards,  juga pemenang nasional Frans Sewa Award. Saat ini saya sudah mendirikan 3 sekolah dan membantu pendirian 3 sekolah lainnya sebagai konsultan kurikulum dan pelatihan guru. Maka tunggu apa lagi? Ayo turut gabung sekarang!",
	},
	OUR_COURSES_S4_T2_NAME: {
		EN: "Mam Anita",
		ID: "Mam Anita",
	},
	OUR_COURSES_S4_T2_PROFILE: {
		EN:
			"What’s up, guys? I’m Anita and I have been an English tutor for more than 20 years. I fall in love with teaching teens and adults because it’s not all about learning English but sharing life as well. I was graduated from Gadjah Mada University earning my B.A in International Relations and I took English Extension Program from Sanata Dharma University. So, looking forward for you to join me asap.",
		ID:
			"Apa kabar, saya Anita dan  sudah jadi  tutor bahasa Inggris lebih dari 20 tahun. Saya jatuh cinta mengajar para remaja dan dewasa karena bersama mereka saya  bukan sekedar mengajar bahasa Inggris tapi lebih berbagi  kehidupan. Saya lulusan Universitas Gajah Mada jurusan Hubungan Internasional dan sekaligus lulusan English Extension Program dari Universitas Sanata Dharma. Saya tunggu anda untuk bergabung dengan kami, secepatnya",
	},
	OUR_COURSES_S4_T3_NAME: {
		EN: "Mam Bill",
		ID: "Mam Bill",
	},
	OUR_COURSES_S4_T3_PROFILE: {
		EN:
			"How’re things? I’m Bill, but I’m a girl. I have been a middle school teacher since 2004 in Pyrenees, France. After earning my B.A in English Teaching and having been to several places in the world, now I’m looking forward to teach foreign students like you. I do believe it would be a great time to know you and this would be precious moment coz I can learn together with you all.",
		ID:
			"Apa kabar semua? Nama saya Bill versi wanita. Saya mengajar di SMP di Pyrenees, Perancis, sejak tahun 2004. Setelah mengambil S1 jurusan Pengajaran Bahasa Inggris dan berkunjung ke  beberapa tempat di dunia, sekarang saya ingin mengajar murid murid dari luar negeri seperti kalian. Saya percaya ini akan jadi pengalaman luar biasa  mengenal kalian dan jadi momen yang berharga karena saya juga bisa belajar bersama kalian semua.",
	},
	OUR_COURSES_S5_TITLE: {
		EN: "Never Worried About the Payment",
		ID: "Jangan Khawatir Tentang Pembayaran",
	},
	OUR_COURSES_S5_DESCRIPTION: {
		EN:
			"Each student is required to purchase a starter kit package first with a choice of intervals of 2 months, 4 months, or 12 months. After purchasing that package, especially for those who choose 2 months and 4 months, it is necessary to purchase a continuation kit according to the interval has been selected.",
		ID:
			"Setiap murid diwajibkan untuk membeli paket Starter kit terlebih dahulu dengan pilihan waktu pembayaran per 2 bulanan, per 4 bulanan, atau 12 bulanan. Setelah membeli paket, terutama bagi mereka yang memilih 2 bulanan dan 4 bulanan, tetap diharuskan untuk membayar paket lanjutan sesuai dengan waktu pembayaran yang dipilih.",
	},
	OUR_COURSES_S5_SP1_TITLE: {
		EN: "Starter kit interval option",
		ID: "Pilihan pembayaran Starter Kit",
	},
	OUR_COURSES_S5_SP2_TITLE: {
		EN: "Continuation kit interval option",
		ID: "Pilihan paket lanjutan",
	},
	OUR_COURSES_S5_SP2_DESCRIPTION: {
		EN:
			"* The continuation kit doesn't need to be paid again for a 12 month payment interval because it's already included in the starter kit.",
		ID:
			"* Paket lanjutan tidak perlu dibayar jika anda memilih pembayaran per 12 bulanan.",
	},
	OUR_COURSES_S5_SP3_DESCRIPTION: {
		EN:
			"We believe that sometimes we need to do a “test drive” before we start commiting into something big. And so English course. That’s why we give you freedom to choose about the payment term.However, of course you can get a bigger discount when you pay full ahead.",
		ID:
			"Kami sadari perlunya “mencoba” sebelum  mulai bergabung untuk sesuatu yang besar, seperti kursus Bahasa inggris ini. Maka dari itu kami menyediakan  beberapa pilihan untuk  pembayaran.  Semakin besar pembayaran di muka yang dipilih semakin banyak diskon yang didapat.",
	},
	OUR_COURSES_S6_TITLE: {
		EN: "a Fun, More Exciting Learning Experiences with Study Kit",
		ID: "Belajar jadi lebih menyenangkan dan asyik dengan Study Kit",
	},
	OUR_COURSES_S6_DESCRIPTION_P1: {
		EN:
			"This study kit consists of one activity module, one worksheet module and learning aids which will be used for one year. This kit will ease your kid's learning to gain the best result.",
		ID:
			"Dalam Study Kit tersedia 1 buah modul aktivitas, 1 buah modul lembar kerja dan seperangkat alat bantu ajar untuk 1 tahun. Kit  membantu anak  belajar, untuk mendapatkan hasil terbaik.",
	},
	OUR_COURSES_S6_DESCRIPTION_P2: {
		EN:
			"A new grade requires a whole set of study kit, so when your kids move grades, they will need a new set.",
		ID:
			"Saat naik ke tingkat yang baru, dibutuhkan Study Kit baru sesuai tingkatannya.",
	},
	// OUR_COURSES_S6_DESCRIPTION_P3: {
	// 	EN:
	// 		"**course fee payment terms are based on your choice. If you pay full ahead, you won’t need to pay for course fee anymore.",
	// 	ID:
	// 		"**course fee payment terms are based on your choice. If you pay full ahead, you won’t need to pay for course fee anymore.",
	// },
	OUR_COURSES_S7_TITLE: {
		EN: "Billing Made Easy",
		ID: "Sistem Penagihan Dimudahkan",
	},
	OUR_COURSES_S7_DESCRIPTION: {
		EN:
			"We integrate lists of billing in your dashboard so it’s easier to track how many bills you still need to pay, which one is successful and which one failed. If you choose the 6x payment term (paying per 2 months), you can see that we list all the 6 bills you need to pay. But if you find it hassle and want to pay for it all, you definitely can do it. But please take note that you will lose the discount benefit of paying full year ahead if you choose 2-month interval.",
		ID:
			"Kami mengintegrasikan pembayaran  di dashboard untuk  memudahkan Anda  melihat berapa lagi biaya yang masih harus dipenuhi. Jika anda memilih 2 bulanan, maka anda dapat melihat disini adanya 6x penagihan. Namun, jika ini dianggap merepotkan,  kami sediakan pembayaran tahunan. Karena, jika anda memilih per 2 bulanan, maka anda kehilangan keuntungan potongan besar jika dibandingkan  dengan pembayaran penuh di muka (pilihan per 12 bulanan).",
	},
	// Our Courses End

	// Welcome Page Start
	WELCOME_PAGE_TITLE: {
		EN: "Welcome to Adiwidya!",
		ID: "Selamat Datang di Adiwidya!",
	},
	WELCOME_PAGE_SUBTITLE: {
		EN:
			"Your account is successfully created. Do not forget to check your email first for account verification.",
		ID:
			"Akun Anda berhasil terbuat. Pastikan cek email Anda untuk verifikasi akun terlebih dahulu.",
	},
	WELCOME_PAGE_BUTTON_TEXT: {
		EN: "Back to Home",
		ID: "Kembali ke Halaman Utama",
	},
	// Welcome Page End

	//Next Grade Choice Start
	NEXT_GRADE_CHOICE_TITLE: {
		EN: "Congratulations! You’ve finished 1 year course",
		ID: "Selamat! Anda telah menyelesaikan kursus 1 tahun",
	},
	NEXT_GRADE_CHOICE_SUBTITLE: {
		EN:
			"You made a successful performance on last grade and ready to step up",
		ID:
			"You made a successful performance on last grade and ready to step up",
	},
	NEXT_GRADE_CHOICE_MODAL_TITLE: {
		EN: "Congratulations! You’ve finished 1 year course",
		ID: "Selamat! Anda telah menyelesaikan kursus 1 tahun",
	},
	NEXT_GRADE_CHOICE_MODAL_SUBTITLE: {
		EN:
			"You made a successful performance on last grade and ready to step up",
		ID:
			"You made a successful performance on last grade and ready to step up",
	},
	NEXT_GRADE_CHOICE_1_P1: {
		EN: "I want to go to",
		ID: "I want to go to",
	},
	NEXT_GRADE_CHOICE_1_P2: {
		EN: "THE NEXT grade",
		ID: "THE NEXT grade",
	},
	NEXT_GRADE_CHOICE_2_P1: {
		EN: "I want to STAY",
		ID: "I want to STAY",
	},
	NEXT_GRADE_CHOICE_2_P2: {
		EN: "in the same grade",
		ID: "in the same grade",
	},
	//Next Grade Choice End

	// User Detail Start
	USER_DETAIL_INFO_CARD: {
		EN:
			"Email is your main identity to courses, billing, and all your information. If you wish to change it, please make sure that you remember your credentials. Password change will need verification via email.",
		ID:
			"Email is your main identity to courses, billing, and all your information. If you wish to change it, please make sure that you remember your credentials. Password change will need verification via email.",
	},
	MY_INFORMATION: {
		EN: "My Information",
		ID: "Informasi Saya",
	},
	CHANGE_PASSWORD: {
		EN: "Change Password",
		ID: "Ubah Password",
	},
	ADDRESS_DETAILS: {
		EN: "Address Details",
		ID: "Detail Alamat",
	},
	// User Detail End

	// Forgot Password Start
	FORGOT_PASSWORD_TITLE: {
		EN: "Forgot Your Password?",
		ID: "Lupa Password Anda?",
	},
	FORGOT_PASSWORD_SUBTITLE: {
		EN: "Insert your e-mail address here.",
		ID: "Masukkan alamat email anda disini.",
	},
	FORGOT_PASSWORD_BUTTON_TEXT: {
		EN: "Submit Change Password",
		ID: "Submit Change Password",
	},
	// Forgot Password End

	// Reset Password Start
	RESET_PASSWORD_TITLE: {
		EN: "Change Password",
		ID: "Change Password",
	},
	RESET_PASSWORD_SUBTITLE: {
		EN: "Insert new password here.",
		ID: "Masukkan password baru disini...",
	},
	RESET_PASSWORD_BUTTON_TEXT: {
		EN: "Change Password",
		ID: "Ubah Password",
	},
	// Reset Password End

	// Child Page Tab Panel Start
	MY_CLASS: {
		EN: "My Class",
		ID: "Kelas Saya",
	},
	PERSONAL_DETAILS: {
		EN: "Personal Details",
		ID: "Data Pribadi",
	},
	BILLING_PROGRESS: {
		EN: "Billing Progress",
		ID: "Kemajuan Penagihan",
	},
	// Child Page Tab Panel End

	// Child Course List Start
	CHILD_BILLING_PROGRESS_TITLE: {
		EN: "Individual Class Session",
		ID: "Sesi Kelas Individu",
	},
	MOVE_TO_NEXT_GRADE: {
		EN: "Move to Next Grade",
		ID: "Pindah ke Kelas Berikutnya",
	},
	// Child Course List End

	// Child Billing Progress Start
	PAYMENT_PROGRESS: {
		EN: "Payment Progress",
		ID: "Kemajuan Pembayaran",
	},
	AVAILABLE_BILLING: {
		EN: "Available Billing",
		ID: "Penagihan yang Tersedia",
	},
	PAID: {
		EN: "Paid",
		ID: "Dibayar",
	},
	PAY_NOW: {
		EN: "Pay Now",
		ID: "Bayar",
	},
	SKIPPED: {
		EN: "Skipped",
		ID: "Dilewati",
	},
	COURSE_START_ON: {
		EN: "Course start on",
		ID: "Kursus dimulai pada",
	},
	MONTHS: {
		EN: "months",
		ID: "bulan",
	},
	COURSE_LOWERCASE: {
		EN: "course",
		ID: "kursus",
	},
	RECURRING_PER: {
		EN: "recurring per",
		ID: "berulang per",
	},
	ANNUAL_BILL_PAID: {
		EN: "Annual Bill Paid",
		ID: "Tagihan Tahunan Dibayar",
	},
	ANNUAL_BILL_PAID_DESCRIPTION: {
		EN: "You’ve paid your bill ahead, you don’t need to do another payment",
		ID:
			"Anda sudah membayar tagihan anda, anda tidak perlu melakukan pembayaran lagi",
	},
	SUCCESS_UPPERCASE: {
		EN: "SUCCESS",
		ID: "SUKSES",
	},
	PENDING_UPPERCASE: {
		EN: "PENDING",
		ID: "TERTUNDA",
	},
	FAILED_UPPERCASE: {
		EN: "FAILED",
		ID: "GAGAL",
	},
	// Child Billing Progress End

	// User Payment Start
	BACK_TO_PAYMENT_LIST: {
		EN: "Back to Payment List",
		ID: "Kembali ke daftar Pembayaran",
	},
	EMPTY_PAYMENT_LIST_TEXT: {
		EN: "There are no payment history at the moment...",
		ID: "Tidak ada riwayat pembayaran saat ini...",
	},
	PAYMENT_USING: {
		EN: "Payment Using",
		ID: "Pembayaran Menggunakan",
	},
	// User Payment End

	// User Inbox Start
	BACK_TO_ALL_MESSAGES: {
		EN: "Back to All Messages",
		ID: "Kembali ke Semua Pesan",
	},
	EMPTY_INBOX_TEXT: {
		EN: "You have no message at the moment...",
		ID: "Anda tidak memiliki pesan saat ini...",
	},
	// User Inbox End

	// User Logout Start
	USER_LOGOUT_TITLE: {
		EN: "You wish to logout, continue?",
		ID: "Anda ingin keluar, lanjutkan?",
	},
	USER_LOGOUT_DESCRIPTION: {
		EN:
			"You need to enter your email and password again to access this	dashboard.",
		ID:
			"You need to enter your email and password again to access this	dashboard.",
	},
	USER_LOGOUT_YES: {
		EN: "Yes, Logout",
		ID: "Ya, Keluar",
	},
	USER_LOGOUT_NO: {
		EN: "No, Cancel",
		ID: "Tidak, Batalkan",
	},
	// User Logout End

	// Footer Start
	OUR_LOCATION: {
		EN: "OUR LOCATION",
		ID: "LOKASI KAMI",
	},
	HELP_CENTER: {
		EN: "HELP CENTER",
		ID: "PUSAT BANTUAN",
	},
	EMAIL_US: {
		EN: "Email Us",
		ID: "Email kami",
	},
	ABOUT_ADIWIDYA: {
		EN: "ABOUT ADIWIDYA",
		ID: "TENTANG ADIWIDYA",
	},
	NEWS: {
		EN: "News",
		ID: "Berita",
	},
	SUPPORT: {
		EN: "SUPPORT",
		ID: "BANTUAN",
	},
	FOLLOW_US_ON_SOCIAL_MEDIA: {
		EN: "FOLLOW US ON SOCIAL MEDIA",
		ID: "IKUTI KAMI DI MEDIA SOSIAL",
	},
	// Footer End

	// Student Performance Start
	WFH_ART: {
		EN: "#WFHArt",
		ID: "#WFHArt",
	},
	MY_PARENTS_AND_ME: {
		EN: "My Parents & Me",
		ID: "Orang Tua & Saya",
	},
	ASSIGNMENTS: {
		EN: "Assignments",
		ID: "Penugasan",
	},
	// Student Performance End

	// Stories of Adiwidya Start
	OUR_HISTORY: {
		EN: "Our History",
		ID: "Sejarah Kami",
	},
	OUR_HISTORY_SUBTITLE: {
		EN: "a long way to nurture only the best",
		ID: "jalan panjang untuk memelihara hanya yang terbaik",
	},
	ADIWIDYA_ADULT_CLASS: {
		EN: "Adiwidya Adult Class",
		ID: "Kelas Dewasa Adiwidya",
	},
	ADIWIDYA_ADULT_CLASS_SUBTITLE: {
		EN: "English for Professionals",
		ID: "Bahasa Inggris untuk Profesional",
	},
	// Stories of Adiwidya End

	// Course Session Card Start
	CHECK_WA_GROUP_FOR_DETAILS: {
		EN: "Please Check your WhatsApp Group for further details",
		ID: "Silakan Periksa Grup WhatsApp Anda untuk detail lebih lanjut",
	},
	SCHEDULED: {
		EN: "SCHEDULED",
		ID: "DIJADWALKAN",
	},
	WATCHED: {
		EN: "WATCHED",
		ID: "DITONTON",
	},
	// Course Session Card End

	// Course Video Start
	BACK_TO_COURSE_LIST: {
		EN: "Back to Course List",
		ID: "Kembali ke Daftar Kursus",
	},
	NEXT_VIDEO: {
		EN: "Next Video",
		ID: "Video Berikutnya",
	},
	// Course Video End

	// Homepage Carousel News Start
	FIXED_NEWS_TITLE: {
		EN: "Adiwidya open registrations for teens and adults class",
		ID: "Adiwidya membuka pendaftaran untuk kelas remaja dan dewasa",
	},
	FIXED_NEWS_BUTTON_TEXT: {
		EN: "Book Your Seat. ASAP.",
		ID: "Daftarkan Dirimu. Segera.",
	},
	SEE_ALL_NEWS: {
		EN: "See All News",
		ID: "Lihat Semua Berita",
	},
	// Homepage Carousel News End

	// Fixed News Start
	FIXED_NEWS_TITLE: {
		EN: "New Online Class Opens every AUGUST and JANUARY.",
		ID: "Kelas Online baru dibuka setiap Bulan AGUSTUS dan JANUARI.",
	},

	// Fixed News End

	// Our History Start
	OUR_HISTORY_MAIN_TITLE: {
		EN: "a history of Adiwidya Course",
		ID: "Sejarah Kursus Adiwidya",
	},
	OUR_HISTORY_S1_TITLE: {
		EN: "Our Mission",
		ID: "Misi Kami",
	},
	OUR_HISTORY_S1_DESCRIPTION: {
		EN:
			"To enable students communicate English actively, both in oral and written, by help building their positive self-understanding facing the global challenges",
		ID:
			"Menjadikan peserta didik mampu  berbahasa Inggris secara aktif secara lisan pun tulisan, untuk pemahaman diri positif yang bersiap diri menghadapi tantangan global",
	},
	OUR_HISTORY_S2_TITLE: {
		EN: "Story Behind the Brand",
		ID: "Cerita dibalik sebuah brand",
	},
	OUR_HISTORY_S2_DESCRIPTION_SP1: {
		EN:
			"<b>Adiwidya</b> means \"the Source of Knowledge\". We chose this name with the hope that whoever involved in our educational institution, especially our students who are still potentially developing their capacities, will become people who feel the 'thirsts for knowledge'. In addition, we see a high demand from the community for kids’ earning strong basic English education, which is of global standard.",
		ID:
			"<b>Adiwidya</b> berarti \"Sumber Pengetahuan\". Nama ini kami pilih dengan harapan siapapun yang berkecimpung di institusi pendidikan kami, khususnya para siswa yang masih berpotensi jauh ke depan, akan menjadi pribadi 'haus ilmu'. Selain itu, kami melihat permintaan yang tinggi dari masyarakat untuk anak-anak dapat memperoleh pendidikan bahasa Inggris dengan dasar yang berstandar global.",
	},
	OUR_HISTORY_S2_DESCRIPTION_SP2: {
		EN:
			"We perceive how Garutnese children (initial place for <b>Adiwidya</b> commencement) are in need of English learning contextual to their future needs. We see the urgency to build our children's self-confidence to communicate actively in context, which is basic for their 'survival of life' later, as we live in a small town with limited English real practice access. We intend to provide this service to turn Garutnese children into district children whose English language is not inferior to those who come from big cities even internationally.",
		ID:
			"Kami melihat betapa anak-anak Garut (tempat awal dimulainya <b>Adiwidya</b>) membutuhkan pembelajaran bahasa Inggris yang kontekstual untuk kebutuhan mereka di masa depan. Kebutuhan anak akan kebahasaan Inggris yang kontekstual dengan kebutuhan masa depan mereka khususnya dari membangun sisi kepercayaan diri anak berkomunikasi secara aktif sesuai konteks adalah sangat mendasar untuk ‘survival of life’ (kebertahanan hidup ) mereka nanti, karena kami tinggal di kota kecil dengan akses terbatas praktik nyata berbahasa Inggris. Kami bermaksud memberikan layanan ini untuk menjadikan anak Garut menjadi anak kabupaten yang kemampuan berbahasa Inggrisnya tidak kalah dengan mereka yang berasal dari kota besar bahkan internasional.",
	},
	OUR_HISTORY_S3_TITLE: {
		EN: "Where it all starts",
		ID: "Dimana semuanya dimulai",
	},
	OUR_HISTORY_S3_DESCRIPTION_SP1: {
		EN:
			"<b>Adiwidya</b> was founded 37 years ago to be precise in 1984 by <b>Mrs. Sari Utami Gunawan</b> and <b>Mr. Tommy Theodjumara (late)</b>. <b>Adiwidya</b> is one of the pioneers of English courses in Garut. <b>Adiwidya</b> has helped prepare thousands of quality English learners to face their future.",
		ID:
			"<b>Adiwidya</b> didirikan 37 tahun yang lalu tepatnya tahun 1984 oleh <b>Ibu Sari Utami Gunawan</b> dan <b>Bapak Tommy Theodjumara (Alm)</b>. <b>Adiwidya</b> adalah salah satu pelopor kursus bahasa Inggris di Garut. <b>Adiwidya</b> telah membantu mempersiapkan ribuan pembelajar bahasa Inggris berkualitas untuk menghadapi masa depan mereka.",
	},
	OUR_HISTORY_S3_DESCRIPTION_SP2: {
		EN:
			"Right in July 2005, <b>the New Adiwidya English Course</b> underwent a fundamental management change and was explored more professionally and pedagogically by <b>Adiwidya</b> Team. Then there is a shift of teaching paradigm towards Active Learning. Our students are invited to approach English reality in use in fun meaningful ways. The thought towards management change itself is based on the absence of an English course availability 'specializes in children learning' in our town.",
		ID:
			"Tepat pada bulan Juli 2005, <b>Kursus Bahasa Inggris Adiwidya Baru</b> mengalami perubahan manajemen yang mendasar dan dieksplorasi secara lebih profesional dan pedagogis oleh Tim <b>Adiwidya</b>. Terjadi pergeseran paradigma mengajar ke arah Pembelajaran Aktif. Siswa kami  fasilitasi belajar mendekati realitas bahasa Inggris yang nyata dengan cara menyenangkan yang bermakna. Pemikiran terhadap perubahan manajemen itu sendiri didasarkan pada tidak adanya ketersediaan kursus bahasa Inggris yang 'mengkhususkan diri pada pembelajaran anak-anak' di kota kecil kami.",
	},
	OUR_HISTORY_S4_TITLE: {
		EN: "Continuous Improvement along the Way...",
		ID: "Penyempurnaan terus menerus sepanjang perjalanan...",
	},
	OUR_HISTORY_S4_DESCRIPTION: {
		EN:
			"Another urgent need that we see is the thirst towards English Course with applied and effective approach, because there is often a tendency for learners to be taught to master English structurally but then they are caught up in difficulties to express their ideas orally and naturally. Therefore, from an early age, we accustom every child to be guided in all aspects of language and not on the emphasis on having <b><i>language perfection</b></i>, but <b><i>language confidence</b></i> so that they become accustomed to actively speaking and expressing their ideas in written aspects as well since early age.",
		ID:
			"Kebutuhan mendesak lainnya yang kami lihat adalah kehausan terhadap Kursus Bahasa Inggris dengan pendekatan terapan dan efektif, karena seringkali ada kecenderungan peserta didik diajar di kelas untuk menguasai bahasa Inggris secara struktural (kuat di grammar) tetapi kemudian mereka terjebak dalam kesulitan mengungkapkan ide-idenya secara lisan dan alami. Oleh karena itu, sejak usia dini, kami membiasakan setiap anak dibimbing dalam segala aspek bahasa dan bukan pada penekanan memperoleh <b><i>kesempurnaan bahasa</b></i>, melainkan <b><i>kepercayaan diri berbahasa Inggris</b></i> sehingga mereka terbiasa aktif berbicara dan mengungkapkan gagasan secara lisan dan tertulis sejak usia dini.",
	},
	OUR_HISTORY_S5_TITLE: {
		EN: "37 years of Experience, with lots of delightful achievements",
		ID: "Pengalaman 37 tahun dengan banyak keberhasilan yang menyenangkan.",
	},
	OUR_HISTORY_S5_QUOTE: {
		EN: "“There is never a one person winning, it’s always a team winning”",
		ID:
			"Tidak ada keberhasilan satu orang saja, yang selalu ada adalah keberhasilan tim”",
	},
	OUR_HISTORY_S5_QUOTE_BY: {
		EN: "Christanti Gomulia, Head of Adiwidya Course",
		ID: "Christanti Gomulia, Pimpinan Kursus Adiwidya",
	},
	OUR_HISTORY_S5_DESCRIPTION_P1: {
		EN:
			"Being a 37-year-old educational institution with thousands of graduates has verified Adiwidya as a solid proven by time & quality English course who now even can equip its students to earn scholarships abroad in several world-class-universities such as Tsing Hua Uni and Cairo Uni etc. though we come from a small town.",
		ID:
			"Sebagai institusi pendidikan yang bisa mencapai usia 37 tahun dengan ribuan lulusan. telah membuktikan Adiwidya sebagai Kursus bahasa Inggris yang kokoh, terbukti lewat waktu dan kualitas. Bahkan kini mampu membekali lulusannya mendapatkan beasiswa di luar negeri di beberapa universitas kelas dunia seperti Tsing Hua Uni dan Cairo Uni dll, meskipun kami berasal dari kota kecil",
	},
	OUR_HISTORY_S5_DESCRIPTION_P2: {
		EN:
			"Successfully organizing 5 colossal English Dramas with hundreds of our students performing, with 700 audiences watching and covered by national media (link). These events were also supported by national institutions such PT Gistex (Rumah Mode), Bank SinarMas, L’agie (PT Fajar Mataram Sedayu), Parahyangan University, Dodol Picnic Garut, DaaiTV. Watch the video here:",
		ID:
			"Menyelenggarakan 5 kali Drama Berbahasa Inggris secara kolosal dengan ratusan anak didik kami, ditonton 700 audiens dan diliput media nasional  (link) Acara akbar ini didukung oleh institusi nasional seperti PT Gistex (Rumah Mode), Bank SinarMas, L’agie (PT Fajar Mataram Sedayu), Parahyangan University, Dodol Picnic Garut, DaaiTV, Indosiar.\n",
	},
	OUR_HISTORY_S5_DESCRIPTION_P3: {
		EN:
			"Our students often become winners of regional and provincial level competitions in English language contests such as debates, storytelling, etc.",
		ID:
			"Putra/i didik kami sering menjadi pemenang pemenang lomba lomba tingkat daerah dan provinsi dalam Lomba Bahasa Inggris seperti debat, story telling dll nya.",
	},
	OUR_HISTORY_S5_DESCRIPTION_P4: {
		EN:
			"Our students could catch on English lessons at the TOP high schools in big cities like Bandung.",
		ID:
			"Anak didik kami berhasil mengikuti pelajaran Bahasa Inggris di jenjang SMU TOP di kota besar seperti Bandung. Mereka tidak mengalami kesulitan dalam mengikuti pembelajaran.",
	},
	OUR_HISTORY_S5_DESCRIPTION_P5: {
		EN:
			"With 2 types of tests both in written and speaking (one-on-one oral speaking test), our students are accustomed to speak English confidently and have strong.",
		ID:
			"Dengan 2 macam jenis Test secara tertulis dan lisan 1 on 1, anak didik kami, memiliki keberanian bicara bahasa Inggris, karena sudah dibiasakan sejak kecil dan sekaligus mampu berkomunikasi tertulis.",
	},
	OUR_HISTORY_S5_DESCRIPTION_P6: {
		EN:
			"As we always end our Unit Theme in project based, which is then presented by the kids, , our students are able to communicate their ideas verbally and creatively in written projects as well.",
		ID:
			"Dengan metoda project based learning yang kemudian dipresentasikan, anak didik kami mampu mengkomunikasikan idenya secara lisan dan tertulis dengan kreatif.",
	},
	OUR_HISTORY_S5_DESCRIPTION_P7: {
		EN:
			"The Adiwidya course has developed fast based on the TRUSTS and demands of our community and parents. In 2007 it established its kindergarten, named Adiwidya Kindergarten (Pre-School). In 2009 it developed towards SD – Primary School (with a different name, but the same concept) SD Bestari Utami. In 2018 it continues its service to SMP Bestari Utami (Bestari Utami Junior High School) Watch the video here:",
		ID:
			"Kursus Adiwidya telah berkembang dengan pesat berdasar kepercayaan dan tuntutan masyarakat dan orangtua. Di tahun 2007 berkembang menjadi PGTK Adiwidya. Di tahun 2009 berkembang ke arah SD (dengan nama berbeda, namun konsep sama) yi : SD Bestari Utami. Di tahun 2018 berkembang menjadi SMP Bestari Utami.\n",
	},
	// Our History End

	// Placement Test Instruction Start
	PLACEMENT_TEST_INSTRUCTION_MAIN_TITLE: {
		EN: "Welcome to Adiwidya Placement Test",
		ID: "Welcome to Adiwidya Placement Test",
	},
	PLACEMENT_TEST_INSTRUCTION_MAIN_SUBTITLE: {
		EN: "Here are some rules about the test",
		ID: "Here are some rules about the test",
	},
	PLACEMENT_TEST_INSTRUCTION_S1_TITLE: {
		EN: "No Parents Help Please. Honesty is the key",
		ID: "No Parents Help Please. Honesty is the key",
	},
	PLACEMENT_TEST_INSTRUCTION_S1_DESCRIPTION: {
		EN:
			"Please don’t ask your parents for answers. Parents may sit next to the child but no answers given.",
		ID:
			"Please don’t ask your parents for answers. Parents may sit next to the child but no answers given.",
	},
	PLACEMENT_TEST_INSTRUCTION_S2_TITLE: {
		EN: "Focus. Focus. Focus. 20 minutes is all you need",
		ID: "Focus. Focus. Focus. 20 minutes is all you need",
	},
	PLACEMENT_TEST_INSTRUCTION_S2_DESCRIPTION: {
		EN:
			"Let’s put away all the distraction. Your time is limited. Don’t spend too much in 1 question.",
		ID:
			"Let’s put away all the distraction. Your time is limited. Don’t spend too much in 1 question.",
	},
	PLACEMENT_TEST_INSTRUCTION_S3_TITLE: {
		EN: "Give your best effort on the test",
		ID: "Give your best effort on the test",
	},
	PLACEMENT_TEST_INSTRUCTION_S3_DESCRIPTION: {
		EN:
			"Placement Test result will determine in which grade you should start. Do your best!",
		ID:
			"Placement Test result will determine in which grade you should start. Do your best!",
	},
	PLACEMENT_TEST_INSTRUCTION_S4_TITLE: {
		EN: "Prepare Your Best Device for the Test",
		ID: "Prepare Your Best Device for the Test",
	},
	PLACEMENT_TEST_INSTRUCTION_S4_DESCRIPTION: {
		EN:
			"for better experience and result, test is best taken in larger screen (tablet, laptop, or PC)",
		ID:
			"for better experience and result, test is best taken in larger screen (tablet, laptop, or PC)",
	},
	PLACEMENT_TEST_INSTRUCTION_NOTES: {
		EN:
			"Mohon tunggu setidaknya 5 menit sebelum putra/i anda yang lain (jika ada) mengambil Test Penempatan",
		ID:
			"You have to wait at least for another 5 minutes before your other kids (if any) taking the placement test.",
	},
	PLACEMENT_TEST_INSTRUCTION_BUTTON_TEXT: {
		EN: "I’m Ready for the Test!",
		ID: "I’m Ready for the Test!",
	},
	// Placement Test Instruction End

	// News Start
	NEWS_LINK_COPIED: {
		EN: "News Link copied!",
		ID: "Tautan Berita disalin!",
	},
	// News End

	// SHARED
	LOGIN: {
		EN: "Login",
		ID: "Masuk",
	},
	SIGNUP: {
		EN: "Sign Up",
		ID: "Daftar",
	},
	EMAIL: {
		EN: "E-mail",
		ID: "E-mail",
	},
	PASSWORD: {
		EN: "Password",
		ID: "Password",
	},
	BACK: {
		EN: "Back",
		ID: "Kembali",
	},
	NEXT: {
		EN: "Next",
		ID: "Lanjut",
	},
	REQUIRED: {
		EN: "are required",
		ID: "dibutuhkan",
	},
	INVALID_EN: {
		EN: "Invalid ",
		ID: "",
	},
	INVALID_ID: {
		EN: "",
		ID: " tidak valid",
	},
	FIRST_NAME: {
		EN: "First Name",
		ID: "Nama Depan",
	},
	LAST_NAME: {
		EN: "Last Name",
		ID: "Nama Belakang",
	},
	PHONE_NUMBER: {
		EN: "Phone Number",
		ID: "Nomor Ponsel",
	},
	DATE_OF_BIRTH: {
		EN: "Date of Birth",
		ID: "Tanggal Lahir",
	},
	SEX: {
		EN: "Sex",
		ID: "Jenis Kelamin",
	},
	PROVINCE: {
		EN: "Province",
		ID: "Provinsi",
	},
	CITY: {
		EN: "City",
		ID: "Kota",
	},
	ZIP_CODE: {
		EN: "Zip Code",
		ID: "Kode Pos",
	},
	ADDRESS_DETAILS: {
		EN: "Address Details",
		ID: "Detail Alamat",
	},
	SPECIAL_NOTES: {
		EN: "Special Notes",
		ID: "Catatan Khusus",
	},
	BACK_TO_DASHBOARD: {
		EN: "Back to Dashboard",
		ID: "Kembali ke Dashboard",
	},
	BUY_COURSE: {
		EN: "Buy Course",
		ID: "Beli Kursus",
	},
	COURSE: {
		EN: "Course",
		ID: "Kursus",
	},
	CONTINUE: {
		EN: "Continue",
		ID: "Lanjutkan",
	},
	TOTAL_AMOUNT_LABEL: {
		EN: "Total Amount to Pay",
		ID: "Jumlah yang harus dibayarkan",
	},
	CHECK_PAYMENT_LABEL: {
		EN: "Already do a payment? Check the status",
		ID: "Sudah melakukan pembayaran? Cek statusnya",
	},
	CHECK_PAYMENT_BUTTON_TEXT: {
		EN: "Check Payment Status",
		ID: "Cek Status Pembayaran",
	},
	MON: {
		EN: "Mon",
		ID: "Sen",
	},
	TUE: {
		EN: "Tue",
		ID: "Sel",
	},
	WED: {
		EN: "Wed",
		ID: "Rab",
	},
	THU: {
		EN: "Thu",
		ID: "Kam",
	},
	FRI: {
		EN: "Fri",
		ID: "Jum",
	},
	SAT: {
		EN: "Sat",
		ID: "Sab",
	},
	SUN: {
		EN: "Sun",
		ID: "Min",
	},
	MONDAY: {
		EN: "Monday",
		ID: "Senin",
	},
	TUESDAY: {
		EN: "Tuesday",
		ID: "Selasa",
	},
	WEDNESDAY: {
		EN: "Wednesday",
		ID: "Rabu",
	},
	THURSDAY: {
		EN: "Thursday",
		ID: "Kamis",
	},
	FRIDAY: {
		EN: "Friday",
		ID: "Jumat",
	},
	SATURDAY: {
		EN: "Saturday",
		ID: "Sabtu",
	},
	SUNDAY: {
		EN: "Sunday",
		ID: "Minggu",
	},
	JAN: {
		EN: "Jan",
		ID: "Jan",
	},
	FEB: {
		EN: "Feb",
		ID: "Feb",
	},
	MAR: {
		EN: "Mar",
		ID: "Mar",
	},
	APR: {
		EN: "Apr",
		ID: "Apr",
	},
	MAY: {
		EN: "May",
		ID: "Mei",
	},
	JUN: {
		EN: "Jun",
		ID: "Jun",
	},
	JUL: {
		EN: "Jul",
		ID: "Jul",
	},
	AUG: {
		EN: "Aug",
		ID: "Agu",
	},
	SEP: {
		EN: "Sep",
		ID: "Sep",
	},
	OCT: {
		EN: "Oct",
		ID: "Okt",
	},
	NOV: {
		EN: "Nov",
		ID: "Nov",
	},
	DEC: {
		EN: "Dec",
		ID: "Des",
	},
	JANUARY: {
		EN: "January",
		ID: "Januari",
	},
	FEBRUARY: {
		EN: "February",
		ID: "Februari",
	},
	MARCH: {
		EN: "March",
		ID: "Maret",
	},
	APRIL: {
		EN: "April",
		ID: "April",
	},
	MAY: {
		EN: "May",
		ID: "Mei",
	},
	JUNE: {
		EN: "June",
		ID: "Juni",
	},
	JULY: {
		EN: "July",
		ID: "Juli",
	},
	AUGUST: {
		EN: "August",
		ID: "Agustus",
	},
	SEPTEMBER: {
		EN: "September",
		ID: "September",
	},
	OCTOBER: {
		EN: "October",
		ID: "Oktober",
	},
	NOVEMBER: {
		EN: "November",
		ID: "November",
	},
	DECEMBER: {
		EN: "December",
		ID: "Desember",
	},
	TERMS_AND_CONDITIONS_APPLIED: {
		EN: "*Terms & conditions applied",
		ID: "*Syarat & ketentuan berlaku",
	},
	OLD_PASSWORD: {
		EN: "Old Password",
		ID: "Password Lama",
	},
	SAVE_CHANGES: {
		EN: "Save Changes",
		ID: "Simpan Perubahan",
	},
	GRADE: {
		EN: "Grade",
		ID: "Kelas",
	},
	POSTED_ON: {
		EN: "Posted on",
		ID: "Diposting pada",
	},
	CONTACT_US: {
		EN: "Contact Us",
		ID: "Hubungi Kami",
	},
	VIRTUAL_ACCOUNT_NUMBER_COPIED: {
		EN: "Virtual Account Number Copied!",
		ID: "Nomor Virtual Account Disalin!",
	},
};

const useDictionary = () => {
	const language = useSelector((state) => state.user.language);

	const translate = useCallback(
		(type) => {
			return !!dictionary[type] ? dictionary[type][language] : "";
		},
		[language]
	);

	return translate;
};

export default useDictionary;
