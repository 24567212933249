import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import moment from "moment";
import { delay } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import { fetchChildPaymentStatus } from "redux/user/user.sagas";
import { selectUserLanguage } from "redux/user/user.selector";
import { selectCurrentUser } from "redux/user/user.selector";
import {
	addChildFailure,
	addChildSuccess,
	childCourseMarkWatchVideo,
	clearChildError,
	fetchChildBillingProgressFailure,
	fetchChildBillingProgressSuccess,
	fetchChildByAcademicYearFailure,
	fetchChildByAcademicYearSuccess,
	fetchChildCourseFailure,
	fetchChildCoursesFailure,
	fetchChildCoursesSuccess,
	fetchChildCourseSuccess,
	fetchChildFailure,
	fetchChildStart,
	fetchChildSuccess,
	setChildActiveBilling,
	updateChildFailure,
	updateChildGradeFailure,
	updateChildGradeSuccess,
	updateChildSuccess,
	uploadChildPictureFailure,
	uploadChildPictureSuccess,
} from "./child.action";
import { selectSelectedChild } from "./child.selector";
import ChildActionTypes from "./child.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchEligibleNextGrade(childId) {
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/grade/next-grade`,
			data: {
				child_id: childId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		return response.data.eligible;
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildFailure(errorMessage));
	}
}

export function* fetchLatestAcademicYear(childId) {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/childs/last-academic-year/${childId}`,
			data: {
				child_id: childId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		return response.data.academic_year;
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildFailure(errorMessage));
	}
}

export function* fetchChild({ payload }) {
	const childId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/childs/${childId}`,
			headers: {},
		});

		const eligibleNextGrade = yield call(fetchEligibleNextGrade, childId);
		const latestAcademicYear = yield call(fetchLatestAcademicYear, childId);

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const childData = response.data.childs;
		const tempPaymentStatus = yield fetchChildPaymentStatus(childId);

		yield put(
			fetchChildSuccess({
				...childData,
				academicYearId: latestAcademicYear.academic_year_id,
				latestAcademicYear,
				paymentStatus: tempPaymentStatus.payment_status,
				isAuto: tempPaymentStatus.is_auto,
				grade: tempPaymentStatus.grade,
				eligibleNextGrade,
			})
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildFailure(errorMessage));
	}
}

export function* onFetchChildStart() {
	yield takeLatest(ChildActionTypes.FETCH_CHILD_START, fetchChild);
}

export function* addChild({ payload: { childInfo, history } }) {
	const currentUser = yield select(selectCurrentUser);

	let formData = new FormData();
	formData.append("email", currentUser.email);
	formData.append("first_name", childInfo.firstName);
	formData.append("last_name", childInfo.lastName);
	formData.append("gender", childInfo.gender);
	formData.append(
		"birthdate",
		moment(childInfo.childBirthday).format("YYYY-MM-DD")
	);
	formData.append("image", childInfo.image[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/users/register-child`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(addChildSuccess());
		history.push({
			pathname: "/profile/add-child-finish",
			state: { childId: response.data.childs.child_id },
		});
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(addChildFailure(errorMessage));
	}
}

export function* onAddChildStart() {
	yield takeLatest(ChildActionTypes.ADD_CHILD_START, addChild);
}

export function* uploadChildPicture({ payload }) {
	const currentChild = yield select(selectSelectedChild);
	let data = new FormData();
	data.append("image_url", payload);
	data.append("child_id", currentChild.child_id);
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/childs/update-image`,
			data: data,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(uploadChildPictureSuccess());
		yield put(fetchChildStart(currentChild.child_id));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(uploadChildPictureFailure(errorMessage));
	}
}

export function* onUploadChildPictureStart() {
	yield takeLatest(
		ChildActionTypes.UPLOAD_CHILD_PICTURE_START,
		uploadChildPicture
	);
}

export function* updateChild({ payload }) {
	const childInfo = payload;
	const language = yield select(selectUserLanguage);

	const data = {
		child_id: childInfo.id,
		first_name: childInfo.firstName,
		last_name: childInfo.lastName,
		gender: childInfo.gender,
		birthdate: childInfo.childBirthday,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/childs/update-child`,
			data,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const childData = response.data.childs;
		yield put(updateChildSuccess(childData));
		yield put(fetchChildStart(childInfo.id));
		const snackbarData = {
			message: language === "EN" ? "Update Success!" : "Update Sukses!",
			color: "success",
			place: "bl",
			dispatchActions: [],
		};
		yield put(openSnackbar(snackbarData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateChildFailure(errorMessage));
	}
}

export function* onUpdateChildStart() {
	yield takeLatest(ChildActionTypes.UPDATE_CHILD_START, updateChild);
}

export function* fetchChildBillingProgress({ payload }) {
	const { childId, academicYearId } = payload;
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/payment/billing`,
			data: {
				child_id: childId,
				academic_year_id: academicYearId,
			},
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const billing = response.data.billing;
		const activeBilling = billing.find((bill) =>
			moment().isBetween(
				moment(bill.billing_period_start),
				moment(bill.billing_period_start)
			)
		);

		yield put(
			setChildActiveBilling(!!activeBilling ? activeBilling : billing[0])
		);
		yield put(fetchChildBillingProgressSuccess(billing));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildBillingProgressFailure(errorMessage));
	}
}

export function* onFetchChildBillingProgressStart() {
	yield takeLatest(
		ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_START,
		fetchChildBillingProgress
	);
}

export function* fetchChildCourses({ payload }) {
	const childId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/child/billing/${childId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const classes = response.data.billing;

		yield put(fetchChildCoursesSuccess(classes));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildCoursesFailure(errorMessage));
	}
}

export function* onFetchChildCoursesStart() {
	yield takeLatest(
		ChildActionTypes.FETCH_CHILD_COURSES_START,
		fetchChildCourses
	);
}

export function* fetchChildCourse({ payload }) {
	const childCourseId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/course/child-course/${childCourseId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const currentCourse = response.data.current_course;
		const currentCourseBillingStatus = response.data.billing_status;
		const nextCourse = response.data.next_course;
		const nextCourseBillingStatus = response.data.next_billing_status;

		yield put(
			fetchChildCourseSuccess({
				currentCourse: {
					...currentCourse.child_course,
					master_course: currentCourse.master_course,
					billing_status: currentCourseBillingStatus,
				},
				nextCourse: !!nextCourse
					? {
							...nextCourse.child_course,
							master_course: nextCourse.master_course,
							billing_status: nextCourseBillingStatus,
					  }
					: null,
			})
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildCourseFailure(errorMessage));
	}
}

export function* onFetchChildCourseStart() {
	yield takeLatest(
		ChildActionTypes.FETCH_CHILD_COURSE_START,
		fetchChildCourse
	);
}

export function* markWatchVideo({ payload }) {
	const childCourseId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/course/mark/${childCourseId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;

		console.log("Error Mark Watch ", errorMessage);
		yield delay(5000);
		yield put(childCourseMarkWatchVideo(childCourseId));
	}
}

export function* onMarkWatchVideo() {
	yield takeLatest(ChildActionTypes.MARK_WATCH_VIDEO, markWatchVideo);
}

//ADMIN
export function* fetchChildByAcademicYear({ payload }) {
	const academicYearId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/childs/academic-year/${academicYearId}`,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const childData = response.data.childs.map((child) => ({
			...child,
			childFullname: `${child.first_name} ${child.last_name}`,
			parentFullname: `${child.parent_first_name} ${child.parent_last_name}`,
		}));
		yield put(fetchChildByAcademicYearSuccess(childData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchChildByAcademicYearFailure(errorMessage));
	}
}

export function* onFetchChildByAcademicYearStart() {
	yield takeLatest(
		ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_START,
		fetchChildByAcademicYear
	);
}

export function* updateChildGrade({ payload }) {
	const childData = payload;
	const data = {
		child_id: childData.childId,
		grade: childData.grade,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/course/change-grade`,
			data,
			headers: {},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateChildGradeSuccess());
		if (!!childData.onFinishUpdate) {
			childData.onFinishUpdate();
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearChildError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateChildGradeFailure(errorMessage));
	}
}

export function* onUpdateChildGradeStart() {
	yield takeLatest(
		ChildActionTypes.UPDATE_CHILD_GRADE_START,
		updateChildGrade
	);
}

export function* childSagas() {
	yield all([
		call(onFetchChildStart),
		call(onAddChildStart),
		call(onUploadChildPictureStart),
		call(onFetchChildBillingProgressStart),
		call(onFetchChildByAcademicYearStart),
		call(onUpdateChildGradeStart),
		call(onFetchChildCoursesStart),
		call(onFetchChildCourseStart),
		call(onUpdateChildStart),
		call(onMarkWatchVideo),
	]);
}
