import moment from "moment";
import { createSelector } from "reselect";

const selectPlacementTest = (state) => state.placementTest;

export const selectPlacementTestError = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.error
);

export const selectPlacementTestIsLoading = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.isLoading
);

export const selectPlacementTestActiveChild = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.activeChildId
);

export const selectPlacementTestFinishTime = createSelector(
	[selectPlacementTest],
	(placementTest) =>
		!!placementTest.finishTime ? moment(placementTest.finishTime) : null
);

export const selectPlacementTestAnswers = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.answers
);

export const selectPlacementTestResult = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.result
);

export const selectPlacementTestActivePart = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.activePart
);

export const selectIsButtonNextDisabled = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.isButtonNextDisabled
);

export const selectPlacementTestData = createSelector(
	[selectPlacementTest],
	(placementTest) => placementTest.placementTestData
);
