import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
	CircularProgress,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import LoginBackground from "assets/img/login-background.jpeg";
import { useStyles } from "./LoginAdmin.styles";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { emailSignInStart } from "redux/user/user.action";
import { createStructuredSelector } from "reselect";
import { selectUserLoading } from "redux/user/user.selector";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			(C) 2021. Adiwidya Course. All Rights Reserved. Powered by{" "}
			<a href="https://www.anthem.co.id/" target="_blank">
				Anthem
			</a>
			.
		</Typography>
	);
}

const LoginAdmin = (props) => {
	const classes = useStyles();
	const { isLoading } = props;
	const { register, handleSubmit, errors } = useForm();
	const [showPassword, setShowPassword] = useState(false);

	const login = async (data) => {
		if (!isLoading) {
			const { emailSignInStart } = props;
			emailSignInStart({ credentials: data, type: 1 }); // 1 === "Admin"
		}
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />

			<Grid item xs={false} sm={4} md={7} className={classes.image}>
				<img
					className={classes.backgroundImage}
					src={LoginBackground}
					alt="login background"
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<div className={classes.paper}>
					{/* <img src={HavillaLogo} alt="logo"></img> */}
					<Typography component="h1" variant="h5">
						Login
					</Typography>
					<form
						className={classes.form}
						onSubmit={handleSubmit(login)}
					>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							inputRef={register({
								required: {
									value: true,
									message: "Email are required",
								},
							})}
							error={!!errors.email}
							helperText={errors.email && errors.email.message}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							name="password"
							label="Password"
							type={showPassword ? "text" : "password"}
							id="password"
							autoComplete="current-password"
							inputRef={register({
								required: {
									value: true,
									message: "Password are required",
								},
							})}
							error={!!errors.password}
							helperText={
								errors.password && errors.password.message
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												setShowPassword(!showPassword)
											}
											// onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							{isLoading ? (
								<CircularProgress color="inherit" />
							) : (
								"Sign In"
							)}
						</Button>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectUserLoading,
});

const mapDispatchToProps = (dispatch) => ({
	emailSignInStart: (credentials) => dispatch(emailSignInStart(credentials)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);
