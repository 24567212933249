import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	selectCategoriesContainer: {
		minWidth: "200px",

		[theme.breakpoints.up("lg")]: {
			paddingLeft: "24px",
		},
	},
	pillButton: {
		color: "white",
		margin: "4px",
		padding: "12px 24px",
		minWidth: "130px",

		[theme.breakpoints.down("sm")]: {
			minWidth: "100px",
		},
	},
	dangerButton: {
		background: "#FF6961",
	},
	buttonContainer: {
		paddingLeft: "12px",
		paddingRight: "12px",
	},
}));
