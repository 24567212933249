import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	pillButton: {
		borderRadius: "78px",
		border: "none",
		fontSize: "14px",
		fontWeight: "600",
		lineHeight: "16px",
		textAlign: "center",
		padding: "20px 30px 20px 30px",
		cursor: "pointer",
		backgroundColor: ({ backgroundColor }) => backgroundColor,
		color: ({ color }) => color,

		"&.disabled": {
			cursor: "not-allowed",
			pointerEvents: "none",

			/*Button disabled - CSS color class*/
			color: "#c0c0c0",
			backgroundColor: "#EBEBEB",
		},
	},
}));
