import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectUserLanguage } from "redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { useStyles } from "./LanguageSelector.styles";
import { ReactComponent as ArrowDown } from "../../../assets/img/arrow-down.svg";
import { ReactComponent as EnglishIcon } from "../../../assets/img/english-icon.svg";
import { ReactComponent as IndonesiaIcon } from "../../../assets/img/indonesia-icon.svg";
import { Grid } from "@material-ui/core";
import { updateUserLanguage } from "redux/user/user.action";
import { selectUserLoading } from "redux/user/user.selector";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { updateLocale } from "redux/user/user.action";
import { selectCurrentUser } from "redux/user/user.selector";

const LanguageSelector = (props) => {
	const {
		language,
		updateUserLanguage,
		isUserLoading,
		updateLocale,
		currentUser,
	} = props;
	const classes = useStyles();
	const [openLanguage, setOpenLanguage] = useState(false);
	const [isUpdateLanguageStart, setIsUpdateLanguageStart] = useState(false);
	const [tempLanguage, setTempLanguage] = useState(null);

	const handleOnClickLanguage = () => {
		setOpenLanguage(!openLanguage);
	};

	const handleLanguageChange = (language) => {
		setTempLanguage(language);
		setIsUpdateLanguageStart(true);
	};

	useEffect(() => {
		if (!isUserLoading && isUpdateLanguageStart && !!tempLanguage) {
			setOpenLanguage(false);
			updateUserLanguage(tempLanguage);
			setIsUpdateLanguageStart(false);
			setTempLanguage(null);

			if (!!currentUser) updateLocale(tempLanguage);
		}
	}, [isUserLoading, isUpdateLanguageStart, tempLanguage, currentUser]);

	return (
		<>
			<LoadingBackdrop
				color="black"
				backgroundColor="rgba(0, 0, 0, 0.5)"
				open={isUserLoading && isUpdateLanguageStart}
			/>
			<span className={classes.language} onClick={handleOnClickLanguage}>
				{language === "EN" ? <EnglishIcon /> : <IndonesiaIcon />}
				<p>{language}</p>
				<ArrowDown
					className={`${classes.languageArrowIcon} ${
						openLanguage && "active"
					}`}
				/>
			</span>
			<Grid
				container
				className={`${classes.menuContainer} ${
					openLanguage ? "opened" : null
				}`}
			>
				<Grid
					item
					xs={12}
					container
					className={classes.languageContainer}
					onClick={() => handleLanguageChange("EN")}
				>
					<Grid item xs={4} className={classes.iconContainer}>
						<EnglishIcon />
					</Grid>
					<Grid
						item
						xs={8}
						className={`${classes.text} ${
							language === "EN" ? "active" : null
						}`}
					>
						English
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					container
					className={classes.languageContainer}
					onClick={() => handleLanguageChange("ID")}
				>
					<Grid item xs={4} className={classes.iconContainer}>
						<IndonesiaIcon />
					</Grid>
					<Grid
						item
						xs={8}
						className={`${classes.text} ${
							language === "ID" ? "active" : null
						}`}
					>
						Indonesia
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const mapStateToProps = createStructuredSelector({
	language: selectUserLanguage,
	isUserLoading: selectUserLoading,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	updateUserLanguage: (language) => dispatch(updateUserLanguage(language)),
	updateLocale: (locale) => dispatch(updateLocale(locale)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
