import React, { useEffect, useRef, useState } from "react";
import { useStyles, CustomCheckBox } from "./UpdateAcademicYear.styles";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Card from "components/Shared/Card/Card";
import CardHeader from "components/Shared/Card/CardHeader";
import CardBody from "components/Shared/Card/CardBody";
import { FormControlLabel, Grid } from "@material-ui/core";
import LoadingBackdrop from "components/Shared/LoadingBackdrop/LoadingBackdrop";
import { useHistory } from "react-router";
import CustomDatePicker from "components/Shared/CustomDatePicker/CustomDatePicker";
import moment from "moment";
import { selectAcademicYearLoading } from "redux/academic-year/academic-year.selector";
import { updateAcademicYearStart } from "redux/academic-year/academic-year.action";
import Button from "components/Shared/CustomButtons/Button";
import { fetchAcademicYearStart } from "redux/academic-year/academic-year.action";
import { selectAcademicYearSingular } from "redux/academic-year/academic-year.selector";
import { useParams } from "react-router-dom";

const UpdateAcademicYear = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { academicYearId } = useParams();
	const {
		isLoading,
		updateAcademicYearStart,
		fetchAcademicYearStart,
		academicYear,
	} = props;
	const { handleSubmit, errors, watch, control, setValue } = useForm();
	const [holidayWeekStarts, setHolidayWeekStarts] = useState([]);

	const startDate = useRef();
	startDate.current = watch("startDate", "");
	const startRegistrationDate = useRef();
	startRegistrationDate.current = watch("startRegistrationDate", "");

	useEffect(() => {
		fetchAcademicYearStart(academicYearId);
	}, []);

	useEffect(() => {
		if (!!academicYear) {
			setValue("startDate", new Date(academicYear.start_date));
			setValue(
				"startRegistrationDate",
				new Date(academicYear.start_registration)
			);
			setValue(
				"endRegistrationDate",
				new Date(academicYear.end_registration)
			);
			setHolidayWeekStarts(JSON.parse(academicYear.holiday_week));
		}
	}, [academicYear]);

	useEffect(() => {
		if (!!startDate) {
			setHolidayWeekStarts([]);
		}
	}, [startDate]);

	const updateBatch = (data) => {
		if (!isLoading) {
			const finalData = {
				startRegistrationDate: data.startRegistrationDate,
				endRegistrationDate: data.endRegistrationDate,
				id: academicYearId,
			};
			updateAcademicYearStart({ academicYearData: finalData, history });
		}
	};
	return (
		<Grid container>
			<LoadingBackdrop open={isLoading} />
			<Grid item xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="primary">
						<h4>Update Batch</h4>
					</CardHeader>
					<CardBody>
						<form onSubmit={handleSubmit(updateBatch)}>
							<Grid container spacing={2}>
								<Grid item container xs={12} lg={6} spacing={2}>
									<Grid item xs={12}>
										<CustomDatePicker
											fullWidth
											autoOk
											id="startDate"
											name="startDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="Start Date"
											views={["year", "month", "date"]}
											disabled
										/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<CustomDatePicker
											disabled={!!!startDate.current}
											maxDate={
												!!startDate && startDate.current
											}
											fullWidth
											autoOk
											id="startRegistrationDate"
											name="startRegistrationDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="Start Registration Date"
											views={["year", "month", "date"]}
											rules={{
												required: {
													value: true,
													message:
														"Start Registration Date are required",
												},
											}}
											error={
												!!errors.startRegistrationDate
											}
											helperText={
												errors.startRegistrationDate &&
												errors.startRegistrationDate
													.message
											}
										/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<CustomDatePicker
											disabled={
												!!!startRegistrationDate.current
											}
											minDate={
												!!startRegistrationDate &&
												startRegistrationDate.current
											}
											fullWidth
											autoOk
											id="endRegistrationDate"
											name="endRegistrationDate"
											control={control}
											placeholder="dd-mm-yyyy"
											format="dd-MM-yyyy"
											label="End Registration Date"
											views={["year", "month", "date"]}
											rules={{
												required: {
													value: true,
													message:
														"End Registration Date are required",
												},
											}}
											error={!!errors.endRegistrationDate}
											helperText={
												errors.endRegistrationDate &&
												errors.endRegistrationDate
													.message
											}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									lg={6}
									className={classes.ulContainer}
								>
									<h4 className={classes.holidayLabel}>
										Holiday Weeks
									</h4>
									{holidayWeekStarts.length > 0 ? (
										<ul className={classes.ul}>
											{holidayWeekStarts.map(
												(date, idx) => (
													<li key={idx}>
														{moment(date).format(
															"DD-MM-YYYY"
														)}{" "}
														until{" "}
														{moment(date)
															.add(6, "days")
															.format(
																"DD-MM-YYYY"
															)}
													</li>
												)
											)}
										</ul>
									) : (
										"There are no holiday week in this batch"
									)}
								</Grid>
								<Grid item container justify="flex-end" xs={12}>
									<Button
										type="submit"
										variant="contained"
										color="info"
										className={classes.submit}
									>
										Update Batch
									</Button>
								</Grid>
							</Grid>
						</form>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectAcademicYearLoading,
	academicYear: selectAcademicYearSingular,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAcademicYearStart: (academicYearId) =>
		dispatch(fetchAcademicYearStart(academicYearId)),
	updateAcademicYearStart: ({ academicYearData, history }) =>
		dispatch(updateAcademicYearStart({ academicYearData, history })),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAcademicYear);
