import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	imagePreview: {
		width: "508px",
		height: "577px",
	},
	previewContainer: {
		maxHeight: "577px",
		display: "flex",
		justifyContent: "center",
		marginBottom: "120px",

		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
		},
		"& h3": {
			fontWeight: "600",
			marginTop: "20px",
			marginBottom: "30px",
		},
	},
	videoContainer: {
		marginTop: "24px",
	},
	videoWrapper: {
		position: "relative",
		paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
	},
	videoPlayer: {
		position: "absolute",
		top: 0,
		left: 0,
	},
}));
