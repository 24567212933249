const CourseMasterActionTypes = {
	// FETCH ALL COURSE_MASTERS
	FETCH_COURSE_MASTERS_START: "FETCH_COURSE_MASTERS_START",
	FETCH_COURSE_MASTERS_SUCCESS: "FETCH_COURSE_MASTERS_SUCCESS",
	FETCH_COURSE_MASTERS_FAILURE: "FETCH_COURSE_MASTERS_FAILURE",
	// FETCH ONE COURSE_MASTER
	FETCH_COURSE_MASTER_START: "FETCH_COURSE_MASTER_START",
	FETCH_COURSE_MASTER_SUCCESS: "FETCH_COURSE_MASTER_SUCCESS",
	FETCH_COURSE_MASTER_FAILURE: "FETCH_COURSE_MASTER_FAILURE",
	// CREATE COURSE_MASTER
	CREATE_COURSE_MASTER_START: "CREATE_COURSE_MASTER_START",
	CREATE_COURSE_MASTER_SUCCESS: "CREATE_COURSE_MASTER_SUCCESS",
	CREATE_COURSE_MASTER_FAILURE: "CREATE_COURSE_MASTER_FAILURE",
	// UPDATE COURSE_MASTER
	UPDATE_COURSE_MASTER_START: "UPDATE_COURSE_MASTER_START",
	UPDATE_COURSE_MASTER_SUCCESS: "UPDATE_COURSE_MASTER_SUCCESS",
	UPDATE_COURSE_MASTER_FAILURE: "UPDATE_COURSE_MASTER_FAILURE",
	// DELETE COURSE_MASTER
	DELETE_COURSE_MASTER_START: "DELETE_COURSE_MASTER_START",
	DELETE_COURSE_MASTER_SUCCESS: "DELETE_COURSE_MASTER_SUCCESS",
	DELETE_COURSE_MASTER_FAILURE: "DELETE_COURSE_MASTER_FAILURE",
	// SWAP COURSE_MASTER
	SWAP_COURSE_MASTER_START: "SWAP_COURSE_MASTER_START",
	SWAP_COURSE_MASTER_SUCCESS: "SWAP_COURSE_MASTER_SUCCESS",
	SWAP_COURSE_MASTER_FAILURE: "SWAP_COURSE_MASTER_FAILURE",
	CLEAR_ERROR: "CLEAR_ERROR",
	CLEAR_DATA: "CLEAR_DATA",
	SET_COURSE_MASTER_UPLOAD_PROGRESS: "SET_COURSE_MASTER_UPLOAD_PROGRESS",
	SET_COURSE_MASTER_FILTER: "SET_COURSE_MASTER_FILTER",
};

export default CourseMasterActionTypes;
