import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	footerSectionTitle: {
		fontSize: "16px",
		fontWeight: "bold",
		textAlign: "center",

		[theme.breakpoints.down("sm")]: {
			marginTop: "30px",
		},
	},
	footerSectionItems: {
		marginTop: "20px",
		listStyleType: "none",
		listStyle: "none",
		"& li": {
			whiteSpace: "pre-wrap", // enabled "\n" on string
			textAlign: "center",
			fontSize: "16px",
			lineHeight: "32px",
		},
	},
}));
