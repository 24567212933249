import React from "react";

const NavLinkUnderline = (props) => {
	const { hidden } = props;
	return (
		<svg
			width={204}
			height={16}
			viewBox={`0 0 ${props.width} 16`}
			fill="none"
			style={{
				visibility: hidden ? "hidden" : "visible",
			}}
			{...props}
		>
			<path
				d="M2 10.3665C3.59168 10.3809 4.08767 10.1063 5.04841 8.8939C5.59078 8.20943 5.56773 6.95398 6.09473 6.34523C6.66278 5.68906 6.6647 5.03649 7.52433 4.56313C8.48415 4.03461 9.6771 3.98994 10.419 4.74541C11.1355 5.47498 11.7437 6.03643 12.3333 6.90464C12.5561 7.23287 12.8341 7.52182 13.1055 7.81348C13.3619 8.08912 13.3868 8.60947 13.721 8.79028C15.3514 9.67241 17.5768 8.97832 18.1084 6.93088C18.3395 6.04044 18.6792 5.13486 19.103 4.34706C19.4785 3.64898 20.8659 3.62631 21.4476 3.98672C22.4826 4.62804 23.5713 5.03808 23.8889 6.35883C24.0956 7.21875 24.6962 7.95922 25.3525 8.47929C26.2072 9.15655 27.5083 9.03644 28.5472 9.04584C29.9943 9.05894 30.805 7.7234 31.535 6.61013C31.8587 6.11639 31.9641 5.55276 32.4157 5.10923C33.0331 4.50297 33.7947 4.36761 34.5673 4.04474C35.9579 3.46361 36.5927 4.02463 37.393 5.13692C38.5463 6.73984 38.8288 8.17732 40.9611 8.52515C41.8898 8.67663 43.2108 9.00636 44.0916 8.52746C44.9501 8.06059 44.9469 6.59018 45.5893 5.92217C46.4833 4.99256 48.3994 4.0148 49.1839 5.41705C49.382 5.77109 49.3812 6.28679 49.6417 6.5832C50.0125 7.00524 50.4339 7.38399 50.8801 7.72172C51.8535 8.4586 52.7246 8.32809 53.9269 8.33897C56.8151 8.3651 57.3079 5.72332 58.9602 4.10071C59.5362 3.53499 61.4315 3.44586 62.1558 3.60933C63.0306 3.80677 63.8104 4.50432 64.2042 5.31017C64.9905 6.91894 66.0419 7.35597 67.7323 7.37126C68.5813 7.37895 69.5183 7.38634 69.9002 6.41965C70.0737 5.98028 71.5977 4.29735 72 4.10071"
				stroke="#FFDD28"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M70.014 7.61043C70.5563 6.92597 70.987 6.61389 71.514 6.00514C72.082 5.34897 72.1319 5.11946 72.9915 4.6461C73.9513 4.11758 75.1443 4.07291 75.8862 4.82839C76.6027 5.55796 77.2109 6.11941 77.8004 6.98761C78.0233 7.31585 78.3013 7.6048 78.5727 7.89645C78.8291 8.17209 78.854 8.69244 79.1882 8.87325C80.8186 9.75539 83.0439 9.06129 83.5755 7.01386C83.8067 6.12341 84.1464 5.21783 84.5701 4.43003C84.9457 3.73195 86.3331 3.70929 86.9147 4.06969C87.9498 4.71102 89.0385 5.12105 89.356 6.4418C89.5628 7.30172 90.1634 8.0422 90.8197 8.56226C91.6744 9.23953 92.9754 9.11941 94.0144 9.12882C95.4614 9.14191 96.2722 7.80638 97.0022 6.6931C97.3259 6.19936 97.4313 5.63573 97.8829 5.1922C98.5003 4.58594 99.2618 4.45059 100.034 4.12771C101.425 3.54658 102.06 4.1076 102.86 5.2199C104.014 6.82282 104.296 8.2603 106.428 8.60812C107.357 8.7596 108.678 9.08934 109.559 8.61043C110.417 8.14356 110.414 6.67316 111.056 6.00514C111.951 5.07554 113.867 4.09777 114.651 5.50003C114.849 5.85407 114.848 6.36976 115.109 6.66617C115.48 7.08821 115.901 7.46696 116.347 7.8047C117.321 8.54157 118.192 8.41106 119.394 8.42194C122.282 8.44807 122.775 5.80629 124.427 4.18368C125.003 3.61796 126.899 3.52883 127.623 3.6923C128.498 3.88975 129.278 4.5873 129.671 5.39314C130.458 7.00191 131.509 7.43894 133.199 7.45424C134.048 7.46192 134.985 7.46931 135.367 6.50263C135.541 6.06325 137.112 3.80707 137.514 3.61044"
				stroke="#FFDD28"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M135.011 6.7875C135.554 6.10303 135.637 5.50494 136.164 4.89619C136.732 4.24002 136.782 4.01051 137.641 3.53715C138.601 3.00863 139.794 2.96396 140.536 3.71944C141.253 4.449 141.861 5.01046 142.45 5.87866C142.673 6.2069 142.951 6.49584 143.223 6.7875C143.479 7.06314 143.504 7.58349 143.838 7.7643C145.469 8.64643 147.694 7.95234 148.226 5.9049C148.457 5.01446 148.796 4.10888 149.22 3.32108C149.596 2.623 150.983 2.60033 151.565 2.96074C152.6 3.60206 153.332 3.57544 153.65 4.89619C153.857 5.75611 154.813 6.93324 155.47 7.45331C156.324 8.13057 157.625 8.01046 158.664 8.01986C160.111 8.03296 160.922 6.69743 161.652 5.58415C161.976 5.09041 162.081 4.52678 162.533 4.08325C163.15 3.47699 163.912 3.34163 164.684 3.01876C166.075 2.43763 166.71 2.99865 167.51 4.11095C168.664 5.71386 168.946 7.15134 171.078 7.49917C172.007 7.65065 173.328 7.98038 174.209 7.50148C175.067 7.03461 175.064 5.5642 175.706 4.89619C176.6 3.96658 178.517 2.98882 179.301 4.39108C179.499 4.74511 179.498 5.26081 179.759 5.55722C180.13 5.97926 180.551 6.35801 180.997 6.69574C181.971 7.43262 182.842 7.30211 184.044 7.31299C186.932 7.33912 187.425 4.69734 189.077 3.07473C189.653 2.50901 191.549 2.41988 192.273 2.58335C193.148 2.78079 193.928 3.47834 194.321 4.28419C195.108 5.89296 196.159 6.32999 197.849 6.34528C198.698 6.35297 199.635 6.36036 200.017 5.39368C200.191 4.9543 200.607 3.29262 201.009 3.09599"
				stroke="#FFDD28"
				strokeWidth={3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default NavLinkUnderline;
