import { createSelector } from "reselect";

const selectAcademicYear = (state) => state.academicYear;

export const selectAcademicYearSingular = createSelector(
	[selectAcademicYear],
	(academicYear) => academicYear.singular
);

export const selectAcademicYearPlural = createSelector(
	[selectAcademicYear],
	(academicYear) => academicYear.plural
);

export const selectAcademicYearError = createSelector(
	[selectAcademicYear],
	(academicYear) => academicYear.error
);

export const selectAcademicYearLoading = createSelector(
	[selectAcademicYear],
	(academicYear) => academicYear.loading
);
