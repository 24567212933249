import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	testimoniesContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	imagePreview: {
		width: "508px",
		height: "577px",
	},
	previewContainer: {
		display: "flex",
		justifyContent: "center",
	},
	contentDetailContainer: {
		marginTop: "32px",
		display: "flex",
		justifyContent: "center",
	},
	dividerContainer: {
		marginTop: "24px",
	},
	contentHeaderPreview: {
		display: "flex",
		justifyContent: "center",

		"& img": {
			width: "100%",
		},
	},
	previewLabel: {
		fontWeight: "600",
		marginTop: "20px",
		textAlign: "center",
	},
	languageSelectorContainer: {
		marginBottom: "30px",
	},
}));
