import TestimonyActionTypes from "./testimony.types";

// Fetch all
export const fetchAllTestimoniesStart = (filters) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONIES_START,
	payload: filters,
});

export const fetchAllTestimoniesSuccess = (testimonies) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONIES_SUCCESS,
	payload: testimonies,
});

export const fetchAllTestimoniesFailure = (error) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONIES_FAILURE,
	payload: error,
});

// Fetch One
export const getTestimonyStart = (filters) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONY_START,
	payload: filters,
});

export const getTestimonySuccess = (testimony) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONY_SUCCESS,
	payload: testimony,
});

export const getTestimonyFailure = (error) => ({
	type: TestimonyActionTypes.FETCH_TESTIMONY_FAILURE,
	payload: error,
});

// Create
export const createTestimonyStart = (testimonyData, history) => ({
	type: TestimonyActionTypes.CREATE_TESTIMONY_START,
	payload: { testimonyData, history },
});

export const createTestimonySuccess = () => ({
	type: TestimonyActionTypes.CREATE_TESTIMONY_SUCCESS,
});

export const createTestimonyFailure = (error) => ({
	type: TestimonyActionTypes.CREATE_TESTIMONY_FAILURE,
	payload: error,
});

// Update
export const updateTestimonyStart = (testimonyData, history) => ({
	type: TestimonyActionTypes.UPDATE_TESTIMONY_START,
	payload: { testimonyData, history },
});

export const updateTestimonySuccess = () => ({
	type: TestimonyActionTypes.UPDATE_TESTIMONY_SUCCESS,
});

export const updateTestimonyFailure = (error) => ({
	type: TestimonyActionTypes.UPDATE_TESTIMONY_FAILURE,
	payload: error,
});

// Delete
export const deleteTestimonyStart = ({ testimonyId, category }) => ({
	type: TestimonyActionTypes.DELETE_TESTIMONY_START,
	payload: { testimonyId, category },
});

export const deleteTestimonySuccess = () => ({
	type: TestimonyActionTypes.DELETE_TESTIMONY_SUCCESS,
});

export const deleteTestimonyFailure = (error) => ({
	type: TestimonyActionTypes.DELETE_TESTIMONY_FAILURE,
	payload: error,
});

export const clearTestimonyError = () => ({
	type: TestimonyActionTypes.CLEAR_ERROR,
});

export const clearTestimonyData = () => ({
	type: TestimonyActionTypes.CLEAR_DATA,
});
