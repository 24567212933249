import NotificationActionTypes from "./notification.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	plural: [],
};

const notificationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NotificationActionTypes.FETCH_NOTIFICATIONS_START:
		case NotificationActionTypes.READ_NOTIFICATION_START:
			return {
				...state,
				loading: true,
			};
		case NotificationActionTypes.FETCH_NOTIFICATIONS_FAILURE:
		case NotificationActionTypes.READ_NOTIFICATION_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case NotificationActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case NotificationActionTypes.READ_NOTIFICATION_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default notificationReducer;
