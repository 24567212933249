import { createSelector } from "reselect";

const selectOrder = (state) => state.order;

export const selectOrderSingular = createSelector(
	[selectOrder],
	(order) => order.singular
);

export const selectOrderPlural = createSelector(
	[selectOrder],
	(order) => order.plural
);

export const selectOrderError = createSelector(
	[selectOrder],
	(order) => order.error
);

export const selectOrderLoading = createSelector(
	[selectOrder],
	(order) => order.loading
);
