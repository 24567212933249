import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearAcademicYearError,
	createAcademicYearFailure,
	createAcademicYearSuccess,
	deleteAcademicYearFailure,
	deleteAcademicYearSuccess,
	fetchAcademicYearsFailure,
	fetchAcademicYearsStart,
	fetchAcademicYearsSuccess,
	deactivateAcademicYearFailure,
	deactivateAcademicYearSuccess,
	activateAcademicYearSuccess,
	activateAcademicYearFailure,
	fetchAvailableBatchSuccess,
	fetchAvailableBatchFailure,
	fetchAcademicYearSuccess,
	fetchAcademicYearFailure,
	updateAcademicYearSuccess,
	updateAcademicYearFailure,
} from "./academic-year.action";
import AcademicYearActionTypes from "./academic-year.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchAcademicYears({ payload }) {
	const filters = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/academic-year-admin`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const academicYears = response.data.academic_year;

		yield put(fetchAcademicYearsSuccess(academicYears));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAcademicYearsFailure(errorMessage));
	}
}

export function* onFetchAcademicYearsStart() {
	yield takeLatest(
		AcademicYearActionTypes.FETCH_ACADEMIC_YEARS_START,
		fetchAcademicYears
	);
}

export function* createAcademicYear({ payload }) {
	const { academicYearData, history } = payload;
	const data = {
		isNew: academicYearData.isNew || "",
		start_date: moment(academicYearData.startDate).format("YYYY-MM-DD"),
		start_registration: moment(
			academicYearData.startRegistrationDate
		).format("YYYY-MM-DD"),
		end_registration: moment(academicYearData.endRegistrationDate).format(
			"YYYY-MM-DD"
		),
		holiday_week: academicYearData.holidayWeekStarts,
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/course/academic-year`,
			data,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(createAcademicYearSuccess());
		history.push("/admin/batchs");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createAcademicYearFailure(errorMessage));
	}
}

export function* onCreateAcademicYearsStart() {
	yield takeLatest(
		AcademicYearActionTypes.CREATE_ACADEMIC_YEAR_START,
		createAcademicYear
	);
}

export function* deactivateAcademicYear({ payload }) {
	const academicYearId = payload;

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/academic-year/deactivated`,
			data: {
				academic_year_id: academicYearId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(deactivateAcademicYearSuccess());
		yield put(fetchAcademicYearsStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deactivateAcademicYearFailure(errorMessage));
	}
}

export function* onNonActivateAcademicYearsStart() {
	yield takeLatest(
		AcademicYearActionTypes.NON_ACTIVATE_ACADEMIC_YEAR_START,
		deactivateAcademicYear
	);
}

export function* activateAcademicYear({ payload }) {
	const academicYearId = payload;

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/academic-year/reactivated`,
			data: {
				academic_year_id: academicYearId,
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(activateAcademicYearSuccess());
		yield put(fetchAcademicYearsStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(activateAcademicYearFailure(errorMessage));
	}
}

export function* onActivateAcademicYearsStart() {
	yield takeLatest(
		AcademicYearActionTypes.ACTIVATE_ACADEMIC_YEAR_START,
		activateAcademicYear
	);
}

export function* fetchAvailableBatch() {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/academic-year`,
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const batch = response.data.academic_year;

		yield put(fetchAvailableBatchSuccess(batch));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAvailableBatchFailure(errorMessage));
	}
}

export function* onFetchAvailableBatchStart() {
	yield takeLatest(
		AcademicYearActionTypes.FETCH_AVAILABLE_BATCH_START,
		fetchAvailableBatch
	);
}

export function* fetchAcademicYear({ payload }) {
	const academicYearId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/academic-year/id/${academicYearId}`,
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const academicYear = response.data.academic_year;

		yield put(fetchAcademicYearSuccess(academicYear));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAcademicYearFailure(errorMessage));
	}
}

export function* onFetchAcademicYearStart() {
	yield takeLatest(
		AcademicYearActionTypes.FETCH_ACADEMIC_YEAR_START,
		fetchAcademicYear
	);
}

export function* updateAcademicYear({ payload }) {
	const { academicYearData, history } = payload;
	const data = {
		academic_year_id: academicYearData.id,
		start_registration: moment(
			academicYearData.startRegistrationDate
		).format("YYYY-MM-DD"),
		end_registration: moment(academicYearData.endRegistrationDate).format(
			"YYYY-MM-DD"
		),
	};

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/academic-year/change-registration-date`,
			data,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateAcademicYearSuccess());
		history.push("/admin/batchs");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearAcademicYearError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateAcademicYearFailure(errorMessage));
	}
}

export function* onUpdateAcademicYearsStart() {
	yield takeLatest(
		AcademicYearActionTypes.UPDATE_ACADEMIC_YEAR_START,
		updateAcademicYear
	);
}

export function* academicYearSagas() {
	yield all([
		call(onFetchAcademicYearsStart),
		call(onCreateAcademicYearsStart),
		call(onNonActivateAcademicYearsStart),
		call(onActivateAcademicYearsStart),
		call(onFetchAvailableBatchStart),
		call(onFetchAcademicYearStart),
		call(onUpdateAcademicYearsStart),
	]);
}
