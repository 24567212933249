import NotificationActionTypes from "./notification.types";

export const clearNotificationError = () => ({
	type: NotificationActionTypes.CLEAR_ERROR,
});

export const fetchUserNotificationsStart = () => ({
	type: NotificationActionTypes.FETCH_NOTIFICATIONS_START,
});

export const fetchUserNotificationsSuccess = (notifications) => ({
	type: NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
	payload: notifications,
});

export const fetchUserNotificationsFailure = (error) => ({
	type: NotificationActionTypes.FETCH_NOTIFICATIONS_FAILURE,
	payload: error,
});

export const readUserNotificationStart = (notificationId) => ({
	type: NotificationActionTypes.READ_NOTIFICATION_START,
	payload: notificationId,
});

export const readUserNotificationSuccess = () => ({
	type: NotificationActionTypes.READ_NOTIFICATION_SUCCESS,
});

export const readUserNotificationFailure = (error) => ({
	type: NotificationActionTypes.READ_NOTIFICATION_FAILURE,
	payload: error,
});
