import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	ordersContainer: {
		marginTop: "100px",
	},
	buttonOnTable: {
		margin: "10px",
	},
	selectCategoriesContainer: {
		minWidth: "200px",

		[theme.breakpoints.up("lg")]: {
			paddingLeft: "24px",
		},
	},
}));
