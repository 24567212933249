import axios from "axios";
import { getAttrFromString } from "helpers/App.helper";
import { baseUrlNonApi } from "helpers/App.helper";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearNewsError,
	createNewsFailure,
	createNewsSuccess,
	deleteNewsFailure,
	deleteNewsSuccess,
	fetchAllNewsFailure,
	fetchAllNewsStart,
	fetchAllNewsSuccess,
	fetchNewsFailure,
	fetchNewsSuccess,
	updateNewsFailure,
	updateNewsSuccess,
	uploadTempFileFailure,
	uploadTempFileSuccess,
} from "./news.action";
import NewsActionTypes from "./news.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchAllNews({ payload }) {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/category/1`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const news = response.data.news;
		yield put(fetchAllNewsSuccess(news));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllNewsFailure(errorMessage));
	}
}

export function* onFetchAllNewsStart() {
	yield takeLatest(NewsActionTypes.FETCH_ALL_NEWS_START, fetchAllNews);
}

export function* createNews({ payload }) {
	const { data, history } = payload;
	const formData = new FormData();
	formData.append("content_detail", data.contentDetailEN);
	formData.append("content_detail_indo", data.contentDetailID);
	formData.append("title", data.cardNewsTitleEN);
	formData.append("title_indo", data.cardNewsTitleID);
	formData.append("card_button_text", data.cardButtonTextEN);
	formData.append("card_button_text_indo", data.cardButtonTextID);
	formData.append("content_detail_button_text", data.contentButtonTextEN);
	formData.append(
		"content_detail_button_text_indo",
		data.contentButtonTextID
	);
	formData.append("category", 1); // FIXED NEWS CATEGORY
	formData.append(
		"photo_url",
		JSON.stringify(getAttrFromString(data.contentDetail, "img", "src"))
	);
	formData.append("header_url", data.contentHeaderImage[0]);
	formData.append("image_url", data.cardImage[0]);
	formData.append("link", data.contentLinkTo);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/news/store`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(createNewsSuccess());
		history.push("/admin/news");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createNewsFailure(errorMessage));
	}
}

export function* onCreateNewsStart() {
	yield takeLatest(NewsActionTypes.CREATE_NEWS_START, createNews);
}

export function* uploadTempFile({ payload }) {
	const { file, onSuccess, onFailure } = payload;

	const data = new FormData();
	data.append("file", file, file.name);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/news/temp`,
			data,
		});

		if (response.status !== 200 && response.status !== 201) {
			throw new Error(
				"Failed to upload Temp Image, please try again later."
			);
		}

		const location = response.data.location;

		onSuccess(`${baseUrlNonApi}${location}`);
		yield put(uploadTempFileSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		onFailure(`HTTP Error: ${errorMessage}`);
		yield put(openSnackbar(snackbarData));
		yield put(uploadTempFileFailure(errorMessage));
	}
}

export function* onUploadTempFileStart() {
	yield takeLatest(NewsActionTypes.UPLOAD_TEMP_FILE_START, uploadTempFile);
}

export function* fetchNews({ payload }) {
	const newsId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/id/${newsId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const news = response.data.news;
		yield put(fetchNewsSuccess(news[0]));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchNewsFailure(errorMessage));
	}
}

export function* onFetchNewsStart() {
	yield takeLatest(NewsActionTypes.FETCH_NEWS_START, fetchNews);
}

export function* updateNews({ payload }) {
	const { data, history } = payload;
	const formData = new FormData();
	formData.append("news_id", data.id);
	formData.append("content_detail", data.contentDetailEN);
	formData.append("content_detail_indo", data.contentDetailID);
	formData.append("title", data.cardNewsTitleEN);
	formData.append("title_indo", data.cardNewsTitleID);
	formData.append("card_button_text", data.cardButtonTextEN);
	formData.append("card_button_text_indo", data.cardButtonTextID);
	formData.append("content_detail_button_text", data.contentButtonTextEN);
	formData.append(
		"content_detail_button_text_indo",
		data.contentButtonTextID
	);
	formData.append("category", 1); // FIXED NEWS CATEGORY
	formData.append(
		"photo_url",
		JSON.stringify(getAttrFromString(data.contentDetail, "img", "src"))
	);
	formData.append("header_url", data.contentHeaderImage[0]);
	formData.append("image_url", data.cardImage[0]);
	formData.append("link", data.contentLinkTo);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/news/update`,
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateNewsSuccess());
		history.push("/admin/news");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateNewsFailure(errorMessage));
	}
}

export function* onUpdateNewsStart() {
	yield takeLatest(NewsActionTypes.UPDATE_NEWS_START, updateNews);
}

export function* deleteNews({ payload }) {
	const newsId = payload;

	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/news/destroy/${newsId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(deleteNewsSuccess());
		yield put(fetchAllNewsStart());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearNewsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteNewsFailure(errorMessage));
	}
}

export function* onDeleteNewsStart() {
	yield takeLatest(NewsActionTypes.DELETE_NEWS_START, deleteNews);
}

export function* newsSagas() {
	yield all([
		call(onFetchAllNewsStart),
		call(onCreateNewsStart),
		call(onUploadTempFileStart),
		call(onFetchNewsStart),
		call(onUpdateNewsStart),
		call(onDeleteNewsStart),
	]);
}
