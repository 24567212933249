const PlacementTestActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET ACTIVE CHILD ID
	SET_ACTIVE_CHILD_ID: "SET_ACTIVE_CHILD_ID",
	// SUBMIT PART ANSWER
	SUBMIT_PART_ANSWER: "SUBMIT_PART_ANSWER",
	// SET ACTIVE TYPE
	SET_ACTIVE_PART: "SET_ACTIVE_PART",
	// SET FINISH TIME
	SET_FINISH_TIME: "SET_FINISH_TIME",
	// SET ANSWER
	SET_ANSWER: "SET_ANSWER",
	// SET IS_BUTTON_NEXT_DISABLED
	SET_IS_BUTTON_NEXT_DISABLED: "SET_IS_BUTTON_NEXT_DISABLED",
	// HANDLE FINISH TEST
	HANDLE_FINISH_TEST_START: "HANDLE_FINISH_TEST_START",
	HANDLE_FINISH_TEST_SUCCESS: "HANDLE_FINISH_TEST_SUCCESS",
	HANDLE_FINISH_TEST_FAILURE: "HANDLE_FINISH_TEST_FAILURE",
	// HANDLE START TEST
	HANDLE_START_TEST_START: "HANDLE_START_TEST_START",
	HANDLE_START_TEST_SUCCESS: "HANDLE_START_TEST_SUCCESS",
	HANDLE_START_TEST_FAILURE: "HANDLE_START_TEST_FAILURE",
	// FETCH PLACEMENT TEST
	FETCH_PLACEMENT_TEST_START: "FETCH_PLACEMENT_TEST_START",
	FETCH_PLACEMENT_TEST_SUCCESS: "FETCH_PLACEMENT_TEST_SUCCESS",
	FETCH_PLACEMENT_TEST_FAILURE: "FETCH_PLACEMENT_TEST_FAILURE",
	// RESET PLACEMENT TEST
	RESET_PLACEMENT_TEST: "RESET_PLACEMENT_TEST",
};

export default PlacementTestActionTypes;
