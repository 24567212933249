/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { lazy } from "react";

// core components/views for Public layout
const AddChild = lazy(() => import("views/Public/AddChild/AddChild"));
const AddChildFinish = lazy(() =>
	import("views/Public/AddChildFinish/AddChildFinish")
);
const AllNews = lazy(() => import("views/Public/AllNews/AllNews"));
const ChildDetail = lazy(() => import("views/Public/ChildDetail/ChildDetail"));
const CourseVideo = lazy(() => import("views/Public/CourseVideo/CourseVideo"));
const FAQ = lazy(() => import("views/Public/FAQ/FAQ"));
const Homepage = lazy(() => import("views/Public/Homepage/Homepage"));
const LoginPublic = lazy(() => import("views/Public/LoginPublic/LoginPublic"));
const News = lazy(() => import("views/Public/News/News"));
const OurCourses = lazy(() => import("views/Public/OurCourses/OurCourses"));
const OurHistory = lazy(() => import("views/Public/OurHistory/OurHistory"));
const PlacementTest = lazy(() =>
	import("views/Public/PlacementTest/PlacementTest")
);
const PlacementTestFinish = lazy(() =>
	import("views/Public/PlacementTestFinish/PlacementTestFinish")
);
const PlacementTestInstruction = lazy(() =>
	import("views/Public/PlacementTestInstruction/PlacementTestInstruction")
);
const SignUp = lazy(() => import("views/Public/SignUp/SignUp"));
const StoryOfAdiwidya = lazy(() =>
	import("views/Public/StoryOfAdiwidya/StoryOfAdiwidya")
);
const StudentPerformance = lazy(() =>
	import("views/Public/StudentPerformance/StudentPerformance")
);
const UserProfile = lazy(() => import("views/Public/UserProfile/UserProfile"));
const FixedNews = lazy(() => import("views/Public/FixedNews/FixedNews"));
const WelcomePage = lazy(() => import("views/Public/WelcomePage/WelcomePage"));
const ActivateCallback = lazy(() =>
	import("views/Public/ActivateCallback/ActivateCallback")
);
const AlreadyActivatedCallback = lazy(() =>
	import("views/Public/AlreadyActivatedCallback/AlreadyActivatedCallback")
);
const ResetPasswordCallback = lazy(() =>
	import("views/Public/ResetPasswordCallback/ResetPasswordCallback")
);
const ForgotPassword = lazy(() =>
	import("views/Public/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
	import("views/Public/ResetPassword/ResetPassword")
);

const publicRoutes = [
	{
		path: "/",
		component: Homepage,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/login",
		component: LoginPublic,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: false,
	},
	{
		path: "/sign-up",
		component: SignUp,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: false,
	},
	{
		path: "/welcome",
		component: WelcomePage,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/activate-success",
		component: ActivateCallback,
		layout: "/",
		withFooter: false,
		withHeader: false,
		checkAuth: null,
	},
	{
		path: "/already-activated",
		component: AlreadyActivatedCallback,
		layout: "/",
		withFooter: false,
		withHeader: false,
		checkAuth: null,
	},
	{
		path: "/profile",
		component: UserProfile,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/profile/add-child",
		component: AddChild,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/profile/add-child-finish",
		component: AddChildFinish,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/news/teens-adult-class",
		component: FixedNews,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/news/:newsId",
		component: News,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/our-courses",
		component: OurCourses,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/student-performance",
		component: StudentPerformance,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/story-of-adiwidya",
		component: StoryOfAdiwidya,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/news",
		component: AllNews,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/children/:childId",
		component: ChildDetail,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/placement-test-instruction/:childId",
		component: PlacementTestInstruction,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/placement-test/:childId",
		component: PlacementTest,
		layout: "/",
		withFooter: false,
		withHeader: false,
		checkAuth: true,
	},
	{
		path: "/placement-test-finish/:childId",
		component: PlacementTestFinish,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/courses/:courseId/child/:childId",
		component: CourseVideo,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: true,
	},
	{
		path: "/frequently-asked-questions",
		component: FAQ,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/story-of-adiwidya/our-history",
		component: OurHistory,
		layout: "/",
		withFooter: true,
		withHeader: true,
		checkAuth: null,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: false,
	},
	{
		path: "/reset-password-callback/:token",
		component: ResetPasswordCallback,
		layout: "/",
		withFooter: false,
		withHeader: false,
		checkAuth: false,
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		layout: "/",
		withFooter: false,
		withHeader: true,
		checkAuth: false,
	},
];

export default publicRoutes;
