import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./HomepageCardRectangle.styles";

function HomepageCardRectangle(props) {
	const { title, linkText, linkTo, image, preview } = props;
	const classes = useStyles();
	return (
		<Grid
			container
			className={classes.homepageCardRectangle}
			alignContent="flex-start"
		>
			<Grid
				container
				item
				xs={7}
				justify="flex-start"
				className={classes.contentContainer}
			>
				<Grid item xs={12} className={classes.cardTitle}>
					{title}
				</Grid>
				<Grid item xs={12}>
					<a
						className={classes.link}
						href={linkTo}
						target={!!preview ? "_blank" : null}
					>
						{linkText}
					</a>
				</Grid>
			</Grid>
			<Grid item xs={5} className={classes.imageContainer}>
				<img src={image} alt={title} />
			</Grid>
		</Grid>
	);
}

export default HomepageCardRectangle;
