import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./LoadingBackdrop.styles";
import CircularProgressWithLabel from "../CircularProgressWithLabel/CircularProgressWithLabel";

export default function LoadingBackdrop(props) {
	const { open, color, backgroundColor, value } = props;
	const classes = useStyles({
		color,
		backgroundColor,
	});
	return (
		<Backdrop className={classes.backdrop} open={open}>
			{!!value ? (
				<CircularProgressWithLabel value={value} color="inherit" />
			) : (
				<CircularProgress color="inherit" />
			)}
		</Backdrop>
	);
}
