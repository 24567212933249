import { channel } from "@redux-saga/core";
import {
	all,
	call,
	put,
	select,
	take,
	takeLatest,
} from "@redux-saga/core/effects";
import axios from "axios";
import { paymentMethodTypes } from "helpers/App.helper";
import { paymentForTypes } from "helpers/App.helper";
import { selectBuyCourseAdminFee } from "redux/buy-course/buy-course.selector";
import { selectBuyCourseBatch } from "redux/buy-course/buy-course.selector";
import { selectBuyCoursePaymentInterval } from "redux/buy-course/buy-course.selector";
import { selectBuyCourseActiveChild } from "redux/buy-course/buy-course.selector";
import { selectBuyCourseCourier } from "redux/buy-course/buy-course.selector";
import { selectBuyCourseDeliveryFee } from "redux/buy-course/buy-course.selector";
import { selectChildGrade } from "redux/child/child.selector";
import { selectCourseExtensionActiveChild } from "redux/course-extension/course-extension.selector";
import { selectCourseExtensionAdminFee } from "redux/course-extension/course-extension.selector";
import { selectCourseExtensionActiveBilling } from "redux/course-extension/course-extension.selector";
import { selectNextGradeCourier } from "redux/next-grade/next-grade.selector";
import { selectNextGradeIsMovingToNextGrade } from "redux/next-grade/next-grade.selector";
import { selectNextGradeDeliveryFee } from "redux/next-grade/next-grade.selector";
import { selectNextGradePaymentInterval } from "redux/next-grade/next-grade.selector";
import { selectNextGradeActiveChild } from "redux/next-grade/next-grade.selector";
import { selectNextGradeAdminFee } from "redux/next-grade/next-grade.selector";
import { selectNextGradeBatch } from "redux/next-grade/next-grade.selector";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import { selectCurrentUser } from "redux/user/user.selector";
import {
	fetchPriceListFailure,
	fetchPriceListSuccess,
	clearPaymentError,
	storePaymentSuccess,
	storePaymentFailure,
	setPaymentSuccess,
	setCreditCardToken,
	storePaymentStart,
	setCreditCardRedirectUrl,
	setOpen3dsAuthentication,
	checkPaymentFailure,
	checkPaymentSuccess,
	setOrderId,
	sendPaymentNotificationStart,
	sendPaymentNotificationFailure,
	sendPaymentNotificationSuccess,
	retryPaymentStart,
	retryPaymentFailure,
	retryPaymentSuccess,
	fetchPaymentHistoriesFailure,
	fetchPaymentHistoriesSuccess,
} from "./payment.action";
import { selectPaymentFor } from "./payment.selector";
import { selectCreditCardToken } from "./payment.selector";
import PaymentActionTypes from "./payment.types";

const paymentChannel = channel();

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* watchPaymentChannel() {
	while (true) {
		const action = yield take(paymentChannel);
		yield put(action);
	}
}

export function* fetchPriceList() {
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/payment/price-list`,
			headers: {},
		});
		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const priceList = response.data.settings;
		yield put(
			fetchPriceListSuccess(
				priceList.map((price) => ({
					...price,
					value: JSON.parse(price.value),
				}))
			)
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPriceListFailure(error.message));
	}
}

export function* onFetchPriceListStart() {
	yield takeLatest(PaymentActionTypes.FETCH_PRICE_LIST_START, fetchPriceList);
}

function* handleQRISPayment() {
	const paymentFor = yield select(selectPaymentFor);
	let activeChildId;
	let response;

	switch (paymentFor) {
		case paymentForTypes.BUY_COURSE:
			activeChildId = yield select(selectBuyCourseActiveChild);
			const batchBuyCourse = yield select(selectBuyCourseBatch);
			const paymentIntervalBuyCourse = yield select(
				selectBuyCoursePaymentInterval
			);
			const deliveryFeeBuyCourse = yield select(
				selectBuyCourseDeliveryFee
			);
			const courierBuyCourse = yield select(selectBuyCourseCourier);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/first-payment`,
				data: {
					payment_type: "gopay",
					child_id: activeChildId,
					term: paymentIntervalBuyCourse.value.term,
					price_id: paymentIntervalBuyCourse.setting_id,
					grade: "2",
					academic_year_id: batchBuyCourse,
					delivery_amount: deliveryFeeBuyCourse,
					courier: courierBuyCourse,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.NEXT_GRADE:
			activeChildId = yield select(selectNextGradeActiveChild);
			const childGrade = yield select(selectChildGrade);
			const isMovingToNextGrade = yield select(
				selectNextGradeIsMovingToNextGrade
			);
			const nextGrade = isMovingToNextGrade ? childGrade + 1 : childGrade;
			const batchNextGrade = yield select(selectNextGradeBatch);
			const paymentIntervalNextGrade = yield select(
				selectNextGradePaymentInterval
			);
			const deliveryFeeNextGrade = yield select(
				selectNextGradeDeliveryFee
			);
			const courierNextGrade = yield select(selectNextGradeCourier);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-next-grade`,
				data: {
					payment_type: "gopay",
					child_id: activeChildId,
					term: paymentIntervalNextGrade.value.term,
					price_id: paymentIntervalNextGrade.setting_id,
					grade: nextGrade,
					academic_year_id: batchNextGrade,
					delivery_amount: deliveryFeeNextGrade,
					courier: courierNextGrade,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.COURSE_EXTENSION:
			const bill = yield select(selectCourseExtensionActiveBilling);
			activeChildId = yield select(selectCourseExtensionActiveChild);
			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-billing`,
				data: {
					payment_type: "gopay",
					child_id: activeChildId,
					billing_id: bill.billing_id,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		default:
			throw new Error("Undefined payment for type");
	}
	return !!response ? response.data : null;
}

function* handleVAMandiriPayment() {
	const paymentFor = yield select(selectPaymentFor);
	let activeChildId;
	let response;

	switch (paymentFor) {
		case paymentForTypes.BUY_COURSE:
			activeChildId = yield select(selectBuyCourseActiveChild);
			const batchBuyCourse = yield select(selectBuyCourseBatch);
			const paymentIntervalBuyCourse = yield select(
				selectBuyCoursePaymentInterval
			);
			const deliveryFeeBuyCourse = yield select(
				selectBuyCourseDeliveryFee
			);
			const courierBuyCourse = yield select(selectBuyCourseCourier);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/first-payment`,
				data: {
					payment_type: "echannel",
					child_id: activeChildId,
					term: paymentIntervalBuyCourse.value.term,
					price_id: paymentIntervalBuyCourse.setting_id,
					grade: "2",
					academic_year_id: batchBuyCourse,
					delivery_amount: deliveryFeeBuyCourse,
					courier: courierBuyCourse,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.NEXT_GRADE:
			activeChildId = yield select(selectNextGradeActiveChild);
			const childGrade = yield select(selectChildGrade);
			const isMovingToNextGrade = yield select(
				selectNextGradeIsMovingToNextGrade
			);
			const nextGrade = isMovingToNextGrade ? childGrade + 1 : childGrade;
			const batchNextGrade = yield select(selectNextGradeBatch);
			const paymentIntervalNextGrade = yield select(
				selectNextGradePaymentInterval
			);
			const deliveryFeeNextGrade = yield select(
				selectNextGradeDeliveryFee
			);
			const courierNextGrade = yield select(selectNextGradeCourier);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-next-grade`,
				data: {
					payment_type: "echannel",
					child_id: activeChildId,
					term: paymentIntervalNextGrade.value.term,
					price_id: paymentIntervalNextGrade.setting_id,
					grade: nextGrade,
					academic_year_id: batchNextGrade,
					delivery_amount: deliveryFeeNextGrade,
					courier: courierNextGrade,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.COURSE_EXTENSION:
			const bill = yield select(selectCourseExtensionActiveBilling);
			activeChildId = yield select(selectCourseExtensionActiveChild);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-billing`,
				data: {
					payment_type: "echannel",
					child_id: activeChildId,
					billing_id: bill.billing_id,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		default:
			throw new Error("Undefined payment for type");
	}
	return !!response ? response.data : null;
}

function* handleCreditCardPayment() {
	const paymentFor = yield select(selectPaymentFor);
	const creditCardToken = yield select(selectCreditCardToken);
	let activeChildId;
	let response;

	switch (paymentFor) {
		case paymentForTypes.BUY_COURSE:
			activeChildId = yield select(selectBuyCourseActiveChild);
			const batchBuyCourse = yield select(selectBuyCourseBatch);
			const paymentIntervalBuyCourse = yield select(
				selectBuyCoursePaymentInterval
			);
			const deliveryFeeBuyCourse = yield select(
				selectBuyCourseDeliveryFee
			);
			const courierBuyCourse = yield select(selectBuyCourseCourier);
			const adminFeeBuyCourse = yield select(selectBuyCourseAdminFee);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/first-payment`,
				data: {
					payment_type: "credit_card",
					child_id: activeChildId,
					term: paymentIntervalBuyCourse.value.term,
					token_id: creditCardToken,
					price_id: paymentIntervalBuyCourse.setting_id,
					grade: "2",
					academic_year_id: batchBuyCourse,
					delivery_amount: deliveryFeeBuyCourse,
					courier: courierBuyCourse,
					charge_amount: adminFeeBuyCourse,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.NEXT_GRADE:
			activeChildId = yield select(selectNextGradeActiveChild);
			const childGrade = yield select(selectChildGrade);
			const isMovingToNextGrade = yield select(
				selectNextGradeIsMovingToNextGrade
			);
			const nextGrade = isMovingToNextGrade ? childGrade + 1 : childGrade;
			const batchNextGrade = yield select(selectNextGradeBatch);
			const paymentIntervalNextGrade = yield select(
				selectNextGradePaymentInterval
			);
			const deliveryFeeNextGrade = yield select(
				selectNextGradeDeliveryFee
			);
			const courierNextGrade = yield select(selectNextGradeCourier);
			const adminFeeNextGrade = yield select(selectNextGradeAdminFee);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-next-grade`,
				data: {
					payment_type: "credit_card",
					child_id: activeChildId,
					term: paymentIntervalNextGrade.value.term,
					token_id: creditCardToken,
					price_id: paymentIntervalNextGrade.setting_id,
					grade: nextGrade,
					academic_year_id: batchNextGrade,
					delivery_amount: deliveryFeeNextGrade,
					courier: courierNextGrade,
					charge_amount: adminFeeNextGrade,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		case paymentForTypes.COURSE_EXTENSION:
			const bill = yield select(selectCourseExtensionActiveBilling);
			activeChildId = yield select(selectCourseExtensionActiveChild);
			const adminFeeCourseExtension = yield select(
				selectCourseExtensionAdminFee
			);

			response = yield axios({
				method: "POST",
				url: `${baseUrlApi}/payment/pay-billing`,
				data: {
					payment_type: "credit_card",
					child_id: activeChildId,
					token_id: creditCardToken,
					billing_id: bill.billing_id,
					charge_amount: adminFeeCourseExtension,
				},
			});

			if (response.data.code !== 200 && response.data.code !== 201) {
				throw new Error(response.data.message);
			}
			break;
		default:
			throw new Error("Undefined payment for type");
	}
	return !!response ? response.data : null;
}

export function* storePayment({ payload }) {
	const paymentMethod = payload;

	const deliveryFeeBuyCourse = yield select(selectBuyCourseDeliveryFee);
	const deliveryFeeNextGrade = yield select(selectNextGradeDeliveryFee);
	const paymentFor = yield select(selectPaymentFor);

	let usedDeliveryFee = 0;

	if (paymentFor === paymentForTypes.BUY_COURSE) {
		usedDeliveryFee = deliveryFeeBuyCourse || 0;
	}
	if (paymentFor === paymentForTypes.NEXT_GRADE) {
		usedDeliveryFee = deliveryFeeNextGrade || 0;
	}

	try {
		switch (paymentMethod) {
			case "QRIS":
				const successResponseQRIS = yield call(
					handleQRISPayment,
					handleQRISPayment()
				);
				const hasToRetryQRIS = successResponseQRIS.hasOwnProperty(
					"billing"
				);
				if (hasToRetryQRIS) {
					yield put(
						retryPaymentStart({
							paymentMethod: "QRIS",
							bill: successResponseQRIS.billing.find(
								(billing) => parseInt(billing.sequence) === 1
							),
							deliveryFee: usedDeliveryFee,
						})
					);
				} else {
					yield put(storePaymentSuccess(successResponseQRIS));
					yield put(setOrderId(successResponseQRIS.order_id));
				}
				break;
			case "VA Mandiri":
				const successResponseVAMandiri = yield call(
					handleVAMandiriPayment,
					handleVAMandiriPayment()
				);

				const hasToRetryVAMandiri = successResponseVAMandiri.hasOwnProperty(
					"billing"
				);
				if (hasToRetryVAMandiri) {
					yield put(
						retryPaymentStart({
							paymentMethod: "VA Mandiri",
							bill: successResponseVAMandiri.billing.find(
								(billing) => parseInt(billing.sequence) === 1
							),
							deliveryFee: usedDeliveryFee,
						})
					);
				} else {
					yield put(storePaymentSuccess(successResponseVAMandiri));
					yield put(setOrderId(successResponseVAMandiri.order_id));
				}

				break;
			case "Credit Card":
				const successResponseCreditCard = yield call(
					handleCreditCardPayment,
					handleCreditCardPayment()
				);

				switch (successResponseCreditCard.status_code) {
					case "200":
						yield put(
							storePaymentSuccess(successResponseCreditCard)
						);
						yield put(
							setOrderId(successResponseCreditCard.order_id)
						);

						break;
					case "201":
						yield put(
							setCreditCardRedirectUrl(
								successResponseCreditCard.redirect_url
							)
						);
						yield call(
							authenticateCreditCardMidtrans,
							authenticateCreditCardMidtrans(
								successResponseCreditCard.redirect_url
							)
						);
						break;
					default:
						if (successResponseCreditCard.code === 200) {
							yield put(
								retryPaymentStart({
									paymentMethod: "Credit Card",
									bill: successResponseCreditCard.billing.find(
										(billing) =>
											parseInt(billing.sequence) === 1
									),
									deliveryFee: usedDeliveryFee,
								})
							);
						} else {
							throw new Error(
								"Credit Card payment failed, please try again later"
							);
						}
				}
				break;
			default:
				throw new Error("Undefined payment method");
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(storePaymentFailure(error.message));
	}
}

export function* onStorePaymentStart() {
	yield takeLatest(PaymentActionTypes.STORE_PAYMENT_START, storePayment);
}

export function* retryPayment({ payload }) {
	const { paymentMethod, bill, deliveryFee } = payload;
	const paymentFor = yield select(selectPaymentFor);
	const activeChildId = yield select(selectBuyCourseActiveChild);

	let usedDeliveryFee = 0;

	if (
		paymentFor === paymentForTypes.NEXT_GRADE ||
		paymentFor === paymentForTypes.BUY_COURSE
	) {
		usedDeliveryFee = deliveryFee || 0;
	}

	try {
		switch (paymentMethod) {
			case "QRIS":
				const responseQRIS = yield axios({
					method: "POST",
					url: `${baseUrlApi}/payment/pay-billing`,
					data: {
						payment_type: "gopay",
						child_id: activeChildId,
						billing_id: bill.billing_id,
						charge_amount: usedDeliveryFee,
					},
				});

				if (
					responseQRIS.data.code !== 200 &&
					responseQRIS.data.code !== 201
				) {
					throw new Error(responseQRIS.data.message);
				}

				const successResponseQRIS = responseQRIS.data;

				yield put(storePaymentSuccess(successResponseQRIS));
				yield put(setOrderId(successResponseQRIS.order_id));
				break;
			case "VA Mandiri":
				const responseVAMandiri = yield axios({
					method: "POST",
					url: `${baseUrlApi}/payment/pay-billing`,
					data: {
						payment_type: "echannel",
						child_id: activeChildId,
						billing_id: bill.billing_id,
						charge_amount: usedDeliveryFee,
					},
				});

				if (
					responseVAMandiri.data.code !== 200 &&
					responseVAMandiri.data.code !== 201
				) {
					throw new Error(responseVAMandiri.data.message);
				}

				const successResponseVAMandiri = responseVAMandiri.data;

				yield put(storePaymentSuccess(successResponseVAMandiri));
				yield put(setOrderId(successResponseVAMandiri.order_id));
				break;
			case "Credit Card":
				const creditCardToken = yield select(selectCreditCardToken);
				const adminFeeBuyCourse = yield select(selectBuyCourseAdminFee);

				const responseCreditCard = yield axios({
					method: "POST",
					url: `${baseUrlApi}/payment/pay-billing`,
					data: {
						payment_type: "credit_card",
						child_id: activeChildId,
						token_id: creditCardToken,
						billing_id: bill.billing_id,
						charge_amount: adminFeeBuyCourse + usedDeliveryFee,
					},
				});

				if (
					responseCreditCard.data.code !== 200 &&
					responseCreditCard.data.code !== 201
				) {
					throw new Error(responseCreditCard.data.message);
				}

				const successResponseCreditCard = responseCreditCard.data;

				switch (successResponseCreditCard.status_code) {
					case "200":
						yield put(
							retryPaymentSuccess(successResponseCreditCard)
						);
						yield put(
							setOrderId(successResponseCreditCard.order_id)
						);

						break;
					case "201":
						yield put(
							setCreditCardRedirectUrl(
								successResponseCreditCard.redirect_url
							)
						);
						yield call(
							authenticateCreditCardMidtrans,
							authenticateCreditCardMidtrans(
								successResponseCreditCard.redirect_url
							)
						);
						break;
					default:
						throw new Error(
							"Credit Card payment failed, please try again later"
						);
				}
				break;
			default:
				throw new Error("Undefined payment method");
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(retryPaymentFailure(error.message));
	}
}

export function* onRetryPaymentStart() {
	yield takeLatest(PaymentActionTypes.RETRY_PAYMENT_START, retryPayment);
}

export function* getMidtransCardToken({ payload }) {
	const creditCardData = payload;
	try {
		const expiredDateSplitted = creditCardData.expiryDate.split("/");
		const cardData = {
			card_number: creditCardData.creditCardNumber.replace(/-/g, ""),
			card_exp_month: expiredDateSplitted[0],
			card_exp_year: expiredDateSplitted[1],
			card_cvv: creditCardData.cvv,
		};

		// callback functions
		var options = {
			onSuccess: function (response) {
				// Success to get card token_id, implement as you wish here
				var tokenId = response.token_id;
				paymentChannel.put(setCreditCardToken(tokenId));
				paymentChannel.put(storePaymentStart("Credit Card"));
				// Implement sending the token_id to backend to proceed to next step
			},
			onFailure: function (response) {
				// Fail to get card token_id, implement as you wish here
				console.log("Get Token Error", response);
				const snackbarData = {
					message: "Invalid Credit Card",
					color: "error",
					place: "bl",
					dispatchActions: [clearPaymentError],
				};
				paymentChannel.put(openSnackbar(snackbarData));
				// you may want to implement displaying failure message to customer.
				// Also record the error message to your log, so you can review
				// what causing failure on this transaction.
			},
		};

		// trigger `getCardToken` function
		window.MidtransNew3ds.getCardToken(cardData, options);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(storePaymentFailure(error.message));
	}
}

export function* onSetCreditCard() {
	yield takeLatest(PaymentActionTypes.SET_CREDIT_CARD, getMidtransCardToken);
}

export function* sendPaymentNotification({ payload }) {
	const creditCardResponse = payload;
	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/payment/notification`,
			data: creditCardResponse,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(sendPaymentNotificationSuccess());
		yield put(setOrderId(creditCardResponse.order_id));
		yield put(storePaymentSuccess(creditCardResponse));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(sendPaymentNotificationFailure(error.message));
	}
}

export function* onSendPaymentNotification() {
	yield takeLatest(
		PaymentActionTypes.SEND_PAYMENT_NOTIFICATION_START,
		sendPaymentNotification
	);
}

export function* authenticateCreditCardMidtrans(redirectUrl) {
	var redirect_url = redirectUrl;

	// callback functions
	var options = {
		performAuthentication: function (redirect_url) {
			// Implement how you will open iframe to display 3ds authentication redirect_url to customer
			paymentChannel.put(setOpen3dsAuthentication(true));
		},
		onSuccess: function (response) {
			// 3ds authentication success, implement payment success scenario
			paymentChannel.put(setOpen3dsAuthentication(false));
			// paymentChannel.put(sendPaymentNotificationStart(response));

			paymentChannel.put(setOrderId(response.order_id));
			paymentChannel.put(storePaymentSuccess(response));
		},
		onFailure: function (response) {
			// 3ds authentication failure, implement payment failure scenario
			const snackbarData = {
				message: `Credit Card Payment failed, ${response.status_message}`,
				color: "error",
				place: "bl",
				dispatchActions: [clearPaymentError],
			};
			paymentChannel.put(openSnackbar(snackbarData));
			paymentChannel.put(setOpen3dsAuthentication(false));
			paymentChannel.put(
				storePaymentFailure(
					`Credit Card Payment failed, ${response.status_message}`
				)
			);
		},
		onPending: function (response) {
			// transaction is pending, transaction result will be notified later via
			// HTTP POST notification, implement as you wish here
			paymentChannel.put(setOpen3dsAuthentication(false));
			paymentChannel.put(storePaymentSuccess(response));
			paymentChannel.put(setOrderId(response.order_id));
		},
	};

	// trigger `authenticate` function
	window.MidtransNew3ds.authenticate(redirect_url, options);
}

export function* checkPayment({ payload }) {
	const orderId = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/payment/status/${orderId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		yield put(
			checkPaymentSuccess({
				paymentStatus: response.data.payment_status,
				isAutoGrade: response.data.is_auto,
			})
		);
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(checkPaymentFailure(error.message));
	}
}

export function* onPaymentCheckStart() {
	yield takeLatest(PaymentActionTypes.CHECK_PAYMENT_START, checkPayment);
}

export function* fetchPaymentHistories() {
	const currentUser = yield select(selectCurrentUser);
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/billing/childs/${currentUser.user_id}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}

		const payments = response.data.payment;
		if (!!payments) {
			const paymentHistories = payments.map((payment) => ({
				...payment,
				request: JSON.parse(payment.request),
				response: JSON.parse(payment.response),
				actions: !!payment.actions ? JSON.parse(payment.actions) : null,
				paymentTypeName: paymentMethodTypes[payment.payment_type],
				paymentName: `${payment.get_billing.billing_desc} ${payment.get_child.first_name} ${payment.get_child.last_name}`,
			}));
			yield put(fetchPaymentHistoriesSuccess(paymentHistories));
		} else {
			yield put(fetchPaymentHistoriesSuccess([]));
		}
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPaymentError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPaymentHistoriesFailure(error.message));
	}
}

export function* onFetchPaymentHistoriesStart() {
	yield takeLatest(
		PaymentActionTypes.FETCH_PAYMENT_HISTORIES_START,
		fetchPaymentHistories
	);
}

export function* paymentSagas() {
	yield all([
		call(onFetchPriceListStart),
		call(onStorePaymentStart),
		call(onSetCreditCard),
		call(watchPaymentChannel),
		call(onPaymentCheckStart),
		call(onSendPaymentNotification),
		call(onRetryPaymentStart),
		call(onFetchPaymentHistoriesStart),
	]);
}
