import { Grid, TextField } from "@material-ui/core";
import PillButton from "components/Public/PillButton/PillButton";
import CustomModal from "components/Shared/CustomModal/CustomModal";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { couriers } from "helpers/App.helper";
import { orderStatuses } from "helpers/IndexOrder.helper";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { updateOrderStart } from "redux/order/order.action";
import { selectOrderLoading } from "redux/order/order.selector";
import { createStructuredSelector } from "reselect";
import { useStyles } from "./UpdateOrderModal.styles";

const UpdateOrderModal = (props) => {
	const classes = useStyles();
	const {
		orderData,
		open,
		onClose,
		isOrderLoading,
		updateOrderStart,
	} = props;

	const {
		register,
		handleSubmit,
		errors,
		control,
		reset,
		setValue,
	} = useForm({
		shouldUnregister: false,
	});

	const handleClose = () => {
		onClose();
		reset({
			status: "",
			resi: "",
		});
	};

	const updateOrder = (data) => {
		updateOrderStart({ ...data, orderId: orderData.order_id });
		handleClose();
	};

	useEffect(() => {
		if (!!orderData) {
			setValue("status", orderData.delivery_status.toLowerCase());
			setValue("resi", orderData.resi);
			setValue("courier", orderData.courier);
		}
	}, [orderData]);

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			width="40vw"
			title="Update Order"
		>
			<Grid container>
				<form onSubmit={handleSubmit(updateOrder)}>
					<Grid
						container
						justify="center"
						alignContent="center"
						spacing={3}
					>
						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="courier"
								label="Courier"
								name="courier"
								options={couriers}
								control={control}
								rules={{
									required: {
										value: true,
										message: `Courier is required`,
									},
								}}
								error={!!errors.courier}
								helperText={
									errors.courier && errors.courier.message
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="resi"
								label="Resi"
								name="resi"
								inputRef={register()}
							/>
						</Grid>
						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								id="status"
								label="Order Status"
								name="status"
								options={orderStatuses}
								control={control}
								rules={{
									required: {
										value: true,
										message: "Order Status are required",
									},
								}}
								error={!!errors.status}
								helperText={
									errors.status && errors.status.message
								}
							/>
						</Grid>
						<Grid item xs={12} className={classes.buttonContainer}>
							<PillButton
								element="button"
								type="submit"
								text="UPDATE"
								backgroundColor="#2E7D32"
								color="white"
								additionalClasses={[classes.pillButton]}
							/>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</CustomModal>
	);
};

const mapStateToProps = createStructuredSelector({
	isOrderLoading: selectOrderLoading,
});

const mapDispatchToProps = (dispatch) => ({
	updateOrderStart: (orderInfo) => dispatch(updateOrderStart(orderInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderModal);
