import ChildActionTypes from "./child.types";

export const fetchChildStart = (childId) => ({
	type: ChildActionTypes.FETCH_CHILD_START,
	payload: childId,
});

export const fetchChildSuccess = (child) => ({
	type: ChildActionTypes.FETCH_CHILD_SUCCESS,
	payload: child,
});

export const fetchChildFailure = (error) => ({
	type: ChildActionTypes.FETCH_CHILD_FAILURE,
	payload: error,
});

export const addChildStart = (childInfo, history) => ({
	type: ChildActionTypes.ADD_CHILD_START,
	payload: { childInfo, history },
});

export const addChildSuccess = () => ({
	type: ChildActionTypes.ADD_CHILD_SUCCESS,
});

export const addChildFailure = (error) => ({
	type: ChildActionTypes.ADD_CHILD_FAILURE,
	payload: error,
});

export const resetChild = () => ({
	type: ChildActionTypes.RESET_CHILD,
});

export const clearChildError = () => ({
	type: ChildActionTypes.CLEAR_ERROR,
});

export const uploadChildPictureStart = (file) => ({
	type: ChildActionTypes.UPLOAD_CHILD_PICTURE_START,
	payload: file,
});

export const uploadChildPictureSuccess = () => ({
	type: ChildActionTypes.UPLOAD_CHILD_PICTURE_SUCCESS,
});

export const uploadChildPictureFailure = (error) => ({
	type: ChildActionTypes.UPLOAD_CHILD_PICTURE_FAILURE,
	payload: error,
});

export const updateChildStart = (childInfo) => ({
	type: ChildActionTypes.UPDATE_CHILD_START,
	payload: childInfo,
});

export const updateChildSuccess = () => ({
	type: ChildActionTypes.UPDATE_CHILD_SUCCESS,
});

export const updateChildFailure = (error) => ({
	type: ChildActionTypes.UPDATE_CHILD_FAILURE,
	payload: error,
});

export const fetchChildBillingProgressStart = (child) => ({
	type: ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_START,
	payload: child,
});

export const fetchChildBillingProgressSuccess = (billingProgress) => ({
	type: ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_SUCCESS,
	payload: billingProgress,
});

export const fetchChildBillingProgressFailure = (error) => ({
	type: ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_FAILURE,
	payload: error,
});

export const fetchChildByAcademicYearStart = (academicYearId) => ({
	type: ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_START,
	payload: academicYearId,
});

export const fetchChildByAcademicYearSuccess = (childs) => ({
	type: ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_SUCCESS,
	payload: childs,
});

export const fetchChildByAcademicYearFailure = (error) => ({
	type: ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_FAILURE,
	payload: error,
});

export const updateChildGradeStart = (childData) => ({
	type: ChildActionTypes.UPDATE_CHILD_GRADE_START,
	payload: childData,
});

export const updateChildGradeSuccess = () => ({
	type: ChildActionTypes.UPDATE_CHILD_GRADE_SUCCESS,
});

export const updateChildGradeFailure = (error) => ({
	type: ChildActionTypes.UPDATE_CHILD_GRADE_FAILURE,
	payload: error,
});

export const fetchChildCoursesStart = (childId) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSES_START,
	payload: childId,
});

export const fetchChildCoursesSuccess = (childClasses) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSES_SUCCESS,
	payload: childClasses,
});

export const fetchChildCoursesFailure = (error) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSES_FAILURE,
	payload: error,
});

export const fetchChildCourseStart = (childCourseId) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSE_START,
	payload: childCourseId,
});

export const fetchChildCourseSuccess = (course) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSE_SUCCESS,
	payload: course,
});

export const fetchChildCourseFailure = (error) => ({
	type: ChildActionTypes.FETCH_CHILD_COURSE_FAILURE,
	payload: error,
});

export const setChildActiveBilling = (activeBilling) => ({
	type: ChildActionTypes.SET_CHILD_ACTIVE_BILLING,
	payload: activeBilling,
});

export const childCourseMarkWatchVideo = (childCourseId) => ({
	type: ChildActionTypes.MARK_WATCH_VIDEO,
	payload: childCourseId,
});