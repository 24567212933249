import CourseMasterActionTypes from "./course-master.types";

// Fetch all
export const fetchCourseMastersStart = (filters) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTERS_START,
	payload: filters,
});

export const fetchCourseMastersSuccess = (testimonies) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTERS_SUCCESS,
	payload: testimonies,
});

export const fetchCourseMastersFailure = (error) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTERS_FAILURE,
	payload: error,
});

// Fetch One
export const fetchCourseMasterStart = (courseMasterId) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTER_START,
	payload: courseMasterId,
});

export const fetchCourseMasterSuccess = (courseMaster) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTER_SUCCESS,
	payload: courseMaster,
});

export const fetchCourseMasterFailure = (error) => ({
	type: CourseMasterActionTypes.FETCH_COURSE_MASTER_FAILURE,
	payload: error,
});

// Create
export const createCourseMasterStart = ({ courseMasterData, history }) => ({
	type: CourseMasterActionTypes.CREATE_COURSE_MASTER_START,
	payload: { courseMasterData, history },
});

export const createCourseMasterSuccess = () => ({
	type: CourseMasterActionTypes.CREATE_COURSE_MASTER_SUCCESS,
});

export const createCourseMasterFailure = (error) => ({
	type: CourseMasterActionTypes.CREATE_COURSE_MASTER_FAILURE,
	payload: error,
});

// Update
export const updateCourseMasterStart = ({ courseMasterData, history }) => ({
	type: CourseMasterActionTypes.UPDATE_COURSE_MASTER_START,
	payload: { courseMasterData, history },
});

export const updateCourseMasterSuccess = () => ({
	type: CourseMasterActionTypes.UPDATE_COURSE_MASTER_SUCCESS,
});

export const updateCourseMasterFailure = (error) => ({
	type: CourseMasterActionTypes.UPDATE_COURSE_MASTER_FAILURE,
	payload: error,
});

// Delete
export const deleteCourseMasterStart = (dataId) => ({
	type: CourseMasterActionTypes.DELETE_COURSE_MASTER_START,
	payload: dataId,
});

export const deleteCourseMasterSuccess = () => ({
	type: CourseMasterActionTypes.DELETE_COURSE_MASTER_SUCCESS,
});

export const deleteCourseMasterFailure = (error) => ({
	type: CourseMasterActionTypes.DELETE_COURSE_MASTER_FAILURE,
	payload: error,
});

// Swap
export const swapCourseMasterStart = ({ first, second }) => ({
	type: CourseMasterActionTypes.SWAP_COURSE_MASTER_START,
	payload: { first, second },
});

export const swapCourseMasterSuccess = () => ({
	type: CourseMasterActionTypes.SWAP_COURSE_MASTER_SUCCESS,
});

export const swapCourseMasterFailure = (error) => ({
	type: CourseMasterActionTypes.SWAP_COURSE_MASTER_FAILURE,
	payload: error,
});

export const clearCourseMasterError = () => ({
	type: CourseMasterActionTypes.CLEAR_ERROR,
});

export const clearCourseMasterData = () => ({
	type: CourseMasterActionTypes.CLEAR_DATA,
});

export const setCourseMasterUploadProgress = (progress) => ({
	type: CourseMasterActionTypes.SET_COURSE_MASTER_UPLOAD_PROGRESS,
	payload: progress,
});

export const setCourseMasterFilter = (filter) => ({
	type: CourseMasterActionTypes.SET_COURSE_MASTER_FILTER,
	payload: filter,
});
