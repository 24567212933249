const UserActionTypes = {
	CHECK_USER_SESSION: "CHECK_USER_SESSION",
	CLEAR_ERROR: "CLEAR_ERROR",
	// SIGN IN
	EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
	SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
	SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
	// SIGN OUT
	SIGN_OUT_START: "SIGN_OUT_START",
	SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
	SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
	// SIGN UP
	SIGN_UP_START: "SIGN_UP_START",
	SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
	SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
	// Fetch USER
	FETCH_USER_START: "FETCH_USER_START",
	FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
	FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
	// Upload User Picture
	UPLOAD_USER_PICTURE_START: "UPLOAD_USER_PICTURE_START",
	UPLOAD_USER_PICTURE_SUCCESS: "UPLOAD_USER_PICTURE_SUCCESS",
	UPLOAD_USER_PICTURE_FAILURE: "UPLOAD_USER_PICTURE_FAILURE",
	// UPDATE USER
	UPDATE_USER_START: "UPDATE_USER_START",
	UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
	UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
	// UPDATE USER_LANGUAGE
	UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",
	// SET_USER_PAGE_ACTIVE_TAB
	SET_USER_PAGE_ACTIVE_TAB: "SET_USER_PAGE_ACTIVE_TAB",
	// SET_USER_PAGE_ACTIVE_PAYMENT
	SET_USER_PAGE_ACTIVE_PAYMENT: "SET_USER_PAGE_ACTIVE_PAYMENT",
	// UPDATE_LOCALE
	UPDATE_LOCALE: "UPDATE_LOCALE",
	// Update Password
	UPDATE_USER_PASSWORD_START: "UPDATE_USER_PASSWORD_START",
	UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
	UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",
	// Forgot Password
	FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
	FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
	FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
	// RESET Password
	RESET_PASSWORD_START: "RESET_PASSWORD_START",
	RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

export default UserActionTypes;
