import courseExtensionActionTypes from "./course-extension.types";

const INITIAL_STATE = {
	error: null,
	loading: false,
	activeStep: 0,
	isDrawerOpen: false,
	hideBillFooter: false,
	activeChildId: null,
	activeBilling: null,
	nextStepFunction: null,
	billDetails: [],
	// payment method step
	paymentMethod: null,
	showPaymentDetails: false,
	adminFeePercentage: 0,
};

const courseExtensionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case courseExtensionActionTypes.OPEN_COURSE_EXTENSION_DRAWER:
			return {
				...state,
				isDrawerOpen: true,
			};
		case courseExtensionActionTypes.CLOSE_COURSE_EXTENSION_DRAWER:
			return {
				...state,
				isDrawerOpen: false,
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_ACTIVE_CHILD_ID:
			return {
				...state,
				activeChildId: action.payload,
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_ACTIVE_BILLING:
			const bill = action.payload;
			const tempBill = {
				nameEN: bill.billing_desc,
				nameID: bill.billing_desc,
				descriptionEN: `${bill.billing_desc} period ${bill.billing_period}`,
				descriptionID: `${bill.billing_desc} periode ${bill.billing_period}`,
				// name: `${action.payload} months course`,
				discount: 0,
				discountTextEN: "",
				discountTextID: "",
				value: parseInt(bill.amount),
				additionalInfoEN: ``,
				additionalInfoID: ``,
			};
			return {
				...state,
				activeBilling: action.payload,
				billDetails: [tempBill],
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_PAYMENT_METHOD:
			const paymentMethod = action.payload;

			return {
				...state,
				paymentMethod,
				adminFeePercentage: paymentMethod === "Credit Card" ? 0.03 : 0,
			};
		case courseExtensionActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case courseExtensionActionTypes.RESET_COURSE_EXTENSION:
			return {
				...state,
				error: null,
				loading: false,
				activeStep: 0,
				hideBillFooter: false,
				isDrawerOpen: false,
				activeChildId: null,
				activeBilling: null,
				paymentMethod: null,
				showPaymentDetails: false,
				nextStepFunction: null,
				billDetails: [],
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_ACTIVE_STEP:
			return {
				...state,
				activeStep: action.payload,
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_NEXT_STEP_FUNCTION:
			return {
				...state,
				nextStepFunction: action.payload,
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_SHOW_PAYMENT_DETAILS:
			return {
				...state,
				showPaymentDetails: action.payload,
			};
		case courseExtensionActionTypes.SET_COURSE_EXTENSION_HIDE_BILL_FOOTER:
			return {
				...state,
				hideBillFooter: action.payload,
			};
		default:
			return state;
	}
};

export default courseExtensionReducer;
