import BuyCourseActionTypes from "./buy-course.types";

export const setActiveChildId = (childId) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_ACTIVE_CHILD_ID,
	payload: childId,
});

export const setPaymentInterval = (paymentInterval) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL,
	payload: paymentInterval,
});

export const setPaymentMethod = (paymentMethod) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_METHOD,
	payload: paymentMethod,
});

export const setDeliveryCost = (cost) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_DELIVERY_COST,
	payload: cost,
});

export const resetBuyCourse = () => ({
	type: BuyCourseActionTypes.RESET_BUY_COURSE,
});

export const openBuyCourseDrawer = () => ({
	type: BuyCourseActionTypes.OPEN_BUY_COURSE_DRAWER,
});

export const closeBuyCourseDrawer = () => ({
	type: BuyCourseActionTypes.CLOSE_BUY_COURSE_DRAWER,
});

export const setActiveStep = (activeStep) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_ACTIVE_STEP,
	payload: activeStep,
});

export const setNextStepFunction = (fn) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_NEXT_STEP_FUNCTION,
	payload: fn,
});

export const setShowPaymentDetails = (isShowPaymentDetails) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_SHOW_PAYMENT_DETAILS,
	payload: isShowPaymentDetails,
});

export const setHideBillFooter = (isHiddenBillFooter) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_HIDE_BILL_FOOTER,
	payload: isHiddenBillFooter,
});

export const setBuyCourseBatch = (batch) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_BATCH,
	payload: batch,
});

export const setBuyCourseCourier = (courier) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_COURIER,
	payload: courier,
});

export const clearBuyCourseError = () => ({
	type: BuyCourseActionTypes.CLEAR_ERROR,
});

export const setBuyCourseFixedPriceList = (priceList) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_FIXED_PRICE_LIST,
	payload: priceList,
});

export const setBuyCoursePaymentIntervalDetail = (termOpened) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL_DETAIL,
	payload: termOpened,
});

export const setBuyCoursePaymentIntervalCurrentSlide = (currentSlide) => ({
	type: BuyCourseActionTypes.SET_BUY_COURSE_PAYMENT_INTERVAL_CURRENT_SLIDE,
	payload: currentSlide,
});
