import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	navLists: {
		listStyleType: "none",
		margin: "0",
		padding: "0",
		display: "flex",
		justifyContent: "center",
		listStyle: "none",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
	},
	navigation: {
		fontSize: "16px",
		fontWeight: "700",
		textAlign: "center",
		letterSpacing: "0em",
		paddingTop: "50px",
	},
	navigationContainer: {
		paddingTop: "50px",
		[theme.breakpoints.down("md")]: {
			paddingTop: "30px",
		},
	},
	authContainer: {
		marginTop: "50px",
		[theme.breakpoints.down("md")]: {
			marginTop: "15px",
		},
	},
	languageContainer: {
		paddingTop: "50px",
		position: "relative",
		[theme.breakpoints.down("md")]: {
			paddingTop: "70px",
		},
	},
	language: {
		fontSize: "16px",
		display: "grid",
		gridAutoFlow: "column",
		gridColumnGap: "5px",
		marginLeft: "12px",

		"&.active": {
			textDecoration: "underline",
			fontWeight: "bold",
		},
	},
	languageArrowIcon: {
		transition: "all 0.7s cubic-bezier(0.19, 1, 0.22, 1)",

		"&.active": {
			transform: "rotate(180deg)",
		},
	},
	auth: {
		fontSize: "16px",
		textDecoration: "underline",
	},
	userIcon: {
		marginLeft: "12px",
	},
	userName: {
		fontSize: "16px",
		lineHeight: "24px",
	},
	userThumbnail: {
		maxWidth: "28px",
		maxHeight: "28px",
		borderRadius: "50%",
		marginLeft: "12px",
	},
	divider: {
		marginLeft: "24px",
		marginRight: "24px",
		backgroundColor: "black",
	},
	languageSpan: {
		display: "flex",
	},
}));
