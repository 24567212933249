const RajaOngkirActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// FETCH PROVINCES
	FETCH_PROVINCES_START: "FETCH_PROVINCES_START",
	FETCH_PROVINCES_SUCCESS: "FETCH_PROVINCES_SUCCESS",
	FETCH_PROVINCES_FAILURE: "FETCH_PROVINCES_FAILURE",
	// FETCH CITIES BY PROVINCE ID
	FETCH_CITIES_BY_PROVINCE_START: "FETCH_CITIES_BY_PROVINCE_START",
	FETCH_CITIES_BY_PROVINCE_SUCCESS: "FETCH_CITIES_BY_PROVINCE_SUCCESS",
	FETCH_CITIES_BY_PROVINCE_FAILURE: "FETCH_CITIES_BY_PROVINCE_FAILURE",
	// CALCULATE COST
	CALCULATE_COST_START: "CALCULATE_COST_START",
	CALCULATE_COST_SUCCESS: "CALCULATE_COST_SUCCESS",
	CALCULATE_COST_FAILURE: "CALCULATE_COST_FAILURE",
};

export default RajaOngkirActionTypes;
