import ChildActionTypes from "./child.types";

const INITIAL_STATE = {
	selectedChild: null,
	error: null,
	loading: false,
	billingProgress: [],
	plural: [],

	// courses
	courseLoading: false,
	courses: [],
	course: null,
	nextCourse: null,
	activeBilling: null,
};

const childReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChildActionTypes.FETCH_CHILD_START:
		case ChildActionTypes.ADD_CHILD_START:
		case ChildActionTypes.UPLOAD_CHILD_PICTURE_START:
		case ChildActionTypes.UPDATE_CHILD_START:
		case ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_START:
		case ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_START:
		case ChildActionTypes.UPDATE_CHILD_GRADE_START:
			return {
				...state,
				loading: true,
			};
		case ChildActionTypes.FETCH_CHILD_COURSES_START:
		case ChildActionTypes.FETCH_CHILD_COURSE_START:
			return {
				...state,
				courseLoading: true,
			};
		case ChildActionTypes.FETCH_CHILD_FAILURE:
		case ChildActionTypes.ADD_CHILD_FAILURE:
		case ChildActionTypes.UPLOAD_CHILD_PICTURE_FAILURE:
		case ChildActionTypes.UPDATE_CHILD_FAILURE:
		case ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_FAILURE:
		case ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_FAILURE:
		case ChildActionTypes.UPDATE_CHILD_GRADE_FAILURE:
			return {
				...state,
				error: action.payload,
				courseLoading: false,
			};
		case ChildActionTypes.FETCH_CHILD_COURSES_FAILURE:
		case ChildActionTypes.FETCH_CHILD_COURSE_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ChildActionTypes.FETCH_CHILD_SUCCESS:
			return {
				...state,
				selectedChild: action.payload,
				loading: false,
			};
		case ChildActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ChildActionTypes.ADD_CHILD_SUCCESS:
		case ChildActionTypes.UPLOAD_CHILD_PICTURE_SUCCESS:
		case ChildActionTypes.UPDATE_CHILD_GRADE_SUCCESS:
		case ChildActionTypes.UPDATE_CHILD_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case ChildActionTypes.RESET_CHILD:
			return {
				...state,
				selectedChild: null,
				error: null,
				loading: false,
			};
		case ChildActionTypes.FETCH_CHILD_BILLING_PROGRESS_SUCCESS:
			return {
				...state,
				loading: false,
				billingProgress: action.payload,
			};
		case ChildActionTypes.FETCH_CHILD_BY_ACADEMIC_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				plural: action.payload,
			};
		case ChildActionTypes.FETCH_CHILD_COURSES_SUCCESS:
			return {
				...state,
				courseLoading: false,
				courses: action.payload,
			};
		case ChildActionTypes.FETCH_CHILD_COURSE_SUCCESS:
			return {
				...state,
				courseLoading: false,
				course: action.payload.currentCourse,
				nextCourse: action.payload.nextCourse,
			};
		case ChildActionTypes.SET_CHILD_ACTIVE_BILLING:
			return {
				...state,
				activeBilling: action.payload,
			};
		default:
			return state;
	}
};

export default childReducer;
