import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { openSnackbar } from "redux/snackbar/snackbar.action";
import {
	clearTestimonyError,
	createTestimonyFailure,
	createTestimonySuccess,
	deleteTestimonyFailure,
	deleteTestimonySuccess,
	fetchAllTestimoniesFailure,
	fetchAllTestimoniesStart,
	fetchAllTestimoniesSuccess,
	getTestimonyFailure,
	getTestimonySuccess,
	updateTestimonyFailure,
	updateTestimonySuccess,
} from "./testimony.action";
import TestimonyActionTypes from "./testimony.types";

let baseUrlApi;
if (process.env.NODE_ENV === "development") {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API_DEV;
} else {
	baseUrlApi = process.env.REACT_APP_BASE_URL_API;
}

export function* fetchTestimonies({ payload }) {
	const { category } = payload;
	let url = `${baseUrlApi}/testimony/getAll`;
	if (category) {
		url = `${baseUrlApi}/testimony/category/${category}`;
	}
	try {
		const response = yield axios({
			method: "GET",
			url: `${url}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const testimonials = response.data.testimonials;

		yield put(fetchAllTestimoniesSuccess(testimonials));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTestimonyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchAllTestimoniesFailure(errorMessage));
	}
}

export function* onFetchTestimoniesStart() {
	yield takeLatest(
		TestimonyActionTypes.FETCH_TESTIMONIES_START,
		fetchTestimonies
	);
}

export function* createTestimony({ payload: { testimonyData, history } }) {
	let formData = new FormData();
	formData.append("testimony", testimonyData.testimonyEN);
	formData.append("sender", testimonyData.personNameEN);
	formData.append("testimony_title_indo", testimonyData.testimonyEN);
	formData.append("sender_indo", testimonyData.personNameEN);
	formData.append("category", testimonyData.category);
	formData.append("image", testimonyData.image[0]);

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/testimony/add-testimony`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(createTestimonySuccess());
		history.push("/admin/testimonies");
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTestimonyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createTestimonyFailure(errorMessage));
	}
}

export function* onCreateTestimonyStart() {
	yield takeLatest(
		TestimonyActionTypes.CREATE_TESTIMONY_START,
		createTestimony
	);
}

export function* fetchTestimony({ payload }) {
	const { uuid } = payload;
	try {
		const response = yield axios({
			method: "GET",
			url: `${baseUrlApi}/testimony/uuid/${uuid}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		const testimony = response.data.testimonials;

		yield put(getTestimonySuccess(testimony));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTestimonyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(getTestimonyFailure(errorMessage));
	}
}

export function* onFetchTestimonyStart() {
	yield takeLatest(
		TestimonyActionTypes.FETCH_TESTIMONY_START,
		fetchTestimony
	);
}

export function* updateTestimony({ payload: { testimonyData, history } }) {
	let formData = new FormData();
	formData.append("testimony_id", testimonyData.id);
	formData.append("testimony", testimonyData.testimonyEN);
	formData.append("sender", testimonyData.personNameEN);
	formData.append("testimony_title_indo", testimonyData.testimonyID);
	formData.append("sender_indo", testimonyData.personNameID);
	formData.append("category", testimonyData.category);
	if (testimonyData.image.length > 0) {
		formData.append("image_url", testimonyData.image[0]);
	}

	try {
		const response = yield axios({
			method: "POST",
			url: `${baseUrlApi}/testimony/update-testimony`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(updateTestimonySuccess());
		const snackbarData = {
			message: "Update Success!",
			color: "success",
			place: "bl",
		};
		yield put(openSnackbar(snackbarData));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTestimonyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateTestimonyFailure(errorMessage));
	}
}

export function* onUpdateTestimonyStart() {
	yield takeLatest(
		TestimonyActionTypes.UPDATE_TESTIMONY_START,
		updateTestimony
	);
}

export function* deleteTestimony({ payload }) {
	const { testimonyId, category } = payload;

	try {
		const response = yield axios({
			method: "DELETE",
			url: `${baseUrlApi}/testimony/delete/${testimonyId}`,
		});

		if (response.data.code !== 200 && response.data.code !== 201) {
			throw new Error(response.data.message);
		}
		yield put(deleteTestimonySuccess());
		yield put(fetchAllTestimoniesStart({ category }));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearTestimonyError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(deleteTestimonyFailure(errorMessage));
	}
}

export function* onDeleteTestimonyStart() {
	yield takeLatest(
		TestimonyActionTypes.DELETE_TESTIMONY_START,
		deleteTestimony
	);
}

export function* testimonySagas() {
	yield all([
		call(onFetchTestimoniesStart),
		call(onCreateTestimonyStart),
		call(onFetchTestimonyStart),
		call(onUpdateTestimonyStart),
		call(onDeleteTestimonyStart),
	]);
}
