import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	language: {
		fontWeight: "bold",
		fontSize: "16px",
		textDecoration: "underline",
		display: "grid",
		gridAutoFlow: "column",
		gridColumnGap: "5px",
		cursor: "pointer",
	},
	languageArrowIcon: {
		transition: "all 0.7s cubic-bezier(0.19, 1, 0.22, 1)",

		"&.active": {
			transform: "rotate(180deg)",
		},
	},
	menuContainer: {
		position: "absolute",
		minWidth: "150px",

		// left: 0,
		// right: 0,
		top: "92px",
		background: "#FFFFFF",
		boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
		borderRadius: "12px",
		height: 0,
		overflow: "hidden",
		transition: "all 0.7s cubic-bezier(0.19, 1, 0.22, 1)",
		padding: "0 20px",

		"&.opened": {
			height: "108px",
		},
	},
	iconContainer: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
	},
	text: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		fontWeight: "bold",
		fontSize: "16px",
		lineHeight: "16px",

		"&.active": {
			textDecorationLine: "underline",
		},
	},
	languageContainer: {
		cursor: "pointer",
		marginTop: "10px",
		marginBottom: "10px",

		"&:first-child": {
			marginTop: "20px",
		},
		"&:last-child": {
			marginBottom: "20px",
		},
	},
}));
