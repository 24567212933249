import NewsActionTypes from "./news.types";

// Fetch All
export const fetchAllNewsStart = (filters) => ({
	type: NewsActionTypes.FETCH_ALL_NEWS_START,
	payload: filters,
});

export const fetchAllNewsSuccess = (news) => ({
	type: NewsActionTypes.FETCH_ALL_NEWS_SUCCESS,
	payload: news,
});

export const fetchAllNewsFailure = (error) => ({
	type: NewsActionTypes.FETCH_ALL_NEWS_FAILURE,
	payload: error,
});

// Fetch One
export const fetchNewsStart = (newsId) => ({
	type: NewsActionTypes.FETCH_NEWS_START,
	payload: newsId,
});

export const fetchNewsSuccess = (news) => ({
	type: NewsActionTypes.FETCH_NEWS_SUCCESS,
	payload: news,
});

export const fetchNewsFailure = (error) => ({
	type: NewsActionTypes.FETCH_NEWS_FAILURE,
	payload: error,
});

// Create
export const createNewsStart = ({ data, history }) => ({
	type: NewsActionTypes.CREATE_NEWS_START,
	payload: { data, history },
});

export const createNewsSuccess = () => ({
	type: NewsActionTypes.CREATE_NEWS_SUCCESS,
});

export const createNewsFailure = (error) => ({
	type: NewsActionTypes.CREATE_NEWS_FAILURE,
	payload: error,
});

// Update
export const updateNewsStart = ({ data, history }) => ({
	type: NewsActionTypes.UPDATE_NEWS_START,
	payload: { data, history },
});

export const updateNewsSuccess = () => ({
	type: NewsActionTypes.UPDATE_NEWS_SUCCESS,
});

export const updateNewsFailure = (error) => ({
	type: NewsActionTypes.UPDATE_NEWS_FAILURE,
	payload: error,
});

// Delete
export const deleteNewsStart = (dataId) => ({
	type: NewsActionTypes.DELETE_NEWS_START,
	payload: dataId,
});

export const deleteNewsSuccess = () => ({
	type: NewsActionTypes.DELETE_NEWS_SUCCESS,
});

export const deleteNewsFailure = (error) => ({
	type: NewsActionTypes.DELETE_NEWS_FAILURE,
	payload: error,
});

// Upload Temp File
export const uploadTempFileStart = ({ file, onSuccess, onFailure }) => ({
	type: NewsActionTypes.UPLOAD_TEMP_FILE_START,
	payload: { file, onSuccess, onFailure },
});

export const uploadTempFileSuccess = () => ({
	type: NewsActionTypes.UPLOAD_TEMP_FILE_SUCCESS,
});

export const uploadTempFileFailure = (error) => ({
	type: NewsActionTypes.UPLOAD_TEMP_FILE_FAILURE,
	payload: error,
});

export const clearNewsError = () => ({
	type: NewsActionTypes.CLEAR_ERROR,
});
